import { FC, useCallback, useEffect, useState } from 'react';

import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { generateNewVerifyToken, verifyPhone } from 'js/redux/account/actions';

import { Form, FormInstance } from 'antd';

import Box from 'js/components/Box';
import SmsCodeInput from 'js/components/SmsCodeInput';
import { PrevBtn, SubmitBtn } from 'js/components/Buttons';
import BtnWrapper from 'js/components/BtnWrapper';
import { routes } from 'js/routes';

import 'js/views/Register/Sms/styles.scss';

type SmsData = {
  smsCode: string;
};

type Props = {
  loading: boolean;
  phone: string;
  send: boolean;
  active: boolean;
  edited: boolean;
};

const Sms: FC<Props> = ({ loading, phone, send, active, edited }) => {
  const codeLength = 4;
  const [form]: [FormInstance<SmsData>] = Form.useForm();

  const dispatch: Dispatch = useDispatch();

  const [error, setError] = useState('');

  const handleSendNew = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      form.setFieldsValue({ smsCode: '' });
      if (error) setError('');
      dispatch(generateNewVerifyToken({ phone }));
    },
    [phone, dispatch, form, error]
  );

  const handleSubmit = (values: SmsData) => {
    dispatch(verifyPhone(values));
  };

  useEffect(() => {
    if (phone && !send && !loading && !active && !edited)
      dispatch(generateNewVerifyToken({ phone }));
  }, [phone, send, loading, dispatch, active, edited]);

  return (
    <Box type="content" className="sms-form">
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item name="smsCode" initialValue="">
          <SmsCodeInput
            error={error}
            codeLength={codeLength}
            placeholder="-"
            onChange={(val) => {
              if (error) setError('');
              return val;
            }}
          />
        </Form.Item>

        <button className="sms-form__send-new" onClick={handleSendNew}>
          Wyślij nowy kod
        </button>

        <BtnWrapper>
          <PrevBtn to={routes.editNumber}>Edytuj numer</PrevBtn>
          <SubmitBtn loading={loading}>Przejdź dalej</SubmitBtn>
        </BtnWrapper>
      </Form>
    </Box>
  );
};

export default Sms;
