import { REHYDRATE } from 'redux-persist';
import { RehydrateRootState } from 'js/redux/reducers';
import {
  SmeoEnum,
  SmeoActionTypes,
  SmeoApiTypes,
  SmeoState,
  DecisionEnum,
} from 'js/redux/smeo/types';

import { notification } from 'antd';
import { getDecision, getStep } from 'js/redux/smeo/helpers';

export const INIT_STATE: SmeoState = {
  actualStep: 0,
  limit: null,
  limitOnline: null,
  bankAccounts: [],
  consents: [],
  loading: false,
  processNumber: '',
  error: false,
  agreement: {
    data: '',
    name: '',
  },
  decision: '',
};

export default function reducer(
  state = INIT_STATE,
  action: SmeoActionTypes | SmeoApiTypes | RehydrateRootState
): SmeoState {
  if (state === undefined) {
    return INIT_STATE;
  }

  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        ...action.payload?.Report,
        actualStep: 0,
        loading: false,
      };

    // GET CONSENTS
    case SmeoEnum.GET_SMEO_CONSENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SmeoEnum.GET_SMEO_CONSENTS_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        loading: false,
      };

    case SmeoEnum.GET_SMEO_CONSENTS_FAILURE:
      notification['error']({
        message: 'Błąd podczas pobierania danych',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    // POST CONSENTS
    case SmeoEnum.POST_SMEO_CONSENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SmeoEnum.POST_SMEO_CONSENTS_SUCCESS:
      return {
        ...state,
        actualStep:
          action.payload.data.success &&
          action.payload.data.resultType === 'Success'
            ? 2
            : 1,
        loading: false,
      };

    case SmeoEnum.POST_SMEO_CONSENTS_FAILURE:
      notification['error']({
        message: 'Błąd podczas wysyłania zapytania',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    //VERIFY APPLICATION
    case SmeoEnum.GET_COMPANY_VERIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case SmeoEnum.GET_COMPANY_VERIFICATION_SUCCESS:
      return {
        ...state,
        actualStep:
          action.payload.data.success &&
          action.payload.data.resultType === 'Success' &&
          action.payload.data.decision === DecisionEnum.POSITIVE
            ? 3
            : 2,
        decision: action.payload.data.decision,
        loading: false,
      };

    case SmeoEnum.GET_COMPANY_VERIFICATION_FAILURE:
      notification['error']({
        message: 'Błąd podczas weryfikacji',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
        error: true,
      };

    //SUBMIT APPLICATION
    case SmeoEnum.SUBMIT_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SmeoEnum.SUBMIT_APPLICATION_SUCCESS:
      return {
        ...state,
        actualStep: 4,
        loading: false,
      };

    case SmeoEnum.SUBMIT_APPLICATION_FAILURE:
      notification['error']({
        message: 'Błąd podczas wysyłania zapytania',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    // GET PROCESS APP
    case SmeoEnum.GET_PROCESS_APP_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case SmeoEnum.GET_PROCESS_APP_SUCCESS:
      return {
        ...state,
        limit: action.payload.data.limit,
        limitOnline: action.payload.data.limitOnline,
        bankAccounts: action.payload.data.bankAccountNumbers,
        decision: action.payload.data.decision,
        actualStep:
          action.payload.data.success &&
          action.payload.data.resultType === 'Success' &&
          action.payload.data.decision === DecisionEnum.POSITIVE
            ? 5
            : 4,
        loading: false,
      };

    case SmeoEnum.GET_PROCESS_APP_FAILURE:
      notification['error']({
        message: 'Błąd podczas pobierania danych',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
        error: true,
      };

    // SUBMIT CREATE CONTRACT
    case SmeoEnum.SUBMIT_CREATE_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case SmeoEnum.SUBMIT_CREATE_CONTRACT_SUCCESS:
      return {
        ...state,
        actualStep:
          action.payload.data.success &&
          action.payload.data.resultType === 'Success'
            ? 7
            : 6,
        loading: false,
      };

    case SmeoEnum.SUBMIT_CREATE_CONTRACT_FAILURE:
      notification['error']({
        message: 'Błąd wysyłania danych',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
        error: true,
      };

    // GET CREATE CONTRACT
    case SmeoEnum.GET_CREATE_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case SmeoEnum.GET_CREATE_CONTRACT_SUCCESS:
      return {
        ...state,
        actualStep:
          action.payload.data.success &&
          action.payload.data.resultType === 'Success'
            ? 8
            : 7,
        loading: false,
      };

    case SmeoEnum.GET_CREATE_CONTRACT_FAILURE:
      notification['error']({
        message: 'Błąd wysyłania danych',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
        error: true,
      };

    // GET CONTRACT
    case SmeoEnum.GET_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case SmeoEnum.GET_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        agreement: {
          name: action.payload.data.fileName,
          data: action.payload.data.contractFileDataBase64,
        },
      };

    case SmeoEnum.GET_CONTRACT_FAILURE:
      notification['error']({
        message: 'Wystąpił błąd podczas pobierania wniosku',
      });
      return {
        ...state,
        loading: false,
        error: true,
      };

    // SIGN IN CONTRACT
    case SmeoEnum.SIGN_IN_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SmeoEnum.SIGN_IN_CONTRACT_SUCCESS:
      return {
        ...state,
        actualStep: 9,
        loading: false,
      };

    case SmeoEnum.SIGN_IN_CONTRACT_FAILURE:
      notification['error']({
        message: 'Błąd tworzenia wniosku',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    // GET PROGRESS
    case SmeoEnum.GET_PROGRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SmeoEnum.GET_PROGRESS_SUCCESS:
      const data = action.payload.data;
      const decision = getDecision(data);
      const step = getStep(data);

      return {
        ...state,
        processNumber: data.processNumber,
        bankAccounts: data?.processApplication?.bankAccountNumbers || [],
        limit: data?.processApplication?.limit || null,
        limitOnline: data?.processApplication?.limitOnline || null,
        agreement: {
          name: data?.getContract?.fileName || '',
          data: data?.getContract?.contractFileDataBase64 || '',
        },
        decision: decision,
        actualStep: step,
        loading: false,
      };

    case SmeoEnum.GET_PROGRESS_FAILURE:
      return {
        ...state,
        loading: false,
        actualStep: 1,
      };

    case SmeoEnum.SUBMIT_LIMIT:
      return {
        ...state,
        actualStep: 6,
      };

    default:
      return state;
  }
}
