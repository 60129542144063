import { rest } from 'msw';
import moment from 'moment';

import {
  ReportActionTypes,
  ReportApiTypes,
  ReportEnum,
} from 'js/redux/report/types';
import { EngineTypeEnum } from 'js/interfaces/report';

import {
  AdminActionTypes,
  AdminApiTypes,
  AdminEnum,
} from 'js/redux/admin/types';
import * as dbApp from 'js/mocks/db/application';
import * as dbReport from 'js/mocks/db/report';
import * as dbAccount from 'js/mocks/db/account';

import { getAccount, getToken } from '../helpers';

type GenerateReportRequest = Extract<
  ReportActionTypes,
  { type: ReportEnum.GENERATE_REPORT_REQUEST }
>['data'];

type GenerateReportResponse = Extract<
  ReportApiTypes,
  | { type: ReportEnum.GENERATE_REPORT_SUCCESS }
  | { type: ReportEnum.GENERATE_REPORT_FAILURE }
>['payload'];

type GetReportResponse = Extract<
  ReportApiTypes,
  | { type: ReportEnum.GET_REPORT_SUCCESS }
  | { type: ReportEnum.GET_REPORT_FAILURE }
>['payload'];

type GetReportsResponse = Extract<
  ReportApiTypes,
  | { type: ReportEnum.GET_REPORTS_SUCCESS }
  | { type: ReportEnum.GET_REPORTS_FAILURE }
>['payload'];

type SearchUserRequest = Extract<
  AdminActionTypes,
  { type: AdminEnum.SEARCH_USER_REQUEST }
>['data'];

type SearchUserResponse = Extract<
  AdminApiTypes,
  | { type: AdminEnum.SEARCH_USER_SUCCESS }
  | { type: AdminEnum.SEARCH_USER_FAILURE }
>['payload'];

type RefreshReportResponse = Extract<
  ReportApiTypes,
  | { type: ReportEnum.REFRESH_REPORT_SUCCESS }
  | { type: ReportEnum.REFRESH_REPORT_FAILURE }
>['payload'];

export const reportHandlers = [
  rest.post<GenerateReportRequest, GenerateReportResponse>(
    process.env.API_URL + '/report/generate',
    (req, res, ctx) => {
      const { applicationId } = req.body;
      const account = getAccount(req);
      if (!applicationId)
        return res(
          ctx.status(422),
          ctx.json({
            status: 422,
            code: 'Failed',
            errors: [''],
          })
        );

      dbApp.update(applicationId, {
        hasKontomatik: true,
        hasReport: false,
        hasPendingReport: true,
      });

      if (account?.user)
        dbReport.createRiskbite({
          applicationId,
          company: account.company,
          user: account.user,
          _token: getToken(req),
          rating: 2,
          grantedAmountMax: 5000,
          date: moment().format('DD-MM-YYYY'),
          engineType: EngineTypeEnum.RiskBite,
          expirationDate: moment(moment(), 'DD-MM-YYYY')
            .add(14, 'days')
            .format('DD-MM-YYYY'),
        });

      dbApp.update(applicationId, { hasReport: true, hasPendingReport: false });

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: '',
        })
      );
    }
  ),

  rest.get<never, GetReportResponse>(
    process.env.API_URL + '/report/:id',
    (req, res, ctx) => {
      const { id } = req.params;

      const report = dbReport.read(+id);

      if (report)
        return res(
          ctx.status(200),
          ctx.json({
            status: 200,
            code: 'OK',
            data: {
              ...report,
              user: report.user,
              company: report.company,
            },
          })
        );

      return res(
        ctx.status(404),
        ctx.json({
          status: 422,
          code: 'Failed',
          errors: ['Nie znaleziono raportu o podanym id'],
        })
      );
    }
  ),

  rest.get<never, GetReportsResponse>(
    process.env.API_URL + '/report',
    (req, res, ctx) => {
      const reports = dbReport.readAll(getToken(req));

      if (!reports) return res(ctx.status(204));

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: reports,
        })
      );
    }
  ),

  rest.post<SearchUserRequest, SearchUserResponse>(
    process.env.API_URL + '/report/search',
    (req, res, ctx) => {
      const { email } = req.body;

      const reports = dbReport.findByEmail(email);
      const account = dbAccount.readByEmail(email);
      const company = reports[0].company;

      if (!account) {
        return res(
          ctx.status(422),
          ctx.json({
            status: 422,
            code: 'Unprocessable Entity',
            errors: ['Nie odnaleziono użytkownika o podanym email.'],
          })
        );
      }

      const user = {
        ...account.user,
        id: account.userId,
        createdAt: '19.05.2021',
        canCreateApplication: false,
      };

      const searchUserData = {
        user,
        company,
        reports,
        applications: [],
        appliedOffers: [],
      };

      if (searchUserData)
        return res(
          ctx.status(200),
          ctx.json({
            status: 200,
            code: 'OK',
            data: [searchUserData],
          })
        );

      return res(
        ctx.status(422),
        ctx.json({
          status: 422,
          code: 'Unprocessable Entity',
          errors: ['Nie odnaleziono raportów dla podanego nip.'],
        })
      );
    }
  ),
  rest.post<never, RefreshReportResponse>(
    process.env.API_URL + '/report/refresh',
    (req, res, ctx) => {
      const { reportId } = req.body;
      const data = dbReport.findById(reportId);

      if (data)
        return res(
          ctx.status(200),
          ctx.json({
            status: 200,
            code: 'OK',
            data: 'string',
          })
        );

      return res(
        ctx.status(422),
        ctx.json({
          status: 422,
          code: 'Unprocessable Entity',
          errors: ['Nie odnaleziono raportów dla podanego nip.'],
        })
      );
    }
  ),
];
