import { FC } from 'react';
import { AdaptedPotentialOffer, CompanyEnum } from 'js/interfaces/report';

import PotentialOfferBox from 'js/components/Report/PotentialOfferBox';
import SmeoBox from 'js/views/Smeo/Box';

import 'js/components/Report/OffersList/styles.scss';

type Props = {
  offers: AdaptedPotentialOffer[];
};

const FactoringOffersList: FC<Props> = ({ offers }) => {
  return (
    <section className="report-offers">
      {!!offers?.length &&
        offers.map((potentialOffer) =>
          potentialOffer.company === CompanyEnum.Smeo ? (
            <SmeoBox key={potentialOffer.link} data={potentialOffer} />
          ) : (
            <PotentialOfferBox
              key={potentialOffer.link}
              data={potentialOffer}
            />
          )
        )}
    </section>
  );
};

export default FactoringOffersList;
