import { User } from 'js/interfaces/account';
let users: { [email: string]: User } = {};

export const clearUsers = () => {
  users = {};
};

type UserData = Partial<Omit<User, 'email' | 'phone'>> &
  Pick<User, 'email' | 'phone'>;

function create(data: UserData) {
  users[data.email] = {
    apartmentNo: null,
    city: null,
    firstName: null,
    houseNo: null,
    idCard: null,
    isActive: false,
    lastName: null,
    maritalStatus: null,
    pesel: null,
    postCode: null,
    street: null,
    isWealthonUser: false,
    ...data,
  };
  return users[data.email];
}

function read(email: string) {
  if (!Object.keys(users).includes(email)) return null;
  return users[email];
}

type UpdatedUser<T> = T & {
  [P in Exclude<keyof T, keyof Partial<User>>]: never;
};

function update<T extends Partial<User>>(
  email: string,
  updates: UpdatedUser<T>
) {
  if (!users[email]) return null;
  return Object.assign(users[email], updates);
}

export { create, read, update };
