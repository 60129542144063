import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import moment from 'moment';

import { OfferDescTypeEnum, AdaptedReport } from 'js/interfaces/report';

import { createApp } from 'js/redux/account/actions';

import {
  ExpirationInfoBox,
  ErrorBox,
  KontomatikBox,
} from 'js/components/Report/InfoBoxes';
import NoOfferBox from 'js/components/Report/NoOfferBox';
import ReportRating from 'js/components/Report/Rating';
import ReportOffersList from 'js/components/Report/OffersList';
import FactoringOffersList from 'js/components/Report/FactoringList';
import ReportAssessment from 'js/components/Report/Assesment';
import ReportInfo from 'js/components/Report/Info';
import ReportDesc from 'js/components/Report/ReportDesc';
import ExpiredModal, {
  ExpiredModalType,
} from 'js/components/Report/ExpiredModal';
import PotentialOfferBox from 'js/components/Report/PotentialOfferBox';

import { dateFormat } from 'js/constants';
import { getTimeDiff } from 'js/helpers/timeDiff';

import 'js/pages/Report/styles.scss';

type Props = {
  data: AdaptedReport;
  newestData?: AdaptedReport;
  loading: boolean;
};

const ReportView: FC<Props> = ({ data, newestData, loading }) => {
  const dispatch: Dispatch = useDispatch();

  const [showExpiration, setShowExpiration] = useState(false);

  const canRefresh = moment(moment(), dateFormat).isAfter(
    moment(newestData?.expirationDate || data?.expirationDate, dateFormat),
    'day'
  );

  const isNewest = data?.id === newestData?.id;

  const dateDiff =
    getTimeDiff(newestData?.expirationDate || data.expirationDate, moment()) ??
    0;

  const hasOffers = !![...data.offers, ...data.loanPotentialOffers].length;

  const handleRefresh = useCallback(() => {
    canRefresh ? dispatch(createApp()) : setShowExpiration(true);
  }, [canRefresh, dispatch]);

  return (
    <>
      {(newestData?.date || data?.date) && (
        <ExpiredModal
          show={showExpiration}
          onClose={() => setShowExpiration(false)}
          date={newestData?.date || data.date}
          expirationDate={newestData?.expirationDate || data.expirationDate}
          type={ExpiredModalType.notAllowed}
        />
      )}

      {data && <ReportRating data={data} />}

      {!canRefresh && <KontomatikBox />}

      <ReportDesc type={OfferDescTypeEnum.offer} />

      {isNewest && <ExpirationInfoBox diff={dateDiff} />}

      {canRefresh && isNewest && <ErrorBox onClick={handleRefresh} />}

      {hasOffers ? (
        <>
          {!!data.offers?.length && (
            <ReportOffersList
              data={data}
              loading={loading}
              canRefresh={canRefresh}
              onRefresh={handleRefresh}
            />
          )}
          {!!data.loanPotentialOffers?.length &&
            data.loanPotentialOffers?.map((potentialOffer) => (
              <PotentialOfferBox
                key={potentialOffer.company}
                data={potentialOffer}
              />
            ))}
        </>
      ) : (
        <NoOfferBox />
      )}

      <ReportDesc type={OfferDescTypeEnum.factoring} />

      {isNewest && <ExpirationInfoBox diff={dateDiff} />}

      {/* <ReportOffersLoading /> */}

      {!!data.factoringPotentialOffers?.length ? (
        <FactoringOffersList offers={data.factoringPotentialOffers} />
      ) : (
        <NoOfferBox />
      )}

      {!!data.summaries?.length && (
        <>
          <h3 id="details" className="report-desc__heading">
            Składowe Twojej oceny
          </h3>
          <ReportAssessment summaries={data.summaries} />
        </>
      )}
      <h3 className="report-desc__heading">Informacje o raporcie</h3>

      {data.client && (
        <ReportInfo
          client={data.client}
          date={data.date}
          onClick={handleRefresh}
        />
      )}
      {/* <PrintButton /> */}
    </>
  );
};

export default ReportView;
