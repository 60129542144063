import moment from 'moment';
import { dateFormat } from 'js/constants';
import {
  App,
  AppState,
  UserState,
  User,
  Company,
  ConsentRaw,
  ConsentStepEnum,
} from 'js/interfaces/account';
import { APP_INIT_STATE } from 'js/redux/account/reducer';

export const getStep = (
  app: AppState,
  user: User,
  company: Company,
  consentsRaw: ConsentRaw[]
) => {
  const requiredAgreementIds = consentsRaw
    .filter(
      (consent) => consent.required && consent.step === ConsentStepEnum.consent
    )
    .map((el) => el.id);

  if (
    user.isWealthonUser &&
    (app.hasReport || app.hasPendingReport || app.hasKontomatik) &&
    user.isActive
  )
    return -1;
  if (!user.isActive) return 1;
  if (!company.nip) return 2;
  if (!company.monthlyIncome) return 4;
  if (!user.pesel) return 5;
  if (!user.maritalStatus) return 6;
  if (!user.city) return 7;
  if (
    !app.consents.length ||
    app.consents.some(
      (consent) => requiredAgreementIds.includes(consent.id) && !consent.checked
    )
  )
    return 8;
  if (!app.hasPendingReport && !app.hasReport) return 9;
  return -1;
};

export const getApp = (applications: App[]) => {
  if (applications?.length > 0) {
    const sorted = applications.sort(
      (a: App, b: App) =>
        moment(b.createdAt, dateFormat).unix() -
        moment(a.createdAt, dateFormat).unix()
    );
    return sorted[0];
  }
  return APP_INIT_STATE;
};

export const userStateAdapter = (user: User): UserState => ({
  apartmentNo: user.apartmentNo ?? '',
  city: user.city ?? '',
  email: user.email ?? '',
  firstName: user.firstName ?? '',
  houseNo: user.houseNo ?? '',
  idCard: user.idCard ?? '',
  isActive: user.isActive,
  lastName: user.lastName ?? '',
  maritalStatus: user.maritalStatus ?? null,
  pesel: user.pesel ?? '',
  phone: user.phone ?? '',
  postCode: user.postCode ?? '',
  street: user.street ?? '',
  isWealthonUser: user.isWealthonUser ?? false,
});
