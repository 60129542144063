import { FC, useMemo } from 'react';
import { IBoxContent } from 'js/interfaces';
import { OfferDescTypeEnum } from 'js/interfaces/report';

import 'js/components/Report/ReportDesc/styles.scss';

type Props = {
  type: OfferDescTypeEnum;
};

const ReportDesc: FC<Props> = ({ type }) => {
  const headings: IBoxContent = useMemo(() => {
    return {
      offer: 'Oferty kredytów i pożyczek',
      factoring: 'Finansowanie faktur',
    };
  }, []);

  const descriptions: IBoxContent = useMemo(() => {
    return {
      offer:
        'Kwota kredytu/pożyczki jest wyliczona na podstawie Twojej prognozowanej zdolności kredytowej i może być różna w różnych instytucjach finansujących.',
      factoring:
        'Finansowanie faktur, czyli faktoring, to usługa, która pozwala szybciej  otrzymać środki za wystawione faktury - jeszcze zanim Twoi klienci je zapłacą. Usługa dotyczy faktur z odroczonym terminem płatności.',
    };
  }, []);

  return (
    <div className="report-desc">
      <h3 className="report-desc__heading">{headings[type]}</h3>
      <p className="report-desc__description">{descriptions[type]}</p>
    </div>
  );
};

export default ReportDesc;
