import { FC, useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { Data } from 'js/pages/Smeo/consents';
import { postSmeoConsents } from 'js/redux/smeo/actions';

import Validator from 'js/helpers/Validator';

import { Store } from 'antd/lib/form/interface';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RuleObject } from 'antd/lib/form';
import { Form, Checkbox, Divider, FormInstance } from 'antd';

import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import Provider from 'js/components/Provider';
import { SubmitBtn } from 'js/components/Buttons';

import 'js/views/Smeo/Consents/styles.scss';

type Props = {
  loading: boolean;
  data: Data[];
  applicationId: number;
  processNumber: string;
  nip: string;
  email: string;
  phoneNumber: string;
};

type FormData = {
  [id: number]: boolean;
};

const SmeoConsents: FC<Props> = ({
  loading,
  data,
  applicationId,
  processNumber,
  nip,
  email,
  phoneNumber,
}) => {
  const [form]: [FormInstance<FormData>] = Form.useForm();
  const dispatch: Dispatch = useDispatch();

  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    const hasExactLength = checkedList.length === data.length;
    setIndeterminate(!!checkedList.length && !hasExactLength);
    setCheckAll(hasExactLength);
  }, [checkedList, data]);

  const handleCheck = (e: CheckboxChangeEvent) => {
    const id = Number(e.target.id);
    setCheckedList((state) =>
      state.includes(id) ? state.filter((el) => el !== id) : [...state, id]
    );
  };

  const handleCheckAll = (e: CheckboxChangeEvent) => {
    const list = e.target.checked ? data.map((el) => el.consentId) : [];
    const output = data.reduce((a: Store, item) => {
      a[item.consentId] = e.target.checked;
      return a;
    }, {});

    setCheckAll(e.target.checked);
    setCheckedList(list);
    form.setFieldsValue(output);
  };

  const handleSubmit = () => {
    const newData = data.filter((consent) =>
      checkedList.includes(consent.consentId)
    );

    dispatch(
      postSmeoConsents({
        applicationId,
        processNumber,
        nip,
        email,
        phoneNumber,
        consents: newData,
      })
    );
  };

  return (
    <Form form={form} onFinish={handleSubmit} className="consents-form">
      {data.map((el) => (
        <Box
          type="content"
          key={el.consentId}
          className={`consents-form__checkbox  ${
            el.required ? 'consents-form__checkbox--required' : ''
          }`}
        >
          <div
            className="consents-form__checkbox__content"
            dangerouslySetInnerHTML={{ __html: el.content }}
          />
          <Divider />
          <Form.Item
            name={el.consentId}
            valuePropName="checked"
            initialValue={false}
            required={el.required}
            rules={[
              {
                required: el.required,
                validator: (_: RuleObject, value: boolean) =>
                  new Validator(value)
                    .noCheckboxData('Zgoda jest wymagana', el.required)
                    .res(),
              },
            ]}
          >
            <Checkbox onChange={handleCheck}>Tak</Checkbox>
          </Form.Item>
        </Box>
      ))}
      <Provider company="smeo" />

      <BtnWrapper className="consents-form__button-wrapper">
        <Checkbox
          checked={checkAll}
          indeterminate={indeterminate}
          onChange={handleCheckAll}
        >
          Zaznacz wszystkie zgody
        </Checkbox>
        <SubmitBtn loading={loading}>Wyślij wniosek</SubmitBtn>
      </BtnWrapper>
    </Form>
  );
};

export default SmeoConsents;
