import { FC } from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Box from 'js/components/Box';

import 'js/components/Loader/styles.scss';

type Props = {
  text?: string;
  low?: boolean;
  noBg?: boolean;
};

const Loader: FC<Props> = ({ text, low = false, noBg = false }) => (
  <Box
    type="content"
    className={`loader ${low ? 'loader--low' : ''} ${
      noBg ? 'loader--noBg' : ''
    }`}
  >
    <>
      {!!text && <p className="loader__info">{text}</p>}
      <Spin className="loader__spin" indicator={<LoadingOutlined spin />} />
    </>
  </Box>
);

export default Loader;
