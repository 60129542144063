import { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'js/redux/reducers';
import { clearOffer } from 'js/redux/report/actions';
import { routes } from 'js/routes';

import { Result, Button } from 'antd';
import { LikeTwoTone } from '@ant-design/icons';
import BtnWrapper from 'js/components/BtnWrapper';
import 'js/pages/Report/styles.scss';

const ThankYouPage: FC = () => {
  const dispatch: Dispatch = useDispatch();
  const { chosenOffer } = useSelector((state: RootState) => state.Report);
  const history = useHistory();

  const handleClick = () => {
    dispatch(clearOffer());
    history.push(routes.report);
  };

  const companyName = useMemo(
    () =>
      chosenOffer
        ? chosenOffer.charAt(0).toUpperCase() +
          chosenOffer.slice(1).toLowerCase()
        : '',
    [chosenOffer]
  );

  return (
    <div className="thank-page">
      <Result
        icon={<LikeTwoTone twoToneColor="#6D11BB" />}
        title={`Dziękujemy za zainteresowanie ofertą ${companyName}.`}
        subTitle="Nasz konsultant skontaktuje się z Tobą w ciągu 24ch godzin."
        extra={
          <BtnWrapper>
            <Button
              type="primary"
              onClick={handleClick}
              className="thank-page__btn"
            >
              Wróć do raportu
            </Button>
          </BtnWrapper>
        }
      />
    </div>
  );
};

export default ThankYouPage;
