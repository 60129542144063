import { FC, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'js/redux/reducers';
import { createApp, getUser } from 'js/redux/account/actions';
import routes from 'js/routes/routes';
import { registerOrder, smeoOrder } from 'js/routes/routeOrders';

import Box from 'js/components/Box';
import Loader from 'js/components/Loader';
import Steps from 'js/components/Steps';

import PublicRoute from 'js/routes/Route/public';
import RegisterRoute from 'js/routes/Route/register';
import ReportRoute from 'js/routes/Route/report';
import SmeoRoute from 'js/routes/Route/smeo';
import AdminRoute from 'js/routes/Route/admin';

//register step
import Login from 'js/pages/Register/login';
import Register from 'js/pages/Register/register';
import PasswordReset from 'js/pages/Register/reset-password';
import NewPassword from 'js/pages/Register/new-password';
import Verification from 'js/pages/Register/verification';
import EditNumber from 'js/pages/Register/edit-number';
import SelfData from 'js/pages/Data/self';

//data step
import CompanyData from 'js/pages/Data/company';
import Consents from 'js/pages/Data/consents';
import Nip from 'js/pages/Data/nip';
import PersonalData from 'js/pages/Data/personal';
import TransactionsHistory from 'js/pages/Data/transactions-history';
import CheckData from 'js/pages/Data/check-data';
import Address from 'js/pages/Data/address';

//payment
import PaymentCheckData from 'js/pages/Payment/check-data';
import Payment from 'js/pages/Payment/pay';

//report
import Report from 'js/pages/Report/report';
import Status from 'js/pages/Report/status';
import Thank from 'js/pages/Report/thank';

//welcome
import Welcome from 'js/pages/Welcome';

//account
import Account from 'js/pages/Account';

//admin
import AdminSearch from 'js/pages/Admin/search';
import AdminReport from 'js/pages/Admin/report';

//smeo
import SmeoConsents from 'js/pages/Smeo/consents';
import SmeoCompany from 'js/pages/Smeo/company';
import SmeoLimits from 'js/pages/Smeo/limits';
import SmeoBank from 'js/pages/Smeo/bank';
import SmeoAgreement from 'js/pages/Smeo/agreement';
import SmeoLoader from 'js/pages/Smeo/loader';
import SmeoFinish from 'js/pages/Smeo/finish';

const Routes: FC = () => {
  const {
    login,
    register,
    resetPassword,
    verify,
    editNumber,
    termsOfService,
    company,
    consents,
    nip,
    personal,
    self,
    transactions,
    payment,
    report,
    status,
    thank,
    home,
    check,
    address,
    paymentCheck,
    newPassword,
    account,
    smeoConsents,
    smeoCompanyData,
    smeoLimits,
    smeoBank,
    smeoAgreement,
    smeoLoading,
    smeoFinish,
    search,
    reportView,
    addAccounts,
  } = routes;

  const { token, loading, isAdmin } = useSelector(
    (state: RootState) => state.Auth
  );
  const {
    loading: accLoading,
    user,
    app,
  } = useSelector((state: RootState) => state.Account);
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    if (!isAdmin && token && !loading && !user.email) {
      dispatch(getUser());
    }
  }, [token, loading, dispatch, isAdmin, user.email]);

  useEffect(() => {
    if (!isAdmin && user.isActive && !loading && !app.id) {
      dispatch(createApp());
    }
  }, [user.isActive, loading, dispatch, isAdmin, app.id]);

  if (!isAdmin && accLoading && !user.email)
    return (
      <>
        <Steps active="register" />
        <Box type="heading">
          <h3>Ładowanie danych użytkownika</h3>
        </Box>
        <Loader />
      </>
    );

  return (
    <Switch>
      <PublicRoute exact path={home} component={Welcome} />

      {/* register step */}
      <PublicRoute exact path={login} component={Login} />
      <PublicRoute exact path={register} component={Register} />

      <PublicRoute exact path={resetPassword} component={PasswordReset} />
      <PublicRoute exact path={newPassword} component={NewPassword} />

      <PublicRoute exact path={termsOfService} component={Login} />

      {/* Account verification */}
      <RegisterRoute exact path={verify} component={Verification} />
      <RegisterRoute exact path={editNumber} component={EditNumber} />

      {/* data step */}
      <RegisterRoute exact path={nip} component={Nip} />
      <RegisterRoute exact path={check} component={CheckData} />
      <RegisterRoute exact path={company} component={CompanyData} />
      <RegisterRoute exact path={personal} component={PersonalData} />
      <RegisterRoute exact path={self} component={SelfData} />

      <RegisterRoute exact path={address} component={Address} />
      <RegisterRoute exact path={consents} component={Consents} />
      <RegisterRoute
        exact
        path={transactions}
        component={TransactionsHistory}
      />

      {/* payment */}
      <RegisterRoute exact path={payment} component={Payment} />
      <RegisterRoute exact path={paymentCheck} component={PaymentCheckData} />

      {/* report */}
      <ReportRoute exact path={report} component={Report} />
      <ReportRoute exact path={status} component={Status} />
      <ReportRoute exact path={thank} component={Thank} />
      <ReportRoute exact path={addAccounts} component={TransactionsHistory} />

      {/* account settings */}
      <ReportRoute exact path={account} component={Account} />

      {/* admin */}
      <AdminRoute exact path={search} component={AdminSearch} />
      <AdminRoute exact path={`${reportView}/:id`} component={AdminReport} />

      {/* smeo  */}
      <SmeoRoute exact path={smeoConsents} component={SmeoConsents} />
      <SmeoRoute exact path={smeoCompanyData} component={SmeoCompany} />
      <SmeoRoute exact path={smeoLimits} component={SmeoLimits} />
      <SmeoRoute exact path={smeoBank} component={SmeoBank} />
      <SmeoRoute exact path={smeoAgreement} component={SmeoAgreement} />
      <SmeoRoute exact path={smeoLoading} component={SmeoLoader} />
      <SmeoRoute exact path={smeoFinish} component={SmeoFinish} />
    </Switch>
  );
};

export { routes, registerOrder, smeoOrder };
export default Routes;
