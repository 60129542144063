import { Company, User } from 'js/interfaces/account';

export type ReportData = {
  applicationId: number;
  company: Company;
  date: string;
  expirationDate: string;
  id: number;
  user: User;
};

export type ReportRiskbiteData = ReportData & {
  engineType: EngineTypeEnum.RiskBite;
  grantedAmountMax: number;
  rating: number;
  summaries: ReportSummary[];
};

export type ReportFrsData = ReportData & {
  freeAmountBikKontoAgr: number;
  ratingSimplePlBpmnValue: number;
  engineType: EngineTypeEnum.FRS;
  variables: SummaryVariable[];
  trace: SummaryTrace[];
};

export type ReportTypeData = ReportRiskbiteData | ReportFrsData;

export type ReportListElement = Pick<
  ReportTypeData,
  'id' | 'applicationId' | 'date' | 'expirationDate'
>;

export type SummaryVariable = {
  name: string;
  value: number | string;
};

export type SummaryTrace = {
  step: string;
  outValue: string | boolean | number;
  outMessage: string;
  datetime: string;
};

export type ReportSummary = {
  code: string;
  codeValue: number;
  description: string | null;
  grade: Grade;
  indicator: string | null;
  recommendation: ReportRecommendation[];
} & ReportOutput;

export type OfferOutput =
  | ReportOfferType
  | ReportOfferType[]
  | { clientAge: number }
  | {
      masterDecision: string;
      potentialOffers?: PotentialOffer[];
    };

export type ReportOutput =
  | {
      codeType: CodeEnum.IB;
      output: Obligations | [];
    }
  | {
      codeType: CodeEnum.IT;
      output: Obligations | string[];
    }
  | {
      codeType: CodeEnum.ID;
      output: {
        amount: number;
        dataSource: string;
        entryDate: string;
        lenderName: string | null;
        productType: keyof typeof OtherTransactionsEnum;
      }[];
    }
  | {
      codeType: CodeEnum.IZ;
      output: {
        accounts: number;
        averageInflowCleared: number;
        currentDebt: number;
        dti: number;
        income: number;
      };
    }
  | {
      codeType: CodeEnum.IP;
      output: {
        count: number;
        deviation: number;
        from: string;
        indicator: string;
        inflowCleared: number;
        to: string;
      }[];
    }
  | {
      codeType: CodeEnum.IA;
      output: {
        inquiries: {
          pastKI: number;
          pastP: number;
          recentKI: number;
          recentP: number;
        };
        obligations: Obligations;
        paydayInfo: string | null;
      };
    }
  | {
      codeType: CodeEnum.IM;
      output: OfferOutput;
    };

type Obligations = {
  obligations: Obligation[];
  sum: number;
};

export type Obligation = {
  businessType: string;
  closeDate: string | null;
  creditAmount: number;
  currentBalance: number;
  currentDPD: number;
  currentDueAmount: number;
  installmentAmount: number;
  maxDPD: number;
  productType: keyof typeof TransactionTypeEnum;
  status: keyof typeof PaymentStatusEnum;
};

export type ReportRecommendation = {
  body: string | null;
  title: string;
};

export type ReportOfferType = {
  company: string;
  offer: ReportOfferData;
  productType:
    | 'InstallmentBusiness'
    | 'BridgingBusiness'
    | 'RiskReportBusiness'
    | 'InstallmentPersonal';
  potentialOffers?: PotentialOffer[];
};

export type ReportOfferData = {
  amountToPay: number;
  chance: number;
  grossAmount: number;
  installmentAmount: number;
  netAmount: number;
  tenor: number;
};

export type PotentialOffer = {
  iconUrl: string;
  lender: string;
  productCategory: `${ProductCategoryEnum}`;
  productId: string;
  productLink: string;
  productType: string;
};

// ENUMS

export enum CodeEnum {
  IB = 'IB',
  IT = 'IT',
  ID = 'ID',
  IZ = 'IZ',
  IP = 'IP',
  IA = 'IA',
  IM = 'IM',
}

export enum DirectionEnum {
  UP = 'Up',
  DOWN = 'Down',
  STABLE = 'Stable',
}

export enum CodeNameEnum {
  'IB' = 'Bieżące zaległości',
  'IT' = 'Terminowość',
  'ID' = 'Listy dłużników',
  'IZ' = 'Zadłużenie do przychodów (DTI)',
  'IP' = 'Dynamika przychodów',
  'IA' = 'Inna aktywność kredytowa',
  'IM' = '',
}

export enum CodeDescriptionEnum {
  'IB' = 'Tutaj dowiesz się, czy aktualnie masz opóźnienia w spłacie swoich zobowiązań kredytowych',
  'IT' = 'Dowiedz się, czy zawsze płacisz swoje zobowiązania na czas i jak to wpływa na scoring',
  'ID' = 'Sprawdź, czy widniejesz na liście dłużników',
  'IZ' = 'Ta sekcja pokazuje stosunek Twoich przychodów do obciążeń kredytowych',
  'IP' = 'Poznaj tendencje swoich przychodów w ostatnich miesiącach i jak to wpływa na scoring',
  'IA' = 'Sprawdź pozostałe czynniki wpływające na Twój scoring kredytowy. ',
  'IM' = '',
}

export enum CodeTableHeadingEnum {
  'IB' = 'Lista wpisów',
  'IT' = 'Pokazuje zapytania kredytowe oraz chwilówki. ',
  'ID' = 'Lista wpisów',
  'IZ' = '',
  'IP' = 'Przychód z 3 miesięcy',
  'IA' = 'Zobowiązania z instytucji pozabankowych.',
  'IM' = '',
}

export enum ReportProductTypeEnum {
  'InstallmentBusiness' = 'Raty biznesowe',
  'BridgingBusiness' = 'Pożyczka pomostowa',
  'RiskReportBusiness' = 'Raport ryzyka biznesowego',
  'InstallmentPersonal' = 'Raty personalne',
}

export enum CreditChanceEnum {
  'Brak',
  'Niska',
  'Średnia',
  'Wysoka',
}

export enum ChanceColorEnum {
  'none',
  'red',
  'yellow',
  'green',
}

export enum TransactionTypeEnum {
  'consumerCredit' = 'Kredyty na zakup',
  'noPurposeCredit' = 'Kredyty niecelowe',
  'housingCredit' = 'Kredyty mieszkaniowe',
  'staffCredit' = 'Kredyt pracowniczy',
  'revolvingCredit' = 'Kredyt odnawialny',
  'retailCard' = 'Karta detaliczna',
  'creditCard' = 'Karta kredytowa',
  'chargeCard' = 'Karta charge',
  'debitCard' = 'Karta debetowa',
  'debitLimit' = 'Limit debetowy w ROR',
  'mortgageCreditLine' = 'Hipoteczna linia kredytowa',
  'leasing' = 'Leasing',
  'tenancy' = 'Wynajem',
  'informalAccountDebit' = 'Bezumowny debet w koncie',
  'mailOrderSale' = 'Sprzedaż wysyłkowa',
  'onLineServices' = 'Usługi on-line',
  'telecommunication' = 'Telekomunikacyjne',
  'utilities' = 'Elektryczność, etc.',
  'insurancePolicy' = 'Ubezpieczenie',
  'smeo' = 'Faktoring',
  'workingCapitalCredit' = 'Kredyt obrotowy',
  'investmentCredit' = 'Kredyt inwestycyjny',
  'mortgageCredit' = 'Kredyt hipoteczny lub pożyczka hipoteczna',
  'securitiesPurchaseCredit' = 'Pożyczka',
  'billOfExchangeDiscount' = 'Dyskonto weksli',
  'creditLineForOpeningLettersOfCredit' = 'Linia kredytowa na otwieranie akredytyw',
  'shortTermCashCreditLine' = 'Kredyt płatniczy w rachunku bieżącym',
  'overdraftFacility' = 'Kredyt w rachunku bieżącym lub limit debetowy',
  'creditDebitCard' = 'Karta kredytowa lub płatnicza, debetowa z limitem',
  'bankGuarantee' = 'Gwarancja bankowa',
  'bankBail' = 'Poręczenie bankowe',
  'creditAsGuaranteeResult' = 'Kredyt w wyniku realizacji gwarancji / poręczenia',
  'multipurposeLimit' = 'Limit wielocelowy',
}

export enum OtherTransactionsEnum {
  'sale' = 'Inne',
  'Other' = 'Inne',
  'insurance' = 'Ubezpieczenie',
  'Alimony' = 'Alimony',
}

export enum PaymentStatusEnum {
  'open' = 'Transakcja aktywna / otwarta',
  'closed' = 'Transakcja zamknięta',
  'vindication' = 'Transakcja w windykacji',
  'debtCollection' = 'Transakcja w windykacji',
  'execution' = 'Transakcja w egzekucji',
  'recovered' = 'Transakcja odzyskana',
  'writtenOff' = 'Transakcja umorzona',
  'Current' = '-',
  'Delinquent' = '-',
}

export enum ProductCategoryEnum {
  Factoring = 'Factoring',
  BankLoan = 'Bank loan',
}

export enum CompanyEnum {
  Smeo = 'SMEO',
}

export enum OfferCategoryEnum {
  bankOffer = 1,
  factoring,
}

export enum EngineTypeEnum {
  FRS = 'frs',
  RiskBite = 'riskbite',
}

export enum OfferDescTypeEnum {
  offer = 'offer',
  factoring = 'factoring',
}

export type AdaptedPotentialOffer = {
  appId: number;
  type: string;
  offerType: OfferCategoryEnum;
  category: string;
  company: string;
  link?: string;
  logo: string;
  applied: boolean;
};

export type AdaptedOffer = Omit<
  AdaptedPotentialOffer,
  'category' | 'link' | 'type' | 'offerType'
> & {
  category: OfferCategoryEnum;
  grossAmount: string;
  tenor: number;
  installmentAmount: string;
  amountToPay: string;
  chance: number;
  productType: ReportProductTypeEnum;
};

export type AdaptedRecommendation = {
  title: string;
  description: string;
};

export type AdaptedMark = {
  value: string;
  description: string;
};

export type AdaptedObligation = {
  productType: string;
  status: string;
  currentDueAmount: number;
  currentDPD: number;
  maxDPD: number;
  dataSource: string;
  entryDate: string;
  amount: number;
  lenderName: string | null;
  creditAmount: number;
  installmentAmount: number;
  from: string;
  to: string;
  count: number;
  inflowCleared: number;
  indicator: string;
};

export type AdaptedSummary = {
  code: CodeEnum;
  name: string;
  grade: AdaptedGrade;
  gradeDescription: string;
  description: string;
  mark: AdaptedMark;
  recommendations: AdaptedRecommendation[];
  sum: number | null;
  averageInflowCleared: number | null;
  currentDebt: number | null;
  accounts: number | null;
  obligations: Partial<AdaptedObligation>[];
};

export type AdaptedReportClient = {
  nip: string;
  email: string;
  companyName: string;
  firstName: string;
  lastName: string;
};

export type AdaptedReport = {
  id: number;
  date: string;
  expirationDate: string;
  rating: number;
  amount: number; // grantedAmountMax
  amountIndicator: string; // offersIndicator
  offers: AdaptedOffer[];
  loanPotentialOffers: AdaptedPotentialOffer[];
  factoringPotentialOffers: AdaptedPotentialOffer[];
  summaries: AdaptedSummary[];
  client: AdaptedReportClient;
};

export type Grade = 'Niska' | 'Średnia' | 'Dobra';

export enum GradeValueEnum {
  'Dobra' = 'success',
  'Średnia' = 'warning',
  'Niska' = 'error',
}

export type AdaptedGrade = {
  name: Grade;
  value: GradeValueEnum;
};
