import { configureStore, Reducer } from '@reduxjs/toolkit';
import { AnyAction, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer, PERSIST } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import reducers, { RootState } from 'js/redux/reducers';
import rootSaga from 'js/redux/sagas';

const appReducer = combineReducers({ ...reducers });

const rootReducer: Reducer = (
  state: ReturnType<typeof appReducer>,
  action: AnyAction
) => {
  if (action.type === 'RESET_APP') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const getStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const persistConfig = { key: 'root', storage };

  const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [PERSIST],
        },
      }),
      sagaMiddleware,
    ],
  });
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};

const getTestStore = (
  preloadedState: Partial<RootState> | undefined = undefined
) => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      sagaMiddleware,
    ],
    preloadedState,
  });
  sagaMiddleware.run(rootSaga);

  return { store };
};

export { getStore, getTestStore };
