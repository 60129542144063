import { FC, useEffect } from 'react';
import { Form, Input, FormInstance } from 'antd';
import Box from 'js/components/Box';
import { CompanyData } from 'js/pages/Account';

type Props = {
  data: CompanyData;
};

const AccountCompany: FC<Props> = ({ data }) => {
  const [form]: [FormInstance<CompanyData>] = Form.useForm();

  useEffect(() => {
    data && form.setFieldsValue(data);
  }, [data, form]);

  return (
    <Box type="content" className="settings-form">
      <h2>Dane Twojej firmy</h2>

      <Form
        form={form}
        scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
      >
        <Form.Item name="nip" label="NIP">
          <Input disabled size="large" inputMode="numeric" maxLength={10} />
        </Form.Item>

        <Form.Item name="companyName" label="Nazwa firmy">
          <Input disabled size="large" />
        </Form.Item>
      </Form>
    </Box>
  );
};

export default AccountCompany;
