import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { RootState } from 'js/redux/reducers';
import { routes } from 'js/routes';

const AdminRoute: FC<RouteProps> = ({ component, path }) => {
  const { isAdmin } = useSelector((state: RootState) => state.Auth);

  if (isAdmin) return <Route path={path} component={component} />;

  return <Redirect to={routes.login} />;
};

export default AdminRoute;
