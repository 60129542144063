import { DecisionEnum, Progress, ResultEnum } from './types';

export const getDecision = (data: Progress) =>
  data.processApplication.decision || data.companyVerification.decision;

export const getStep = (data: Progress) => {
  const isDecisionNegative = getDecision(data) === DecisionEnum.NEGATIVE;

  // Two cases:
  // 1. Data already returns pdf data
  // 2. Contract data is ready but we need to send req for pdf data (req is sending in same view)
  const isContract =
    (data.getContract.contractFileDataBase64 &&
      data.getContract.fileName &&
      data.getContract.success) ||
    (data.createContract.success &&
      data.createContract.resultType === ResultEnum.SUCCESS);

  const isCreateContractNotVerified =
    data.createContract.success &&
    data.createContract.resultType === ResultEnum.PENDING;

  const isProcessApplicationVerified =
    data.processApplication.success &&
    data.processApplication.resultType === ResultEnum.SUCCESS &&
    data.processApplication.decision === DecisionEnum.POSITIVE;

  const isProcessApplicationNotVerified =
    data.processApplication.success &&
    (data.processApplication.resultType === ResultEnum.PENDING ||
      data.processApplication.decision === DecisionEnum.MANUAL);

  const isCompanyVerified =
    data.companyVerification.success &&
    data.companyVerification.resultType === ResultEnum.SUCCESS &&
    data.companyVerification.decision === DecisionEnum.POSITIVE;

  const isComapnyNotVerified =
    data.companyVerification.success &&
    (data.companyVerification.resultType === ResultEnum.PENDING ||
      data.companyVerification.decision === DecisionEnum.MANUAL);

  const isContractSigned = data.contractSigned;

  if (isDecisionNegative) return 0;
  if (isContractSigned) return 9;
  if (isContract) return 8;
  if (isCreateContractNotVerified) return 7;
  if (isProcessApplicationVerified) return 5;
  if (isProcessApplicationNotVerified) return 4;
  if (isCompanyVerified) return 3;
  if (isComapnyNotVerified) return 2;
  return 1;
};
