import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { setCompanyData } from 'js/redux/account/actions';
import {
  InvoicePaymentDateEnum,
  BookKeepingRecordEnum,
  MonthlyIncomeEnum,
  TradeEnum,
  FundingPurposeEnum,
} from 'js/interfaces/account';

import { Form, InputNumber, Select, FormInstance } from 'antd';

import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { PrevBtn, SubmitBtn } from 'js/components/Buttons';
import { routes } from 'js/routes';
import { Data } from 'js/pages/Data/company';

import { objectsEquals } from 'js/helpers/objectsEquals';

import 'js/views/Data/Company/styles.scss';
import ArrearsForm, { ArrearsFormData } from 'js/components/AreasForm';

type Props = {
  loading: boolean;
  data: Data;
  isRefresh: boolean;
};

type FormData = Omit<Data, 'arrearsInstallmentUs' | 'arrearsInstallmentZus'> &
  ArrearsFormData;

const CompanyData: FC<Props> = ({ loading, data, isRefresh }) => {
  const [form]: [FormInstance<FormData>] = Form.useForm();
  const dispatch: Dispatch = useDispatch();

  const history = useHistory();

  const selectArrear = (arrear: boolean | undefined) => {
    if (arrear === true || arrear === false) {
      return !!arrear ? 1 : 0;
    }
    return undefined;
  };

  const handleSubmit = (values: FormData) => {
    const newData = {
      arrearsInstallmentUs: !!values.arrearsInstallmentUs,
      arrearsInstallmentZus: !!values.arrearsInstallmentZus,
      arrearsZus: values.arrearsZus ?? 0,
      arrearsUs: values.arrearsUs ?? 0,
      bookkeepingRecordType: values.bookkeepingRecordType,
      contractors: values.contractors.toString(),
      fundingPurpose: values.fundingPurpose,
      invoicePaymentDate: values.invoicePaymentDate,
      monthlyIncome: values.monthlyIncome,
      trade: values.trade,
    };

    objectsEquals(data, newData)
      ? history.push(routes.personal)
      : dispatch(setCompanyData(newData));
  };

  useEffect(() => {
    data &&
      form.setFieldsValue({
        ...data,
        hasArrearsUs: selectArrear(data.arrearsInstallmentUs),
        hasArrearsZus: selectArrear(data.arrearsInstallmentZus),
        arrearsInstallmentUs: selectArrear(data.arrearsInstallmentUs),
        arrearsInstallmentZus: selectArrear(data.arrearsInstallmentZus),
      });
  }, [data, form]);

  return (
    <Box type="content" className="company-data-form">
      <Form
        form={form}
        onFinish={handleSubmit}
        scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
      >
        <ArrearsForm name="Zus" showInputs={!!data.arrearsInstallmentZus} />
        <ArrearsForm name="Us" showInputs={!!data.arrearsInstallmentUs} />

        <Form.Item
          name="trade"
          label="Czym zajmuje się firma?"
          rules={[
            {
              required: true,
              message: 'Proszę o uzupełnienie pola',
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Wybierz"
            options={[
              {
                label: 'Budownictwo',
                value: TradeEnum.construction,
              },
              {
                label: 'Transport',
                value: TradeEnum.transport,
              },
              {
                label: 'IT',
                value: TradeEnum.it,
              },
              {
                label: 'Produkcja i/lub przetwórstwo',
                value: TradeEnum.productionAndProcessing,
              },
              {
                label: 'Handel',
                value: TradeEnum.commerce,
              },
              {
                label: 'Finanse i nieruchomości',
                value: TradeEnum.financeAndRealEstate,
              },
              {
                label: 'Inny',
                value: TradeEnum.other,
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="contractors"
          label="Ilu masz kontrahentów?"
          rules={[
            {
              required: true,
              message: 'Proszę o uzupełnienie pola',
            },
          ]}
        >
          <InputNumber placeholder="Wpisz" type="number" size="large" min={0} />
        </Form.Item>

        <Form.Item
          name="invoicePaymentDate"
          label="Z jakim terminem płatności wystawiasz faktury?"
          rules={[
            {
              required: true,
              message: 'Proszę o uzupełnienie pola',
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Wybierz"
            options={[
              { label: 'Natychmiast', value: InvoicePaymentDateEnum.immediate },
              { label: 'do 6 dni', value: InvoicePaymentDateEnum.oneWeek },
              { label: '7-13 dni', value: InvoicePaymentDateEnum.twoWeeks },
              { label: '14-29 dni', value: InvoicePaymentDateEnum.threeWeeks },
              { label: '30-59 dni', value: InvoicePaymentDateEnum.fourWeeks },
              {
                label: '60+ dni',
                value: InvoicePaymentDateEnum.moreThanFive,
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="bookkeepingRecordType"
          label="Jak prowadzisz ewidencję księgową?"
          rules={[
            {
              required: true,
              message: 'Proszę o uzupełnienie pola',
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Wybierz"
            options={[
              {
                label: 'Księga przychodów i rozchodów',
                value: BookKeepingRecordEnum.ledger,
              },
              {
                label: 'Ryczałt',
                value: BookKeepingRecordEnum.lumpSum,
              },
              {
                label: 'Karta podatkowa',
                value: BookKeepingRecordEnum.taxCard,
              },
              {
                label: 'Pełna księgowość',
                value: BookKeepingRecordEnum.fullAccountancy,
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="monthlyIncome"
          label="Jaki jest średniomiesięczny przychód?"
          rules={[
            {
              required: true,
              message: 'Proszę o uzupełnienie pola',
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Wybierz"
            options={[
              { label: 'do 20 000 zł', value: MonthlyIncomeEnum.upTo20000 },
              { label: 'do 100 000 zł', value: MonthlyIncomeEnum.upTp100000 },
              {
                label: 'powyżej 100 000 zł',
                value: MonthlyIncomeEnum.above100000,
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="fundingPurpose"
          label="Z czym są związane potrzeby Twojej firmy?"
          rules={[
            {
              required: true,
              message: 'Proszę o uzupełnienie pola',
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Wybierz"
            options={[
              {
                label: 'Towar',
                value: FundingPurposeEnum.stockingUp,
              },
              {
                label: 'Kontrakt',
                value: FundingPurposeEnum.contract,
              },
              {
                label: 'Rozszerzenie działalności',
                value: FundingPurposeEnum.expanding,
              },
              {
                label: 'Zakup środków trwałych',
                value: FundingPurposeEnum.purchaseFixedAssets,
              },
              {
                label: 'Spłata ZUS/US',
                value: FundingPurposeEnum.repaymentSocialOrTaxOffice,
              },
              {
                label: 'Spłata innych zobowiązań',
                value: FundingPurposeEnum.repaymentOtherLiabilities,
              },
              {
                label: 'Inne',
                value: FundingPurposeEnum.other,
              },
            ]}
          />
        </Form.Item>

        <BtnWrapper className="company-data-form__button-wrapper">
          <>
            {!isRefresh && <PrevBtn to={routes.check}>Wstecz</PrevBtn>}
            <SubmitBtn loading={loading}>Przejdź dalej</SubmitBtn>
          </>
        </BtnWrapper>
      </Form>
    </Box>
  );
};

export default CompanyData;
