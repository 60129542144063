import { FC, useState, useEffect, useCallback } from 'react';
import 'js/components/SmsCodeInput/styles.scss';

type Props = {
  error: string;
  onChange: (v: string) => string;
  value?: string;
  codeLength?: number;
  placeholder?: string;
};

const SmsCodeInput: FC<Props> = ({
  error,
  codeLength = 4,
  onChange,
  value = '',
  placeholder = '',
}) => {
  const [code, setCode] = useState(value);
  const [isFocus, setIsFocus] = useState(false);

  const keypressHandler = useCallback((e: KeyboardEvent) => {
    const { keyCode } = e;

    const charDot = 190;
    const charComma = 188;

    const arrowLeft = 37;
    const arrowUp = 38;
    const arrowRight = 39;
    const arrowDown = 40;
    const arrows = [arrowLeft, arrowUp, arrowRight, arrowDown];
    if (
      arrows.indexOf(keyCode) >= 0 ||
      keyCode === charDot ||
      keyCode === charComma
    ) {
      e.preventDefault();
      return;
    }
  }, []);

  useEffect(() => {
    setCode(value);
  }, [value]);

  useEffect(() => {
    const mainWrap = document.getElementById('sms-input');

    mainWrap?.addEventListener('keydown', keypressHandler);
    return () => {
      mainWrap?.removeEventListener('keydown', keypressHandler);
    };
  }, [keypressHandler]);

  useEffect(() => {
    if (error) document.getElementById('sms-input-exact')?.focus();
  }, [error]);

  const handleOnCodeChange = useCallback(() => {
    const res = (document.getElementById('sms-input-exact') as HTMLInputElement)
      .value;

    if (res.length <= codeLength) {
      setCode(res);
      onChange(res);
    }
  }, [codeLength, onChange]);

  const handleOnCodeFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleOnCodeBlur = useCallback(() => {
    setIsFocus(false);
  }, []);

  return (
    <div id="sms-input" className="sms-input">
      <div className="sms-input__wrapper">
        {new Array(codeLength).fill(0).map((_, k) => {
          const isLastItem = k === codeLength - 1;
          const isActive =
            isFocus &&
            (code.length === k || (isLastItem && code.length - 1 === k));

          return (
            <button
              key={k}
              id={`sms-input-exact${k}`}
              className="sms-input__box"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById('sms-input-exact')?.focus();
              }}
            >
              <div
                className={`sms-input__value ${
                  error ? 'sms-input__value--error' : ''
                }`}
              >
                {code[k] ?? <span>{placeholder}</span>}
              </div>
              <div className="sms-input__focus-wrapper">
                <div
                  className={`sms-input__focus ${
                    isActive && !error ? 'sms-input__focus--active' : ''
                  }`}
                />
              </div>
            </button>
          );
        })}
        {error && <span className="sms-input__error">{error}</span>}
      </div>
      <input
        id="sms-input-exact"
        value={code}
        autoComplete="off"
        maxLength={codeLength}
        onChange={handleOnCodeChange}
        onFocus={handleOnCodeFocus}
        onBlur={handleOnCodeBlur}
        style={{
          position: 'absolute',
          opacity: '0',
          marginLeft: '-999px',
        }}
      />
    </div>
  );
};

export default SmsCodeInput;
