import { FC } from 'react';

import SmeoSteps from 'js/components/Steps/smeo';
import Agreement from 'js/views/Smeo/Agreement';
import Provider from 'js/components/Provider';

const SmeoAgreement: FC = () => {
  return (
    <>
      <SmeoSteps active="account" />
      <Agreement />
      <Provider company="smeo" />
    </>
  );
};

export default SmeoAgreement;
