const routes = {
  home: '/',

  //register
  login: '/logowanie',
  register: '/rejestracja',

  resetPassword: '/reset-hasla',
  newPassword: '/nowe-haslo',
  verify: '/weryfikacja',
  editNumber: '/edycja-numeru',
  termsOfService: '/regulamin',

  //user data
  nip: '/nip',
  check: '/potwierdz-dane',
  company: '/dane-firmy',
  personal: '/dane-osobowe',
  self: '/dane-o-sobie',
  address: '/dane-adresowe',
  consents: '/zgody',
  transactions: '/historia-transakcji',

  //payment
  payment: '/platnosci',
  paymentCheck: '/dane-platnosci',

  //report
  status: '/status',
  report: '/raport',
  thank: '/dziekujemy',
  addAccounts: '/dodatkowe-konta',

  //account
  account: '/ustawienia-konta',

  //smeo
  smeoConsents: '/smeo-zgody',
  smeoCompanyData: '/smeo-dane-firmy',
  smeoLimits: '/smeo-limity',
  smeoBank: '/smeo-bank',
  smeoAgreement: '/smeo-umowa',
  smeoLoading: '/smeo-przetwarzanie-danych',
  smeoFinish: '/smeo-sukces',

  //admin
  search: '/szukaj-raportu',
  reportView: '/podglad-raportu',
};

export default routes;
