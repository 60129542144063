import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { RootState } from 'js/redux/reducers';
import { routes, smeoOrder } from 'js/routes';

const SmeoRoute: FC<RouteProps> = ({ component, path }) => {
  const { user } = useSelector((state: RootState) => state.Account);
  const { actualStep } = useSelector((state: RootState) => state.Smeo);

  if (!!user.email && path === smeoOrder[actualStep])
    return <Route path={path} component={component} />;
  if (!!user.email) return <Redirect to={smeoOrder[actualStep]} />;

  return <Redirect to={routes.login} />;
};

export default SmeoRoute;
