import { EngineTypeEnum, ReportTypeData } from 'js/interfaces/report';

import { adaptFrsData } from 'js/helpers/Report/frs';
import { adaptRiskbiteData } from 'js/helpers/Report/riskbite';
import { AppliedOffer } from 'js/interfaces/admin';

export const formatReportData = (
  report: ReportTypeData,
  applied?: AppliedOffer[]
) => {
  if (report.engineType === EngineTypeEnum.RiskBite) {
    return adaptRiskbiteData(report, applied);
  }
  if (report.engineType === EngineTypeEnum.FRS) {
    return adaptFrsData(report, applied);
  }
  return null;
};
