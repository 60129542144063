import { AppId } from 'js/interfaces';
import {
  ReportEnum,
  ReportActionTypes,
  CheckOfferData,
  RefreshReportData,
} from 'js/redux/report/types';

export const generateReport = (data: AppId): ReportActionTypes => ({
  type: ReportEnum.GENERATE_REPORT_REQUEST,
  method: 'POST',
  data,
});

export const getReport = (id: number): ReportActionTypes => ({
  type: ReportEnum.GET_REPORT_REQUEST,
  method: 'GET',
  id,
});

export const getReports = (): ReportActionTypes => ({
  type: ReportEnum.GET_REPORTS_REQUEST,
  method: 'GET',
});

export const checkOffer = (data: CheckOfferData): ReportActionTypes => ({
  type: ReportEnum.CHECK_OFFER_REQUEST,
  method: 'POST',
  data,
});

export const clearOffer = (): ReportActionTypes => ({
  type: ReportEnum.CLEAR_OFFER,
});

export const refreshReport = (data: RefreshReportData): ReportActionTypes => ({
  type: ReportEnum.REFRESH_REPORT_REQUEST,
  method: 'POST',
  data,
});
