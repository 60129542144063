import { FC } from 'react';
import moment from 'moment';

import { Modal, Button } from 'antd';
import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';

import { dateFormat, reportValidDays } from 'js/constants';

import 'js/components/Report/ExpiredModal/styles.scss';

export enum ExpiredModalType {
  refresh = 'refresh',
  notAllowed = 'notAllowed',
}

type Props = {
  show: boolean;
  onClose: () => void;
  date: string;
  expirationDate: string;
  type: ExpiredModalType;
  onSubmit?: () => void;
  onRefresh?: () => void;
};

const ExpiredModal: FC<Props> = ({
  show,
  onClose,
  date,
  expirationDate,
  type,
  onSubmit,
  onRefresh,
}) => {
  return (
    <Modal
      width={1000}
      className="expired-modal"
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      visible={show}
    >
      <Box className="" type="content">
        {type === ExpiredModalType.refresh ? (
          <>
            <h3>Oferta może być nie aktualna</h3>
            <p>
              Dana oferta została wygenerowana w dniu
              {moment(date, dateFormat).format(dateFormat)} i może już być nie
              aktualna. Jeżeli chcesz zobaczyć aktualną ofertę, zaktualizuj
              raport.
            </p>
            <BtnWrapper>
              <Button onClick={onSubmit}>Kontynuuj bez odświeżania</Button>
              <Button onClick={onRefresh} type="primary">
                Chce aktualną ofertę
              </Button>
            </BtnWrapper>
          </>
        ) : (
          <>
            <h3>
              Odnowienie raportu nie jest możliwe. Poczekaj do{' '}
              {moment(expirationDate, dateFormat).format(dateFormat)}
            </h3>
            <p>
              Simple.pl pozwala wygenerować raport bezpłatnie raz na{' '}
              {reportValidDays} dni. Raport będziesz mógł odświeżyć{' '}
              {moment(expirationDate, dateFormat).format(dateFormat)}
            </p>
            <BtnWrapper>
              <Button onClick={onClose} type="primary">
                Wróć
              </Button>
            </BtnWrapper>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ExpiredModal;
