import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'js/redux/reducers';

import useWfUser from 'js/hooks/useWfUser';

import Box from 'js/components/Box';
import RegisterForm from 'js/views/Register/Register';

const Register: FC = () => {
  const { loading } = useSelector((state: RootState) => state.Auth);
  const { loading: accLoading, wfUser } = useSelector(
    (state: RootState) => state.Account
  );
  useWfUser();

  return (
    <>
      <Box type="heading">
        <h3>Załóż konto</h3>
        <p>
          Dzięki temu zawsze wrócisz do swojego raportu, gdy będziesz tego
          potrzebować.
        </p>
      </Box>
      <RegisterForm loading={loading || accLoading} wfUser={wfUser} />
    </>
  );
};

export default Register;
