import { rest } from 'msw';
import { DecisionEnum, SmeoApiTypes, SmeoEnum } from 'js/redux/smeo/types';
import { basePdf } from 'js/mocks/db/smeo-pdf';
import consents from 'js/mocks/db/smeo-consents.json';
import bankAccounts from 'js/mocks/db/smeo-bank-accounts.json';

type GetSmeoConsentsRequest = Extract<
  SmeoApiTypes,
  { type: SmeoEnum.GET_SMEO_CONSENTS_REQUEST }
>['data'];

type GetSmeoConsentsResponse = Extract<
  SmeoApiTypes,
  | { type: SmeoEnum.GET_SMEO_CONSENTS_SUCCESS }
  | { type: SmeoEnum.GET_SMEO_CONSENTS_FAILURE }
>['payload'];

type PostSmeoConsentsRequest = Extract<
  SmeoApiTypes,
  { type: SmeoEnum.POST_SMEO_CONSENTS_REQUEST }
>['payload'];

type PostSmeoConsentsResponse = Extract<
  SmeoApiTypes,
  | { type: SmeoEnum.POST_SMEO_CONSENTS_SUCCESS }
  | { type: SmeoEnum.POST_SMEO_CONSENTS_FAILURE }
>['payload'];

type GetCompanyVerificationRequest = Extract<
  SmeoApiTypes,
  { type: SmeoEnum.GET_COMPANY_VERIFICATION_REQUEST }
>['data'];

type GetCompanyVerificationResponse = Extract<
  SmeoApiTypes,
  | { type: SmeoEnum.GET_COMPANY_VERIFICATION_SUCCESS }
  | { type: SmeoEnum.GET_COMPANY_VERIFICATION_FAILURE }
>['payload'];

type SubmitSmeoApplicationRequest = Extract<
  SmeoApiTypes,
  { type: SmeoEnum.SUBMIT_APPLICATION_REQUEST }
>['data'];

type SubmitSmeoApplicationResponse = Extract<
  SmeoApiTypes,
  | { type: SmeoEnum.SUBMIT_APPLICATION_SUCCESS }
  | { type: SmeoEnum.SUBMIT_APPLICATION_FAILURE }
>['payload'];

type GetProcessApplicationRequest = Extract<
  SmeoApiTypes,
  { type: SmeoEnum.GET_PROCESS_APP_REQUEST }
>['data'];

type GetProcessApplicationResponse = Extract<
  SmeoApiTypes,
  | { type: SmeoEnum.GET_PROCESS_APP_SUCCESS }
  | { type: SmeoEnum.GET_PROCESS_APP_FAILURE }
>['payload'];

type SubmitCreateContractRequest = Extract<
  SmeoApiTypes,
  { type: SmeoEnum.SUBMIT_CREATE_CONTRACT_REQUEST }
>['data'];

type SubmitCreateContractResponse = Extract<
  SmeoApiTypes,
  | { type: SmeoEnum.SUBMIT_CREATE_CONTRACT_SUCCESS }
  | { type: SmeoEnum.SUBMIT_CREATE_CONTRACT_FAILURE }
>['payload'];

type GetCreateContractRequest = Extract<
  SmeoApiTypes,
  { type: SmeoEnum.GET_CREATE_CONTRACT_REQUEST }
>['data'];

type GetCreateContractResponse = Extract<
  SmeoApiTypes,
  | { type: SmeoEnum.GET_CREATE_CONTRACT_SUCCESS }
  | { type: SmeoEnum.GET_CREATE_CONTRACT_FAILURE }
>['payload'];

type GetContractRequest = Extract<
  SmeoApiTypes,
  { type: SmeoEnum.GET_CONTRACT_REQUEST }
>['data'];

type GetContractResponse = Extract<
  SmeoApiTypes,
  | { type: SmeoEnum.GET_CONTRACT_SUCCESS }
  | { type: SmeoEnum.GET_CONTRACT_FAILURE }
>['payload'];

type SignInContractRequest = Extract<
  SmeoApiTypes,
  { type: SmeoEnum.SIGN_IN_CONTRACT_REQUEST }
>['data'];

type SignInContractResponse = Extract<
  SmeoApiTypes,
  | { type: SmeoEnum.SIGN_IN_CONTRACT_SUCCESS }
  | { type: SmeoEnum.SIGN_IN_CONTRACT_FAILURE }
>['payload'];

type GetProgressRequest = Extract<
  SmeoApiTypes,
  { type: SmeoEnum.GET_PROGRESS_REQUEST }
>['data'];

type GetProgressResponse = Extract<
  SmeoApiTypes,
  | { type: SmeoEnum.GET_PROGRESS_SUCCESS }
  | { type: SmeoEnum.GET_PROGRESS_FAILURE }
>['payload'];

// type SubmitLimitRequest = Extract<
//   SmeoApiTypes,
//   { type: SmeoEnum.SUBMIT_LIMIT }
// >['data'];

export const smeoHandlers = [
  rest.post<GetSmeoConsentsRequest, GetSmeoConsentsResponse>(
    process.env.API_URL + '/smeo/get-consent',
    (req, res, ctx) => {
      const { applicationId } = req.body;
      if (!applicationId)
        return res(
          ctx.status(422),
          ctx.json({
            status: 422,
            code: 'Failed',
            errors: [''],
          })
        );

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: {
            processNumber: '1',
            consents: consents,
          },
        })
      );
    }
  ),

  rest.post<PostSmeoConsentsRequest, PostSmeoConsentsResponse>(
    process.env.API_URL + '/smeo/submit-company-verification',
    (req, res, ctx) => {
      const { applicationId } = req.body;
      if (!applicationId)
        return res(
          ctx.status(422),
          ctx.json({
            status: 422,
            code: 'Failed',
            errors: [''],
          })
        );

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: {
            success: true,
            resultType: 'Success',
          },
        })
      );
    }
  ),

  rest.post<GetCompanyVerificationRequest, GetCompanyVerificationResponse>(
    process.env.API_URL + '/smeo/get-company-verification',
    (req, res, ctx) => {
      const { applicationId, processNumber } = req.body;
      if (!applicationId || !processNumber)
        return res(
          ctx.status(422),
          ctx.json({
            status: 422,
            code: 'Failed',
            errors: [''],
          })
        );

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: {
            success: true,
            resultType: 'Success',
            decision: DecisionEnum.POSITIVE,
            company: {},
          },
        })
      );
    }
  ),

  rest.post<SubmitSmeoApplicationRequest, SubmitSmeoApplicationResponse>(
    process.env.API_URL + '/smeo/submit-process-application',
    (req, res, ctx) => {
      const { applicationId, processNumber } = req.body;
      if (!applicationId || !processNumber)
        return res(
          ctx.status(422),
          ctx.json({
            status: 422,
            code: 'Failed',
            errors: [''],
          })
        );

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: {
            resultType: 'Success',
            success: true,
          },
        })
      );
    }
  ),

  rest.post<GetProcessApplicationRequest, GetProcessApplicationResponse>(
    process.env.API_URL + '/smeo/get-process-application',
    (req, res, ctx) => {
      const { applicationId, processNumber } = req.body;
      if (!applicationId || !processNumber)
        return res(
          ctx.status(422),
          ctx.json({
            status: 422,
            code: 'Unprocessable Entity',
            errors: ['error'],
          })
        );

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: {
            bankAccountNumbers: bankAccounts,
            decision: DecisionEnum.POSITIVE,
            decisionMessage: '',
            limit: 55555,
            limitOnline: 55555,
            resultType: 'Success',
            success: true,
          },
        })
      );
    }
  ),

  rest.post<SubmitCreateContractRequest, SubmitCreateContractResponse>(
    process.env.API_URL + '/smeo/submit-create-contract',
    (req, res, ctx) => {
      const { applicationId, processNumber } = req.body;
      if (!applicationId || !processNumber)
        return res(
          ctx.status(422),
          ctx.json({
            status: 422,
            code: 'Unprocessable Entity',
            errors: ['error'],
          })
        );

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: {
            success: true,
            resultType: 'Success',
          },
        })
      );
    }
  ),

  rest.post<GetCreateContractRequest, GetCreateContractResponse>(
    process.env.API_URL + '/smeo/get-create-contract',
    (req, res, ctx) => {
      const { applicationId, processNumber } = req.body;
      if (!applicationId || !processNumber)
        return res(
          ctx.status(422),
          ctx.json({
            status: 422,
            code: 'Failed',
            errors: [''],
          })
        );

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: {
            success: true,
            resultType: 'Success',
            contractData: {
              id: 2164,
              signature: '2021/09/27/001-0000001',
              state: 'W',
              vendorClientAccessToken:
                'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZW5kb3JfaWQiOiIxNyIsInZlbmRvcl9jbGllbnRfaWQiOiIyMTY0IiwiaXNzIjoiU21lbyIsImV4cCI6MTY2NDI4MTQwMywibmJmIjoxNjMyNzQ1NDAzfQ.95E6MjhEWscU2SWbX0plI673n0AgyFW7JHaxhD1SNc0',
            },
          },
        })
      );
    }
  ),

  rest.post<GetContractRequest, GetContractResponse>(
    process.env.API_URL + '/smeo/get-contract',
    (req, res, ctx) => {
      const { applicationId, processNumber } = req.body;
      if (!applicationId || !processNumber)
        return res(
          ctx.status(422),
          ctx.json({
            status: 422,
            code: 'Failed',
            errors: [''],
          })
        );

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: {
            success: true,
            contractFileDataBase64: basePdf,
            fileName: 'Umowa-2021-09-27-001-0000001.pdf',
          },
        })
      );
    }
  ),

  rest.post<SignInContractRequest, SignInContractResponse>(
    process.env.API_URL + '/smeo/sign-in-contract',
    (req, res, ctx) => {
      const { applicationId, processNumber } = req.body;
      if (!applicationId || !processNumber)
        return res(
          ctx.status(422),
          ctx.json({
            status: 422,
            code: 'Failed',
            errors: [''],
          })
        );

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: {
            success: true,
          },
        })
      );
    }
  ),

  rest.post<GetProgressRequest, GetProgressResponse>(
    process.env.API_URL + '/smeo/get-process-progress-data',
    (req, res, ctx) => {
      const { applicationId, processNumber } = req.body;
      if (!applicationId || !processNumber)
        return res(
          ctx.status(422),
          ctx.json({
            status: 422,
            code: 'Failed',
            errors: [''],
          })
        );

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: {
            processNumber: '1',
            companyVerification: {
              success: true,
              resultType: 'Success',
              decision: DecisionEnum.POSITIVE,
              company: {},
            },
            processApplication: {
              success: true,
              resultType: 'Success',
              decision: DecisionEnum.POSITIVE,
              bankAccountNumbers: bankAccounts,
              limit: 5555,
              limitOnline: 5555,
              decisionMessage: '',
            },
            contractData: {
              id: 2164,
              state: 'W',
              signature: '2021/09/27/001-0000001',
              vendorClientAccessToken:
                'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZW5kb3JfaWQiOiIxNyIsInZlbmRvcl9jbGllbnRfaWQiOiIyMTY0IiwiaXNzIjoiU21lbyIsImV4cCI6MTY2NDI4MTQwMywibmJmIjoxNjMyNzQ1NDAzfQ.95E6MjhEWscU2SWbX0plI673n0AgyFW7JHaxhD1SNc0',
            },
            createContract: {
              success: true,
              resultType: 'Success',
              contract: {
                id: 2164,
                signature: '2021/09/27/001-0000001',
                state: 'W',
                vendorClientAccessToken:
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZW5kb3JfaWQiOiIxNyIsInZlbmRvcl9jbGllbnRfaWQiOiIyMTY0IiwiaXNzIjoiU21lbyIsImV4cCI6MTY2NDI4MTQwMywibmJmIjoxNjMyNzQ1NDAzfQ.95E6MjhEWscU2SWbX0plI673n0AgyFW7JHaxhD1SNc0',
              },
            },
            getContract: {
              success: true,
              contractFileDataBase64: basePdf,
              fileName: 'Umowa-2021-09-27-001-0000001.pdf',
            },
            contractSigned: true,
          },
        })
      );
    }
  ),
];
