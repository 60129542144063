import { FC, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'js/redux/reducers';
import { BankAccountNumber } from 'js/redux/smeo/types';
import { submitCreateContract } from 'js/redux/smeo/actions';

import { Select } from 'antd';
import Box from 'js/components/Box';
import Loader from 'js/components/Loader';
import BtnWrapper from 'js/components/BtnWrapper';
import { SubmitBtn } from 'js/components/Buttons';

import 'js/views/Smeo/Bank/styles.scss';

const getSelectBanks = (
  banks: BankAccountNumber[],
  currency: BankAccountNumber['currencyCode']
) =>
  banks
    .filter((el) => el.currencyCode === currency)
    .map(({ accountNo }, i) => ({
      label: accountNo,
      value: i,
    }));

const SmeoBank: FC = () => {
  const [selectedAccs, setSelectedAccs] = useState<BankAccountNumber[]>([]);

  const { bankAccounts, processNumber, loading } = useSelector(
    (state: RootState) => state.Smeo
  );

  const { app } = useSelector((state: RootState) => state.Account);

  const dispatch: Dispatch = useDispatch();

  const selectPlnOpts = useMemo(
    () => getSelectBanks(bankAccounts, 'PLN'),
    [bankAccounts]
  );

  const selectEurOpts = useMemo(
    () => getSelectBanks(bankAccounts, 'EUR'),
    [bankAccounts]
  );

  const onSelectBank = (
    index: number,
    currency: BankAccountNumber['currencyCode']
  ) => {
    const prevBanks = selectedAccs.filter(
      (bank) => bank.currencyCode !== currency
    );
    const currentBank = bankAccounts.filter(
      (bank) => bank.currencyCode === currency
    )[index];

    setSelectedAccs(() => [...prevBanks, currentBank]);
  };

  const handleSubmit = () => {
    if (!!selectedAccs.length && app.id)
      dispatch(
        submitCreateContract({
          processNumber,
          applicationId: app.id,
          bankAccountNumbers: selectedAccs,
        })
      );
    setSelectedAccs([]);
  };

  if (loading)
    return (
      <Box type="content" className="smeo-bank">
        <Loader />
      </Box>
    );

  return (
    <Box type="content" className="smeo-bank">
      <>
        <h2 className="smeo-bank__title">Wybierz swoje konto bankowe</h2>
        <h3 className="smeo-bank__subtitle">
          Wskaż konto, na które chcesz otrzymywać od nas pieniądze z faktur.
        </h3>

        {!!selectPlnOpts.length && (
          <>
            <h3 className="smeo-bank__select-title">Konta złotówkowe</h3>
            <Select
              className="small"
              placeholder="Wybierz konto"
              options={selectPlnOpts}
              onSelect={(v: number) => onSelectBank(v, 'PLN')}
            />
          </>
        )}

        {!!selectEurOpts.length && (
          <>
            <h3 className="smeo-bank__select-title smeo-bank__select-title--margin">
              Konta walutowe
            </h3>
            <Select
              className="small"
              placeholder="Wybierz konto"
              options={selectEurOpts}
              onSelect={(v: number) => onSelectBank(v, 'EUR')}
            />
          </>
        )}

        <BtnWrapper className="smeo-bank__btn-wrapper">
          <SubmitBtn
            disabled={
              !!selectEurOpts.length
                ? selectedAccs.length < 2
                : !selectedAccs.length
            }
            onClick={handleSubmit}
          >
            Przejdź dalej
          </SubmitBtn>
        </BtnWrapper>
      </>
    </Box>
  );
};

export default SmeoBank;
