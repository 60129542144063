import { FC, useCallback, useMemo } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { newPassword } from 'js/redux/password/actions';
import { useLocation } from 'react-router';

import { Form, Input, FormInstance } from 'antd';
import { RuleObject } from 'antd/lib/form';

import { State } from 'js/pages/Register/new-password';
import Validator from 'js/helpers/Validator';
import { routes } from 'js/routes';

import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { SubmitBtn, NextBtn } from 'js/components/Buttons';

import queryString from 'query-string';

import 'js/views/Register/NewPassword/styles.scss';

type NewPasswordData = {
  password: string;
};

type Props = {
  state: State;
  loading: boolean;
};

const NewPassword: FC<Props> = ({ state, loading }) => {
  const [form]: [FormInstance<NewPasswordData>] = Form.useForm();
  const dispatch: Dispatch = useDispatch();

  const { search } = useLocation();

  const validatePassword = (_: RuleObject, value: string) =>
    new Validator(value)
      .noValue('Proszę podaj hasło')
      .invalidPassword(
        'Wymagane jest min. 8 znaków, duża litera, mała litera oraz jedna cyfra'
      )
      .res();

  const { token, email } = useMemo(() => {
    return queryString?.parse(search);
  }, [search]);

  const handleSubmit = useCallback(
    (values: NewPasswordData) => {
      if (
        token &&
        email &&
        typeof email === 'string' &&
        typeof token === 'string'
      ) {
        dispatch(newPassword({ ...values, token, email }));
      }
    },
    [token, email, dispatch]
  );

  return (
    <>
      <Box type="content" className="new-password-form">
        {state === 'reset' ? (
          <Form
            form={form}
            scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="password"
              label="Hasło"
              rules={[
                {
                  required: true,
                  validator: validatePassword,
                },
              ]}
            >
              <Input.Password size="large" placeholder="Wpisz hasło..." />
            </Form.Item>

            <BtnWrapper className="new-password-form__buttons">
              <SubmitBtn loading={loading}>Zatwierdź</SubmitBtn>
            </BtnWrapper>
          </Form>
        ) : (
          <BtnWrapper>
            <NextBtn to={routes.login}>Zaloguj się</NextBtn>
          </BtnWrapper>
        )}
      </Box>
    </>
  );
};

export default NewPassword;
