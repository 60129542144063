import { ReportTypeData, EngineTypeEnum } from 'js/interfaces/report';

type Response = {
  status: 200;
  code: 'OK';
  data: ReportTypeData;
};

export const report: Response = {
  status: 200,
  code: 'OK',
  data: {
    id: 3,
    applicationId: 14,
    date: '22-07-2022',
    expirationDate: '05-08-2022',
    engineType: EngineTypeEnum.FRS,
    ratingSimplePlBpmnValue: 1,
    freeAmountBikKontoAgr: 2,
    user: {
      firstName: 'Antoni',
      lastName: 'Dzwoni',
      phone: '555444333',
      email: 'test7@ww.com',
      idCard: 'YRI418264',
      pesel: '34070866262',
      street: 'Kasztanowa',
      houseNo: '4',
      apartmentNo: null,
      city: 'Warszawa',
      postCode: '60-431',
      maritalStatus: 2,
      isActive: true,
      isWealthonUser: false,
    },
    company: {
      nip: '8216313093',
      name: 'Testowa 7',
      street: null,
      houseNo: null,
      apartmentNo: null,
      city: null,
      postCode: null,
      monthlyIncome: 1,
      contractors: '5',
      invoicePaymentDate: 1,
      bookkeepingRecordType: 1,
      trade: 1,
      fundingPurpose: 1,
      arrearsInstallmentZus: false,
      arrearsInstallmentUs: false,
      arrearsZus: 0,
      arrearsUs: 0,
    },
    variables: [
      {
        name: 'creditsNoCloseLimitsNonBankWithin12BikP',
        value: '0.0',
      },
      {
        name: 'taskScoreSumIncomeSalaryCompensationWithin3KontoAgrValue',
        value: '0',
      },
      {
        name: 'sumWelfare300To330KontoTr',
        value: '0.0',
      },
      {
        name: 'sumLimitsNoClosePsnNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'countZusTransactions90To120KontoTr',
        value: '0.0',
      },
      {
        name: 'countZusTransactions60To90KontoTr',
        value: '0.0',
      },
      {
        name: 'sumLeasing30To60KontoTr',
        value: '0.0',
      },
      {
        name: 'firstCreditOpenDateBik',
        value: '124',
      },
      {
        name: 'creditsOpenWithin3NonBankBikP',
        value: '0.0',
      },
      {
        name: 'countNegativeInformationKrd',
        value: '0',
      },
      {
        name: 'taskExternalOffersNestBankMessage',
        value: 'BRAK',
      },
      {
        name: 'avgBalanceMonth4KontoAgr',
        value: '348.28',
      },
      {
        name: 'countUsTransactions120To150KontoTr',
        value: '0.0',
      },
      {
        name: 'sumZusTransactions0To30KontoTr',
        value: '0.0',
      },
      {
        name: 'usPaymentAmountMonth5KontoAgr',
        value: '0.0',
      },
      {
        name: 'avgNegativeBalance0To30KontoTr',
        value: '0.0',
      },
      {
        name: 'avgBalance90To120KontoTr',
        value: '1721.37',
      },
      {
        name: 'taskScoreCreditsOpenWithin3NoCloseDivcreditsOpenBikValue',
        value: '15',
      },
      {
        name: 'ratingSimplePlBpmnValue',
        value: '2',
      },
      {
        name: 'sumUsTransactions0To30KontoTr',
        value: '0.0',
      },
      {
        name: 'sumScoringFinancialFlowsBpmn',
        value: '0.0',
      },
      {
        name: 'countDelaysDays7PsnNoCloseBikKi',
        value: '0',
      },
      {
        name: 'maxDelayDaysNoCloseNonBankBikP',
        value: '0.0',
      },
      {
        name: 'sumLeasing120To150KontoTr',
        value: '0.0',
      },
      {
        name: 'countDeposits210To240KontoTr',
        value: '21.0',
      },
      {
        name: 'sumSalaryB2b60To90KontoTr',
        value: '0.0',
      },
      {
        name: 'inquiriesWithin3Bik',
        value: '20',
      },
      {
        name: 'zusPaymentAmountMonth8KontoAgr',
        value: '0.0',
      },
      {
        name: 'taskScoreAmountsToLimitsBikMessage',
        value: 'BRAK',
      },
      {
        name: 'inquiriesNonBankWithin3BikP',
        value: '20.0',
      },
      {
        name: 'taskRelationDecisionValue',
        value: 'false',
      },
      {
        name: 'statusDebExeNoCloseNonBankBikP',
        value: '0.0',
      },
      {
        name: 'sumLoan0To360KontoTr',
        value: '154.44',
      },
      {
        name: 'maxDelayDaysWithin12BankBikP',
        value: '0.0',
      },
      {
        name: 'sumLeasing0To30KontoTr',
        value: '0.0',
      },
      {
        name: 'sumIncomeChargesMonth11BikKontoAgr',
        value: '0.0',
      },
      {
        name: 'statusDebExeNoCloseBankBikKi',
        value: '0.0',
      },
      {
        name: 'avgPositiveBalance60To90KontoTr',
        value: '2026.72',
      },
      {
        name: 'sumTerlPaym240To270KontoTr',
        value: '0.0',
      },
      {
        name: 'taskScoreSumCurrentBalanceDelaysDays4BikMessage',
        value: 'BRAK',
      },
      {
        name: 'creditsNoCloseBankBankBikP',
        value: '0.0',
      },
      {
        name: 'countWithdrawals90To120KontoTr',
        value: '117.0',
      },
      {
        name: 'minBalance120To150KontoTr',
        value: '0.0',
      },
      {
        name: 'sumInstallmentsNoCloseBankBikP',
        value: '0.0',
      },
      {
        name: 'firstCreditOpenDateBankBikKi',
        value: '124.0',
      },
      {
        name: 'statusWriRecCloseWithin36NonBankBikKi',
        value: '0.0',
      },
      {
        name: 'creditsLimitsNoCloseWithin6NonBankBikKi',
        value: '0.0',
      },
      {
        name: 'noFullAccountingTypeFw',
        value: 'true',
      },
      {
        name: 'taskScoreCreditsOpenWithin3NoCloseDivcreditsOpenBikMessage',
        value: 'BRAK',
      },
      {
        name: 'taskScoringGroupInterestMessage',
        value: 'IX',
      },
      {
        name: 'endBalance210To240KontoTr',
        value: '0.0',
      },
      {
        name: 'taskScoreOutstandingAmountInstallmentZusUsFwMessage',
        value: 'BRAK',
      },
      {
        name: 'creditsClosedOpenWithin6BikKi',
        value: '0',
      },
      {
        name: 'sumLimitsNoCloseBankBikP',
        value: '0.0',
      },
      {
        name: 'creditsOpenWithin3NoCloseDivcreditsOpenBik',
        value: '0.0',
      },
      {
        name: 'taskSimpleDebtsDtiValue',
        value: '2',
      },
      {
        name: 'taskInstallmentLoanManuallyOrAutomaticMessage',
        value: 'reject',
      },
      {
        name: 'sumLimitsNoCloseNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'quantityAndAmountKrd',
        value: 'false',
      },
      {
        name: 'taskScoreOutstandingAmountInstallmentZusUsFwValue',
        value: '30',
      },
      {
        name: 'usPaymentCountMonth10KontoAgr',
        value: '0.0',
      },
      {
        name: 'creditsCloseBankBikP',
        value: '0.0',
      },
      {
        name: 'countDeposits240To270KontoTr',
        value: '11.0',
      },
      {
        name: 'dtiKontoAgrBik',
        value: '999999.0',
      },
      {
        name: 'lastMonth12KontoAgr',
        value: '2021-07',
      },
      {
        name: 'sumUsTransactions330To360KontoTr',
        value: '0.0',
      },
      {
        name: 'sumSalaryB2b210To240KontoTr',
        value: '0.0',
      },
      {
        name: 'countNegativeInformationKrdKi',
        value: '0.0',
      },
      {
        name: 'defaultPoscashOfferNettoPercDailyPaymBpmn',
        value: '15%',
      },
      {
        name: 'usPaymentAmountMonth2KontoAgr',
        value: '0.0',
      },
      {
        name: 'zusPaymentAmountMonth5KontoAgr',
        value: '0.0',
      },
      {
        name: 'sumLeasing60To90KontoTr',
        value: '0.0',
      },
      {
        name: 'avgNegativeBalance180To210KontoTr',
        value: '0.0',
      },
      {
        name: 'avgPositiveBalance30To60KontoTr',
        value: '447.31',
      },
      {
        name: 'idNumberFw',
        value: 'CFZ096980',
      },
      {
        name: 'specificPkdCeidg',
        value: 'false',
      },
      {
        name: 'sumDeposits240To270KontoTr',
        value: '3800.0',
      },
      {
        name: 'sumSalaryB2b30To60KontoTr',
        value: '0.0',
      },
      {
        name: 'sumLimitsCreditsNoCloseBikKi',
        value: '0.0',
      },
      {
        name: 'taskFlagsBigiMessage',
        value: 'ZIELONA',
      },
      {
        name: 'sumWithdrawals120To150KontoTr',
        value: '6894.71',
      },
      {
        name: 'taskScoreClientAgeFwValue',
        value: '20',
      },
      {
        name: 'sumWelfare0To30KontoTr',
        value: '0.0',
      },
      {
        name: 'creditsNoCloseLimitsBankWithin6BikP',
        value: '0.0',
      },
      {
        name: 'sumMortgage330To360KontoTr',
        value: '0.0',
      },
      {
        name: 'sumLoan330To360KontoTr',
        value: '0.0',
      },
      {
        name: 'countUsTransactions180To210KontoTr',
        value: '0.0',
      },
      {
        name: 'zusInstallmentFw',
        value: 'false',
      },
      {
        name: 'sumWithdrawals180To210KontoTr',
        value: '3776.03',
      },
      {
        name: 'sumNegativeInformationIm',
        value: '0.0',
      },
      {
        name: 'sumMortgage300To330KontoTr',
        value: '0.0',
      },
      {
        name: 'sumTerlPaym300To330KontoTr',
        value: '0.0',
      },
      {
        name: 'countDelaysDays30DueAmount500NoPsnNoCloseWithin6bankBikKi',
        value: '0.0',
      },
      {
        name: 'maxDelayDaysDueAmount500BankBikKi',
        value: '0.0',
      },
      {
        name: 'maxDelayDaysWithin6BankBikP',
        value: '0.0',
      },
      {
        name: 'avgBalance240To270KontoTr',
        value: '341.47',
      },
      {
        name: 'creditsNoCloseBikP',
        value: '0',
      },
      {
        name: 'statusDebExeNoCloseNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumLeasing180To210KontoTr',
        value: '0.0',
      },
      {
        name: 'poscashOfferBruttoAmount05on12Bpmn',
        value: '0.0',
      },
      {
        name: 'sumAmountsCreditsNoCloseBikP',
        value: '0.0',
      },
      {
        name: 'invoicesTerm',
        value: 'IMMEDIATE',
      },
      {
        name: 'countDeposits270To300KontoTr',
        value: '16.0',
      },
      {
        name: 'scorePoiBikKi',
        value: '45.4',
      },
      {
        name: 'creditsNoCloseBankNonBankBikP',
        value: '0.0',
      },
      {
        name: 'countDeposits0To30KontoTr',
        value: '8.0',
      },
      {
        name: 'sumInstallmentsNoCloseNoPsnBankBikP',
        value: '0.0',
      },
      {
        name: 'zusPaymentCountMonth2KontoAgr',
        value: '0.0',
      },
      {
        name: 'countUsTransactions30To60KontoTr',
        value: '0.0',
      },
      {
        name: 'sumMortgage30To60KontoTr',
        value: '0.0',
      },
      {
        name: 'usPaymentCountMonth5KontoAgr',
        value: '0.0',
      },
      {
        name: 'maxBalance60To90KontoTr',
        value: '0.0',
      },
      {
        name: 'maxBalance150To180KontoTr',
        value: '0.0',
      },
      {
        name: 'countDelaysDays7PsnNoCloseNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumUsTransactions300To330KontoTr',
        value: '0.0',
      },
      {
        name: 'sumSalaryB2b240To270KontoTr',
        value: '0.0',
      },
      {
        name: 'poscashOfferBruttoAmount15on12Bpmn',
        value: '0.0',
      },
      {
        name: 'zusPaymentAmountMonth1KontoAgr',
        value: '0.0',
      },
      {
        name: 'nacionalCeidg',
        value: '1.0',
      },
      {
        name: 'taskScoreFirstRelationBikMessage',
        value: 'BRAK',
      },
      {
        name: 'sumDeposits60To90KontoTr',
        value: '9383.44',
      },
      {
        name: 'sumLoan120To150KontoTr',
        value: '0.0',
      },
      {
        name: 'sumNegativeInformationKrdP',
        value: '0.0',
      },
      {
        name: 'usPaymentCountMonth8KontoAgr',
        value: '0.0',
      },
      {
        name: 'sumRepayment240To270KontoTr',
        value: '0.0',
      },
      {
        name: 'countDelaysDays7PsnNoCloseBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumZusTransactions300To330KontoTr',
        value: '0.0',
      },
      {
        name: 'creditsNoClosePsnNonBankBikP',
        value: '0.0',
      },
      {
        name: 'lastMonth3KontoAgr',
        value: '2022-04',
      },
      {
        name: 'taskScoreNoIncomeWithin3KontoTrMessage',
        value: 'BRAK',
      },
      {
        name: 'noScoreValueMessageBikKi',
        value: 'false',
      },
      {
        name: 'avgPositiveBalance90To120KontoTr',
        value: '1721.37',
      },
      {
        name: 'sumRepayment90To120KontoTr',
        value: '-135.63',
      },
      {
        name: 'sumInstallmentsBikDivIncomeWithin12KontoTr',
        value: 'true',
      },
      {
        name: 'fundingPurposeFw',
        value: 'STOCKING_UP',
      },
      {
        name: 'countNegativeInformationKrdP',
        value: '0.0',
      },
      {
        name: 'poscashOfferBruttoAmount05on9Bpmn',
        value: '0.0',
      },
      {
        name: 'usPaymentAmountMonth11KontoAgr',
        value: '0.0',
      },
      {
        name: 'avgBalanceMonth1KontoAgr',
        value: '403.29',
      },
      {
        name: 'taskScoreLevelsSpendsLimitBikValue',
        value: '0',
      },
      {
        name: 'sumLimitsCreditsNoCloseBik',
        value: '0.0',
      },
      {
        name: 'zusPaymentCountMonth5KontoAgr',
        value: '0.0',
      },
      {
        name: 'monthlyChargerBik',
        value: '2433.0',
      },
      {
        name: 'taskFlagsPoscashValue',
        value: 'true',
      },
      {
        name: 'amountInterestforCompanyMatrixWealthon',
        value: '0.0',
      },
      {
        name: 'taskSimpleDebtsDtiMessage',
        value: 'low',
      },
      {
        name: 'poscashOfferNettoAmount05on9Bpmn',
        value: '0.0',
      },
      {
        name: 'lastMonth9KontoAgr',
        value: '2021-10',
      },
      {
        name: 'endBalance240To270KontoTr',
        value: '0.0',
      },
      {
        name: 'sumUsTransactions180To210KontoTr',
        value: '0.0',
      },
      {
        name: 'bailiffWithin12KontoTr',
        value: '0.0',
      },
      {
        name: 'countOverdueInstallmentsNonBankBikP',
        value: '0.0',
      },
      {
        name: 'lastMonth6KontoAgr',
        value: '2022-01',
      },
      {
        name: 'amountPeriodFreeAmountInterestForCompany',
        value: '-14889.96',
      },
      {
        name: 'sumTerlPaym30To60KontoTr',
        value: '0.0',
      },
      {
        name: 'taskPoscashManuallyOrAutomaticMessage',
        value: 'accept',
      },
      {
        name: 'sumInstallmentsNoClosePsnBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumZusTransactions330To360KontoTr',
        value: '0.0',
      },
      {
        name: 'maxDelayDaysNoCloseBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumRepayment210To240KontoTr',
        value: '0.0',
      },
      {
        name: 'noIncomeWithin3KontoTr',
        value: '3',
      },
      {
        name: 'maxBalance120To150KontoTr',
        value: '0.0',
      },
      {
        name: 'sumMortgage180To210KontoTr',
        value: '0.0',
      },
      {
        name: 'defPosPreparationFee',
        value: '18.0',
      },
      {
        name: 'zusPaymentAmountMonth11KontoAgr',
        value: '0.0',
      },
      {
        name: 'lastNameFw',
        value: 'TELUS',
      },
      {
        name: 'taskScoreSumNegativeInformationImKrdErifValue',
        value: '30',
      },
      {
        name: 'taskScoreFundingPurposeFwMessage',
        value: 'BRAK',
      },
      {
        name: 'sumLoan30To60KontoTr',
        value: '0.0',
      },
      {
        name: 'countWithdrawals30To60KontoTr',
        value: '63.0',
      },
      {
        name: 'creditsClosedOpenWithin6BankBikKi',
        value: '0.0',
      },
      {
        name: 'zusUsIsAmountFw',
        value: 'false',
      },
      {
        name: 'avgLoanWithin12KontoTr',
        value: '12.87',
      },
      {
        name: 'minBalance60To90KontoTr',
        value: '0.0',
      },
      {
        name: 'statusWriRecCloseWithin36BankBikKi',
        value: '0.0',
      },
      {
        name: 'countNegativeInformationImP',
        value: '0.0',
      },
      {
        name: 'avgPositiveBalance180To210KontoTr',
        value: '339.44',
      },
      {
        name: 'freeAmountBikKontoAgr',
        value: '-1240.83',
      },
      {
        name: 'countDelaysDays30DueAmount500NoPsnNoCloseWithin6BikKi',
        value: '0',
      },
      {
        name: 'avgBalance210To240KontoTr',
        value: '384.47',
      },
      {
        name: 'sumMortgage120To150KontoTr',
        value: '0.0',
      },
      {
        name: 'poscashProdAndAge276to348Fw',
        value: 'false',
      },
      {
        name: 'sumAmountsCreditsNoCloseBik',
        value: '292668.0',
      },
      {
        name: 'taskScoreMonthsStartCompanyCeidgValue',
        value: '25',
      },
      {
        name: 'sumWithdrawals330To360KontoTr',
        value: '4346.99',
      },
      {
        name: 'sumTerlPaym330To360KontoTr',
        value: '0.0',
      },
      {
        name: 'percDailyPaymBpmn',
        value: '0.15',
      },
      {
        name: 'sumLimitsNoCloseBankBikKi',
        value: '0.0',
      },
      {
        name: 'taskScoreNoIncomeWithin3KontoTrValue',
        value: '0',
      },
      {
        name: 'taskSimpleDynamicsIncomeValue',
        value: '2',
      },
      {
        name: 'taskFlagsBigiValue',
        value: 'false',
      },
      {
        name: 'taskExternalOffersSmeoMessage',
        value: 'BRAK',
      },
      {
        name: 'maxBalance270To300KontoTr',
        value: '0.0',
      },
      {
        name: 'creditsNoClosePsnNotPsnBik',
        value: 'false',
      },
      {
        name: 'sumCurBalNoClosePsnNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumWithdrawals30To60KontoTr',
        value: '3496.75',
      },
      {
        name: 'taskScoreSumCurrentBalanceDelaysDays4BikValue',
        value: '20',
      },
      {
        name: 'sumBailiff0To360KontoTr',
        value: '0.0',
      },
      {
        name: 'taskFlagsRozneZrodlaMessage',
        value: 'CZERWONA',
      },
      {
        name: 'endBalance150To180KontoTr',
        value: '0.0',
      },
      {
        name: 'poscashOfferBruttoAmount15on9Bpmn',
        value: '0.0',
      },
      {
        name: 'sumLeasing330To360KontoTr',
        value: '0.0',
      },
      {
        name: 'taskExternalOffersBibbyMessage',
        value: 'Bibby Financial Services',
      },
      {
        name: 'taskSimpleListDebtorsValue',
        value: '0',
      },
      {
        name: 'creditsOpenWithin6NonBankBikP',
        value: '0.0',
      },
      {
        name: 'taskScoreLevelsSpendsLimitBikMessage',
        value: 'BRAK',
      },
      {
        name: 'sumUsTransactions90To120KontoTr',
        value: '0.0',
      },
      {
        name: 'taskScoreIncomeStabilityWithin3Divide12KontoAgrMessage',
        value: 'BRAK',
      },
      {
        name: 'taskScoreSumIncomeSalaryCompensationWithin3KontoAgrMessage',
        value: 'BRAK',
      },
      {
        name: 'zusPaymentAmountMonth4KontoAgr',
        value: '0.0',
      },
      {
        name: 'endBalance30To60KontoTr',
        value: '0.0',
      },
      {
        name: 'avgPositiveBalance300To330KontoTr',
        value: '315.46',
      },
      {
        name: 'sumNegativeInformationErif',
        value: '0.0',
      },
      {
        name: 'sumUsTransactions240To270KontoTr',
        value: '0.0',
      },
      {
        name: 'sumCurBalNoClosePsnBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumZusTransactions60To90KontoTr',
        value: '0.0',
      },
      {
        name: 'creditsNoCloseBankBankBikKi',
        value: '2.0',
      },
      {
        name: 'firstCreditOpenDateBankBikP',
        value: '0.0',
      },
      {
        name: 'poscashOfferNettoAmount15on9Bpmn',
        value: '0.0',
      },
      {
        name: 'sumDeposits210To240KontoTr',
        value: '5337.0',
      },
      {
        name: 'avgNegativeBalance270To300KontoTr',
        value: '0.0',
      },
      {
        name: 'creditsOpenWithin6Bik',
        value: '0',
      },
      {
        name: 'amountRequestApp',
        value: '60000.0',
      },
      {
        name: 'tradeFw',
        value: 'CONSTRUCTION',
      },
      {
        name: 'sumLoan300To330KontoTr',
        value: '0.0',
      },
      {
        name: 'closeWithin12BankBikKi',
        value: '1.0',
      },
      {
        name: 'monthsStartCompanyWithin36PkdCeidg',
        value: 'false',
      },
      {
        name: 'taskScoreAmountsToLimitsBikValue',
        value: '0',
      },
      {
        name: 'minBalance210To240KontoTr',
        value: '0.0',
      },
      {
        name: 'taskExternalOffersIdeaMoneyMessage',
        value: 'Idea Money',
      },
      {
        name: 'sumWithdrawals210To240KontoTr',
        value: '5415.44',
      },
      {
        name: 'sumCurrentBalanceDelaysDays4BankBikKi',
        value: '0.0',
      },
      {
        name: 'sumInstallmentsNoCloseBankBikKi',
        value: '2433.0',
      },
      {
        name: 'taskExternalOffersSantanderValue',
        value: 'false',
      },
      {
        name: 'countDelaysDays30DueAmount500NoPsnNoCloseWithin12BikKi',
        value: '0',
      },
      {
        name: 'flagsPoscashBpmn',
        value: 'true',
      },
      {
        name: 'minBalance330To360KontoTr',
        value: '0.0',
      },
      {
        name: 'taskScoreFirstRelationBikValue',
        value: '20',
      },
      {
        name: 'sumNegativeInformationImKrdErif',
        value: '0.0',
      },
      {
        name: 'maxTerlPaymDateKontoTr',
        value: '0.0',
      },
      {
        name: 'usInstallmentFw',
        value: 'false',
      },
      {
        name: 'countWithdrawals180To210KontoTr',
        value: '85.0',
      },
      {
        name: 'inquiriesWithin3BankBikKi',
        value: '0.0',
      },
      {
        name: 'creditsNoCloseLimitsBankWithin12BikP',
        value: '0.0',
      },
      {
        name: 'statusBel90CloseNonBankBikP',
        value: '0.0',
      },
      {
        name: 'sumInstallmentsNoClosePsnNonBankBikP',
        value: '0.0',
      },
      {
        name: 'quantityAndAmountIm',
        value: 'false',
      },
      {
        name: 'sumDeposits270To300KontoTr',
        value: '4524.0',
      },
      {
        name: 'statusWriRecCloseWithin36BikP',
        value: '0',
      },
      {
        name: 'taskScoreClientAgeFwMessage',
        value: 'BRAK',
      },
      {
        name: 'maxDelayDaysWithin12NonBankBikP',
        value: '0.0',
      },
      {
        name: 'defaultPoscashOfferNettoAmountBpmn',
        value: '0.0',
      },
      {
        name: 'countZusTransactions0To30KontoTr',
        value: '0.0',
      },
      {
        name: 'countZusTransactions120To150KontoTr',
        value: '0.0',
      },
      {
        name: 'dtiAmountKontoAgrBik',
        value: '-2433.0',
      },
      {
        name: 'sumWithdrawals0To30KontoTr',
        value: '2755.05',
      },
      {
        name: 'countryOfBusinessAddressPlCeidg',
        value: '0.0',
      },
      {
        name: 'maxDelayDaysNoCloseBik',
        value: '0',
      },
      {
        name: 'sumIncomeChargesMonth8BikKontoAgr',
        value: '0.0',
      },
      {
        name: 'zusPaymentAmountMonth2KontoAgr',
        value: '0.0',
      },
      {
        name: 'taskScoreRegularPaymentZusUsWithin3KontoAgrMessage',
        value: 'BRAK',
      },
      {
        name: 'regularPaymentZusUsWithin3KontoAgr',
        value: '3',
      },
      {
        name: 'avgNegativeBalance150To180KontoTr',
        value: '0.0',
      },
      {
        name: 'sumZusTransactions30To60KontoTr',
        value: '0.0',
      },
      {
        name: 'defaultPoscashOfferBruttoAmountBpmn',
        value: '10000.0',
      },
      {
        name: 'lastMonth8KontoAgr',
        value: '2021-11',
      },
      {
        name: 'sumLeasing210To240KontoTr',
        value: '0.0',
      },
      {
        name: 'sumRepayment30To60KontoTr',
        value: '0.0',
      },
      {
        name: 'countOverdueInstallmentsBankBikKi',
        value: '0.0',
      },
      {
        name: 'countZusTransactions240To270KontoTr',
        value: '0.0',
      },
      {
        name: 'taskFlagsBikMessage',
        value: 'CZERWONA',
      },
      {
        name: 'avgPositiveBalance330To360KontoTr',
        value: '416.86',
      },
      {
        name: 'sumRepayment150To180KontoTr',
        value: '-135.63',
      },
      {
        name: 'avgBalance270To300KontoTr',
        value: '345.39',
      },
      {
        name: 'sumIncomeLoansWithin3KontoAgr',
        value: '0.0',
      },
      {
        name: 'taskExternalOffersIdeaMoneyValue',
        value: 'true',
      },
      {
        name: 'valScoringKontoScores',
        value: '0.53',
      },
      {
        name: 'usPaymentAmountMonth9KontoAgr',
        value: '0.0',
      },
      {
        name: 'sumWelfare180To210KontoTr',
        value: '0.0',
      },
      {
        name: 'countNegativeInformationImKi',
        value: '0.0',
      },
      {
        name: 'creditsOpenWithin6NonBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumWelfare30To60KontoTr',
        value: '0.0',
      },
      {
        name: 'taskScoreQuantityAmountBaliffWithin6KontoTrMessage',
        value: 'BRAK',
      },
      {
        name: 'sumLimitsNoClosePsnNonBankBikP',
        value: '0.0',
      },
      {
        name: 'zusPaymentAmountMonth9KontoAgr',
        value: '0.0',
      },
      {
        name: 'usPaymentAmountMonth12KontoAgr',
        value: '0.0',
      },
      {
        name: 'amountsToLimitsBik',
        value: '1.0',
      },
      {
        name: 'countWithdrawals60To90KontoTr',
        value: '139.0',
      },
      {
        name: 'taskSimpleOtherCreditsActivityValue',
        value: '0',
      },
      {
        name: 'sumMortgage150To180KontoTr',
        value: '0.0',
      },
      {
        name: 'sumLeasing90To120KontoTr',
        value: '0.0',
      },
      {
        name: 'sumNegativeInformationKrd',
        value: '0.0',
      },
      {
        name: 'avgLoanCompanyWithin12KontoTr',
        value: '-49.81',
      },
      {
        name: 'creditsNoCloseAmountsBankWithin6BikP',
        value: '0.0',
      },
      {
        name: 'poscashOfferBruttoAmount20on12Bpmn',
        value: '0.0',
      },
      {
        name: 'creditsNoClosePsnBankBikP',
        value: '0.0',
      },
      {
        name: 'poscashOfferNettoAmount10on12Bpmn',
        value: '0.0',
      },
      {
        name: 'sumWelfare330To360KontoTr',
        value: '0.0',
      },
      {
        name: 'zusPaymentCountMonth6KontoAgr',
        value: '0.0',
      },
      {
        name: 'maxDelayDaysWithin6Bik',
        value: '20',
      },
      {
        name: 'sumCurrentLimitsNoCloseNonBankBikP',
        value: '0.0',
      },
      {
        name: 'creditsOpenWithin3NoCloseNonBankBikP',
        value: '0.0',
      },
      {
        name: 'countDeposits90To120KontoTr',
        value: '6.0',
      },
      {
        name: 'countUsTransactions150To180KontoTr',
        value: '0.0',
      },
      {
        name: 'sumTerlPaym60To90KontoTr',
        value: '0.0',
      },
      {
        name: 'sumSalaryB2b270To300KontoTr',
        value: '0.0',
      },
      {
        name: 'endBalance120To150KontoTr',
        value: '0.0',
      },
      {
        name: 'endBalance270To300KontoTr',
        value: '0.0',
      },
      {
        name: 'sumSalaryB2b120To150KontoTr',
        value: '0.0',
      },
      {
        name: 'sumCurrentLimitsNoCloseBankBikKi',
        value: '0.0',
      },
      {
        name: 'taskScoreCreditsNoCloseBikMessage',
        value: 'BRAK',
      },
      {
        name: 'countUsTransactions0To30KontoTr',
        value: '0.0',
      },
      {
        name: 'maxDelayDaysWithin12Bik',
        value: '20',
      },
      {
        name: 'sumCurrentLimitsNoCloseBankBikP',
        value: '0.0',
      },
      {
        name: 'taskRelationDecisionMessage',
        value: 'NIE',
      },
      {
        name: 'lastMonth7KontoAgr',
        value: '2021-12',
      },
      {
        name: 'sumLoan60To90KontoTr',
        value: '154.44',
      },
      {
        name: 'taskScoreMonthsStartCompanyCeidgMessage',
        value: 'BRAK',
      },
      {
        name: 'countNegativeInformationErifKi',
        value: '0.0',
      },
      {
        name: 'sumScoringSocioParametersBpmn',
        value: '20.0',
      },
      {
        name: 'taskFlagsKontomatikValue',
        value: 'true',
      },
      {
        name: 'avgNegativeBalance120To150KontoTr',
        value: '0.0',
      },
      {
        name: 'monthsStartCompanyWithin24PkdCeidg',
        value: 'false',
      },
      {
        name: 'sumZusTransactions240To270KontoTr',
        value: '0.0',
      },
      {
        name: 'maxBalance210To240KontoTr',
        value: '0.0',
      },
      {
        name: 'usPaymentAmountMonth3KontoAgr',
        value: '0.0',
      },
      {
        name: 'countWithdrawals300To330KontoTr',
        value: '108.0',
      },
      {
        name: 'poscashOfferNettoAmount20on9Bpmn',
        value: '0.0',
      },
      {
        name: 'avgPositiveBalance0To30KontoTr',
        value: '460.73',
      },
      {
        name: 'sumRepayment120To150KontoTr',
        value: '-135.63',
      },
      {
        name: 'sumRepayment270To300KontoTr',
        value: '0.0',
      },
      {
        name: 'tableScoringAmountPeriod',
        value: '0.0',
      },
      {
        name: 'amountUsAppFw',
        value: '0.0',
      },
      {
        name: 'maxDelayDaysNoCloseBankBikP',
        value: '0.0',
      },
      {
        name: 'accountingTypeFw',
        value: 'BOOK_OF_REVENUES_AND_EXPENSES',
      },
      {
        name: 'sumIncomeChargesMonth5BikKontoAgr',
        value: '0.0',
      },
      {
        name: 'zusUsInstallmentFw',
        value: 'false',
      },
      {
        name: 'sumTerlPaym210To240KontoTr',
        value: '0.0',
      },
      {
        name: 'taskScoreTradeFwMessage',
        value: 'BRAK',
      },
      {
        name: 'poscashOfferBruttoAmount20on9Bpmn',
        value: '0.0',
      },
      {
        name: 'taskSimpleDynamicsIncomeMessage',
        value: 'low',
      },
      {
        name: 'sumDeposits30To60KontoTr',
        value: '3832.27',
      },
      {
        name: 'sumLoan180To210KontoTr',
        value: '0.0',
      },
      {
        name: 'taskScoringAmountPeriodValue',
        value: '0.0',
      },
      {
        name: 'countUsTransactions270To300KontoTr',
        value: '0.0',
      },
      {
        name: 'avgNegativeBalance30To60KontoTr',
        value: '0.0',
      },
      {
        name: 'sumAmountsNoCloseBankBikP',
        value: '0.0',
      },
      {
        name: 'sumWithdrawals90To120KontoTr',
        value: '6623.07',
      },
      {
        name: 'maxDelayDaysWithin6NonBankBikKi',
        value: '0.0',
      },
      {
        name: 'usPaymentAmountMonth8KontoAgr',
        value: '0.0',
      },
      {
        name: 'avgBalanceMonth5KontoAgr',
        value: '284.81',
      },
      {
        name: 'creditsNoCloseWithin6BikP',
        value: '0.0',
      },
      {
        name: 'countWithdrawals330To360KontoTr',
        value: '94.0',
      },
      {
        name: 'sumScoringExchangeRiskBpmn',
        value: '0.0',
      },
      {
        name: 'creditsCloseNonBankBikP',
        value: '0.0',
      },
      {
        name: 'countNegativeInformationErifP',
        value: '0.0',
      },
      {
        name: 'defaultPoscashOfferNettoPeriodBpmn',
        value: '9 miesi\u0119cy',
      },
      {
        name: 'poscashOfferNettoAmount15on12Bpmn',
        value: '0.0',
      },
      {
        name: 'endBalance60To90KontoTr',
        value: '0.0',
      },
      {
        name: 'usPaymentCountMonth4KontoAgr',
        value: '0.0',
      },
      {
        name: 'statusBel90CloseWithin12NonBankBikP',
        value: '0.0',
      },
      {
        name: 'statusDebExeNoCloseBankBikP',
        value: '0.0',
      },
      {
        name: 'sumSalaryB2b150To180KontoTr',
        value: '0.0',
      },
      {
        name: 'creditsNoCloseBankBikKi',
        value: '2.0',
      },
      {
        name: 'taskSimpleCurrentArrearsValue',
        value: '0',
      },
      {
        name: 'zusPaymentAmountMonth3KontoAgr',
        value: '0.0',
      },
      {
        name: 'sumIncomeChargesMonth1BikKontoAgr',
        value: '0.0',
      },
      {
        name: 'maxBalance240To270KontoTr',
        value: '0.0',
      },
      {
        name: 'maxBalance0To30KontoTr',
        value: '0.0',
      },
      {
        name: 'amountPeriodFreeAmountInterestForClient',
        value: '-11145.18',
      },
      {
        name: 'countDeposits300To330KontoTr',
        value: '15.0',
      },
      {
        name: 'usPaymentCountMonth12KontoAgr',
        value: '0.0',
      },
      {
        name: 'creditsNoClosePsnBik',
        value: '0',
      },
      {
        name: 'lastMonth2KontoAgr',
        value: '2022-05',
      },
      {
        name: 'taskSimplePunctualityValue',
        value: '0',
      },
      {
        name: 'sumZusTransactions210To240KontoTr',
        value: '0.0',
      },
      {
        name: 'creditsNoClosePsnNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'zusPaymentCountMonth12KontoAgr',
        value: '0.0',
      },
      {
        name: 'taskFlagsRozneZrodlaValue',
        value: 'true',
      },
      {
        name: 'statusDebExeNoCloseBikP',
        value: '0',
      },
      {
        name: 'sumNegativeInformationImP',
        value: '0.0',
      },
      {
        name: 'maxDelayDaysWithin12NonBankBikKi',
        value: '0.0',
      },
      {
        name: 'inquiriesWithin3BikKi',
        value: '0',
      },
      {
        name: 'taskScoreCreditsNoCloseBikValue',
        value: '10',
      },
      {
        name: 'creditsNoCloseBankBikP',
        value: '0.0',
      },
      {
        name: 'statusBel90CloseWithin12BankBikKi',
        value: '0.0',
      },
      {
        name: 'isPlBusinessAddressCeidg',
        value: 'false',
      },
      {
        name: 'quantityAndAmountErif',
        value: 'false',
      },
      {
        name: 'statusBel90CloseWithin12NonBankBikKi',
        value: '0.0',
      },
      {
        name: 'countWithdrawals270To300KontoTr',
        value: '79.0',
      },
      {
        name: 'maxDelayDaysDueAmount500Bik',
        value: '0',
      },
      {
        name: 'statusBel90ClosekWithin12Bik',
        value: '0.0',
      },
      {
        name: 'sumWelfare150To180KontoTr',
        value: '0.0',
      },
      {
        name: 'sumAmountsNoCloseNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'usPaymentAmountMonth10KontoAgr',
        value: '0.0',
      },
      {
        name: 'taskInstallmentLoanManuallyOrAutomaticValue',
        value: 'false',
      },
      {
        name: 'inquiriesWithin3NonBankBikKi',
        value: '0.0',
      },
      {
        name: 'creditsNoCloseAmountsBankWithin12BikP',
        value: '0.0',
      },
      {
        name: 'taskScoringGroupInterestValue',
        value: '0.03',
      },
      {
        name: 'sumLimitsNoCloseNonBankBikP',
        value: '0.0',
      },
      {
        name: 'sumUsTransactions270To300KontoTr',
        value: '0.0',
      },
      {
        name: 'creditsClosedOpenWithin6NonBankBikKi',
        value: '0.0',
      },
      {
        name: 'zusPaymentCountMonth4KontoAgr',
        value: '0.0',
      },
      {
        name: 'creditsNoCloseLimitsNonBankWithin6BikP',
        value: '0.0',
      },
      {
        name: 'sumTerlPaym90To120KontoTr',
        value: '0.0',
      },
      {
        name: 'sumWelfare90To120KontoTr',
        value: '0.0',
      },
      {
        name: 'lastMonth1KontoAgr',
        value: '2022-06',
      },
      {
        name: 'isFlag',
        value: 'true',
      },
      {
        name: 'usPaymentCountMonth3KontoAgr',
        value: '0.0',
      },
      {
        name: 'poscashOfferNettoAmount10on9Bpmn',
        value: '0.0',
      },
      {
        name: 'countDelaysDays30DueAmount500NoPsnNoCloseWithin12NonbankBikKi',
        value: '0.0',
      },
      {
        name: 'creditsNoCloseWithin12BikP',
        value: '0.0',
      },
      {
        name: 'avgSalaryCompensationWithin12KontoAgr',
        value: '-2433.0',
      },
      {
        name: 'tierScoringKontoScores',
        value: 'D',
      },
      {
        name: 'sumOverdueInstallmentsBik',
        value: '0.0',
      },
      {
        name: 'sumNegativeInformationImKi',
        value: '0.0',
      },
      {
        name: 'poscashOfferBruttoAmount10on9Bpmn',
        value: '0.0',
      },
      {
        name: 'maxDelayDaysWithin6NonBankBikP',
        value: '0.0',
      },
      {
        name: 'avgNegativeBalance240To270KontoTr',
        value: '0.0',
      },
      {
        name: 'maxDelayDaysWithin12BankBikKi',
        value: '20.0',
      },
      {
        name: 'sumNegativeInformationErifP',
        value: '0.0',
      },
      {
        name: 'countZusTransactions150To180KontoTr',
        value: '0.0',
      },
      {
        name: 'zusPaymentCountMonth1KontoAgr',
        value: '0.0',
      },
      {
        name: 'usPaymentCountMonth6KontoAgr',
        value: '0.0',
      },
      {
        name: 'sumLoan90To120KontoTr',
        value: '0.0',
      },
      {
        name: 'sumMortgage270To300KontoTr',
        value: '0.0',
      },
      {
        name: 'avgPositiveBalance240To270KontoTr',
        value: '341.47',
      },
      {
        name: 'inquiriesWithin3BikP',
        value: '20',
      },
      {
        name: 'creditsOpenWithin3Bik',
        value: '0',
      },
      {
        name: 'countDeposits180To210KontoTr',
        value: '12.0',
      },
      {
        name: 'countDeposits60To90KontoTr',
        value: '8.0',
      },
      {
        name: 'noIncomeWithin6KontoTr',
        value: '6',
      },
      {
        name: 'zusPaymentAmountMonth10KontoAgr',
        value: '0.0',
      },
      {
        name: 'sumIncomeChargesMonth4BikKontoAgr',
        value: '0.0',
      },
      {
        name: 'poscashOfferNettoAmount05on6Bpmn',
        value: '0.0',
      },
      {
        name: 'sumZusTransactions120To150KontoTr',
        value: '0.0',
      },
      {
        name: 'zusPaymentCountMonth11KontoAgr',
        value: '0.0',
      },
      {
        name: 'inquiriesWithin12BikKi',
        value: '4',
      },
      {
        name: 'sumInstallmentsNoClosePsnBankBikP',
        value: '0.0',
      },
      {
        name: 'sumTerlPaym120To150KontoTr',
        value: '0.0',
      },
      {
        name: 'taskScoreFundingPurposeFwValue',
        value: '30',
      },
      {
        name: 'sumMortgage90To120KontoTr',
        value: '0.0',
      },
      {
        name: 'sumCurBalNoClosePsnBikKi',
        value: '0.0',
      },
      {
        name: 'poscashProdAndTerlPaymNoWithin6KontoTrFw',
        value: 'false',
      },
      {
        name: 'sumMortgage210To240KontoTr',
        value: '0.0',
      },
      {
        name: 'avgBalance120To150KontoTr',
        value: '340.84',
      },
      {
        name: 'creditsAmountsNoCloseWithin6BankBikKi',
        value: '0.0',
      },
      {
        name: 'countNegativeInformationErif',
        value: '0',
      },
      {
        name: 'zusPaymentCountMonth7KontoAgr',
        value: '0.0',
      },
      {
        name: 'maxTransactionDateKontoTr',
        value: '13.0',
      },
      {
        name: 'avgBalance150To180KontoTr',
        value: '281.96',
      },
      {
        name: 'sumIncomeChargesMonth10BikKontoAgr',
        value: '0.0',
      },
      {
        name: 'countWithdrawals150To180KontoTr',
        value: '111.0',
      },
      {
        name: 'countZusTransactions270To300KontoTr',
        value: '0.0',
      },
      {
        name: 'relationDecisionBpmn',
        value: 'false',
      },
      {
        name: 'dueDateOfInvoicesImmediateFw',
        value: 'true',
      },
      {
        name: 'activityStatusCeidg',
        value: 'AKTYWNY',
      },
      {
        name: 'creditsNoClosePsnBankBikKi',
        value: '0.0',
      },
      {
        name: 'avgBalance0To30KontoTr',
        value: '460.73',
      },
      {
        name: 'taskInterestPoscashValue',
        value: '2.5',
      },
      {
        name: 'countDeposits330To360KontoTr',
        value: '15.0',
      },
      {
        name: 'sumUsTransactions210To240KontoTr',
        value: '0.0',
      },
      {
        name: 'creditsAmountsLimitsNoCloseWithin6BikKi',
        value: '0.0',
      },
      {
        name: 'creditsOpenWithin3BankBikP',
        value: '0.0',
      },
      {
        name: 'sumLimitsNoCloseNoPsnNonBankBikP',
        value: '0.0',
      },
      {
        name: 'taskExternalOffersSmeoValue',
        value: 'false',
      },
      {
        name: 'peselFw',
        value: '89071116359',
      },
      {
        name: 'avgPositiveBalance210To240KontoTr',
        value: '384.47',
      },
      {
        name: 'avgBalanceSalaryCompensationWithin6KontoAgr',
        value: '0.0',
      },
      {
        name: 'countDelaysDays30DueAmount500NoPsnNoCloseWithin6NonbankBikKi',
        value: '0.0',
      },
      {
        name: 'firstCreditOpenDateNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'usPaymentCountMonth9KontoAgr',
        value: '0.0',
      },
      {
        name: 'sumZusTransactions150To180KontoTr',
        value: '0.0',
      },
      {
        name: 'creditsNoCloseBankNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumDeposits120To150KontoTr',
        value: '6800.67',
      },
      {
        name: 'sumSalaryB2b330To360KontoTr',
        value: '0.0',
      },
      {
        name: 'taskScoreDtiKontoAgrBikValue',
        value: '0',
      },
      {
        name: 'poscashOfferBruttoAmount05on6Bpmn',
        value: '0.0',
      },
      {
        name: 'sumWithdrawals240To270KontoTr',
        value: '3759.52',
      },
      {
        name: 'sumTerlPaym180To210KontoTr',
        value: '0.0',
      },
      {
        name: 'sumLoan210To240KontoTr',
        value: '0.0',
      },
      {
        name: 'sumRepayment180To210KontoTr',
        value: '0.0',
      },
      {
        name: 'lastMonth4KontoAgr',
        value: '2022-03',
      },
      {
        name: 'sumIncomeChargesMonth9BikKontoAgr',
        value: '0.0',
      },
      {
        name: 'taskPoscashManuallyOrAutomaticValue',
        value: 'false',
      },
      {
        name: 'creditsNoCloseNoLeaFakNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'minBalance240To270KontoTr',
        value: '0.0',
      },
      {
        name: 'sumLoan270To300KontoTr',
        value: '0.0',
      },
      {
        name: 'taskScoreSumNegativeInformationImKrdErifMessage',
        value: 'BRAK',
      },
      {
        name: 'statusWriRecCloseWithin36NonBankBikP',
        value: '0.0',
      },
      {
        name: 'sumCurrentBalanceDelaysDays4NonBankBikKi',
        value: '0.0',
      },
      {
        name: 'countOverdueInstallmentsBik',
        value: '0',
      },
      {
        name: 'taskFlagsCeidgFormularzMessage',
        value: 'ZIELONA',
      },
      {
        name: 'countUsTransactions300To330KontoTr',
        value: '0.0',
      },
      {
        name: 'creditsNoCloseAmountsNonBankWithin12BikP',
        value: '0.0',
      },
      {
        name: 'creditsOpenWithin3NonBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumLeasing300To330KontoTr',
        value: '0.0',
      },
      {
        name: 'creditPeriodRequest',
        value: '12.0',
      },
      {
        name: 'usPaymentAmountMonth7KontoAgr',
        value: '0.0',
      },
      {
        name: 'closeWithin12BankBikP',
        value: '0.0',
      },
      {
        name: 'avgBalanceMonth6KontoAgr',
        value: '323.26',
      },
      {
        name: 'sumLimitsNoCloseNoPsnBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumScoringPaymentDiscipline',
        value: '260.4',
      },
      {
        name: 'poscashOfferNettoAmount05on12Bpmn',
        value: '0.0',
      },
      {
        name: 'maxDelayDaysWithin6BankBikKi',
        value: '20.0',
      },
      {
        name: 'poscashOfferNettoAmount20on12Bpmn',
        value: '0.0',
      },
      {
        name: 'countZusTransactions210To240KontoTr',
        value: '0.0',
      },
      {
        name: 'usPaymentCountMonth11KontoAgr',
        value: '0.0',
      },
      {
        name: 'levelsSpendsLimitBik',
        value: '1.0',
      },
      {
        name: 'sumWelfare60To90KontoTr',
        value: '0.0',
      },
      {
        name: 'sumZusTransactions270To300KontoTr',
        value: '0.0',
      },
      {
        name: 'sumIncomeChargesMonth12BikKontoAgr',
        value: '0.0',
      },
      {
        name: 'sumScoringEconomicParametersBpmn',
        value: '85.0',
      },
      {
        name: 'creditsOpenWithin6BankBikP',
        value: '0.0',
      },
      {
        name: 'sumDeposits150To180KontoTr',
        value: '5050.0',
      },
      {
        name: 'defaultPoscashOfferPercDailyPaymBpmn',
        value: '10%',
      },
      {
        name: 'statusWriRecCloseWithin36BankBikP',
        value: '0.0',
      },
      {
        name: 'creditsNoCloseNonBankBikP',
        value: '0.0',
      },
      {
        name: 'taskExternalOffersMoneviaValue',
        value: 'true',
      },
      {
        name: 'taskScoreValueBikKiMessage',
        value: 'BRAK',
      },
      {
        name: 'taskExternalOffersMoneviaMessage',
        value: 'Monevia',
      },
      {
        name: 'scoreMessageBikKi',
        value: 'OBLICZONO OCEN\u0118 PUNKTOW\u0104 _00_',
      },
      {
        name: 'statusBel90ClosekBikPCloseBikP',
        value: '0.0',
      },
      {
        name: 'defCardLimitOfferPeriodBpmn',
        value: '12',
      },
      {
        name: 'poscashOfferBruttoAmount20on6Bpmn',
        value: '0.0',
      },
      {
        name: 'creditsLimitsNoCloseWithin6BankBikKi',
        value: '0.0',
      },
      {
        name: 'sumNegativeInformationErifKi',
        value: '0.0',
      },
      {
        name: 'amountZusUsRequestFw',
        value: '0.0',
      },
      {
        name: 'sumWelfare210To240KontoTr',
        value: '0.0',
      },
      {
        name: 'taskScoreMaxDelayDaysNoCloseBikValue',
        value: '40',
      },
      {
        name: 'minBalance0To30KontoTr',
        value: '0.0',
      },
      {
        name: 'sumInstallmentsNoCloseNonBankBikP',
        value: '0.0',
      },
      {
        name: 'firstCreditOpenDateNonBankBikP',
        value: '0.0',
      },
      {
        name: 'sumLoan0To30KontoTr',
        value: '0.0',
      },
      {
        name: 'countUsTransactions240To270KontoTr',
        value: '0.0',
      },
      {
        name: 'defCardLimitOfferBruttoAmountBpmn',
        value: '10000.0',
      },
      {
        name: 'countUsTransactions90To120KontoTr',
        value: '0.0',
      },
      {
        name: 'sumUsTransactions30To60KontoTr',
        value: '0.0',
      },
      {
        name: 'sumLeasing240To270KontoTr',
        value: '0.0',
      },
      {
        name: 'taskScoreMaxDelayDaysDueAmount500BikMessage',
        value: 'BRAK',
      },
      {
        name: 'sumSalaryB2b180To210KontoTr',
        value: '0.0',
      },
      {
        name: 'sumCurrentLimitsNoCloseNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumLimitsNoCloseNoPsnNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'taskFlagsBikValue',
        value: 'true',
      },
      {
        name: 'scoreBikKi',
        value: '552.0',
      },
      {
        name: 'clientAgeFw',
        value: '396.0',
      },
      {
        name: 'endBalance90To120KontoTr',
        value: '0.0',
      },
      {
        name: 'usPaymentAmountMonth4KontoAgr',
        value: '0.0',
      },
      {
        name: 'sumIncomeSalaryCompensationWithin3KontoAgr',
        value: '1.0',
      },
      {
        name: 'taskSimpleCurrentArrearsMessage',
        value: 'good',
      },
      {
        name: 'sumTerlPaym0To30KontoTr',
        value: '0.0',
      },
      {
        name: 'avgNegativeBalance210To240KontoTr',
        value: '0.0',
      },
      {
        name: 'sumCurrentBalanceDelaysDays4Bik',
        value: '0.0',
      },
      {
        name: 'endBalance180To210KontoTr',
        value: '0.0',
      },
      {
        name: 'countNegativeInformationIm',
        value: '0',
      },
      {
        name: 'countWithdrawals210To240KontoTr',
        value: '129.0',
      },
      {
        name: 'sumCurrentBalanceDelaysDays4NonBankBikP',
        value: '0.0',
      },
      {
        name: 'avgBalance30To60KontoTr',
        value: '447.31',
      },
      {
        name: 'taskScoreAvgBalanceSalaryCompensationWithin6KontoAgrMessage',
        value: 'BRAK',
      },
      {
        name: 'taskFlagsPoscashMessage',
        value: 'avgTerlPaymWithin6KontoTr < 7500',
      },
      {
        name: 'monthlyChargerNoPsnBik',
        value: '2433.0',
      },
      {
        name: 'sumIncomeChargesMonth2BikKontoAgr',
        value: '0.0',
      },
      {
        name: 'sumLoan150To180KontoTr',
        value: '0.0',
      },
      {
        name: 'minBalance300To330KontoTr',
        value: '0.0',
      },
      {
        name: 'sumBailiffWithin6KontoTr',
        value: '0.0',
      },
      {
        name: 'sumWithdrawals300To330KontoTr',
        value: '4135.27',
      },
      {
        name: 'minBalance1800To210KontoTr',
        value: '0.0',
      },
      {
        name: 'zusPaymentAmountMonth7KontoAgr',
        value: '0.0',
      },
      {
        name: 'sumCurrentBalanceDelaysDays4BankBikP',
        value: '0.0',
      },
      {
        name: 'lastMonth10KontoAgr',
        value: '2021-09',
      },
      {
        name: 'poscashOfferNettoAmount20on6Bpmn',
        value: '0.0',
      },
      {
        name: 'sumZusTransactions90To120KontoTr',
        value: '0.0',
      },
      {
        name: 'avgMortgageWithin12KontoTr',
        value: '0.0',
      },
      {
        name: 'taskScoreValueBikKiValue',
        value: 'scorePoiBikKi',
      },
      {
        name: 'taskScoreDtiKontoAgrBikMessage',
        value: 'BRAK',
      },
      {
        name: 'statusBel90CloseBankBikP',
        value: '0.0',
      },
      {
        name: 'groupScoringKontoScores',
        value: 'D',
      },
      {
        name: 'creditsOpenWithin3NoCloseNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'incomeStabilityWithin3Divide12KontoAgr',
        value: '0.0',
      },
      {
        name: 'sumScoringWealthonIsHigh',
        value: 'false',
      },
      {
        name: 'sumLimitsNoClosePsnBankBikP',
        value: '0.0',
      },
      {
        name: 'sumLimitsNoClosePsnBankBikKi',
        value: '0.0',
      },
      {
        name: 'avgNegativeBalance60To90KontoTr',
        value: '0.0',
      },
      {
        name: 'countUsTransactions330To360KontoTr',
        value: '0.0',
      },
      {
        name: 'sumRepayment0To30KontoTr',
        value: '0.0',
      },
      {
        name: 'taskScoreMaxDelayDaysDueAmount500BikValue',
        value: '30',
      },
      {
        name: 'productRequestType',
        value: '3.0',
      },
      {
        name: 'interestPoscashBpmn',
        value: '2.5',
      },
      {
        name: 'statusBel90CloseWithin12BankBikP',
        value: '0.0',
      },
      {
        name: 'countZusTransactions180To210KontoTr',
        value: '0.0',
      },
      {
        name: 'depositsMinusWithdrawals0To360KontoTr',
        value: '151.74',
      },
      {
        name: 'countWithdrawals240To270KontoTr',
        value: '66.0',
      },
      {
        name: 'sumMortgage0To30KontoTr',
        value: '0.0',
      },
      {
        name: 'sumIncomeChargesMonth7BikKontoAgr',
        value: '0.0',
      },
      {
        name: 'creditsNoCloseBikKi',
        value: '2',
      },
      {
        name: 'countSuspendWithin12Ceidg',
        value: '0.0',
      },
      {
        name: 'phoneNumberFw',
        value: '793384265',
      },
      {
        name: 'sumUsTransactions120To150KontoTr',
        value: '0.0',
      },
      {
        name: 'typeProductRequest',
        value: '3',
      },
      {
        name: 'endBalance300To330KontoTr',
        value: '0.0',
      },
      {
        name: 'avgNegativeBalance90To120KontoTr',
        value: '0.0',
      },
      {
        name: 'sumDeposits0To30KontoTr',
        value: '2714.0',
      },
      {
        name: 'taskCreditLimitManuallyOrAutomaticMessage',
        value: 'accept',
      },
      {
        name: 'statusDebExeNoCloseBikKi',
        value: '0',
      },
      {
        name: 'avgNegativeBalance300To330KontoTr',
        value: '0.0',
      },
      {
        name: 'maxBalance330To360KontoTr',
        value: '0.0',
      },
      {
        name: 'countDeposits150To180KontoTr',
        value: '13.0',
      },
      {
        name: 'sumSalaryB2b300To330KontoTr',
        value: '0.0',
      },
      {
        name: 'countWithdrawals120To150KontoTr',
        value: '95.0',
      },
      {
        name: 'sumRepayment300To330KontoTr',
        value: '0.0',
      },
      {
        name: 'maxDelayDaysDueAmount500BankBikP',
        value: '0.0',
      },
      {
        name: 'sumTerlPaym270To300KontoTr',
        value: '0.0',
      },
      {
        name: 'sumDeposits0To360KontoTr',
        value: '60205.23',
      },
      {
        name: 'avgBalance0To360KontoTr',
        value: '678.07',
      },
      {
        name: 'sumRepayment60To90KontoTr',
        value: '-190.86',
      },
      {
        name: 'usPaymentAmountMonth1KontoAgr',
        value: '0.0',
      },
      {
        name: 'taskInterestPoscashMessage',
        value: 'A',
      },
      {
        name: 'avgBalance330To360KontoTr',
        value: '416.86',
      },
      {
        name: 'sumMortgage240To270KontoTr',
        value: '0.0',
      },
      {
        name: 'sumDeposits90To120KontoTr',
        value: '6683.95',
      },
      {
        name: 'sumDeposits330To360KontoTr',
        value: '4207.0',
      },
      {
        name: 'lastMonth11KontoAgr',
        value: '2021-08',
      },
      {
        name: 'avgPositiveBalance270To300KontoTr',
        value: '345.39',
      },
      {
        name: 'lastMonth5KontoAgr',
        value: '2022-02',
      },
      {
        name: 'zusPaymentCountMonth8KontoAgr',
        value: '0.0',
      },
      {
        name: 'sumNegativeInformationKrdKi',
        value: '0.0',
      },
      {
        name: 'monthsResumCompanyCeidg',
        value: 'false',
      },
      {
        name: 'sumWithdrawals270To300KontoTr',
        value: '4437.29',
      },
      {
        name: 'sumLimitsNoCloseNoPsnBankBikP',
        value: '0.0',
      },
      {
        name: 'countBaliffWithin6KontoTr',
        value: '0.0',
      },
      {
        name: 'countUniqueLoanCompanyWithin12KontoTr',
        value: '0.0',
      },
      {
        name: 'sumWithdrawals60To90KontoTr',
        value: '9497.78',
      },
      {
        name: 'nacionalityCeidg',
        value: 'true',
      },
      {
        name: 'sumWelfare240To270KontoTr',
        value: '0.0',
      },
      {
        name: 'avgBalanceMonth3KontoAgr',
        value: '2421.68',
      },
      {
        name: 'sumIncomeChargesMonth6BikKontoAgr',
        value: '0.0',
      },
      {
        name: 'usPaymentCountMonth2KontoAgr',
        value: '0.0',
      },
      {
        name: 'noIncomeWithin12KontoTr',
        value: '12',
      },
      {
        name: 'taskScoreQuantityAmountBaliffWithin6KontoTrValue',
        value: '20',
      },
      {
        name: 'sumAmountsCreditsNoCloseBikKi',
        value: '292668.0',
      },
      {
        name: 'creditsNoCloseAmountsNonBankWithin6BikP',
        value: '0.0',
      },
      {
        name: 'avgBalance180To210KontoTr',
        value: '339.44',
      },
      {
        name: 'countUsTransactions210To240KontoTr',
        value: '0.0',
      },
      {
        name: 'zusPaymentCountMonth3KontoAgr',
        value: '0.0',
      },
      {
        name: 'minBalance90To120KontoTr',
        value: '0.0',
      },
      {
        name: 'countOverdueInstallmentsBankBikP',
        value: '0.0',
      },
      {
        name: 'creditsOpenWithin3BankBikKi',
        value: '0.0',
      },
      {
        name: 'usPaymentAmountMonth6KontoAgr',
        value: '0.0',
      },
      {
        name: 'firstNameFw',
        value: 'ADAM',
      },
      {
        name: 'creditsNoCloseNoLeaFakBankBikP',
        value: '0.0',
      },
      {
        name: 'valueScoringKontoScores',
        value: '0.53',
      },
      {
        name: 'countWithdrawals0To30KontoTr',
        value: '64.0',
      },
      {
        name: 'poscashOfferBruttoAmount10on12Bpmn',
        value: '0.0',
      },
      {
        name: 'minBalance270To300KontoTr',
        value: '0.0',
      },
      {
        name: 'sumZusTransactions180To210KontoTr',
        value: '0.0',
      },
      {
        name: 'sumScoringWealthonBpmn',
        value: '365',
      },
      {
        name: 'countOverdueInstallmentsNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'taskScoreTradeFwValue',
        value: '30',
      },
      {
        name: 'countDeposits120To150KontoTr',
        value: '10.0',
      },
      {
        name: 'zusPaymentCountMonth10KontoAgr',
        value: '0.0',
      },
      {
        name: 'creditsOpenWithin6BankBikKi',
        value: '0.0',
      },
      {
        name: 'maxBalance300To330KontoTr',
        value: '0.0',
      },
      {
        name: 'poscashOfferNettoAmount15on6Bpmn',
        value: '0.0',
      },
      {
        name: 'taskScoreAvgBalanceSalaryCompensationWithin6KontoAgrValue',
        value: '0',
      },
      {
        name: 'taskFlagsKontomatikMessage',
        value: 'CZERWONA',
      },
      {
        name: 'creditsOpenWithin3NoCloseBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumDeposits180To210KontoTr',
        value: '3676.4',
      },
      {
        name: 'sumOverdueInstallmentsNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'maxDelayDaysBik',
        value: '0',
      },
      {
        name: 'sumTerlPaym150To180KontoTr',
        value: '0.0',
      },
      {
        name: 'countDelaysDays30DueAmount500NoPsnNoCloseWithin12bankBikKi',
        value: '0.0',
      },
      {
        name: 'taskScoringAmountPeriodMessage',
        value: '0m',
      },
      {
        name: 'avgBalanceMonth2KontoAgr',
        value: '1288.22',
      },
      {
        name: 'monthsStartCompanyCeidg',
        value: '40.0',
      },
      {
        name: 'maxDelayDaysNoCloseNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumLeasing150To180KontoTr',
        value: '0.0',
      },
      {
        name: 'creditsOpenWithin3NoCloseBankBikP',
        value: '0.0',
      },
      {
        name: 'sumOverdueInstallmentsBankBikKi',
        value: '0.0',
      },
      {
        name: 'creditsNoCloseNoLeaFakBik',
        value: '2',
      },
      {
        name: 'creditsNoCloseNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'maxDelayDaysDueAmount500NonBankBikP',
        value: '0.0',
      },
      {
        name: 'sumOverdueInstallmentsBankBikP',
        value: '0.0',
      },
      {
        name: 'defaultPoscashOfferPeriodBpmn',
        value: '9 miesi\u0119cy',
      },
      {
        name: 'closeWithin12NonBankBikP',
        value: '0.0',
      },
      {
        name: 'endBalance0To30KontoTr',
        value: '0.0',
      },
      {
        name: 'endBalance330To360KontoTr',
        value: '0.0',
      },
      {
        name: 'avgPositiveBalance120To150KontoTr',
        value: '340.84',
      },
      {
        name: 'poscashOfferBruttoAmount15on6Bpmn',
        value: '0.0',
      },
      {
        name: 'taskSimpleOtherCreditsActivityMessage',
        value: 'good',
      },
      {
        name: 'taskExternalOffersBibbyValue',
        value: 'true',
      },
      {
        name: 'creditsAmountsNoCloseWithin6NonBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumInstallmentsNoCloseNoPsnBankBikKi',
        value: '2433.0',
      },
      {
        name: 'creditsNoCloseNoLeaFakNonBankBikP',
        value: '0.0',
      },
      {
        name: 'countZusTransactions330To360KontoTr',
        value: '0.0',
      },
      {
        name: 'sumInstallmentsNoClosePsnNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumUsTransactions60To90KontoTr',
        value: '0.0',
      },
      {
        name: 'statusWriRecCloseWithin36Bik',
        value: '0',
      },
      {
        name: 'taskScoreMaxDelayDaysNoCloseBikMessage',
        value: 'BRAK',
      },
      {
        name: 'zusPaymentAmountMonth6KontoAgr',
        value: '0.0',
      },
      {
        name: 'creditsNoCloseNoLeaFakBankBikKi',
        value: '2.0',
      },
      {
        name: 'sumLoan240To270KontoTr',
        value: '0.0',
      },
      {
        name: 'taskExternalOffersSantanderMessage',
        value: 'BRAK',
      },
      {
        name: 'minBalance150To180KontoTr',
        value: '0.0',
      },
      {
        name: 'avgBalance60To90KontoTr',
        value: '2026.72',
      },
      {
        name: 'sumInstallmentsNoCloseNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumWithdrawals150To180KontoTr',
        value: '4915.59',
      },
      {
        name: 'usPaymentCountMonth1KontoAgr',
        value: '0.0',
      },
      {
        name: 'avgWelfareWithin12KontoTr',
        value: '0.0',
      },
      {
        name: 'sumMortgage60To90KontoTr',
        value: '0.0',
      },
      {
        name: 'zusPaymentAmountMonth12KontoAgr',
        value: '0.0',
      },
      {
        name: 'sumSalaryB2b90To120KontoTr',
        value: '0.0',
      },
      {
        name: 'sumWelfare120To150KontoTr',
        value: '0.0',
      },
      {
        name: 'poscashOfferNettoAmount10on6Bpmn',
        value: '0.0',
      },
      {
        name: 'sumWelfare270To300KontoTr',
        value: '0.0',
      },
      {
        name: 'avgTerlPaymWithin6KontoTr',
        value: '0.0',
      },
      {
        name: 'inquiriesBankWithin3BikP',
        value: '0.0',
      },
      {
        name: 'sumScoringCreditworthinessBpmn',
        value: '0.0',
      },
      {
        name: 'sumLeasing270To300KontoTr',
        value: '0.0',
      },
      {
        name: 'zusPaymentCountMonth9KontoAgr',
        value: '0.0',
      },
      {
        name: 'taskFlagsCeidgFormularzValue',
        value: 'false',
      },
      {
        name: 'inquiriesWithin12NonBankBikKi',
        value: '2.0',
      },
      {
        name: 'sumSalaryWithin3KontoAgr',
        value: '0.0',
      },
      {
        name: 'isActiveStatusCeidg',
        value: 'true',
      },
      {
        name: 'taskSimplePunctualityMessage',
        value: 'good',
      },
      {
        name: 'taskSimpleListDebtorsMessage',
        value: 'good',
      },
      {
        name: 'sumAmountsNoCloseNonBankBikP',
        value: '0.0',
      },
      {
        name: 'sumSalaryB2b0To30KontoTr',
        value: '0.0',
      },
      {
        name: 'amountZusAppFw',
        value: '0.0',
      },
      {
        name: 'maxBalance90To120KontoTr',
        value: '0.0',
      },
      {
        name: 'avgBalance300To330KontoTr',
        value: '315.46',
      },
      {
        name: 'emailFw',
        value: 'atelus+17@wealthon.com',
      },
      {
        name: 'sumScoringFinancingStructureBpmn',
        value: '0.0',
      },
      {
        name: 'sumOverdueInstallmentsNonBankBikP',
        value: '0.0',
      },
      {
        name: 'monthlyChargerPsnBik',
        value: '0.0',
      },
      {
        name: 'taskScoreIncomeStabilityWithin3Divide12KontoAgrValue',
        value: '0',
      },
      {
        name: 'maxBalance180To210KontoTr',
        value: '0.0',
      },
      {
        name: 'closeWithin12NonBankBikKi',
        value: '0.0',
      },
      {
        name: 'minBalance30To60KontoTr',
        value: '0.0',
      },
      {
        name: 'countZusTransactions30To60KontoTr',
        value: '0.0',
      },
      {
        name: 'sumIncomeChargesMonth3BikKontoAgr',
        value: '0.0',
      },
      {
        name: 'creditsNoCloseBik',
        value: '2',
      },
      {
        name: 'sumLimitsCreditsNoCloseBikP',
        value: '0.0',
      },
      {
        name: 'maxDelayDaysDueAmount500NonBankBikKi',
        value: '0.0',
      },
      {
        name: 'sumAmountsNoCloseBankBikKi',
        value: '292668.0',
      },
      {
        name: 'sumCompensationB2bWithin3KontoAgr',
        value: '0.0',
      },
      {
        name: 'sumRepayment330To360KontoTr',
        value: '0.0',
      },
      {
        name: 'sumUsTransactions150To180KontoTr',
        value: '0.0',
      },
      {
        name: 'usPaymentCountMonth7KontoAgr',
        value: '0.0',
      },
      {
        name: 'countNegativeInformationImKrdErif',
        value: '0',
      },
      {
        name: 'sumInstallmentsNoCloseNoPsnNonBankBikKi',
        value: '0.0',
      },
      {
        name: 'countZusTransactions300To330KontoTr',
        value: '0.0',
      },
      {
        name: 'avgPositiveBalance150To180KontoTr',
        value: '281.96',
      },
      {
        name: 'statusWriRecCloseWithin36BikKi',
        value: '0',
      },
      {
        name: 'taskScoreRegularPaymentZusUsWithin3KontoAgrValue',
        value: '0',
      },
      {
        name: 'countDeposits30To60KontoTr',
        value: '19.0',
      },
      {
        name: 'sumWithdrawals0To360KontoTr',
        value: '60053.49',
      },
      {
        name: 'taskExternalOffersNestBankValue',
        value: 'false',
      },
      {
        name: 'incomeSalaryB2bWithin3KontoTr',
        value: '0.0',
      },
      {
        name: 'sumDeposits300To330KontoTr',
        value: '4196.5',
      },
      {
        name: 'sumSalaryCompensationWithin12KontoAgr',
        value: '0.0',
      },
      {
        name: 'maxBalance30To60KontoTr',
        value: '0.0',
      },
      {
        name: 'poscashOfferBruttoAmount10on6Bpmn',
        value: '0.0',
      },
      {
        name: 'sumInstallmentsNoCloseNoPsnNonBankBikP',
        value: '0.0',
      },
      {
        name: 'nipFw',
        value: '7681735277',
      },
      {
        name: 'avgNegativeBalance330To360KontoTr',
        value: '0.0',
      },
      {
        name: 'countUsTransactions60To90KontoTr',
        value: '0.0',
      },
      {
        name: 'inquiriesWithin12BankBikKi',
        value: '2.0',
      },
    ],
    trace: [
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '20',
        step: 'taskScoreFirstRelationBik',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '10',
        step: 'taskScoreCreditsNoCloseBik',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '15',
        step: 'taskScoreCreditsOpenWithin3NoCloseDivcreditsOpenBik',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '40',
        step: 'taskScoreMaxDelayDaysNoCloseBik',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '20',
        step: 'taskScoreSumCurrentBalanceDelaysDays4Bik',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '30',
        step: 'taskScoreMaxDelayDaysDueAmount500Bik',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '30',
        step: 'taskScoreSumNegativeInformationImKrdErif',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '30',
        step: 'taskScoreOutstandingAmountInstallmentZusUsFw',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '0',
        step: 'taskScoreRegularPaymentZusUsWithin3KontoAgr',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '20',
        step: 'taskScoreQuantityAmountBaliffWithin6KontoTr',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '30',
        step: 'taskScoreFundingPurposeFw',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '30',
        step: 'taskScoreTradeFw',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '25',
        step: 'taskScoreMonthsStartCompanyCeidg',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '0',
        step: 'taskScoreIncomeStabilityWithin3Divide12KontoAgr',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '0',
        step: 'taskScoreNoIncomeWithin3KontoTr',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '20',
        step: 'taskScoreClientAgeFw',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '0',
        step: 'taskScoreAmountsToLimitsBik',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '0',
        step: 'taskScoreLevelsSpendsLimitBik',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '0',
        step: 'taskScoreDtiKontoAgrBik',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '0',
        step: 'taskScoreSumIncomeSalaryCompensationWithin3KontoAgr',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: '0',
        step: 'taskScoreAvgBalanceSalaryCompensationWithin6KontoAgr',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: 'scorePoiBikKi',
        step: 'taskScoreValueBikKi',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'CZERWONA',
        outValue: 'true',
        step: 'taskFlagsRozneZrodla',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'ZIELONA',
        outValue: 'false',
        step: 'taskFlagsCeidgFormularz',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'ZIELONA',
        outValue: 'false',
        step: 'taskFlagsBigi',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'CZERWONA',
        outValue: 'true',
        step: 'taskFlagsKontomatik',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'CZERWONA',
        outValue: 'true',
        step: 'taskFlagsBik',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'good',
        outValue: '0',
        step: 'taskSimpleCurrentArrears',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'good',
        outValue: '0',
        step: 'taskSimplePunctuality',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'low',
        outValue: '2',
        step: 'taskSimpleDynamicsIncome',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'good',
        outValue: '0',
        step: 'taskSimpleListDebtors',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'low',
        outValue: '2',
        step: 'taskSimpleDebtsDti',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'good',
        outValue: '0',
        step: 'taskSimpleOtherCreditsActivity',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'avgTerlPaymWithin6KontoTr < 7500',
        outValue: 'true',
        step: 'taskFlagsPoscash',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'IX',
        outValue: '0.028',
        step: 'taskScoringGroupInterest',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: '0m',
        outValue: '0.0',
        step: 'taskScoringAmountPeriod',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'A',
        outValue: '2.5',
        step: 'taskInterestPoscash',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'Bibby Financial Services',
        outValue: 'true',
        step: 'taskExternalOffersBibby',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'Monevia',
        outValue: 'true',
        step: 'taskExternalOffersMonevia',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'Idea Money',
        outValue: 'true',
        step: 'taskExternalOffersIdeaMoney',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: 'false',
        step: 'taskExternalOffersSantander',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: 'false',
        step: 'taskExternalOffersSmeo',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'BRAK',
        outValue: 'false',
        step: 'taskExternalOffersNestBank',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'NIE',
        outValue: 'false',
        step: 'taskRelationDecision',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'accept',
        outValue: 'false',
        step: 'taskPoscashManuallyOrAutomatic',
      },
      {
        datetime: '2022-07-22T12:12:46.000000Z',
        outMessage: 'reject',
        outValue: 'false',
        step: 'taskInstallmentLoanManuallyOrAutomatic',
      },
    ],
  },
};
