import moment from 'moment';
import { TableColumnsType } from 'antd';

export interface TableItem {
  id: number;
  createdAt: string;
  email: string;
  nip: string;
  companyName: string;
  hasReport: string;
  isWealthonUser: string;
}

export const columns: TableColumnsType<TableItem> = [
  {
    title: 'Id',
    dataIndex: 'id',
  },
  {
    title: 'Data utworzenia',
    dataIndex: 'createdAt',
    render: (_: undefined, record: TableItem) =>
      moment(record.createdAt).format('DD.MM.YYYY HH:mm'),
    width: 200,
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Numer telefonu',
    dataIndex: 'phone',
    sorter: true,
    width: 200,
  },
  {
    title: 'Nip',
    dataIndex: 'nip',
  },
  {
    title: 'Nazwa firmy',
    dataIndex: 'companyName',
  },
  {
    title: 'Posiada raport',
    dataIndex: 'hasReport',
    width: 140,
  },
  {
    title: 'Użytkownik Wealthon',
    dataIndex: 'isWealthonUser',
    width: 180,
  },
];
