import { useIdleTimer } from 'react-idle-timer';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'js/redux/reducers';
import { logoutUser } from 'js/redux/auth/actions';

/**
 * Hook auto logging out an idle user after the given time.
 * @param timeout Idle timeout in milliseconds
 */
const useAutoLogout = (timeout: number) => {
  const { token } = useSelector((state: RootState) => state.Auth);

  const dispatch: Dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutUser());
    dispatch({ type: 'RESET_APP' });
  };

  useIdleTimer({
    timeout,
    onIdle: token ? logout : undefined,
    debounce: 500,
  });
};

export default useAutoLogout;
