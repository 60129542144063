import { FC, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'js/redux/reducers';

import { IBoxContent } from 'js/interfaces';

import Steps from 'js/components/Steps';
import Box from 'js/components/Box';
import ResetPasswordForm from 'js/views/Register/ResetPassword';
import { NextBtn } from 'js/components/Buttons';
import BtnWrapper from 'js/components/BtnWrapper';
import { routes } from 'js/routes';

export type State = 'new' | 'success';

const ResetPassword: FC = () => {
  const { isResetPassword, loading } = useSelector(
    (state: RootState) => state.Password
  );

  const [state, setState] = useState<State>('new');

  const headings: IBoxContent = useMemo(() => {
    return {
      new: 'Podaj email, na który wyślemy Tobie link do zmiany hasła',
      success: 'Mail został wysłany',
    };
  }, []);

  useEffect(() => {
    if (!loading && isResetPassword) {
      setState('success');
    }
  }, [loading, isResetPassword]);

  return (
    <>
      <Steps active="register" />
      <Box type="heading">
        <h3>{headings[state]}</h3>
      </Box>

      {state === 'success' ? (
        <Box type="content">
          <BtnWrapper>
            <NextBtn to={routes.login}>Wróć do logowania</NextBtn>
          </BtnWrapper>
        </Box>
      ) : (
        <ResetPasswordForm loading={loading} />
      )}
    </>
  );
};

export default ResetPassword;
