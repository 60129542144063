import { AppId } from 'js/interfaces';
import {
  ReportTypeData,
  OfferCategoryEnum,
  AdaptedReport,
} from 'js/interfaces/report';

export enum ReportEnum {
  GENERATE_REPORT_REQUEST = 'GENERATE_REPORT_REQUEST',
  GENERATE_REPORT_SUCCESS = 'GENERATE_REPORT_SUCCESS',
  GENERATE_REPORT_FAILURE = 'GENERATE_REPORT_FAILURE',

  GET_REPORT_REQUEST = 'GET_REPORT_REQUEST',
  GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS',
  GET_REPORT_FAILURE = 'GET_REPORT_FAILURE',

  GET_REPORTS_REQUEST = 'GET_REPORTS_REQUEST',
  GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS',
  GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE',

  CHECK_OFFER_REQUEST = 'CHECK_OFFER_REQUEST',
  CHECK_OFFER_SUCCESS = 'CHECK_OFFER_SUCCESS',
  CHECK_OFFER_FAILURE = 'CHECK_OFFER_FAILURE',

  REFRESH_REPORT_REQUEST = 'REFRESH_REPORT_REQUEST',
  REFRESH_REPORT_SUCCESS = 'REFRESH_REPORT_SUCCESS',
  REFRESH_REPORT_FAILURE = 'REFRESH_REPORT_FAILURE',

  CLEAR_OFFER = 'CLEAR_OFFER',
}

export interface ReportState {
  chosenOffer: string | null;
  list: ReportListElement[];
  error: boolean;
  isLogged: boolean;
  loading: boolean;
  pendingReport: boolean;
  byId: Record<number, AdaptedReport>;
  newestReportId: number | null;
  newestReportData: AdaptedReport | null;
}

export type ReportListElement = {
  id: number;
  applicationId: number;
  date: string;
  expirationDate: string;
};

export type CheckOfferData = {
  applicationId: number | null;
  company: string;
  category: OfferCategoryEnum;
};

export type RefreshReportData = {
  reportId: number;
};

// Actions

// GENERATE
interface GenerateReportAction {
  type: ReportEnum.GENERATE_REPORT_REQUEST;
  method: 'POST';
  data: AppId;
}

interface GenerateReportSuccess {
  type: ReportEnum.GENERATE_REPORT_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: string;
  };
}

interface GenerateReportFailure {
  type: ReportEnum.GENERATE_REPORT_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// GET ONE
interface GetReportAction {
  type: ReportEnum.GET_REPORT_REQUEST;
  method: 'GET';
  id: number;
}

interface GetReportSuccess {
  type: ReportEnum.GET_REPORT_SUCCESS;
  id: number;
  payload: {
    status: 200;
    code: 'OK';
    data: ReportTypeData;
  };
}

interface GetReportFailure {
  type: ReportEnum.GET_REPORT_FAILURE;
  id: number;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// GET ALL
interface GetReportsAction {
  type: ReportEnum.GET_REPORTS_REQUEST;
  method: 'GET';
}

interface GetReportsSuccess {
  type: ReportEnum.GET_REPORTS_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: ReportListElement[];
  };
}

interface GetReportsFailure {
  type: ReportEnum.GET_REPORTS_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// SEND OFFER
interface CheckOfferAction {
  type: ReportEnum.CHECK_OFFER_REQUEST;
  method: 'POST';
  data: CheckOfferData;
}

interface CheckOfferSuccess {
  type: ReportEnum.CHECK_OFFER_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      applicationId: number;
      category: number;
      company: string;
    };
  };
}

interface CheckOfferFailure {
  type: ReportEnum.CHECK_OFFER_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

interface RefreshReportAction {
  type: ReportEnum.REFRESH_REPORT_REQUEST;
  method: 'POST';
  data: RefreshReportData;
}

interface RefreshReportSuccess {
  type: ReportEnum.REFRESH_REPORT_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: 'string';
  };
}

interface RefreshReportFailure {
  type: ReportEnum.REFRESH_REPORT_FAILURE;
  payload: {
    status: 422;
    code: 'Unprocessable Entity';
    errors: string[];
  };
}

interface ClearOfferAction {
  type: ReportEnum.CLEAR_OFFER;
}

export type ReportActionTypes =
  | GetReportsAction
  | GetReportAction
  | GenerateReportAction
  | CheckOfferAction
  | ClearOfferAction
  | RefreshReportAction;

export type ReportApiTypes =
  | GetReportsSuccess
  | GetReportsFailure
  | GetReportSuccess
  | GetReportFailure
  | GenerateReportSuccess
  | GenerateReportFailure
  | CheckOfferSuccess
  | CheckOfferFailure
  | RefreshReportSuccess
  | RefreshReportFailure;
