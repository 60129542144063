import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'js/redux/reducers';
import { cancelAppData } from 'js/redux/account/actions';

import { Button } from 'antd';

import 'js/components/CancelUpdate/styles.scss';

const CancelUpdate: FC = () => {
  const { app, loading } = useSelector((state: RootState) => state.Account);

  const dispatch: Dispatch = useDispatch();

  const handleCancel = useCallback(() => {
    app.id && dispatch(cancelAppData(app.id));
  }, [app, dispatch]);

  return (
    <div className="cancel-update">
      <Button loading={loading} type="primary" onClick={handleCancel}>
        Przerwij aktualizacje raportu
      </Button>
    </div>
  );
};

export default CancelUpdate;
