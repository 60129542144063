import { FC } from 'react';

import { Steps } from 'antd';

import Icon from 'js/components/Icon';
import 'js/components/Steps/styles.scss';

type Props = {
  active?: 'application' | 'account' | 'agreement';
};

const SmeoSteps: FC<Props> = ({ active }) => {
  const { Step } = Steps;

  return (
    <Steps labelPlacement="vertical" className="custom-steps">
      <Step
        status={
          active === 'application' ||
          active === 'account' ||
          active === 'agreement'
            ? 'finish'
            : 'wait'
        }
        title="Wniosek"
        icon={<Icon name="pencil" />}
      />
      <Step
        status={
          active === 'agreement' || active === 'account' ? 'finish' : 'wait'
        }
        title="Konto"
        icon={<Icon name="dolar" />}
      />
      <Step
        status={active === 'agreement' ? 'finish' : 'wait'}
        title="Umowa"
        icon={<Icon name="document" />}
      />
    </Steps>
  );
};

export default SmeoSteps;
