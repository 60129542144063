import { FC, MouseEvent, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import Consent from 'js/components/Consent';

import 'js/components/Collapse/styles.scss';

type Props = {
  id: number;
  label: string;
  text: string;
  required: boolean;
  initCheck?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
};

const Collapse: FC<Props> = ({
  id,
  label,
  text,
  required,
  initCheck = false,
  onChange,
}) => {
  const [active, setActive] = useState(false);

  const toggleActive = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setActive(!active);
  };

  return (
    <div className={`collapse ${active ? 'collapse--active' : ''}`}>
      <div className="collapse__header">
        <Consent
          id={id}
          required={required}
          onChange={onChange}
          initCheck={initCheck}
        >
          {label}
        </Consent>
        <button className="collapse__btn-more" onClick={toggleActive}>
          {active ? 'Zwiń' : 'Rozwiń'}
        </button>
      </div>

      <div
        className="collapse__content"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

export default Collapse;
