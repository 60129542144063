import { REHYDRATE } from 'redux-persist';
import { RehydrateRootState } from 'js/redux/reducers';
import {
  PasswordEnum,
  PasswordState,
  PasswordActionTypes,
  PasswordApiTypes,
} from 'js/redux/password/types';

import { notification } from 'antd';

export const INIT_STATE: PasswordState = {
  error: '',
  loading: false,
  isResetPassword: false,
  isNewPassword: false,
  isVerified: false,
};

export default function reducer(
  state = INIT_STATE,
  action: PasswordActionTypes | PasswordApiTypes | RehydrateRootState
): PasswordState {
  if (state === undefined) {
    return INIT_STATE;
  }

  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        loading: false,
        error: '',
      };

    case PasswordEnum.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        isResetPassword: false,
      };

    case PasswordEnum.RESET_PASSWORD_SUCCESS:
      notification['success']({
        message:
          'Wiadomość z linkiem do zmiany hasła została wysłana na podany adres email',
        description: 'Kliknij w link z wiadomości email aby zresetować hasło',
      });
      return {
        ...state,
        error: '',
        loading: false,
        isResetPassword: true,
      };

    case PasswordEnum.RESET_PASSWORD_FAILURE:
      notification['error']({
        message: 'Błąd podczas resetowania hasła',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        error: '',
        loading: false,
      };

    case PasswordEnum.NEW_PASSWORD_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        isNewPassword: false,
      };

    case PasswordEnum.NEW_PASSWORD_SUCCESS:
      notification['success']({
        message: 'Hasło zmienione pomyślnie',
      });
      return {
        ...state,
        error: '',
        loading: false,
        isNewPassword: true,
      };

    case PasswordEnum.NEW_PASSWORD_FAILURE:
      notification['error']({
        message: 'Błąd ustawiania nowego hasła',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        error: '',
        loading: false,
      };

    case PasswordEnum.VERIFY_PASSWORD_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        isVerified: false,
      };

    case PasswordEnum.VERIFY_PASSWORD_SUCCESS:
      notification['success']({
        message: 'Kod weryfikacyjny został wysłany na twój numer telefonu.',
      });
      return {
        ...state,
        error: '',
        loading: false,
        isVerified: true,
      };

    case PasswordEnum.VERIFY_PASSWORD_FAILURE:
      notification['error']({
        message: 'Błąd podczas wysyłania kodu sms.',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        error: '',
        loading: false,
      };

    case PasswordEnum.CHANGE_PASSWORD_FAILURE:
      notification['error']({
        message: 'Błąd podczas zmiany hasła',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        error: '',
        loading: false,
      };

    case PasswordEnum.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
      };

    case PasswordEnum.CHANGE_PASSWORD_SUCCESS:
      notification['success']({
        message: 'Hasło zmienione pomyślnie',
      });
      return {
        ...state,
        error: '',
        loading: false,
        isVerified: false,
      };

    default:
      return state;
  }
}
