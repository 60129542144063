import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import { getStore } from 'js/redux/store';

import { worker } from 'js/mocks/browser';
import SWReload from 'js/components/SwReload';

import App from 'js/App';

if (process.env.IS_PRESENTATION === 'true') {
  worker.start();
}

if (!!process.env.SENTRY_DNS) {
  Sentry.init({
    dsn: process.env.SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.ENVIRONMENT,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

if (!!process.env.GTM_ID) {
  TagManager.initialize({ gtmId: process.env.GTM_ID });
}

const { store, persistor } = getStore();

ReactDOM.render(
  <Provider store={store}>
    <SWReload />
    <PersistGate loading={null} persistor={persistor}>
      <HashRouter>
        <App />
      </HashRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
