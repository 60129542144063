import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'js/redux/reducers';

import AccountPersonal from 'js/views/Account/personal';
import AccountCompany from 'js/views/Account/company';
import AccountPassword from 'js/views/Account/password';

import { MaritalStatusEnum } from 'js/interfaces/account';

import { Col, Row } from 'antd';

import 'js/pages/Account/styles.scss';

export type PersonalData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  pesel: string;
  idCard: string;
  street: string;
  houseNo: string;
  apartmentNo: string;
  city: string;
  postCode: string;
  smsCode: string;
  maritalStatus: MaritalStatusEnum | null;
};

export type CompanyData = {
  nip: string;
  companyName: string;
};

export type PasswordData = {
  currentPassword: string;
  newPassword: string;
  smsCode: string;
};

const Account: FC = () => {
  const { loading } = useSelector((state: RootState) => state.Auth);
  const {
    loading: accLoading,
    user,
    company,
    isVerified: isAccVerified,
  } = useSelector((state: RootState) => state.Account);
  const { loading: passLoading, isVerified: isPassVerified } = useSelector(
    (state: RootState) => state.Password
  );

  const defaultPersonalData: PersonalData = useMemo(
    () => ({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      pesel: user.pesel,
      idCard: user.idCard,
      street: user.street,
      houseNo: user.houseNo,
      apartmentNo: user.apartmentNo,
      city: user.city,
      postCode: user.postCode,
      smsCode: '',
      maritalStatus: user?.maritalStatus ?? null,
    }),
    [user]
  );

  const defaultCompanyData = useMemo(
    () => ({
      nip: company.nip ?? '',
      companyName: company.name ?? '',
      arrearsInstallmentUs: company?.arrearsInstallmentUs ? 0 : 1,
      arrearsInstallmentZus: company?.arrearsInstallmentZus ? 0 : 1,
      arrearsUs: company.arrearsUs ?? 0,
      arrearsZus: company.arrearsZus ?? 0,
    }),
    [company]
  );

  const defaultPasswordData: PasswordData = useMemo(
    () => ({
      currentPassword: '',
      newPassword: '',
      smsCode: '',
    }),
    []
  );

  return (
    <div className="settings-forms">
      <Row gutter={{ xs: 0, sm: 0, md: 48, lg: 48 }}>
        <Col xs={24} sm={24} md={12}>
          <AccountPersonal
            data={defaultPersonalData}
            loading={loading || accLoading}
            verified={isAccVerified}
          />
        </Col>
        <Col xs={24} sm={24} md={12}>
          <AccountCompany data={defaultCompanyData} />
          <AccountPassword
            data={defaultPasswordData}
            loading={passLoading}
            verified={isPassVerified}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Account;
