import { takeLatest } from 'redux-saga/effects';
import { AccountEnum } from 'js/redux/account/types';
import { createSaga } from 'js/redux/sagas';

function* accountSaga() {
  yield takeLatest(
    AccountEnum.CREATE_APP_REQUEST,
    createSaga('CREATE_APP', '/application', 'POST')
  );

  yield takeLatest(
    AccountEnum.GET_APPS_REQUEST,
    createSaga('GET_APPS', '/application', 'GET')
  );

  yield takeLatest(
    AccountEnum.NIP_REQUEST,
    createSaga('NIP', '/company/ceidg', 'GET')
  );

  yield takeLatest(
    AccountEnum.CONFIRM_NIP_REQUEST,
    createSaga('CONFIRM_NIP', '/company/confirm', 'POST')
  );

  yield takeLatest(
    AccountEnum.PERSONAL_REQUEST,
    createSaga('PERSONAL', '/user/personal-data', 'POST')
  );

  yield takeLatest(
    AccountEnum.SELF_REQUEST,
    createSaga('SELF', '/user/additional-data', 'POST')
  );

  yield takeLatest(
    AccountEnum.COMPANY_REQUEST,
    createSaga('COMPANY', '/company/update-data', 'POST')
  );

  yield takeLatest(
    AccountEnum.GET_CONSENTS_REQUEST,
    createSaga('GET_CONSENTS', '/consents', 'GET')
  );

  yield takeLatest(
    AccountEnum.POST_CONSENTS_REQUEST,
    createSaga('POST_CONSENTS', '/consents', 'POST')
  );

  yield takeLatest(
    AccountEnum.ADDRESS_REQUEST,
    createSaga('ADDRESS', '/user/address-data', 'POST')
  );

  yield takeLatest(
    AccountEnum.EDIT_PHONE_REQUEST,
    createSaga(
      'EDIT_PHONE',
      '/user/generate-new-phone-verification-token',
      'POST'
    )
  );

  yield takeLatest(
    AccountEnum.VERIFY_PHONE_REQUEST,
    createSaga('VERIFY_PHONE', '/user/confirm-phone', 'POST')
  );

  yield takeLatest(
    AccountEnum.NEW_VERIFY_CODE_REQUEST,
    createSaga(
      'NEW_VERIFY_CODE',
      '/user/generate-new-phone-verification-token',
      'POST'
    )
  );

  yield takeLatest(
    AccountEnum.GET_USER_REQUEST,
    createSaga('GET_USER', '/user', 'GET')
  );

  yield takeLatest(
    AccountEnum.VERIFY_DATA_REQUEST,
    createSaga('VERIFY_DATA', '/user/verify-data', 'POST')
  );

  yield takeLatest(
    AccountEnum.CHANGE_DATA_REQUEST,
    createSaga('CHANGE_DATA', '/user/data', 'POST')
  );

  yield takeLatest(
    AccountEnum.CANCEL_APP_UPDATE_REQUEST,
    createSaga('CANCEL_APP_UPDATE', '/application', 'POST')
  );

  yield takeLatest(
    AccountEnum.GET_WF_USER_REQUEST,
    createSaga('GET_WF_USER', '/wealthon/account-data')
  );
}

export default accountSaga;
