import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { formatPrice } from 'js/helpers/formatters';

import BtnWrapper from 'js/components/BtnWrapper';
import { SubmitBtn } from 'js/components/Buttons';
import Box from 'js/components/Box';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import { submitLimit } from 'js/redux/smeo/actions';

import 'js/views/Smeo/Limits/styles.scss';

type Props = {
  loading: boolean;
  limit: number;
  limitOnline: number;
};

const SmeoLimits: FC<Props> = ({ loading, limit, limitOnline }) => {
  const dispatch: Dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(submitLimit());
  };

  return (
    <Box type="content" className="wider wider--heading">
      <div className="limit-form__content">
        <div className="limit-form__content__info">
          <h5>Twój limit</h5>
          <h3>{formatPrice(limit)}zł</h3>
          <small>
            <ExclamationCircleOutlined /> Z papierowa umową
          </small>
        </div>
        <div className="limit-form__content__info">
          <h5>Dostępny online</h5>
          <h3>{formatPrice(limitOnline)}zł</h3>
        </div>
      </div>

      <BtnWrapper>
        <SubmitBtn onClick={handleSubmit} loading={loading}>
          Podpisz umowę online
        </SubmitBtn>
      </BtnWrapper>
    </Box>
  );
};

export default SmeoLimits;
