import { FC, useEffect } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { setPersonalData } from 'js/redux/account/actions';

import { onlyDigits } from 'js/helpers/formatters';

import { Form, Input, FormInstance } from 'antd';
import { SafetyCertificateOutlined } from '@ant-design/icons';

import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { PrevBtn, SubmitBtn } from 'js/components/Buttons';
import { routes } from 'js/routes';

import { Data } from 'js/pages/Data/personal';
import { useHistory } from 'react-router';
import { objectsEquals } from 'js/helpers/objectsEquals';

import { validateID, validatePesel } from 'js/helpers/validators';

import 'js/views/Data/Personal/styles.scss';

type Props = {
  loading: boolean;
  data: Data;
};

const EncryptedText: FC = () => (
  <span className="personal-form__icon-text">
    <SafetyCertificateOutlined />
    Twoje dane są bezpieczne
  </span>
);

const Personal: FC<Props> = ({ loading, data }) => {
  const [form]: [FormInstance<Data>] = Form.useForm();
  const dispatch: Dispatch = useDispatch();

  const history = useHistory();

  const handleSubmit = (values: Data) => {
    objectsEquals(data, values)
      ? history.push(routes.self)
      : dispatch(setPersonalData(values));
  };

  useEffect(() => {
    data && form.setFieldsValue(data);
  }, [data, form]);

  return (
    <Box type="content" className="personal-form">
      <Form
        form={form}
        onFinish={handleSubmit}
        scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
      >
        <Form.Item
          name="idCard"
          label="Numer i seria dowodu osobistego"
          rules={[
            {
              required: true,
              validator: validateID,
            },
          ]}
        >
          <Input
            size="large"
            maxLength={9}
            placeholder="Wpisz numer dowodu..."
          />
        </Form.Item>

        <EncryptedText />

        <Form.Item
          name="pesel"
          label="PESEL"
          normalize={onlyDigits}
          rules={[
            {
              required: true,
              validator: validatePesel,
            },
          ]}
        >
          <Input
            size="large"
            inputMode="numeric"
            maxLength={11}
            placeholder="Wpisz numer PESEL..."
          />
        </Form.Item>

        <EncryptedText />

        <BtnWrapper>
          <PrevBtn to={routes.company}>Wstecz</PrevBtn>
          <SubmitBtn loading={loading}>Przejdź dalej</SubmitBtn>
        </BtnWrapper>

        <div className="personal-form__certyficates">
          <h5>
            <b>Simple.pl </b> posiada certyfikaty wiarygodnej firmy
          </h5>
          <div>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.big.pl/certyfikat/nip/8252185724"
            >
              <img
                alt="Firma wiarygodna finansowo"
                src="/images/finansowo.png"
              />
            </a>
          </div>
        </div>
      </Form>
    </Box>
  );
};

export default Personal;
