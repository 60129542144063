import Auth from 'js/redux/auth/reducer';
import Account from 'js/redux/account/reducer';
import Admin from 'js/redux/admin/reducer';
import Banking from 'js/redux/banking/reducer';
import Password from 'js/redux/password/reducer';
import Report from 'js/redux/report/reducer';
import Smeo from 'js/redux/smeo/reducer';

import { AuthState } from 'js/redux/auth/types';
import { AccountState } from 'js/redux/account/types';
import { AdminState } from 'js/redux/admin/types';
import { BankState } from 'js/redux/banking/types';
import { PasswordState } from 'js/redux/password/types';
import { ReportState } from 'js/redux/report/types';
import { SmeoState } from 'js/redux/smeo/types';

import { RehydrateAction } from 'redux-persist';

export interface RootState {
  Auth: AuthState;
  Account: AccountState;
  Admin: AdminState;
  Banking: BankState;
  Password: PasswordState;
  Report: ReportState;
  Smeo: SmeoState;
}

export interface RehydrateRootState extends RehydrateAction {
  payload?: RootState;
}

export default {
  Auth,
  Account,
  Admin,
  Banking,
  Password,
  Report,
  Smeo,
};
