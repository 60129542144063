import { FC } from 'react';

import Steps from 'js/components/Steps';
import Box from 'js/components/Box';

const CheckAndPay: FC = () => (
  <>
    <Steps active="payment" />
    <Box type="heading">
      <h3>Sprawdź dane poniżej i dokonaj płatności</h3>
    </Box>
  </>
);

export default CheckAndPay;
