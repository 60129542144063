import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';

import queryString from 'query-string';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'js/redux/reducers';
import { getWfUser } from 'js/redux/account/actions';

import { routes } from 'js/routes';

const useWfUser = () => {
  const history = useHistory();
  const { wfUser } = useSelector((state: RootState) => state.Account);
  const dispatch: Dispatch = useDispatch();

  const wfToken = useMemo(
    () => queryString.parse(history?.location?.search)?.wf_token,
    [history]
  );

  useEffect(() => {
    if (!!wfToken?.length && !Array.isArray(wfToken)) {
      dispatch(getWfUser({ token: wfToken }));
      history.replace({
        search: '',
      });
    }
  }, [wfToken, dispatch, history]);

  useEffect(() => {
    if (wfUser.exists) {
      history.push(routes.login);
    }
  }, [wfUser, history]);
};

export default useWfUser;
