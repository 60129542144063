import { FC, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'js/redux/reducers';

import { checkOffer } from 'js/redux/report/actions';

import { AdaptedPotentialOffer, CompanyEnum } from 'js/interfaces/report';

import { SubmitBtn } from 'js/components/Buttons';
import { routes } from 'js/routes';

import 'js/components/Report/PotentialOfferBox/styles.scss';

type Props = {
  data: AdaptedPotentialOffer;
};

const PotentialOfferBox: FC<Props> = ({ data }) => {
  const history = useHistory();
  const dispatch: Dispatch = useDispatch();
  const { chosenOffer } = useSelector((state: RootState) => state.Report);

  const onSubmitOffer = (link: string, isExternal: boolean = false) => {
    dispatch(
      checkOffer({
        applicationId: data.appId,
        company: data.company?.toLowerCase(),
        category: data.offerType,
      })
    );

    isExternal ? window.open(link, '_blank')?.focus() : history.push(link);
  };

  useEffect(() => {
    if (chosenOffer) history.push(routes.thank);
  }, [chosenOffer, history]);

  return (
    <div
      className={`factoring-box ${
        data.applied ? 'factoring-box --applied' : ''
      }`}
    >
      <div className="factoring-box__logo">
        {data.logo && <img src={data.logo} alt={data.company} />}
      </div>

      <ul className="factoring-box__info">
        <li>
          <h4>Nazwa firmy</h4>
          <span>{data.company}</span>
        </li>
        <li>
          <h4>Typ produktu</h4>
          <span>{data.type}</span>
        </li>
        <li>
          <h4>Kategoria produktu</h4>
          <span>{data.category}</span>
        </li>
      </ul>
      <div className="factoring-box__btn-wrapper">
        {data.company === CompanyEnum.Smeo && (
          <SubmitBtn onClick={() => onSubmitOffer(routes.smeoFinish)}>
            Złóż wniosek
          </SubmitBtn>
        )}
        {data.link && data.company !== CompanyEnum.Smeo && (
          <SubmitBtn
            onClick={() =>
              onSubmitOffer(
                data.link?.includes('http') ? data.link : `//${data.link}`,
                true
              )
            }
          >
            Złóż wniosek
          </SubmitBtn>
        )}
      </div>
    </div>
  );
};

export default PotentialOfferBox;
