import { FC, useMemo } from 'react';
import { RootState } from 'js/redux/reducers';
import { useSelector } from 'react-redux';

import Steps from 'js/components/Steps';
import Box from 'js/components/Box';
import AddressForm from 'js/views/Data/Address';

export type Data = {
  street: string;
  houseNo: string;
  apartmentNo: string;
  city: string;
  postCode: string;
};

const Address: FC = () => {
  const { loading, user, isRefresh } = useSelector(
    (state: RootState) => state.Account
  );

  const defaultData = useMemo(
    () => ({
      street: user.street,
      houseNo: user.houseNo,
      apartmentNo: user.apartmentNo,
      city: user.city,
      postCode: user.postCode,
    }),
    [user]
  );

  return (
    <>
      <Steps active="data" />
      <Box type="heading">
        <h3>
          {isRefresh
            ? 'Sprawdź, czy podany adres zamieszkania jest prawidłowy'
            : 'Podaj swój adres zamieszkania'}
        </h3>
      </Box>
      <AddressForm data={defaultData} loading={loading} />
    </>
  );
};

export default Address;
