import { AppId } from 'js/interfaces';
import {
  BookKeepingEnum,
  EducationEnum,
  HousingStatusEnum,
  MaritalStatusEnum,
} from 'js/interfaces/account';

export enum SmeoEnum {
  GET_SMEO_CONSENTS_REQUEST = 'GET_SMEO_CONSENTS_REQUEST',
  GET_SMEO_CONSENTS_SUCCESS = 'GET_SMEO_CONSENTS_SUCCESS',
  GET_SMEO_CONSENTS_FAILURE = 'GET_SMEO_CONSENTS_FAILURE',

  POST_SMEO_CONSENTS_REQUEST = 'POST_SMEO_CONSENTS_REQUEST',
  POST_SMEO_CONSENTS_SUCCESS = 'POST_SMEO_CONSENTS_SUCCESS',
  POST_SMEO_CONSENTS_FAILURE = 'POST_SMEO_CONSENTS_FAILURE',

  GET_COMPANY_VERIFICATION_REQUEST = 'GET_COMPANY_VERIFICATION_REQUEST',
  GET_COMPANY_VERIFICATION_SUCCESS = 'GET_COMPANY_VERIFICATION_SUCCESS',
  GET_COMPANY_VERIFICATION_FAILURE = 'GET_COMPANY_VERIFICATION_FAILURE',

  SUBMIT_APPLICATION_REQUEST = 'SUBMIT_APPLICATION_REQUEST',
  SUBMIT_APPLICATION_SUCCESS = 'SUBMIT_APPLICATION_SUCCESS',
  SUBMIT_APPLICATION_FAILURE = 'SUBMIT_APPLICATION_FAILURE',

  GET_PROCESS_APP_REQUEST = 'GET_PROCESS_APP_REQUEST',
  GET_PROCESS_APP_SUCCESS = 'GET_PROCESS_APP_SUCCESS',
  GET_PROCESS_APP_FAILURE = 'GET_PROCESS_APP_FAILURE',

  SUBMIT_CREATE_CONTRACT_REQUEST = 'SUBMIT_CREATE_CONTRACT_REQUEST',
  SUBMIT_CREATE_CONTRACT_SUCCESS = 'SUBMIT_CREATE_CONTRACT_SUCCESS',
  SUBMIT_CREATE_CONTRACT_FAILURE = 'SUBMIT_CREATE_CONTRACT_FAILURE',

  GET_CREATE_CONTRACT_REQUEST = 'GET_CREATE_CONTRACT_REQUEST',
  GET_CREATE_CONTRACT_SUCCESS = 'GET_CREATE_CONTRACT_SUCCESS',
  GET_CREATE_CONTRACT_FAILURE = 'GET_CREATE_CONTRACT_FAILURE',

  GET_CONTRACT_REQUEST = 'GET_CONTRACT_REQUEST',
  GET_CONTRACT_SUCCESS = 'GET_CONTRACT_SUCCESS',
  GET_CONTRACT_FAILURE = 'GET_CONTRACT_FAILURE',

  SIGN_IN_CONTRACT_REQUEST = 'SIGN_IN_CONTRACT_REQUEST',
  SIGN_IN_CONTRACT_SUCCESS = 'SIGN_IN_CONTRACT_SUCCESS',
  SIGN_IN_CONTRACT_FAILURE = 'SIGN_IN_CONTRACT_FAILURE',

  GET_PROGRESS_REQUEST = 'GET_PROGRESS_REQUEST',
  GET_PROGRESS_SUCCESS = 'GET_PROGRESS_SUCCESS',
  GET_PROGRESS_FAILURE = 'GET_PROGRESS_FAILURE',

  SUBMIT_LIMIT = 'SUBMIT_LIMIT',
}

export type SmeoState = {
  actualStep: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  bankAccounts: BankAccountNumber[];
  consents: SmeoConsent[];
  limit: number | null;
  limitOnline: number | null;
  loading: boolean;
  processNumber: string;
  error: boolean;
  agreement: {
    name: string;
    data: string;
  };
  decision: DecisionEnum | '';
};

export enum DecisionEnum {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
  MANUAL = 'Manual',
}

export enum ResultEnum {
  SUCCESS = 'Success',
  PENDING = 'Pending',
  ERROR = 'Error',
}

export type SmeoConsent = {
  consentId: number;
  content: string;
  required: boolean;
  consentType: string;
};

export type SmeoSignatory = {
  pesel: string;
  firstName: string;
  surname: string;
};

export type PostSmeoConsentsData = {
  applicationId: number;
  processNumber: string;
  nip: string;
  email: string;
  phoneNumber: string;
  consents: SmeoConsent[];
};

export type VerifiData = {
  applicationId: number | null;
  processNumber: string;
};

export type SubmitApplicationData = {
  applicationId: number | null;
  processNumber: string;
  application: {
    factorerNip: string | null;
    income: number | null;
    contractorCount: number | null;
    isDeferredPaymentDate: boolean;
    bookkeepingTypeId: BookKeepingEnum | null;
    avgInvoiceMaturity: string | null;
    proposedLimit?: number;
    applicant: {
      pesel: string;
      firstName: string;
      surname: string;
      idNumber: string;
      idIssueDate: string;
      idExpiryDate: string;
      mainAddress: {
        locality: string;
        voivodship?: string;
        postalCode: string;
        street: string;
        houseNumber: string;
        apartmentNumber?: string;
      };
      correspondenceAddress: {
        locality: string;
        voivodship?: string;
        postalCode: string;
        street: string;
        houseNumber: string;
        apartmentNumber?: string;
      };
      educationTypeId: EducationEnum | null;
      maritalStatusTypeId: MaritalStatusEnum | null;
      houseStatusTypeId: HousingStatusEnum | null;
      otherIncome: number | null;
      phone: string;
      email: string;
      consents: { id: number; checked: boolean }[];
      isRepresentative: boolean;
      isShareholder: boolean;
      isPep: boolean;
    };
  };
};

export type Progress = {
  processNumber: string;
  companyVerification: {
    success: boolean;
    resultType: 'Success' | 'Error' | 'Pending';
    decision: DecisionEnum | '';
    company: {};
  };
  processApplication: {
    success: boolean;
    resultType: 'Success' | 'Error' | 'Pending';
    decision: DecisionEnum | '';
    bankAccountNumbers: BankAccountNumber[];
    limit: number;
    limitOnline: number;
    decisionMessage: string;
  };
  contractData: {
    id: number;
    state: string;
    signature: string;
    vendorClientAccessToken: string;
  };
  createContract: {
    success: boolean;
    resultType: 'Success' | 'Error' | 'Pending';
    contract: {};
  };
  getContract: {
    success: true;
    contractFileDataBase64: string;
    fileName: string;
  };
  contractSigned: boolean;
};

export type BankAccountNumber = {
  accountNo: string;
  currencyCode: 'EUR' | 'PLN' | string;
};

export type SubmitCreateContractData = {
  applicationId: number;
  processNumber: string;
  bankAccountNumbers: BankAccountNumber[];
};

export type SignInContractData = {
  applicationId: number;
  processNumber: string;
  signatories: SmeoSignatory[];
};

// Actions

// GET SMEO CONSENTS
interface GetSmeoConsentsAction {
  type: SmeoEnum.GET_SMEO_CONSENTS_REQUEST;
  method: 'POST';
  data: AppId;
}

interface GetSmeoConsentsSuccess {
  type: SmeoEnum.GET_SMEO_CONSENTS_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      processNumber: string;
      consents: SmeoConsent[];
    };
  };
}

interface GetSmeoConsentsFailure {
  type: SmeoEnum.GET_SMEO_CONSENTS_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// POST SMEO CONSENTS
interface PostSmeoConsentsAction {
  type: SmeoEnum.POST_SMEO_CONSENTS_REQUEST;
  method: 'POST';
  data: PostSmeoConsentsData;
}

interface PostSmeoConsentsSuccess {
  type: SmeoEnum.POST_SMEO_CONSENTS_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      success: true;
      resultType: 'Success' | 'Error' | 'Pending';
    };
  };
}

interface PostSmeoConsentsFailure {
  type: SmeoEnum.POST_SMEO_CONSENTS_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// VERIFY APPLICATION
interface GetCompanyVerificationAction {
  type: SmeoEnum.GET_COMPANY_VERIFICATION_REQUEST;
  method: 'POST';
  data: VerifiData;
}

interface GetCompanyVerificationSuccess {
  type: SmeoEnum.GET_COMPANY_VERIFICATION_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      success: boolean;
      resultType: 'Success' | 'Error' | 'Pending';
      decision: DecisionEnum | '';
      company: {};
    };
  };
}

interface GetCompanyVerificationFailure {
  type: SmeoEnum.GET_COMPANY_VERIFICATION_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// SUBMIT APPLICATION
interface SubmitApplicationAction {
  type: SmeoEnum.SUBMIT_APPLICATION_REQUEST;
  method: 'POST';
  data: SubmitApplicationData;
}

interface SubmitApplicationSuccess {
  type: SmeoEnum.SUBMIT_APPLICATION_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      resultType: 'Success';
      success: boolean;
    };
  };
}

interface SubmitApplicationFailure {
  type: SmeoEnum.SUBMIT_APPLICATION_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// GET PROCESS APPLCIATION
interface GetProcessAppAction {
  type: SmeoEnum.GET_PROCESS_APP_REQUEST;
  method: 'POST';
  data: VerifiData;
}

interface GetProcessAppSuccess {
  type: SmeoEnum.GET_PROCESS_APP_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      success: true;
      resultType: 'Success' | 'Error' | 'Pending';
      decision: DecisionEnum | '';
      bankAccountNumbers: BankAccountNumber[];
      limit: number;
      limitOnline: number;
      decisionMessage: string;
    };
  };
}

interface GetProcessAppFailure {
  type: SmeoEnum.GET_PROCESS_APP_FAILURE;
  payload: {
    status: 422;
    code: 'Unprocessable Entity';
    errors: string[];
  };
}

// SUBMIT CREATE CONTRACT
interface SubmitCreateContractAction {
  type: SmeoEnum.SUBMIT_CREATE_CONTRACT_REQUEST;
  method: 'POST';
  data: SubmitCreateContractData;
}

interface SubmitCreateContractSuccess {
  type: SmeoEnum.SUBMIT_CREATE_CONTRACT_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      success: true;
      resultType: 'Success' | 'Error' | 'Pending';
    };
  };
}

interface SubmitCreateContractFailure {
  type: SmeoEnum.SUBMIT_CREATE_CONTRACT_FAILURE;
  payload: {
    status: 422;
    code: 'Unprocessable Entity';
    errors: string[];
  };
}

// GET CREATE CONTRACT
interface GetCreateContractAction {
  type: SmeoEnum.GET_CREATE_CONTRACT_REQUEST;
  method: 'POST';
  data: VerifiData;
}

interface GetCreateContractSuccess {
  type: SmeoEnum.GET_CREATE_CONTRACT_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      success: boolean;
      resultType: 'Success' | 'Error' | 'Pending';
      contractData: {};
    };
  };
}

interface GetCreateContractFailure {
  type: SmeoEnum.GET_CREATE_CONTRACT_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// GET CONTRACT
interface GetContractAction {
  type: SmeoEnum.GET_CONTRACT_REQUEST;
  method: 'POST';
  data: VerifiData;
}

interface GetContractSuccess {
  type: SmeoEnum.GET_CONTRACT_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      success: true;
      contractFileDataBase64: string;
      fileName: string;
    };
  };
}

interface GetContractFailure {
  type: SmeoEnum.GET_CONTRACT_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// SIGN IN CONTRACT
interface SignInContractAction {
  type: SmeoEnum.SIGN_IN_CONTRACT_REQUEST;
  method: 'POST';
  data: SignInContractData;
}

interface SignInContractSuccess {
  type: SmeoEnum.SIGN_IN_CONTRACT_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      success: boolean;
    };
  };
}

interface SignInContractFailure {
  type: SmeoEnum.SIGN_IN_CONTRACT_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// GET PROGRESS
interface GetProgressAction {
  type: SmeoEnum.GET_PROGRESS_REQUEST;
  method: 'POST';
  data: AppId;
}

interface GetProgressSuccess {
  type: SmeoEnum.GET_PROGRESS_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: Progress;
  };
}

interface GetProgressFailure {
  type: SmeoEnum.GET_PROGRESS_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

interface SubmitLimit {
  type: SmeoEnum.SUBMIT_LIMIT;
}

export type SmeoActionTypes =
  | GetSmeoConsentsAction
  | PostSmeoConsentsAction
  | GetCompanyVerificationAction
  | SubmitApplicationAction
  | GetProcessAppAction
  | SubmitCreateContractAction
  | GetCreateContractAction
  | GetContractAction
  | SignInContractAction
  | GetProgressAction
  | SubmitLimit;

export type SmeoApiTypes =
  | GetSmeoConsentsSuccess
  | GetSmeoConsentsFailure
  | PostSmeoConsentsSuccess
  | PostSmeoConsentsFailure
  | SubmitApplicationSuccess
  | SubmitApplicationFailure
  | GetCompanyVerificationSuccess
  | GetCompanyVerificationFailure
  | GetProcessAppSuccess
  | GetProcessAppFailure
  | SubmitCreateContractSuccess
  | SubmitCreateContractFailure
  | GetCreateContractSuccess
  | GetCreateContractFailure
  | GetContractSuccess
  | GetContractFailure
  | SignInContractSuccess
  | SignInContractFailure
  | GetProgressSuccess
  | GetProgressFailure;
