import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { setBankingStatus } from 'js/redux/banking/actions';
import { getApps } from 'js/redux/account/actions';
import { IBoxContent } from 'js/interfaces';
import { RootState } from 'js/redux/reducers';

import Steps from 'js/components/Steps';
import Box from 'js/components/Box';
import BankAccounts from 'js/views/Data/BankAccounts';
import TransactionsInfo from 'js/views/Data/TransactionsInfo';

const BankAccountsPage: FC = () => {
  const { status } = useSelector((state: RootState) => state.Banking);
  const { app } = useSelector((state: RootState) => state.Account);
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    if (app.hasKontomatik) {
      dispatch(setBankingStatus('success'));
    }
  }, [dispatch, app.hasKontomatik]);

  useEffect(() => {
    if (!app.token) {
      const interval = setInterval(() => {
        dispatch(getApps());
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [dispatch, app.token]);

  const headings: IBoxContent = useMemo(() => {
    return {
      info: 'Dodaj historie transakcji z kont bankowych, które wykorzystujesz do prowadzenie swojej firmy za pomocą narzędzia “Kontomatik”',
      login: 'Dodaj konta bankowe',
      success: 'Możesz dodać kolejne konto!',
      failure: 'Nie udało się...',
      repeated: 'To konto bankowe już zostało dodane!',
    };
  }, []);

  const descriptions: IBoxContent = useMemo(() => {
    return {
      info: 'Do utworzenia szczegółowych rekomendacji kredytowych i oszacowania Twojej realnej zdolności kredytowej, musimy poznać historię rachunku bankowego Twojej firmy. Dzięki aplikacji Kontomatik bezpiecznie załadujesz historię transakcji. W ten sposób otrzymasz lepszą ofertę finansowania, a my będziemy mogli skuteczniej Ci doradzić.',
      login:
        'Postępuj zgodnie z instrukcjami. Możesz dodać wiele kont, zwiększy to Twoje szanse na otrzymanie finansowania.',
      success:
        'Twoje konto zostało dodane. Jeśli masz konto w innym banku, dodaj je. Dzięki temu otrzymasz dokładniejsze rekomendacje oraz zwiększysz swoje szanse na kredyt.',
      failure: 'Coś poszło nie tak. Dodaj konto ponownie.',
      repeated: 'Wybierz inne konto',
    };
  }, []);

  return (
    <>
      <Steps active="data" />
      <Box type="heading">
        <h3>{headings[status]}</h3>
        <p>{descriptions[status]}</p>
      </Box>
      {status === 'info' ? (
        <TransactionsInfo
          clickNext={() => dispatch(setBankingStatus('login'))}
        />
      ) : (
        <BankAccounts />
      )}
    </>
  );
};

export default BankAccountsPage;
