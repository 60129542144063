import { FC, useMemo } from 'react';
import { RootState } from 'js/redux/reducers';
import { useSelector } from 'react-redux';

import Steps from 'js/components/Steps';
import Box from 'js/components/Box';
import NipForm from 'js/views/Data/Nip';

const Nip: FC = () => {
  const { loading, company, isRefresh } = useSelector(
    (state: RootState) => state.Account
  );

  const defaultData = useMemo(
    () => ({
      nip: company?.nip ?? '',
    }),
    [company]
  );

  return (
    <>
      <Steps active="data" />
      <Box type="heading">
        <h3>Podaj NIP swojej firmy</h3>
      </Box>
      <NipForm data={defaultData} loading={loading} isRefresh={isRefresh} />
    </>
  );
};

export default Nip;
