import { AppId } from 'js/interfaces';
import { AdminEnum, AdminActionTypes } from 'js/redux/admin/types';

export const searchUser = (data: { email: string }): AdminActionTypes => ({
  type: AdminEnum.SEARCH_USER_REQUEST,
  method: 'POST',
  data,
});

export const removeUser = (id: number): AdminActionTypes => ({
  type: AdminEnum.REMOVE_USER_REQUEST,
  method: 'DELETE',
  id,
});

export const getUsers = (data: {
  count: number;
  page: number;
}): AdminActionTypes => ({
  type: AdminEnum.GET_USERS_REQUEST,
  data,
});

export const generateReportManual = (data: AppId): AdminActionTypes => ({
  type: AdminEnum.GENERATE_REPORT_MANUAL_REQUEST,
  method: 'POST',
  data,
});
