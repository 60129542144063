import { takeLatest } from 'redux-saga/effects';
import { AuthEnum } from 'js/redux/auth/types';
import { createSaga } from 'js/redux/sagas';

function* authSaga() {
  yield takeLatest(
    AuthEnum.REGISTER_REQUEST,
    createSaga('REGISTER', '/auth/register', 'POST')
  );

  yield takeLatest(
    AuthEnum.LOGIN_REQUEST,
    createSaga('LOGIN', '/auth/login', 'POST')
  );

  yield takeLatest(
    AuthEnum.LOGOUT_REQUEST,
    createSaga('LOGOUT', '/auth/logout', 'POST')
  );
}

export default authSaga;
