import { REHYDRATE } from 'redux-persist';
import { RehydrateRootState } from 'js/redux/reducers';
import {
  AdminEnum,
  AdminState,
  AdminActionTypes,
  AdminApiTypes,
} from 'js/redux/admin/types';

import { notification } from 'antd';
import { getNewestReport } from 'js/helpers/Report/getNewest';
import { getUsersReports } from 'js/helpers/Report/getUserReports';

export const INIT_STATE: AdminState = {
  list: [],
  applications: [],
  usersTable: {
    data: [],
    pagination: {
      current_page: 0,
      from: 0,
      last_page: 0,
      links: {
        active: false,
        label: '',
        url: '',
      },
      path: '',
      per_page: 0,
      to: 0,
      total: 0,
    },
  },
  loading: false,
  reportLoading: false,
  pendingReport: false,
  user: null,
  appliedOffers: [],
  newestReport: null,
};

export default function reducer(
  state = INIT_STATE,
  action: AdminActionTypes | AdminApiTypes | RehydrateRootState
): AdminState {
  if (state === undefined) {
    return INIT_STATE;
  }

  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        ...action.payload?.Admin,
        loading: false,
      };

    case AdminEnum.SEARCH_USER_REQUEST:
      if (state.pendingReport) {
        return state;
      }
      return {
        ...state,
        loading: true,
        applications: [],
        list: [],
        appliedOffers: [],
        user: null,
      };

    case AdminEnum.SEARCH_USER_SUCCESS:
      const reports = getUsersReports(action.payload.data);
      return {
        ...state,
        loading: false,
        applications: action.payload.data.length
          ? action.payload.data[0].applications
          : [],
        list: reports,
        user: action.payload.data[0].user,
        appliedOffers: action.payload.data[0].appliedOffers,
        pendingReport:
          state.pendingReport && action.payload.data.length
            ? !action.payload.data[0]?.reports?.length
            : state.pendingReport,
        newestReport: getNewestReport(reports),
      };

    case AdminEnum.SEARCH_USER_FAILURE:
      notification['warning']({
        message: 'Nie odnaleziono użytkownika o podanym adresie mail',
        description: 'Użytkownik nie istnieje lub został usunięty',
      });
      return {
        ...state,
        loading: false,
      };

    case AdminEnum.REMOVE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AdminEnum.REMOVE_USER_SUCCESS:
      notification['success']({
        message: 'Użytkownik został usunięty pomyślnie!',
      });
      return {
        ...state,
        loading: false,
        list: [],
        user: null,
      };

    case AdminEnum.REMOVE_USER_FAILURE:
      notification['error']({
        message: 'Usuwanie użytkownika nie powiodło się',
      });
      return {
        ...state,
        loading: false,
      };

    case AdminEnum.GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AdminEnum.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        usersTable: {
          data: action.payload.data.data,
          pagination: action.payload.data.meta,
        },
      };

    case AdminEnum.GET_USERS_FAILURE:
      notification['error']({
        message: 'Błąd podczas pobierania listy użytkowników',
      });
      return {
        ...state,
        loading: false,
      };

    case AdminEnum.GENERATE_REPORT_MANUAL_REQUEST:
      return {
        ...state,
        reportLoading: true,
      };

    case AdminEnum.GENERATE_REPORT_MANUAL_SUCCESS:
      notification['success']({
        message: 'Prośba o wygenerowanie raportu została wysłana.',
        description:
          'Prosimy o cierpliwość, generowanie raportu może potrwać kilka minut.',
      });
      return {
        ...state,
        pendingReport: true,
        reportLoading: false,
      };

    case AdminEnum.GENERATE_REPORT_MANUAL_FAILURE:
      notification['error']({
        message: 'Błąd podczas generowania raportu',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        reportLoading: false,
      };

    default:
      return state;
  }
}
