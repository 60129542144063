import { FC } from 'react';

import 'js/components/Box/styles.scss';

type Props = {
  type: 'content' | 'heading';
  children: string | string[] | JSX.Element | JSX.Element[];
  className?: string;
};

const Box: FC<Props> = ({ type, className = '', children }) => (
  <div className={`box ${className} box--${type}`}>
    <div className={`box__inner box__inner--${type}`}>
      {type === 'heading' && <img alt="Ada Knapik" src="/images/woman.png" />}
      {type === 'heading' ? <section>{children}</section> : children}
    </div>
  </div>
);

export default Box;
