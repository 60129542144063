import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps, useHistory } from 'react-router-dom';
import { RootState } from 'js/redux/reducers';
import { routes, registerOrder } from 'js/routes';

const RegisterRoute: FC<RouteProps> = ({ component, path }) => {
  const { user, actualStep, app } = useSelector(
    (state: RootState) => state.Account
  );
  const history = useHistory();

  useEffect(() => {
    if (actualStep !== -1) history.push(registerOrder[actualStep]);
  }, [actualStep, history]);

  if (
    !!user.email &&
    actualStep !== -1 &&
    Object.values(registerOrder).includes(`${path}`)
  )
    return <Route path={path} component={component} />;

  if (!!user.email && actualStep === -1) {
    return app?.hasReport ? (
      <Redirect to={routes.report} />
    ) : (
      <Redirect to={routes.status} />
    );
  }

  return <Redirect to={routes.login} />;
};

export default RegisterRoute;
