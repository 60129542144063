import { FC } from 'react';
import { RuleObject } from 'antd/lib/form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Validator from 'js/helpers/Validator';

import { Form, Checkbox } from 'antd';

import 'js/components/Consent/styles.scss';

type Props = {
  id: number | string;
  children: JSX.Element | string;
  required?: boolean;
  initCheck?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
};

const Consent: FC<Props> = ({
  id,
  required = false,
  onChange,
  initCheck = false,
  children,
}) => (
  <div className={`consent ${required ? 'consent--required' : ''}`}>
    <Form.Item
      name={id}
      valuePropName="checked"
      initialValue={initCheck}
      rules={[
        {
          required: required,
          validator: (_: RuleObject, value: boolean) =>
            new Validator(value)
              .noCheckboxData('Zgoda jest wymagana', required)
              .res(),
        },
      ]}
    >
      <Checkbox data-testid={`zgoda-${id}`} onChange={onChange}>
        {children}
      </Checkbox>
    </Form.Item>
  </div>
);

export default Consent;
