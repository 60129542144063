import { FC, useState } from 'react';
import { Form, InputNumber, Select } from 'antd';
import { validateAmount } from 'js/helpers/validators';

export type ArrearsFormData = {
  hasArrearsUs: 0 | 1;
  hasArrearsZus: 0 | 1;

  arrearsInstallmentUs?: 0 | 1;
  arrearsInstallmentZus?: 0 | 1;

  arrearsUs?: number;
  arrearsZus?: number;
};

type Props = {
  name: 'Us' | 'Zus';
  showInputs: boolean;
};

const ArrearsForm: FC<Props> = ({ name, showInputs }) => {
  const [showInput, setShowInput] = useState(showInputs);

  return (
    <>
      <Form.Item
        label={`Czy posiadasz zadłużenie lub układ ratalny w ${name.toUpperCase()}?`}
        name={`hasArrears${name}`}
        rules={[
          {
            required: true,
            message: `Proszę zaznaczyć czy posiadasz zaległości w ${name.toUpperCase()}`,
          },
        ]}
      >
        <Select
          size="large"
          placeholder="Wybierz"
          onChange={(val) => setShowInput(!!val)}
          options={[
            { label: 'Tak', value: 1 },
            { label: 'Nie', value: 0 },
          ]}
        />
      </Form.Item>
      {showInput && (
        <Form.Item
          label="Układ ratalny"
          name={`arrearsInstallment${name}`}
          rules={[
            {
              required: true,
              message: 'Proszę zaznacz czy spłata jest w układzie ratalnym',
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Wybierz"
            options={[
              { label: 'Tak', value: 1 },
              { label: 'Nie', value: 0 },
            ]}
          />
        </Form.Item>
      )}
      {showInput && (
        <Form.Item
          label={`Podaj przybliżoną kwotę zadłużenia w ${name.toUpperCase()}`}
          name={`arrears${name}`}
          rules={[
            {
              required: true,
              validator: validateAmount,
            },
          ]}
        >
          <InputNumber size="large" precision={0} min={0} />
        </Form.Item>
      )}
    </>
  );
};

export default ArrearsForm;
