import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { RootState } from 'js/redux/reducers';
import { routes, registerOrder } from 'js/routes';

const PublicRoute: FC<RouteProps> = ({ component, path }) => {
  const { isAdmin } = useSelector((state: RootState) => state.Auth);
  const { user, actualStep, app } = useSelector(
    (state: RootState) => state.Account
  );

  if (isAdmin) return <Redirect to={routes.search} />;

  if (!user.email) return <Route path={path} component={component} />;

  if (app.hasReport && user.isActive) return <Redirect to={routes.report} />;

  if (app.hasPendingReport && user.isActive)
    return <Redirect to={routes.status} />;

  return (
    <Redirect
      to={actualStep !== -1 ? registerOrder[actualStep] : routes.transactions}
    />
  );
};

export default PublicRoute;
