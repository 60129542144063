import { FC, useEffect } from 'react';

import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'js/redux/reducers';
import { registerUser } from 'js/redux/auth/actions';
import { ConsentStepEnum, WfUser } from 'js/interfaces/account';

import { phoneNumber } from 'js/helpers/formatters';
import { validatePassword, validatePhone } from 'js/helpers/validators';

import { Form, Input, Popover, FormInstance } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';
import Box from 'js/components/Box';
import Loader from 'js/components/Loader';
import Consent from 'js/components/Consent';
import BtnWrapper from 'js/components/BtnWrapper';
import { SubmitBtn } from 'js/components/Buttons';
import { routes } from 'js/routes';

import 'js/views/Register/Register/styles.scss';

export type RegisterData = {
  email: string;
  password: string;
  phone: string;
  [key: number]: boolean;
};

type Props = {
  loading: boolean;
  wfToken?: string;
  wfUser?: WfUser;
};

const Register: FC<Props> = ({ loading, wfUser }) => {
  const [form]: [FormInstance<RegisterData>] = Form.useForm();
  const dispatch: Dispatch = useDispatch();
  const { consentsRaw, consentsLoading } = useSelector(
    (state: RootState) => state.Account
  );

  const { login } = routes;

  useEffect(() => {
    if (form && wfUser?.email) {
      form.setFieldsValue({
        email: wfUser.email,
        phone: wfUser.phone,
      });
    }
  }, [form, wfUser]);

  const handleSubmit = (value: RegisterData) => {
    dispatch(
      registerUser({
        email: value.email,
        password: value.password,
        phone: value.phone.split(' ').join(''),
        consents: Object.entries(value)
          .filter(([key]) => Number.isInteger(+key))
          .map(([key, val]) => ({ id: +key, checked: !!val })),
        token: wfUser?.token || undefined,
      })
    );
  };

  return (
    <>
      <Box type="content" className="register-form">
        <Form
          form={form}
          onFinish={handleSubmit}
          scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
        >
          <Form.Item
            name="email"
            label="Adres e-mail"
            rules={[
              { required: true, message: 'Proszę podaj email' },
              { type: 'email', message: 'Podaj poprawny adres email' },
            ]}
          >
            <Input
              size="large"
              placeholder="Wpisz adres e-mail..."
              type="email"
              disabled={!!wfUser?.email}
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="Hasło"
            rules={[
              {
                required: true,
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password size="large" placeholder="Utwórz hasło..." />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Numer telefonu"
            normalize={phoneNumber}
            rules={[
              {
                required: true,
                validator: validatePhone,
              },
            ]}
          >
            <Input
              type="tel"
              disabled={!!wfUser?.phone}
              size="large"
              placeholder="Wpisz numer..."
            />
          </Form.Item>

          <Popover
            placement="topLeft"
            trigger="click"
            overlayClassName="register-form-popover"
            content={
              <div className="register-form-popover__wrapper">
                <InfoCircleOutlined className="register-form-popover__icon" />
                <span className="register-form-popover__text">
                  Na ten numer wyślemy Ci za chwilę SMS z kodem weryfikacyjnym.
                  W ten sposób aktywujesz swoje konto. Dodatkowo będziesz mógł
                  uzyskać nasze telefoniczne wsparcie, na przykład jeśli coś
                  pójdzie nie tak, strona się zawiesi lub pojawi się jakiś błąd.
                </span>
              </div>
            }
          >
            <div className="register-form__popover-text">
              <InfoCircleOutlined className="register-form__popover-icon" />
              Dlaczego potrzebujemy numeru telefonu?
            </div>
          </Popover>

          <div className="register-form__consents">
            {!consentsRaw.length && consentsLoading ? (
              <Loader low />
            ) : (
              consentsRaw.map(
                ({ id, required, text, step }) =>
                  step === ConsentStepEnum.registration && (
                    <Consent key={id} id={id} required={required}>
                      <div dangerouslySetInnerHTML={{ __html: text }} />
                    </Consent>
                  )
              )
            )}
          </div>

          <BtnWrapper>
            <SubmitBtn loading={loading}>Załóż konto</SubmitBtn>
          </BtnWrapper>

          <div className="register-form__login">
            Masz już konto?{' '}
            <Link to={login} className="register-form__link">
              Zaloguj się
            </Link>
          </div>
        </Form>
      </Box>
    </>
  );
};

export default Register;
