import { FC } from 'react';
import { SubmitBtn, NextBtn } from 'js/components/Buttons';

import {
  FieldTimeOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { formatTime } from 'js/helpers/formatters';
import { routes } from 'js/routes';

import 'js/components/Report/InfoBoxes/styles.scss';

type SmeoProcessBoxProps = {
  finish: boolean;
  negative: boolean;
  loading: boolean;
};

const SmeoProcessBox: FC<SmeoProcessBoxProps> = ({
  finish,
  negative,
  loading,
}) => (
  <div
    className={`info-box ${
      negative ? 'info-box--loading info-box--error' : 'info-box--loading'
    }`}
  >
    <div>
      {negative ? <ExclamationCircleOutlined /> : <FieldTimeOutlined />}
      <h3>
        {negative &&
          'Otrzymano decyzję NEGATYWNĄ podczas procesowania oferty faktoringowej SMEO'}
        {finish && 'Oferta faktoringowa SMEO jest gotowa'}
        {!negative &&
          !finish &&
          'Oferta faktoringowa SMEO jest w trakcie procesowania.'}
      </h3>
    </div>

    <NextBtn to={routes.smeoFinish} loading={loading}>
      Przejdź do {finish && !negative ? 'oferty' : 'procesu'}
    </NextBtn>
  </div>
);

type ErrorBoxProps = {
  onClick: () => void;
};

const ErrorBox: FC<ErrorBoxProps> = ({ onClick }) => (
  <div className="info-box info-box--error">
    <ExclamationCircleOutlined />
    <h3>
      <b>Oferty mogą być nieaktualne</b>, ponieważ zostały wygenerowane ponad 14
      dni temu. Aby zobaczyć najnowsze oferty i swoje aktualne szanse na ich
      pozyskanie, zaktualizuj raport.
    </h3>

    <SubmitBtn onClick={onClick} className="small">
      Aktualizuj raport
    </SubmitBtn>
  </div>
);

type ErrorBoxBigProps = {
  onSelect: () => void;
};

const ErrorBoxBig: FC<ErrorBoxBigProps> = ({ onSelect }) => (
  <div className="info-box info-box--error info-box--error-big">
    <h3>
      To jest raport historyczny. Każdy raport jest aktualny tylko jeden dzień
      po jego wygenerowaniu. Na twoim koncie istnieje nowsza wersja raportu
    </h3>

    <SubmitBtn onClick={() => onSelect()} className="small">
      Zobacz ostatni raport
    </SubmitBtn>
  </div>
);

type ExpirationInfoBoxProps = {
  diff: number;
};

const ExpirationInfoBox: FC<ExpirationInfoBoxProps> = ({ diff }) => {
  if (diff <= 0) {
    return null;
  }

  return (
    <div className="info-box info-box--expiration">
      <FieldTimeOutlined />
      <h3>
        Oferty ważne przez: <span>{formatTime(diff)}</span>
      </h3>
    </div>
  );
};

const KontomatikBox: FC = () => (
  <div className="info-box info-box--loading">
    <div>
      <h3>
        Jeśli nie dodałeś wszystkich kont firmowych podczas składania wniosku,
        możesz zrobić to teraz.
      </h3>
    </div>

    <NextBtn to={routes.addAccounts} loading={false}>
      Dodaj inne konta
    </NextBtn>
  </div>
);

export {
  ExpirationInfoBox,
  SmeoProcessBox,
  ErrorBox,
  ErrorBoxBig,
  KontomatikBox,
};
