import { RuleObject } from 'antd/lib/form';
import Validator from 'js/helpers/Validator';

export const validateAmount = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue(`Proszę podać wysokość zadłużenia`)
    .noMoreThanZero('Podana kwota musi być większa od zera')
    .res();

export const validateID = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę wpisz numer dowodu')
    .invalidIdCard('Nieprawidłowy numer dowodu')
    .res();

export const validatePhone = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę podaj numer telefonu')
    .invalidPhone('Podany numer telefonu jest nieprawidłowy')
    .res();

export const validatePesel = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę wpisz numer PESEL')
    .invalidPESEL('Nieprawidłowy numer PESEL')
    .res();

export const validateDate = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę wprowadź datę')
    .isDateAfter('Nieprawidłowa data', value)
    .res();

export const validatePostCode = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę wpisz kod pocztowy')
    .invalidPostCode('Nieprawidłowy kod pocztowy')
    .res();

export const validateCity = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę podaj miejscowość')
    .illegalChar('Wpisano niedozwolony znak')
    .res();

export const validatePassword = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę podaj hasło')
    .invalidPassword(
      'Wymagane jest min. 8 znaków, duża litera, mała litera oraz jedna cyfra'
    )
    .res();

export const validateNewPassword = (value: string, secondValue: string) =>
  new Validator(value)
    .noValue('Proszę podaj hasło')
    .invalidPassword(
      'Wymagane jest min. 8 znaków, duża litera, mała litera oraz jedna cyfra'
    )
    .isEqual(secondValue, 'Nowe hasło nie może być takie samo jak poprzednie')
    .res();

export const validateIssueDate = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę wprowadź datę')
    .isDateAfter('Nieprawidłowa data', value)
    .res();

export const validateExpirationDate = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę wprowadź datę')
    .isDateBefore('Nieprawidłowa data', value)
    .res();

export const validateNip = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę wpisz NIP')
    .invalidNIP('Nieprawidłowy NIP')
    .res();
