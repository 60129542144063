export enum MonthlyIncomeEnum {
  upTo20000 = 1,
  upTp100000,
  above100000,
}

export enum TradeEnum {
  construction = 1,
  transport,
  it,
  productionAndProcessing,
  commerce,
  other,
  financeAndRealEstate,
}

export enum FundingPurposeEnum {
  stockingUp = 1,
  contract,
  expanding,
  purchaseFixedAssets,
  repaymentOtherLiabilities,
  repaymentSocialOrTaxOffice,
  other,
}

export enum InvoicePaymentDateEnum {
  immediate = 1,
  oneWeek,
  twoWeeks,
  threeWeeks,
  fourWeeks,
  moreThanFive,
}

export enum BookKeepingEnum {
  individual = 1,
  bookKeepingOffice,
  fulltimeAccountant,
  thirdParty,
}

export enum BookKeepingRecordEnum {
  ledger = 1,
  lumpSum,
  taxCard,
  fullAccountancy,
}

export enum EducationEnum {
  primary = 1,
  secondary,
  vocational,
  student,
  higherVocational,
  higherMasterDegree,
}

export enum MaritalStatusEnum {
  single = 1,
  married,
  widow,
  divorced,
  freeRelationship,
}

export enum HousingStatusEnum {
  owner = 1,
  cooperativeTenant,
  withParents,
  familyMember,
  rented,
  communal,
  studentHouse,
  business,
  noPermanentResidence,
}

export enum ConsentStepEnum {
  registration = 'registration',
  consent = 'consent',
}

export type User = {
  apartmentNo: string | null;
  city: string | null;
  email: string;
  firstName: string | null;
  houseNo: string | null;
  idCard: string | null;
  isActive: boolean;
  lastName: string | null;
  maritalStatus: MaritalStatusEnum | null;
  pesel: string | null;
  phone: string;
  postCode: string | null;
  street: string | null;
  isWealthonUser: boolean;
};

export interface UserState {
  apartmentNo: string;
  city: string;
  email: string;
  firstName: string;
  houseNo: string;
  idCard: string;
  isActive: boolean;
  lastName: string;
  maritalStatus: MaritalStatusEnum | null;
  pesel: string;
  phone: string;
  postCode: string;
  street: string;
  isWealthonUser: boolean;
}
export interface WfUser {
  email: string;
  phone: string;
  exists: boolean;
  token: string;
}

export type Company = {
  nip: string | null;
  name: string | null;
  street: string | null;
  houseNo: string | null;
  apartmentNo: string | null;
  city: string | null;
  postCode: string | null;
  monthlyIncome: MonthlyIncomeEnum | null;
  contractors: string | null;
  invoicePaymentDate: InvoicePaymentDateEnum | null;
  bookkeepingRecordType: BookKeepingRecordEnum | null;
  trade: TradeEnum | null;
  fundingPurpose: FundingPurposeEnum | null;
  arrearsInstallmentZus: boolean;
  arrearsInstallmentUs: boolean;
  arrearsZus: number;
  arrearsUs: number;
};

export type ConsentRaw = {
  id: number;
  required: boolean;
  step: ConsentStepEnum;
  text: string;
  title: string;
};

export type ConsentChecked = {
  id: number;
  checked: boolean;
};

export type App = {
  consents: ConsentChecked[];
  createdAt: string;
  hasKontomatik: boolean;
  hasPendingReport: boolean;
  hasReport: boolean;
  id: number;
  token: string | null;
  userId: number;
};

export type AppState = Omit<App, 'id' | 'userId'> & {
  id: number | null;
  userId: number | null;
};

export type Step = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
