import * as dbAccount from 'js/mocks/db/account';
import * as dbUser from 'js/mocks/db/user';
import * as dbCompany from 'js/mocks/db/company';
import * as dbApp from 'js/mocks/db/application';
import consents from 'js/mocks/db/consents.json';

import { MaritalStatusEnum } from 'js/interfaces/account';

export const createBeforeKontomatik = () => {
  // before Kontomatik step
  dbAccount.create({
    consents: [],
    user: dbUser.create({
      apartmentNo: '1',
      city: 'Warszawa',
      email: 'hasConsents@t.pl',
      firstName: 'Jan',
      houseNo: '2',
      idCard: 'GDH606387',
      isActive: true,
      lastName: 'Kowalski',
      maritalStatus: MaritalStatusEnum.married,
      pesel: '87071357475',
      phone: '555666777',
      postCode: '89-123',
      street: 'Dzicza',
    }),
    company: dbCompany.create({
      name: 'Jan Kowalski "KowalBud"',
      nip: '9571021081',
      city: 'Warszawa',
      postCode: '80-301',
      street: 'Topolowa',
      houseNo: '23',
      apartmentNo: '5',
      monthlyIncome: 1,
      contractors: '11',
      invoicePaymentDate: 2,
      bookkeepingRecordType: null,
      trade: 2,
      fundingPurpose: 1,
      arrearsInstallmentZus: false,
      arrearsInstallmentUs: true,
      arrearsZus: 0,
      arrearsUs: 0,
    }),
    applications: [
      dbApp.create({
        consents: consents.map(({ id }) => ({ id, checked: true })),
        createdAt: '01-09-2021',
        token: 'bgx5t994xl9ukps9hk272',
        userId: dbAccount.getLastId() + 1,
      }),
    ],
    isAdmin: false,
    _hiddenData: {
      password: 'Password1',
      smsCode: '',
    },
  });
};
