import { REHYDRATE } from 'redux-persist';
import { RehydrateRootState } from 'js/redux/reducers';
import {
  BankEnum,
  BankState,
  BankingActionTypes,
  BankingApiTypes,
} from 'js/redux/banking/types';

import { notification } from 'antd';

export const INIT_STATE: BankState = {
  loading: false,
  status: 'info',
  lastBank: '',
  bankList: [],
  isKontomatikReady: false,
};

export default function reducer(
  state = INIT_STATE,
  action: BankingActionTypes | BankingApiTypes | RehydrateRootState
): BankState {
  if (state === undefined) {
    return INIT_STATE;
  }

  // TODO: clear kontomatik on refresh report eventually
  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        loading: false,
      };

    case BankEnum.POST_KONTOMATIK_REQUEST:
      return {
        ...state,
        loading: true,
        lastBank: action.data.target,
      };

    case BankEnum.POST_KONTOMATIK_SUCCESS:
      notification['success']({
        message: 'Bank został dodany pomyślnie',
      });
      return {
        ...state,
        bankList: [...state.bankList, state.lastBank],
        loading: false,
        status: 'success',
      };

    case BankEnum.POST_KONTOMATIK_FAILURE:
      notification['error']({
        message: 'Błąd podczas dodawania konta',
      });
      return {
        ...state,
        loading: false,
        status: 'failure',
      };

    case BankEnum.KONTOMATIK_READY_REQUEST:
      return {
        ...state,
        loading: true,
        isKontomatikReady: false,
      };

    case BankEnum.KONTOMATIK_READY_SUCCESS:
      return {
        ...state,
        loading: false,
        isKontomatikReady: true,
      };

    case BankEnum.KONTOMATIK_READY_FAILURE:
      notification['error']({
        message: 'Wystąpił błąd',
      });
      return {
        ...state,
        loading: false,
      };

    case BankEnum.SET_BANKING_STATUS:
      return {
        ...state,
        status: action.data,
      };

    case BankEnum.CLEAR_KONTOMATIK:
      return {
        ...state,
        isKontomatikReady: false,
        bankList: [],
      };

    default:
      return state;
  }
}
