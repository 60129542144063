import { FC, useState, useMemo, useEffect } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { getApps } from 'js/redux/account/actions';
import { generateReport } from 'js/redux/report/actions';

import { IBoxContent } from 'js/interfaces';
import { RootState } from 'js/redux/reducers';

import Steps from 'js/components/Steps';
import Box from 'js/components/Box';
import ReportStatus from 'js/components/Report/ReportStatus';

export type State = 'progress' | 'success' | 'failure';

const Status: FC = () => {
  const dispatch: Dispatch = useDispatch();
  const { app, loading } = useSelector((state: RootState) => state.Account);
  const { error, pendingReport } = useSelector(
    (state: RootState) => state.Report
  );

  const [state, setState] = useState<State>('progress');

  useEffect(() => {
    if (error) setState('failure');
    else if (app.hasReport && !loading) setState('success');
    else if (pendingReport) setState('progress');
  }, [error, app.hasReport, pendingReport, loading]);

  useEffect(() => {
    if (!error && !app.hasReport) {
      if (!pendingReport && !app.hasPendingReport)
        dispatch(generateReport({ applicationId: app.id }));
      else {
        const interval = setInterval(() => dispatch(getApps()), 5000);
        return () => clearInterval(interval);
      }
    }
  }, [dispatch, error, app, pendingReport]);

  const headings: IBoxContent = useMemo(
    () => ({
      progress: 'Proces zakończony. Generujemy Twój raport!',
      failure: 'Pojawił się problem podczas generowania raportu.',
      success: 'Raport jest gotowy!',
    }),
    []
  );

  return (
    <>
      <Steps active="report" />
      <Box type="heading">
        <h3>{headings[state]}</h3>
      </Box>
      <ReportStatus status={state} />
    </>
  );
};

export default Status;
