import { FC } from 'react';

import { LeftOutlined } from '@ant-design/icons';

import {
  CodeDescriptionEnum,
  CodeNameEnum,
  CodeEnum,
} from 'js/interfaces/report';

import 'js/components/Report/RatingModal/styles.scss';

type Props = {
  code: keyof typeof CodeEnum;
  onClose: () => void;
};

const ReportModalTitle: FC<Props> = ({ code, onClose }) => (
  <>
    <div className="rating-modal__header-title">
      <button onClick={onClose}>
        <LeftOutlined />
      </button>
      <h4>{code && CodeNameEnum[code]} </h4>{' '}
    </div>
    <h5 className="rating-modal__header-desc">
      <span>–</span> {code && CodeDescriptionEnum[code]}
    </h5>
  </>
);

export default ReportModalTitle;
