import { FC } from 'react';
import {
  SafetyCertificateOutlined,
  LockOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';

import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { SubmitBtn } from 'js/components/Buttons';

import 'js/views/Data/TransactionsInfo/styles.scss';

type Props = {
  clickNext: () => void;
};

const TransactionsInfo: FC<Props> = ({ clickNext }) => (
  <Box type="content" className="transaction-info">
    <div className="transaction-info__text">
      <LockOutlined />
      <span>
        Historie konta dodasz{' '}
        <b>szybko bez dostarczania wyciągu za pomocą Kontomatika.</b>
      </span>
    </div>
    <div className="transaction-info__text">
      <SafetyCertificateOutlined />
      <span>
        Kontomatik <b>jest bezpieczny </b> i posiada certyfikat ISO27001.
      </span>
    </div>
    <div className="transaction-info__text">
      <FieldTimeOutlined />
      <span>
        Kontomatik, ani żaden inny podmiot{' '}
        <b>
          nie zapamiętuje Twoich danych logowania. Są one w pełni bezpieczne.
        </b>
      </span>
    </div>
    <BtnWrapper>
      <SubmitBtn onClick={clickNext}>Dodaj historię transakcji</SubmitBtn>
    </BtnWrapper>

    <div className="transaction-info__contact">
      Nie jesteś pewien?
      <br />
      Skontaktuj się z naszym agentem
      <br />
      <a href="tel:+48780080360">
        <b>+48 780 080 360</b>{' '}
      </a>
    </div>
  </Box>
);

export default TransactionsInfo;
