import { takeLatest } from 'redux-saga/effects';
import { ReportEnum } from 'js/redux/report/types';
import { createSaga } from 'js/redux/sagas';

function* reportSaga() {
  yield takeLatest(
    ReportEnum.GENERATE_REPORT_REQUEST,
    createSaga('GENERATE_REPORT', '/report/generate', 'POST')
  );
  yield takeLatest(
    ReportEnum.GET_REPORT_REQUEST,
    createSaga('GET_REPORT', '/report', 'GET')
  );
  yield takeLatest(
    ReportEnum.GET_REPORTS_REQUEST,
    createSaga('GET_REPORTS', '/report', 'GET')
  );
  yield takeLatest(
    ReportEnum.CHECK_OFFER_REQUEST,
    createSaga('CHECK_OFFER', '/offer/apply', 'POST')
  );
  yield takeLatest(
    ReportEnum.REFRESH_REPORT_REQUEST,
    createSaga('REFRESH_REPORT', '/report/refresh', 'POST')
  );
}

export default reportSaga;
