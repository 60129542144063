import { FC } from 'react';
import moment from 'moment';

import { AdaptedReportClient } from 'js/interfaces/report';

import { SubmitBtn } from 'js/components/Buttons';

import { dateFormat } from 'js/constants';

import 'js/components/Report/Info/styles.scss';

type Props = {
  client: AdaptedReportClient;
  date: string;
  onClick: () => void;
};

const ReportInfo: FC<Props> = ({ client, date, onClick }) => {
  return (
    <div className="report-info">
      <div>
        <ul>
          <li>
            <h5>Nazwa działalności:</h5>
            <h3>{client.companyName}</h3>
          </li>
          <li>
            <h5>NIP:</h5>
            <h3>{client.nip}</h3>
          </li>
          <li>
            <h5>Data wygenerowania raportu:</h5>
            <h3>{moment(date, dateFormat).format(dateFormat)}</h3>
          </li>
          <li>
            <h5>Email:</h5>
            <h3>{client.email}</h3>
          </li>
        </ul>
        <SubmitBtn onClick={onClick} className="small">
          Aktualizuj raport
        </SubmitBtn>
      </div>
    </div>
  );
};

export default ReportInfo;
