export const objectsEquals = (object1: Object, object2: Object) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  const getKeyValue =
    <T extends object, U extends keyof T>(obj: T) =>
    (key: U) =>
      obj[key];

  for (const key of keys1) {
    const val1 = getKeyValue(object1)(key as keyof Object);
    const val2 = getKeyValue(object2)(key as keyof Object);
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !objectsEquals(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
};

const isObject = (object: Object) => {
  return object != null && typeof object === 'object';
};
