import { FC } from 'react';
import { AdaptedReport } from 'js/interfaces/report';
import { formatPrice } from 'js/helpers/formatters';

import { Row, Col } from 'antd';
import Icon from 'js/components/Icon';
import RatingBox from 'js/components/Report/RatingBox';
import Box from 'js/components/Box';

import 'js/components/Report/Rating/styles.scss';

type Props = {
  data: AdaptedReport;
};

const ReportRating: FC<Props> = ({ data }) => {
  return (
    <section className="raport-rating">
      <Row gutter={{ xs: 0, sm: 0, md: 16, lg: 16 }}>
        <Col xs={24} sm={24} md={16}>
          {data.summaries?.length > 0 && (
            <RatingBox rating={data.rating} summaries={data.summaries} />
          )}
        </Col>
        <Col className="raport-rating__boxes" xs={24} sm={24} md={8}>
          <Box type="content">
            <h4>Prognozowana aktualna zdolność kredytowa:</h4>
            <h2>
              <small>{data.amountIndicator}</small> {formatPrice(data.amount)}{' '}
              PLN
            </h2>
          </Box>

          <Box type="content">
            <h4>Potrzebujesz wsparcia?</h4>
            <p>Skontaktuj się z naszym agentem, jeśli masz pytania.</p>
            <a href="tel:+48780080360">+48 780 080 360</a>
            <Icon name="customerService" />
          </Box>
        </Col>
      </Row>
    </section>
  );
};

export default ReportRating;
