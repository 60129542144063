import { FC, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'js/redux/reducers';

import { IBoxContent } from 'js/interfaces';

import Steps from 'js/components/Steps';
import Box from 'js/components/Box';
import NewPasswordForm from 'js/views/Register/NewPassword';

export type State = 'reset' | 'success';

const NewPassword: FC = () => {
  const { isNewPassword, loading } = useSelector(
    (state: RootState) => state.Password
  );

  const { loading: accLoading } = useSelector(
    (state: RootState) => state.Account
  );

  const [state, setState] = useState<State>('reset');

  const headings: IBoxContent = useMemo(() => {
    return {
      reset: 'Wpisz nowe hasło',
      success: 'Hasło zresetowane! Możesz się zalogować.',
    };
  }, []);

  useEffect(() => {
    if (!loading && isNewPassword) {
      setState('success');
    }
  }, [loading, isNewPassword]);

  return (
    <>
      <Steps active="register" />
      <Box type="heading">
        <h3>{headings[state]}</h3>
      </Box>
      <NewPasswordForm loading={loading || accLoading} state={state} />
    </>
  );
};

export default NewPassword;
