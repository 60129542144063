import { takeLatest } from 'redux-saga/effects';
import { PasswordEnum } from 'js/redux/password/types';
import { createSaga } from 'js/redux/sagas';

function* passwordSaga() {
  yield takeLatest(
    PasswordEnum.RESET_PASSWORD_REQUEST,
    createSaga('RESET_PASSWORD', '/auth/password/reset', 'POST')
  );

  yield takeLatest(
    PasswordEnum.NEW_PASSWORD_REQUEST,
    createSaga('NEW_PASSWORD', '/auth/password/new', 'POST')
  );

  yield takeLatest(
    PasswordEnum.VERIFY_PASSWORD_REQUEST,
    createSaga('VERIFY_PASSWORD', '/user/verify-password', 'POST')
  );

  yield takeLatest(
    PasswordEnum.CHANGE_PASSWORD_REQUEST,
    createSaga('CHANGE_PASSWORD', '/user/password', 'POST')
  );
}

export default passwordSaga;
