import {
  PasswordEnum,
  PasswordActionTypes,
  ResetPasswordData,
  NewPasswordData,
  VerifyPasswordData,
  ChangePasswordData,
} from 'js/redux/password/types';

export const resetPassword = (
  data: ResetPasswordData
): PasswordActionTypes => ({
  type: PasswordEnum.RESET_PASSWORD_REQUEST,
  method: 'POST',
  data,
});

export const newPassword = (data: NewPasswordData): PasswordActionTypes => ({
  type: PasswordEnum.NEW_PASSWORD_REQUEST,
  method: 'POST',
  data,
});

export const verifyPassword = (
  data: VerifyPasswordData
): PasswordActionTypes => ({
  type: PasswordEnum.VERIFY_PASSWORD_REQUEST,
  method: 'POST',
  data,
});

export const changePassword = (
  data: ChangePasswordData
): PasswordActionTypes => ({
  type: PasswordEnum.CHANGE_PASSWORD_REQUEST,
  method: 'POST',
  data,
});
