import { FC, useCallback, useEffect, useState } from 'react';

import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { changeData, verifyData } from 'js/redux/account/actions';

import { Form, Input, FormInstance, Button, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { phoneNumber, onlyDigits, postCode } from 'js/helpers/formatters';
import {
  validateCity,
  validateID,
  validatePesel,
  validatePhone,
  validatePostCode,
} from 'js/helpers/validators';

import { MaritalStatusEnum } from 'js/interfaces/account';

import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { SubmitBtn } from 'js/components/Buttons';

import { PersonalData } from 'js/pages/Account';
import SmsCode from 'js/components/SmsCode';

import { objectsEquals } from 'js/helpers/objectsEquals';

type Props = {
  loading: boolean;
  data: PersonalData;
  verified: boolean;
};

const AccountPersonal: FC<Props> = ({ loading, data, verified }) => {
  const [form]: [FormInstance<PersonalData>] = Form.useForm();
  const [showButtons, setShowButtons] = useState(false);

  const dispatch: Dispatch = useDispatch();

  const handleCancel = useCallback(() => {
    form.setFieldsValue(data);
    setShowButtons(false);
  }, [form, data]);

  const handleChange = useCallback(() => {
    const values = form.getFieldsValue();

    const newData = {
      ...values,
      smsCode: values.smsCode || '',
    };

    const equals = !!objectsEquals(newData, data);
    setShowButtons(!equals);
  }, [form, data]);

  const handleSubmit = (values: PersonalData) => {
    if (values.smsCode && values.maritalStatus) {
      dispatch(changeData(values));
      form.setFieldsValue({ smsCode: '' });
    } else {
      dispatch(verifyData(values));
    }
  };

  useEffect(() => {
    data && form.setFieldsValue(data);
    setShowButtons(false);
  }, [data, form]);

  return (
    <Box type="content" className="settings-form sms-form">
      <h2>Dane osobiste</h2>

      <Form
        form={form}
        onFinish={handleSubmit}
        onChange={handleChange}
        scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
      >
        <Form.Item name="firstName" label="Imię">
          <Input
            suffix={<EditOutlined />}
            size="large"
            placeholder="Wpisz imię..."
          />
        </Form.Item>

        <Form.Item name="lastName" label="Nazwisko">
          <Input
            suffix={<EditOutlined />}
            size="large"
            placeholder="Wpisz nazwisko..."
          />
        </Form.Item>

        <Form.Item
          name="email"
          label="Adres e-mail"
          rules={[
            { required: true, message: 'Proszę podaj email' },
            { type: 'email', message: 'Podaj poprawny adres email' },
          ]}
        >
          <Input
            suffix={<EditOutlined />}
            size="large"
            placeholder="Wpisz adres e-mail..."
            type="email"
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Numer telefonu"
          normalize={phoneNumber}
          rules={[
            {
              required: true,
              validator: validatePhone,
            },
          ]}
        >
          <Input
            suffix={<EditOutlined />}
            type="tel"
            size="large"
            placeholder="Wpisz numer..."
          />
        </Form.Item>

        <Form.Item
          name="pesel"
          label="PESEL"
          normalize={onlyDigits}
          rules={[
            {
              required: true,
              validator: validatePesel,
            },
          ]}
        >
          <Input
            suffix={<EditOutlined />}
            size="large"
            inputMode="numeric"
            maxLength={11}
            placeholder="Wpisz numer PESEL..."
          />
        </Form.Item>

        <Form.Item
          name="idCard"
          label="Numer i seria dowodu osobistego"
          rules={[
            {
              required: true,
              validator: validateID,
            },
          ]}
        >
          <Input
            suffix={<EditOutlined />}
            size="large"
            maxLength={9}
            placeholder="Wpisz numer dowodu..."
          />
        </Form.Item>

        <Form.Item name="street" label="Ulica">
          <Input
            suffix={<EditOutlined />}
            size="large"
            placeholder="Wpisz ulicę..."
          />
        </Form.Item>
        <div className="settings-form__space-between">
          <Form.Item
            name="houseNo"
            label="Numer budynku"
            rules={[
              {
                required: true,
                message: 'Proszę wpisz numer budynku',
              },
            ]}
          >
            <Input
              suffix={<EditOutlined />}
              size="large"
              placeholder="Wpisz numer..."
            />
          </Form.Item>

          <Form.Item name="apartmentNo" label="Numer lokalu">
            <Input
              suffix={<EditOutlined />}
              size="large"
              placeholder="Wpisz numer..."
            />
          </Form.Item>
        </div>

        <div className="settings-form__space-between">
          <Form.Item
            name="city"
            label="Miejscowość"
            rules={[
              {
                required: true,
                validator: validateCity,
              },
            ]}
          >
            <Input
              suffix={<EditOutlined />}
              size="large"
              placeholder="Wpisz miejscowość..."
            />
          </Form.Item>

          <Form.Item
            name="postCode"
            label="Kod pocztowy"
            normalize={postCode}
            rules={[
              {
                required: true,
                validator: validatePostCode,
              },
            ]}
          >
            <Input
              suffix={<EditOutlined />}
              size="large"
              inputMode="numeric"
              maxLength={6}
              placeholder="Wpisz kod..."
            />
          </Form.Item>
        </div>

        <Form.Item
          name="maritalStatus"
          label="Stan cywilny"
          rules={[
            {
              required: true,
              message: 'Proszę o uzupełnienie pola',
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Wybierz"
            onSelect={handleChange}
            options={[
              { label: 'Kawaler / Panna', value: MaritalStatusEnum.single },
              {
                label: 'Żonaty / Mężatka',
                value: MaritalStatusEnum.married,
              },
              {
                label: 'Wdowiec / Wdowa',
                value: MaritalStatusEnum.widow,
              },
              { label: 'Rozwiedziony/a', value: MaritalStatusEnum.divorced },
              {
                label: 'Wolny związek',
                value: MaritalStatusEnum.freeRelationship,
              },
            ]}
          />
        </Form.Item>

        {!verified && (
          <BtnWrapper>
            <>
              {showButtons && (
                <Button onClick={handleCancel} loading={loading}>
                  Anuluj
                </Button>
              )}
              <SubmitBtn disabled={!showButtons} loading={loading}>
                Zapisz zmiany
              </SubmitBtn>
            </>
          </BtnWrapper>
        )}

        {verified && <SmsCode loading={loading} />}
      </Form>
    </Box>
  );
};

export default AccountPersonal;
