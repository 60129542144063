import { FC, useMemo } from 'react';
import { RootState } from 'js/redux/reducers';
import { useSelector } from 'react-redux';
import {
  InvoicePaymentDateEnum,
  BookKeepingRecordEnum,
  MonthlyIncomeEnum,
  TradeEnum,
  FundingPurposeEnum,
} from 'js/interfaces/account';

import Steps from 'js/components/Steps';
import Box from 'js/components/Box';
import Company from 'js/views/Data/Company';

export type Data = {
  monthlyIncome: MonthlyIncomeEnum | null;
  contractors: string;
  invoicePaymentDate: InvoicePaymentDateEnum | null;
  bookkeepingRecordType: BookKeepingRecordEnum | null;
  trade: TradeEnum | null;
  fundingPurpose: FundingPurposeEnum | null;
  arrearsInstallmentZus: boolean;
  arrearsInstallmentUs: boolean;
  arrearsZus: number;
  arrearsUs: number;
};

const CompanyData: FC = () => {
  const { loading, company, isRefresh } = useSelector(
    (state: RootState) => state.Account
  );

  const defaultData: Data = useMemo(
    () => ({
      monthlyIncome: company.monthlyIncome ?? null,
      contractors: company.contractors ?? '',
      invoicePaymentDate: company.invoicePaymentDate,
      bookkeepingRecordType: company.bookkeepingRecordType,
      trade: company.trade,
      fundingPurpose: company.fundingPurpose,
      arrearsInstallmentZus: company.arrearsInstallmentZus,
      arrearsInstallmentUs: company.arrearsInstallmentUs,
      arrearsZus: company.arrearsZus,
      arrearsUs: company.arrearsUs,
    }),
    [company]
  );

  return (
    <>
      <Steps active="data" />
      <Box type="heading">
        <h3>
          {isRefresh ? 'Potwierdź' : 'Uzupełnij'} dane finansowe swojej firmy
        </h3>
      </Box>
      <Company data={defaultData} loading={loading} isRefresh={isRefresh} />
    </>
  );
};

export default CompanyData;
