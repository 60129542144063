import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pl';

import useAutoLogout from 'js/hooks/useAutoLogout';
import useInitialData from 'js/hooks/useInitialData';

import Header from 'js/views/Header';
import Footer from 'js/components/Footer';
import Routes, { routes } from 'js/routes';

import 'scss/app.scss';

const App: FC = () => {
  const { pathname } = useLocation();
  const { report, home, account, reportView, search } = routes;
  const history = useHistory();

  useAutoLogout(300000);
  useInitialData();

  useEffect(() => {
    moment.locale('pl');
  }, []);

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <>
      <Header />
      <div
        className={[
          'wrapper',
          pathname === home ? 'white' : '',
          pathname === report ? 'wide ' : '',
          pathname === account || pathname === search ? 'wide no-flex' : '',
          pathname.includes(reportView) ? 'wide' : '',
        ].join(' ')}
      >
        <main className="container">
          <Routes />
        </main>
      </div>
      <Footer />
    </>
  );
};

export default App;
