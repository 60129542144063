import { FC } from 'react';
import { AdaptedSummary } from 'js/interfaces/report';

import { Col, Row } from 'antd';
import SummaryBox from 'js/components/Report/SummaryBox';

import 'js/components/Report/Assesment/styles.scss';

type Props = {
  summaries: AdaptedSummary[];
};

const ReportAssessment: FC<Props> = ({ summaries }) => (
  <section className="raport-assesstment">
    <Row gutter={{ xs: 0, sm: 0, md: 16, lg: 16 }}>
      {summaries.map((summary, key) => (
        <Col key={key} xs={24} sm={24} md={8}>
          <SummaryBox data={summary} />
        </Col>
      ))}
    </Row>
  </section>
);

export default ReportAssessment;
