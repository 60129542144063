import { FC } from 'react';
import moment from 'moment';

import {
  ArrowUpOutlined,
  ArrowRightOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons';

import {
  CodeEnum,
  DirectionEnum,
  AdaptedObligation,
  GradeValueEnum,
} from 'js/interfaces/report';

import { formatPrice } from 'js/helpers/formatters';
import { dateFormat } from 'js/constants';

type Props = {
  data: Partial<AdaptedObligation>[];
  code: CodeEnum;
  grade: GradeValueEnum;
};

const ObligationArray: FC<Props> = ({ data, code, grade }) => {
  return (
    <ul className="rating-modal__entry">
      {data.map((obligation, key) => {
        if (code === CodeEnum.IB) {
          return (
            <li key={key}>
              <ul
                className={
                  obligation?.maxDPD
                    ? obligation?.maxDPD > 30
                      ? 'red'
                      : obligation?.maxDPD > 10
                      ? 'yellow'
                      : 'green'
                    : ''
                }
              >
                <li>
                  <p>Produkt</p>
                  <span>{obligation.productType}</span>
                </li>
                <li>
                  <p>Kwota zaległości</p>
                  <span>{formatPrice(obligation.currentDueAmount)} PLN</span>
                </li>
                <li>
                  <p>Liczba dni opóźnienia</p>
                  <span>{obligation.currentDPD}</span>
                </li>
              </ul>
            </li>
          );
        }
        if (code === CodeEnum.IT) {
          return (
            <li key={key}>
              <ul
                className={
                  obligation?.maxDPD
                    ? obligation?.maxDPD > 30
                      ? 'red'
                      : obligation?.maxDPD > 10
                      ? 'yellow'
                      : 'green'
                    : ''
                }
              >
                <li>
                  <p>Produkt</p>
                  <span>{obligation.productType}</span>
                </li>
                <li>
                  <p>Kwota zaległości</p>
                  <span>{formatPrice(obligation.currentDueAmount)} PLN</span>
                </li>

                <li>
                  <p>Dni opóźnienia</p>
                  <span>{obligation.currentDueAmount} PLN</span>
                </li>

                <li>
                  <p>Status płatności</p>
                  <span>{obligation.status || '-'}</span>
                </li>
                <li className="with-dot">
                  <p>
                    <span
                      className={
                        obligation.maxDPD
                          ? obligation.maxDPD > 30
                            ? 'red'
                            : obligation.maxDPD > 10
                            ? 'yellow'
                            : 'green'
                          : ''
                      }
                    />
                  </p>
                  <span>
                    {obligation.maxDPD
                      ? obligation.maxDPD > 30
                        ? 'opóźnienie powyżej 30 dni'
                        : obligation.maxDPD > 10
                        ? 'opóźnienie do 30 dni'
                        : 'opóźnienie od 4 do 10 dni'
                      : ''}
                  </span>
                </li>
              </ul>
            </li>
          );
        }
        if (code === CodeEnum.ID) {
          return (
            <li key={key}>
              <ul>
                <li>
                  <p>Rejestr</p>
                  <span>{obligation.dataSource}</span>
                </li>
                <li>
                  <p>Produkt</p>
                  <span>{obligation.productType}</span>
                </li>
                <li>
                  <p>Data wpisu </p>
                  <span>{moment(obligation.entryDate).format(dateFormat)}</span>
                </li>
                <li>
                  <p>Wartość zadłużenia </p>
                  <span>{formatPrice(obligation.amount)} PLN</span>
                </li>
                <li>
                  <p>Podmiot</p>
                  <span>{obligation.lenderName}</span>
                </li>
                <li className="with-dot">
                  <p>
                    <span />
                  </p>
                  <span />
                </li>
              </ul>
            </li>
          );
        }
        if (code === CodeEnum.IA) {
          return (
            <li key={key}>
              <ul>
                <li>
                  <p>Produkt</p>
                  <span>{obligation.productType}</span>
                </li>
                <li>
                  <p>Wartość </p>
                  <span>{formatPrice(obligation.creditAmount)} PLN</span>
                </li>
                <li>
                  <p>Wartość miesięczna zobowiązania</p>
                  <span>{formatPrice(obligation.installmentAmount)} PLN</span>
                </li>
              </ul>
            </li>
          );
        }
        if (code === CodeEnum.IP) {
          return (
            <li key={key}>
              <ul
                className={
                  obligation.indicator
                    ? obligation.indicator === DirectionEnum.DOWN
                      ? 'red'
                      : obligation.indicator === DirectionEnum.STABLE
                      ? 'yellow'
                      : 'green'
                    : ''
                }
              >
                <li>
                  <p>Miesiąc </p>
                  <span>
                    {moment(obligation.from).format('MMMM yyyy')} -{' '}
                    {moment(obligation.to).format('MMMM yyyy')}
                  </span>
                </li>{' '}
                <li>
                  <p>Liczba transakcji</p>
                  <span>{obligation.count}</span>
                </li>
                <li>
                  <p>Łączna suma</p>
                  <span>{formatPrice(obligation.inflowCleared)} PLN</span>
                </li>
                <li>
                  <p>Wskaźnik</p>
                  <span>
                    <>
                      {obligation.indicator === DirectionEnum.UP && (
                        <ArrowUpOutlined />
                      )}
                      {obligation.indicator === DirectionEnum.STABLE && (
                        <ArrowRightOutlined />
                      )}
                      {obligation.indicator === DirectionEnum.DOWN && (
                        <ArrowDownOutlined />
                      )}
                    </>
                  </span>
                </li>
              </ul>
            </li>
          );
        }
        return null;
      })}
    </ul>
  );
};

export default ObligationArray;
