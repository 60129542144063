import { FC } from 'react';

import Icon from 'js/components/Icon';
import 'js/components/Footer/styles.scss';

const Footer: FC = () => (
  <footer className="footer">
    <div className="footer__inside">
      <div className="footer__inside--left">
        <Icon name="logo" />
      </div>
      <div className="footer__inside--right">
        <p>W razie pytań skontaktuj się z nami!</p>
        <div>
          <Icon name="chat" />
          <a href="tel:+48780080360">
            Simple.pl
            <br />
            +48 780 080 360
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
