import { rest } from 'msw';
import {
  AccountActionTypes,
  AccountApiTypes,
  AccountEnum,
} from 'js/redux/account/types';
import * as dbAccount from 'js/mocks/db/account';
import * as dbUser from 'js/mocks/db/user';
import * as dbApp from 'js/mocks/db/application';
import * as dbCompany from 'js/mocks/db/company';
import consents from 'js/mocks/db/consents.json';
import { getAccount } from '../helpers';
import { ConsentRaw } from 'js/interfaces/account';

type GetUserResponse = Extract<
  AccountApiTypes,
  | { type: AccountEnum.GET_USER_SUCCESS }
  | { type: AccountEnum.GET_USER_FAILURE }
>['payload'];

type GeneratePhoneRequest = Extract<
  AccountActionTypes,
  { type: AccountEnum.EDIT_PHONE_REQUEST }
>['data'];

type GeneratePhoneResponse = Extract<
  AccountApiTypes,
  | { type: AccountEnum.EDIT_PHONE_SUCCESS }
  | { type: AccountEnum.EDIT_PHONE_FAILURE }
>['payload'];

type PhoneVerifyRequest = Extract<
  AccountActionTypes,
  { type: AccountEnum.VERIFY_PHONE_REQUEST }
>['data'];

type PhoneVerifyResponse = Extract<
  AccountApiTypes,
  | { type: AccountEnum.VERIFY_PHONE_SUCCESS }
  | { type: AccountEnum.VERIFY_PHONE_FAILURE }
>['payload'];

type NewApplicationResponse = Extract<
  AccountApiTypes,
  | { type: AccountEnum.CREATE_APP_SUCCESS }
  | { type: AccountEnum.CREATE_APP_FAILURE }
>['payload'];

type NipRequest = Extract<
  AccountActionTypes,
  { type: AccountEnum.NIP_REQUEST }
>['data'];

type NipResponse = Extract<
  AccountApiTypes,
  { type: AccountEnum.NIP_SUCCESS } | { type: AccountEnum.NIP_FAILURE }
>['payload'];

type ConfirmRequest = Extract<
  AccountActionTypes,
  { type: AccountEnum.CONFIRM_NIP_REQUEST }
>['data'];

type ConfirmResponse = Extract<
  AccountApiTypes,
  | { type: AccountEnum.CONFIRM_NIP_SUCCESS }
  | { type: AccountEnum.CONFIRM_NIP_FAILURE }
>['payload'];

type CompanyRequest = Extract<
  AccountActionTypes,
  { type: AccountEnum.COMPANY_REQUEST }
>['data'];

type CompanyResponse = Extract<
  AccountApiTypes,
  { type: AccountEnum.COMPANY_SUCCESS } | { type: AccountEnum.COMPANY_FAILURE }
>['payload'];

type PersonalRequest = Extract<
  AccountActionTypes,
  { type: AccountEnum.PERSONAL_REQUEST }
>['data'];

type PersonalResponse = Extract<
  AccountApiTypes,
  | { type: AccountEnum.PERSONAL_SUCCESS }
  | { type: AccountEnum.PERSONAL_FAILURE }
>['payload'];

type SelfRequest = Extract<
  AccountActionTypes,
  { type: AccountEnum.SELF_REQUEST }
>['data'];

type SelfResponse = Extract<
  AccountApiTypes,
  { type: AccountEnum.SELF_SUCCESS } | { type: AccountEnum.SELF_FAILURE }
>['payload'];

type AddressRequest = Extract<
  AccountActionTypes,
  { type: AccountEnum.ADDRESS_REQUEST }
>['data'];

type AddressResponse = Extract<
  AccountApiTypes,
  { type: AccountEnum.ADDRESS_SUCCESS } | { type: AccountEnum.ADDRESS_FAILURE }
>['payload'];

type GetAgreementsResponse = Extract<
  AccountApiTypes,
  | { type: AccountEnum.GET_CONSENTS_SUCCESS }
  | { type: AccountEnum.GET_CONSENTS_FAILURE }
>['payload'];

type AgreementsRequest = Extract<
  AccountActionTypes,
  { type: AccountEnum.POST_CONSENTS_REQUEST }
>['data'];

type AgreementsResponse = Extract<
  AccountApiTypes,
  | { type: AccountEnum.POST_CONSENTS_SUCCESS }
  | { type: AccountEnum.POST_CONSENTS_FAILURE }
>['payload'];

type GetAppsResponse = Extract<
  AccountApiTypes,
  | { type: AccountEnum.GET_APPS_SUCCESS }
  | { type: AccountEnum.GET_APPS_FAILURE }
>['payload'];

let ceidgTemp: Extract<
  AccountApiTypes,
  { type: AccountEnum.NIP_SUCCESS }
>['payload']['data'] = {
  apartmentNo: null,
  city: null,
  houseNo: null,
  name: null,
  nip: null,
  postCode: null,
  street: null,
};

export const accountHandlers = [
  // Get User
  rest.get<never, GetUserResponse>(
    process.env.API_URL + '/user',
    (req, res, ctx) => {
      const account = getAccount(req);
      if (!account) return res(ctx.status(422));

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: {
            applications: account.applications,
            user: account.user,
            company: account.company,
            consents: account.consents,
          },
        })
      );
    }
  ),

  // Edit phone
  rest.post<GeneratePhoneRequest, GeneratePhoneResponse>(
    process.env.API_URL + '/user/generate-new-phone-verification-token',
    (req, res, ctx) => {
      const account = getAccount(req);
      if (!account) return res(ctx.status(422));

      const { phone } = req.body;

      const updatedUser = dbUser.update(account.user.email, { phone });

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: {
            phone: updatedUser?.phone ?? account.user.phone,
          },
        })
      );
    }
  ),

  // Phone verification
  rest.post<PhoneVerifyRequest, PhoneVerifyResponse>(
    process.env.API_URL + '/user/confirm-phone',
    (req, res, ctx) => {
      const account = getAccount(req);
      if (!account) return res(ctx.status(422));

      const { smsCode } = req.body;

      if (smsCode !== account._hiddenData.smsCode)
        return res(
          ctx.status(400),
          ctx.json({
            status: 400,
            code: 'Bad Request',
            errors: ['Podano nieprawidłowy kod.'],
          })
        );

      const updatedUser = dbUser.update(account.user.email, { isActive: true });

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: {
            user: updatedUser ?? account.user,
            company: account.company,
            consents: account.consents,
            applications: [],
          },
        })
      );
    }
  ),

  // Create new application
  rest.post<never, NewApplicationResponse>(
    process.env.API_URL + '/application',
    (req, res, ctx) => {
      const account = getAccount(req);
      if (!account) return res(ctx.status(422));

      const app = dbApp.create({ userId: account.userId });

      dbAccount.update(account.token, {
        applications: [...account.applications, app],
      });

      return res(
        ctx.status(201),
        ctx.json({
          status: 201,
          code: 'Created',
          data: {
            consents: app.consents,
            createdAt: app.createdAt,
            hasKontomatik: app.hasKontomatik,
            hasPendingReport: app.hasPendingReport,
            hasReport: app.hasReport,
            id: app.id,
            token: app.token,
            userId: app.userId,
          },
        })
      );
    }
  ),

  // Get data by NIP
  rest.get<NipRequest, NipResponse>(
    process.env.API_URL + '/company/ceidg',
    (req, res, ctx) => {
      const account = getAccount(req);
      if (!account) return res(ctx.status(422));

      const nip = req.url.searchParams.get('nip');

      ceidgTemp = {
        apartmentNo: '5',
        city: 'Gdańsk',
        houseNo: '23',
        name: 'Jan Kowalski "KowalBud"',
        nip,
        postCode: '80-301',
        street: 'Topolowa',
      };

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: ceidgTemp,
        })
      );
    }
  ),

  // Confirm NIP
  rest.post<ConfirmRequest, ConfirmResponse>(
    process.env.API_URL + '/company/confirm',
    (req, res, ctx) => {
      const account = getAccount(req);
      if (!account) return res(ctx.status(422));

      const company = dbCompany.update(account.company.id, ceidgTemp);
      if (!company) return res(ctx.status(422));

      dbUser.update(account.user.email, {
        firstName: 'Michal',
        lastName: 'Zenek',
      });

      return res(
        ctx.status(201),
        ctx.json({
          status: 201,
          code: 'Created',
          data: company,
        })
      );
    }
  ),

  // Update company data
  rest.post<CompanyRequest, CompanyResponse>(
    process.env.API_URL + '/company/update-data',
    (req, res, ctx) => {
      const account = getAccount(req);
      if (!account) return res(ctx.status(422));

      const data = req.body;

      const company = dbCompany.update(account.company.id, data);
      if (!company) return res(ctx.status(422));

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: company,
        })
      );
    }
  ),

  // Update personal data
  rest.post<PersonalRequest, PersonalResponse>(
    process.env.API_URL + '/user/personal-data',
    (req, res, ctx) => {
      const account = getAccount(req);
      if (!account) return res(ctx.status(422));

      const data = req.body;

      const user = dbUser.update(account.user.email, data);
      if (!user) return res(ctx.status(422));

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: user,
        })
      );
    }
  ),

  // Self additional data
  rest.post<SelfRequest, SelfResponse>(
    process.env.API_URL + '/user/additional-data',
    (req, res, ctx) => {
      const account = getAccount(req);
      if (!account) return res(ctx.status(422));

      const data = req.body;

      const user = dbUser.update(account.user.email, data);
      if (!user) return res(ctx.status(422));

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: user,
        })
      );
    }
  ),

  // Address data
  rest.post<AddressRequest, AddressResponse>(
    process.env.API_URL + '/user/address-data',
    (req, res, ctx) => {
      const account = getAccount(req);
      if (!account) return res(ctx.status(422));

      const data = req.body;

      const user = dbUser.update(account.user.email, data);
      if (!user) return res(ctx.status(422));

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: user,
        })
      );
    }
  ),

  // Get agreements
  rest.get<never, GetAgreementsResponse>(
    process.env.API_URL + '/consents',
    (_, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: consents as ConsentRaw[],
        })
      );
    }
  ),

  // Post agreements
  rest.post<AgreementsRequest, AgreementsResponse>(
    process.env.API_URL + '/consents',
    (req, res, ctx) => {
      const { consents, applicationId } = req.body;

      if (!applicationId) return res(ctx.status(422));

      dbApp.generateToken(applicationId);

      dbApp.update(applicationId, {
        consents,
      });

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: consents,
        })
      );
    }
  ),

  // Get applications
  rest.get<never, GetAppsResponse>(
    process.env.API_URL + '/application',
    (req, res, ctx) => {
      const account = getAccount(req);
      if (!account) return res(ctx.status(422));

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: account.applications,
        })
      );
    }
  ),
];
