import {
  AuthEnum,
  AuthActionTypes,
  LoginData,
  RegisterData,
} from 'js/redux/auth/types';

export const registerUser = (data: RegisterData): AuthActionTypes => ({
  type: AuthEnum.REGISTER_REQUEST,
  method: 'POST',
  data,
});

export const loginUser = (data: LoginData): AuthActionTypes => ({
  type: AuthEnum.LOGIN_REQUEST,
  method: 'POST',
  data,
});

export const logoutUser = (): AuthActionTypes => ({
  type: AuthEnum.LOGOUT_REQUEST,
  method: 'POST',
});
