import { takeLatest } from 'redux-saga/effects';
import { BankEnum } from 'js/redux/banking/types';
import { createSaga } from 'js/redux/sagas';

function* bankingSaga() {
  yield takeLatest(
    BankEnum.POST_KONTOMATIK_REQUEST,
    createSaga('POST_KONTOMATIK', '/kontomatik', 'POST')
  );

  yield takeLatest(
    BankEnum.KONTOMATIK_READY_REQUEST,
    createSaga('KONTOMATIK_READY', '/kontomatik/ready', 'POST')
  );
}

export default bankingSaga;
