import { FC, useMemo, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { getReport, getReports } from 'js/redux/report/actions';
import { RootState } from 'js/redux/reducers';

import Loader from 'js/components/Loader';
import ReportHeading from 'js/components/Report/Heading';
import { ErrorBoxBig } from 'js/components/Report/InfoBoxes';
import ReportView from 'js/views/Report';

import 'js/pages/Report/styles.scss';

const Report: FC = () => {
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const dispatch: Dispatch = useDispatch();

  const { app, loading: accLoading } = useSelector(
    (state: RootState) => state.Account
  );
  const {
    list,
    byId,
    loading,
    pendingReport,
    newestReportData,
    newestReportId,
  } = useSelector((state: RootState) => state.Report);

  const data = useMemo(() => {
    if (selectedId && byId[selectedId]) {
      return byId[selectedId];
    }
    return null;
  }, [byId, selectedId]);

  useEffect(() => {
    if ((!!app.id && !list.length) || pendingReport) {
      const interval = setInterval(() => {
        dispatch(getReports());
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [app.id, list, dispatch, pendingReport]);

  useEffect(() => {
    if (newestReportId) setSelectedId(newestReportId);
  }, [newestReportId]);

  useEffect(() => {
    if (!data && selectedId && !byId[selectedId]?.id)
      dispatch(getReport(selectedId));
  }, [selectedId, data, dispatch, byId]);

  return (
    <div className="raport">
      {!selectedId || !data || !newestReportData || loading || accLoading ? (
        <Loader text="Wczytuję raport" noBg />
      ) : (
        <>
          <ReportHeading
            selected={selectedId}
            list={list}
            firstName={data.client.firstName}
            onSelect={setSelectedId}
          />

          {newestReportData && selectedId !== newestReportId && (
            <ErrorBoxBig onSelect={() => setSelectedId(newestReportId)} />
          )}

          <ReportView
            data={data}
            newestData={newestReportData}
            loading={false}
          />
        </>
      )}
    </div>
  );
};

export default Report;
