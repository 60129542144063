import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dispatch } from 'redux';
import { RootState } from 'js/redux/reducers';
import { getProgress } from 'js/redux/smeo/actions';

import { DecisionEnum } from 'js/redux/smeo/types';
import { AdaptedPotentialOffer } from 'js/interfaces/report';

import { SmeoProcessBox } from 'js/components/Report/InfoBoxes';
import PotentialOfferBox from 'js/components/Report/PotentialOfferBox';

type Props = {
  data: AdaptedPotentialOffer;
};

const SmeoBox: FC<Props> = ({ data }) => {
  const dispatch: Dispatch = useDispatch();

  const { app } = useSelector((state: RootState) => state.Account);
  const { actualStep, processNumber, decision, loading } = useSelector(
    (state: RootState) => state.Smeo
  );

  const isNegative = useMemo(
    () => decision === DecisionEnum.NEGATIVE,
    [decision]
  );

  const isFinish = useMemo(() => actualStep === 9, [actualStep]);

  const currentId = data.appId ?? app.id;

  useEffect(() => {
    currentId && dispatch(getProgress({ applicationId: currentId }));
    const interval = setInterval(
      () => {
        if (currentId && !isNegative && !isFinish) {
          dispatch(getProgress({ applicationId: currentId }));
        }
      },
      process.env.IS_PRESENTATION === 'true' ? 5000 : 30000
    );
    return () => clearInterval(interval);
  }, [currentId, isFinish, isNegative, dispatch]);

  if (!!processNumber) {
    return (
      <SmeoProcessBox
        finish={isFinish}
        negative={isNegative}
        loading={loading}
      />
    );
  }

  return <PotentialOfferBox data={data} />;
};

export default SmeoBox;
