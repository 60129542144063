import { FC, useMemo } from 'react';
import { routes } from 'js/routes';
import { nip } from 'js/helpers/formatters';

import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { NextBtn } from 'js/components/Buttons';

import 'js/views/Payment/ConfirmData/styles.scss';

type Data = {
  first_name: string;
  last_name: string;
  address: string;
  postCode: string;
  city: string;
  nip: string;
  product: string;
  price: number;
};

const ConfirmData: FC = () => {
  //TODO replace mock with data
  const data: Data = useMemo(() => {
    return {
      first_name: 'Dawid',
      last_name: 'Martyniuk',
      address: 'ul. Zacisze 3/23',
      postCode: '02-354',
      city: 'Warszawa',
      nip: '9462699950',
      product: 'Raport Simple',
      price: 99.99,
    };
  }, []);

  return (
    <Box type="content">
      <div className="confirm-data">
        <div className="confirm-data__info">
          <h4>Dane</h4>
          <h3>
            {data.first_name} {data.last_name}
            <br />
            {data.address}
            <br />
            {data.postCode} {data.city}
          </h3>
          <h3>NIP {nip(data.nip)}</h3>
        </div>

        <div className="confirm-data__info">
          <h4>Podsumowanie</h4>
          <div className="confirm-data__info__columns">
            <div>
              <h5>Nazwa produktu</h5>
              <h3>{data.product}</h3>
            </div>
            <div>
              <h5>Do zapłaty</h5>
              <h3>
                {data.price} PLN <span>(brutto)</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <BtnWrapper className="confirm-data__buttons">
        <NextBtn to={routes.payment}>Przejdź do płatności</NextBtn>
      </BtnWrapper>
    </Box>
  );
};

export default ConfirmData;
