import { FC } from 'react';
import moment from 'moment';

import { ReportListElement } from 'js/redux/report/types';
import { AdaptedReport } from 'js/interfaces/report';

import { Select } from 'antd';

import { dateFormat } from 'js/constants';

import 'js/components/Report/Heading/styles.scss';

type Props = {
  selected?: number | null;
  list: ReportListElement[] | AdaptedReport[];
  firstName: string;
  onSelect?: (id: number) => void;
};

const ReportHeading: FC<Props> = ({ list, selected, onSelect, firstName }) => {
  const options = list.map((report) => ({
    label: moment(report.date, dateFormat).format(dateFormat),
    value: report.id,
  }));

  return (
    <div className="report-heading">
      <h3>
        Cześć<span>{firstName ? ` ${firstName},` : ','}</span> poniżej Twoja
        ocena oraz oferty!
      </h3>
      {selected && !!options.length && (
        <div>
          <span>Wersja raportu</span>
          <Select
            value={selected}
            className="small"
            placeholder="Wybierz"
            options={options}
            onChange={(e) => onSelect && onSelect(Number(e))}
            disabled={options.length < 2}
          />
        </div>
      )}
    </div>
  );
};

export default ReportHeading;
