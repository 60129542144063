import { createBeforeKontomatik } from 'js/mocks/seeder/beforeKontomatik';
import { createHasReport } from 'js/mocks/seeder/hasReport';
import { createNoNip } from 'js/mocks/seeder/noNip';
import { createNoVerified } from 'js/mocks/seeder/noVerified';

import { clearAccounts } from 'js/mocks/db/account';
import { clearApplications } from 'js/mocks/db/application';
import { clearCompanies } from 'js/mocks/db/company';
import { clearUsers } from 'js/mocks/db/user';

export const seed = () => {
  createNoVerified();
  createNoNip();
  createBeforeKontomatik();
  createHasReport();
};

export const reset = () => {
  clearAccounts();
  clearApplications();
  clearUsers();
  clearCompanies();
};
