import { FC } from 'react';

import { Result, Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import SmeoSteps from 'js/components/Steps/smeo';
import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import Provider from 'js/components/Provider';

import 'js/pages/Smeo/styles.scss';

const SmeoFinish: FC = () => (
  <>
    <SmeoSteps active="agreement" />
    <Box type="content" className="wider wider--content smeo-finish">
      <Result
        icon={<CheckCircleFilled twoToneColor="#6D11BB" />}
        title="Możesz już korzystać z faktoringu"
        subTitle={
          <p>
            Po kliknięciu w poniższy przycisk zostaniesz przekierowany na stronę
            SMEO. Aby móc skorzystać z przyznanego faktoringu, należy wybrać
            opcję przypominania hasła i wpisać adres email, który wykorzystujesz
            w{' '}
            <a
              href="https://simple.pl"
              rel="noopener noreferrer"
              target="_blank"
            >
              Simple.pl
            </a>
            . Hasło zostanie przesłane na podany adres email. Po jego wpisaniu
            będziesz mógł zalogować się do swojego panelu klienta.
          </p>
        }
        extra={
          <BtnWrapper>
            <Button
              type="primary"
              href="https://smeo24.pl/frontoffice/login"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Dodaj fakturę
            </Button>
          </BtnWrapper>
        }
      />
    </Box>
    <Provider company="smeo" />
  </>
);

export default SmeoFinish;
