import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'js/redux/reducers';

import SmeoSteps from 'js/components/Steps/smeo';
import Provider from 'js/components/Provider';
import Box from 'js/components/Box';

import { RedoOutlined } from '@ant-design/icons';
import { Result } from 'antd';

import 'js/pages/Smeo/styles.scss';
import {
  getCreateContract,
  getProcessApplication,
  getCompanyVerification,
  getProgress,
} from 'js/redux/smeo/actions';
import { DecisionEnum } from 'js/redux/smeo/types';
import routes from 'js/routes/routes';
import { NextBtn } from 'js/components/Buttons';
import BtnWrapper from 'js/components/BtnWrapper';

const SmeoLoader: FC = () => {
  const dispatch: Dispatch = useDispatch();

  const { app } = useSelector((state: RootState) => state.Account);

  const { actualStep, processNumber, error, loading, decision } = useSelector(
    (state: RootState) => state.Smeo
  );

  useEffect(() => {
    actualStep === 0 &&
      app.id &&
      dispatch(getProgress({ applicationId: app.id }));
  }, [app.id, dispatch, actualStep]);

  useEffect(() => {
    const data = {
      applicationId: app.id,
      processNumber,
    };
    const interval = setInterval(() => {
      if (app.id && !error && decision !== DecisionEnum.NEGATIVE) {
        if (actualStep === 2) {
          dispatch(getCompanyVerification(data));
        }
        if (actualStep === 4) {
          dispatch(getProcessApplication(data));
        }
        if (actualStep === 7) {
          dispatch(getCreateContract(data));
        }
      }
    }, 15000);

    if (decision === DecisionEnum.NEGATIVE) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [actualStep, dispatch, app.id, processNumber, loading, error, decision]);

  if (decision === DecisionEnum.NEGATIVE) {
    return (
      <Box type="content" className="wider wider--content smeo-error">
        <h2>Decyzja negatywna</h2>
        <Result status="error" />
        <BtnWrapper>
          <NextBtn to={routes.report}>Wróć do raportu</NextBtn>
        </BtnWrapper>
      </Box>
    );
  }

  return (
    <div className="smeo-limits smeo-loader">
      <SmeoSteps />

      {error ? (
        <>
          <h1>Błąd</h1>
          <h2>Wystąpiła problem podczas weryfikacji danych.</h2>
          <Box type="content" className="wider wider--content">
            <Result status="error" />
          </Box>
        </>
      ) : (
        <>
          <h1>Trwa przetwarzanie danych</h1>
          <h2>
            Gdy przygotujemy dla Ciebie ofertę otrzymasz od nas powiadomienie na
            podany podczas rejestracji adres email.
          </h2>

          <Box type="content" className="wider wider--content">
            <RedoOutlined spin />
          </Box>
        </>
      )}

      <Provider company="smeo" />
    </div>
  );
};

export default SmeoLoader;
