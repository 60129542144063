import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { RootState } from 'js/redux/reducers';
import { routes, registerOrder } from 'js/routes';

const ReportRoute: FC<RouteProps> = ({ component, path }) => {
  const { user, actualStep, app } = useSelector(
    (state: RootState) => state.Account
  );

  if (!!user.email && !app.hasKontomatik && !app.hasPendingReport)
    return (
      <Redirect
        to={actualStep !== -1 ? registerOrder[actualStep] : routes.transactions}
      />
    );
  if (!!user.email) return <Route path={path} component={component} />;

  return <Redirect to={routes.login} />;
};

export default ReportRoute;
