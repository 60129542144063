import queryString, { StringifiableRecord } from 'query-string';

const getCookie = (name: string) => {
  if (!document.cookie) {
    return null;
  }
  const token = document.cookie
    .split(';')
    .map((c) => c.trim())
    .filter((c) => c.startsWith(name + '='));

  if (token.length === 0) {
    return null;
  }
  return decodeURIComponent(token[0].split('=')[1]);
};

interface FetchOptions {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers: Headers;
  body: string | FormData | null;
  credentials: 'include' | 'same-origin';
}

interface IApi {
  token: string;
  url: string;
  headers: Headers;
  csrfUrl: string;
  call(
    token: string,
    endpoint: string,
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
    data?: StringifiableRecord,
    formData?: FormData
  ): Promise<Response>;
}

const API: IApi = {
  url: process.env.API_URL ?? '',
  csrfUrl: `${process.env.BACKEND_URL}/sanctum/csrf-cookie` ?? '',
  token: '',

  get headers() {
    const headers = new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    const csrfToken = getCookie('XSRF-TOKEN');

    if (API.token) {
      headers.set('Authorization', `Bearer ${API.token}`);
    }

    csrfToken && headers.set('X-XSRF-TOKEN', csrfToken);

    return headers;
  },

  call(token, endpoint, method = 'GET', data, formData) {
    API.token = token;

    const fetchOpt: FetchOptions = {
      method: method,
      headers: API.headers,
      body: null,
      credentials: 'include',
    };

    if (method !== 'GET' && data) {
      fetchOpt.body = JSON.stringify(data);
    }

    if (method === 'GET' && data) {
      endpoint += '?' + queryString.stringify(data);
    }

    if (formData) {
      fetchOpt.body = formData;
      fetchOpt.headers.delete('Content-Type');
    }

    if (!document.cookie.includes('XSRF-TOKEN')) {
      return fetch(API.csrfUrl, {
        method: 'GET',
        credentials: 'include',
      }).then(() => {
        const csrfToken = getCookie('XSRF-TOKEN');
        const optsWithCsrf = {
          ...fetchOpt,
          headers: {
            ...fetchOpt.headers,
            'X-XSRF-TOKEN': csrfToken,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        };
        return fetch(API.url + endpoint, optsWithCsrf);
      });
    }

    return fetch(API.url + endpoint, fetchOpt);
  },
};

export default API;
