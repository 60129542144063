import { User, Company, ConsentChecked, App } from 'js/interfaces/account';

interface Account {
  token: string;
  userId: number;
  user: User;
  company: Company & { id: string };
  isAdmin: boolean;
  consents: ConsentChecked[];
  applications: App[];
  _hiddenData: {
    password: string;
    smsCode: string;
  };
}

let accounts: { [token: string]: Account } = {};

export const clearAccounts = () => {
  accounts = {};
};

type AccountData = Omit<Account, 'token' | 'userId'>;

function create(data: AccountData) {
  if (!data.user.email) return null;

  const token = hash(data.user.email);

  accounts[token] = {
    token,
    userId: Object.keys(accounts).length + 1,
    ...data,
  };

  return accounts[token];
}

function getLastId() {
  return Object.keys(accounts).length;
}

function read(token: string) {
  if (validate(token)) return accounts[token];
  return null;
}

function readByEmail(email: string) {
  const token = hash(email);
  return read(token);
}

function update(token: string, updates: Partial<Account>) {
  if (!validate(token)) return null;

  Object.assign(accounts[token], updates);
  return read(token);
}

function validate(token: string) {
  if (!Object.keys(accounts).includes(token)) return false;
  return true;
}

function hash(str: string) {
  let hash = 5381;
  let i = str.length;

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }
  return String(hash >>> 0);
}

export { create, read, readByEmail, update, getLastId };
