import { Company } from 'js/interfaces/account';

let companies: { [token: string]: Company & { id: string } } = {};

export const clearCompanies = () => {
  companies = {};
};

type CompanyData = Partial<Company>;

function create(data: CompanyData) {
  const token = `${Date.now()}`;

  companies[token] = {
    id: token,
    nip: null,
    name: null,
    city: null,
    postCode: null,
    street: null,
    apartmentNo: null,
    houseNo: null,
    monthlyIncome: null,
    contractors: null,
    invoicePaymentDate: null,
    bookkeepingRecordType: null,
    trade: null,
    fundingPurpose: null,
    arrearsInstallmentZus: false,
    arrearsInstallmentUs: false,
    arrearsZus: 0,
    arrearsUs: 0,
    ...data,
  };
  return companies[token];
}

function read(token: string) {
  if (validate(token)) return companies[token];
  return null;
}

function update(token: string, updates: Partial<Company>) {
  if (!validate(token)) return null;

  Object.assign(companies[token], updates);
  return read(token);
}

function findFirst(key: keyof Company, value: string | number | boolean) {
  return Object.values(companies).find((el) => {
    if (!Object.keys(el).includes(key)) return false;
    return el[key] === value;
  });
}

function validate(token: string) {
  if (!Object.keys(companies).includes(token)) return false;
  return true;
}

export { create, read, findFirst, update };
