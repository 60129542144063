import { SearchUserData } from 'js/interfaces/admin';
import { AdaptedReport } from 'js/interfaces/report';
import { formatReportData } from 'js/helpers/Report';

export const getUsersReports = (data: SearchUserData[]) => {
  const reports = data.length
    ? data[0].reports
        .map((report) => formatReportData(report))
        .filter((report): report is AdaptedReport => report !== null)
    : [];

  return reports;
};
