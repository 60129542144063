import { FC, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import moment from 'moment';

import { RootState } from 'js/redux/reducers';
import { finishStep } from 'js/redux/account/actions';
import { refreshReport } from 'js/redux/report/actions';

import {
  clearKontomatik,
  finishKontomatik,
  postKontomatik,
  setBankingStatus,
} from 'js/redux/banking/actions';
import { routes } from 'js/routes';
import { embedKontox } from 'js/views/Data/BankAccounts/widget';

import { Result, Button } from 'antd';
import Box from 'js/components/Box';
import Loader from 'js/components/Loader';
import BtnWrapper from 'js/components/BtnWrapper';

import 'js/views/Data/BankAccounts/styles.scss';

const BankAccounts: FC = () => {
  const dispatch: Dispatch = useDispatch();

  const { status, loading, bankList, isKontomatikReady } = useSelector(
    (state: RootState) => state.Banking
  );
  const { app } = useSelector((state: RootState) => state.Account);
  const { list } = useSelector((state: RootState) => state.Report);

  const kontomatikWrapper = useRef<HTMLDivElement>(null);
  const ownerExternalId = `wf-app-id-${app.token}`;
  const history = useHistory();

  const onSuccess = (
    target: string,
    sessionId: string,
    sessionIdSignature: string,
    options: {
      officialName: string;
      multipleImportId: boolean;
    }
  ) => {
    dispatch(
      postKontomatik({
        target,
        options,
        sessionId,
        sessionIdSignature,
        applicationId: app.id,
      })
    );
  };

  const isAdditional = useMemo(
    () => history.location.pathname === routes.addAccounts,
    [history]
  );

  const reportId = useMemo(
    () => list.find((e) => e.applicationId === app.id)?.id,
    [app, list]
  );

  const onTargetSelected = ({ name }: { name: string }) => {
    if (bankList.includes(name)) dispatch(setBankingStatus('repeated'));
  };

  const renderKontomatik = (id: string) => {
    embedKontox({
      client: process.env.KONTOMATIK_CLIENT,
      locale: process.env.KONTOMATIK_LOCALE,
      divId: id,
      ownerExternalId,
      onSuccess,
      onTargetSelected,
      psd2: {
        forceSca: true,
        maxImportDate: moment().subtract(13, 'months').format('YYYY-MM-01'),
      },
    });
  };

  const handleFinish = () => {
    if (!bankList.length && isAdditional) {
      history.push(routes.report);
    } else {
      dispatch(finishKontomatik({ applicationId: app.id }));
    }
  };

  useEffect(() => {
    if (isKontomatikReady) {
      if (isAdditional && reportId) {
        dispatch(refreshReport({ reportId }));
      } else {
        dispatch(finishStep());
        history.push(routes.status);
      }
      dispatch(clearKontomatik());
    }
  }, [isKontomatikReady, isAdditional, reportId, dispatch, history]);

  useEffect(() => {
    if (isAdditional && !list.length) {
      history.push(routes.report);
    }
  }, [isAdditional, list, history]);

  const handleRepeat = () => {
    dispatch(setBankingStatus('login'));
  };

  useEffect(() => {
    if (kontomatikWrapper.current && status === 'login' && app.token) {
      renderKontomatik(kontomatikWrapper.current.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kontomatikWrapper, status, app.token]);

  if (!app.token)
    return (
      <Box type="content" className="kontomatik-wrapper">
        <Loader />
      </Box>
    );

  return (
    <Box type="content" className="kontomatik-wrapper">
      <>
        {status === 'login' && <div id="kontomatik" ref={kontomatikWrapper} />}
        {status === 'success' && (
          <BtnWrapper className="kontomatik-wrapper__btn-wrapper">
            <Button type="default" onClick={handleFinish} loading={loading}>
              Przejdź dalej
            </Button>
            <Button type="primary" onClick={handleRepeat}>
              Dodaj kolejne konto
            </Button>
          </BtnWrapper>
        )}
        {status === 'failure' && (
          <BtnWrapper className="kontomatik-wrapper__btn-wrapper">
            <Result
              status="error"
              extra={
                <Button type="primary" onClick={handleRepeat}>
                  Dodaj konto jeszcze raz
                </Button>
              }
            />
          </BtnWrapper>
        )}
        {status === 'repeated' && (
          <BtnWrapper className="kontomatik-wrapper__btn-wrapper">
            <Result
              status="error"
              extra={
                <>
                  <Button
                    type="default"
                    onClick={handleFinish}
                    loading={loading}
                  >
                    Przejdź dalej
                  </Button>

                  <Button type="primary" onClick={handleRepeat}>
                    Dodaj kolejne konto
                  </Button>
                </>
              }
            />
          </BtnWrapper>
        )}
      </>
    </Box>
  );
};

export default BankAccounts;
