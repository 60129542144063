import { FC, useMemo } from 'react';
import { Progress } from 'antd';
import Box from 'js/components/Box';
import { AdaptedSummary } from 'js/interfaces/report';

import 'js/components/Report/RatingBox/styles.scss';

type Status = {
  type: string;
  msg: string;
  description: string;
};

type Props = {
  summaries: AdaptedSummary[];
  rating: number;
};

const RatingBox: FC<Props> = ({ summaries, rating }) => {
  const status: Status = useMemo(() => {
    if (rating >= 5) {
      return {
        type: 'success',
        msg: 'Dobra',
        description:
          'Nie powinieneś mieć problemu z pozyskaniem kredytu. Na twoją ocenę składa się sześć kryteriów.',
      };
    } else if (rating >= 3) {
      return {
        type: 'warning',
        msg: 'Średnia',
        description: 'Masz średnie szanse na uzyskanie finansowania',
      };
    }
    return {
      type: 'error',
      msg: 'Słaba',
      description: 'Masz niskie szanse na uzyskanie finansowania',
    };
  }, [rating]);

  const onScroll = () => {
    document.getElementById('details')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box className={`rating-box rating-box--${status?.type}`} type="content">
      <Progress
        gapDegree={80}
        type="dashboard"
        width={132}
        percent={(rating / 6) * 100}
        format={() => (
          <>
            {rating}
            <span>/6</span>
          </>
        )}
      />
      <h3>
        Twoja ocena: <span>{status?.msg}</span>
      </h3>
      <p>{status?.description}</p>
      <ul>
        {summaries.map((summary, key) => (
          <li key={key} className={summary.grade.value}>
            {summary.name}
          </li>
        ))}
      </ul>
      <button onClick={onScroll}>
        Sprawdź szczegóły oceny i rekomendacje poniżej
      </button>
    </Box>
  );
};

export default RatingBox;
