import { takeLatest } from 'redux-saga/effects';
import { SmeoEnum } from 'js/redux/smeo/types';
import { createSaga } from 'js/redux/sagas';

function* reportSaga() {
  yield takeLatest(
    SmeoEnum.GET_SMEO_CONSENTS_REQUEST,
    createSaga('GET_SMEO_CONSENTS', '/smeo/get-consent', 'POST')
  );

  yield takeLatest(
    SmeoEnum.POST_SMEO_CONSENTS_REQUEST,
    createSaga(
      'POST_SMEO_CONSENTS',
      '/smeo/submit-company-verification',
      'POST'
    )
  );

  yield takeLatest(
    SmeoEnum.GET_COMPANY_VERIFICATION_REQUEST,
    createSaga(
      'GET_COMPANY_VERIFICATION',
      '/smeo/get-company-verification',
      'POST'
    )
  );

  yield takeLatest(
    SmeoEnum.SUBMIT_APPLICATION_REQUEST,
    createSaga('SUBMIT_APPLICATION', '/smeo/submit-process-application', 'POST')
  );

  yield takeLatest(
    SmeoEnum.GET_PROCESS_APP_REQUEST,
    createSaga('GET_PROCESS_APP', '/smeo/get-process-application', 'POST')
  );

  yield takeLatest(
    SmeoEnum.SUBMIT_CREATE_CONTRACT_REQUEST,
    createSaga('SUBMIT_CREATE_CONTRACT', '/smeo/submit-create-contract', 'POST')
  );

  yield takeLatest(
    SmeoEnum.GET_CREATE_CONTRACT_REQUEST,
    createSaga('GET_CREATE_CONTRACT', '/smeo/get-create-contract', 'POST')
  );

  yield takeLatest(
    SmeoEnum.GET_CONTRACT_REQUEST,
    createSaga('GET_CONTRACT', '/smeo/get-contract', 'POST')
  );

  yield takeLatest(
    SmeoEnum.SIGN_IN_CONTRACT_REQUEST,
    createSaga('SIGN_IN_CONTRACT', '/smeo/sign-in-contract', 'POST')
  );

  yield takeLatest(
    SmeoEnum.GET_PROGRESS_REQUEST,
    createSaga('GET_PROGRESS', '/smeo/get-process-progress-data', 'POST')
  );
}

export default reportSaga;
