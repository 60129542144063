import { FC } from 'react';
import { State } from 'js/pages/Report/status';
import { routes } from 'js/routes';

import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { NextBtn } from 'js/components/Buttons';
import { RedoOutlined } from '@ant-design/icons';

import { Result } from 'antd';

import 'js/components/Report/ReportStatus/styles.scss';

type Props = {
  status: State;
};

const ReportStatus: FC<Props> = ({ status }) => (
  <Box type="content">
    <div className="status">
      {status === 'progress' && <RedoOutlined spin />}
      {status === 'success' && (
        <>
          <Result status="success" />
          <BtnWrapper>
            <NextBtn to={routes.report}>Przejdź do raportu</NextBtn>
          </BtnWrapper>
        </>
      )}
      {status === 'failure' && (
        <div className="status__contact">
          <Result status="error" />
          <p>Skontaktuj się z naszym agentem </p>
          <a href="tel:+48780080360">+48 780 080 360 </a>
        </div>
      )}
    </div>
  </Box>
);

export default ReportStatus;
