import { FC } from 'react';
import moment from 'moment';
import 'moment/locale/pl';

import {
  ArrowUpOutlined,
  ArrowRightOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons';

import {
  CodeEnum,
  DirectionEnum,
  AdaptedObligation,
  GradeValueEnum,
} from 'js/interfaces/report';

import { formatPrice } from 'js/helpers/formatters';

import { dateFormat } from 'js/constants';

type Props = {
  data: Partial<AdaptedObligation>[];
  code: CodeEnum;
  grade: GradeValueEnum;
};

const ObligationTable: FC<Props> = ({ data, code, grade }) => (
  <table>
    <thead>
      {code === CodeEnum.IB && (
        <tr>
          <th>Produkt</th>
          <th>Kwota zadłużenia</th>
          <th>Liczba dni opóźnienia</th>
        </tr>
      )}
      {code === CodeEnum.IT && (
        <tr>
          <th></th>
          <th>Produkt</th>
          <th>Kwota zaległości</th>
          <th>Dni opóźnienia</th>
          <th>Status płatności</th>
        </tr>
      )}
      {code === CodeEnum.ID && (
        <tr>
          <th></th>
          <th>Rejestr</th>
          <th>Produkt</th>
          <th>Data wpisu</th>
          <th>Wartość zadłużenia</th>
          <th>Podmiot</th>
        </tr>
      )}
      {code === CodeEnum.IA && (
        <tr>
          <th>Produkt</th>
          <th>Wartość</th>
          <th>Wartość miesięczna zobowiązania</th>
        </tr>
      )}
      {code === CodeEnum.IP && (
        <tr>
          <th>Miesiąc </th>
          <th>Liczba transakcji</th>
          <th>Łączna suma</th>
          <th>Wskaźnik</th>
        </tr>
      )}
    </thead>
    <tbody>
      {data.map((obligation, key) => {
        if (code === CodeEnum.IB) {
          return (
            <tr key={key}>
              <td>{obligation.productType}</td>
              <td> {formatPrice(obligation.currentDueAmount)} PLN</td>
              <td>{obligation.currentDPD}</td>
            </tr>
          );
        }

        if (code === CodeEnum.IT) {
          return (
            <tr key={key}>
              <td>
                {obligation?.maxDPD && (
                  <span
                    className={[
                      'dot',
                      obligation?.maxDPD > 30
                        ? 'dot--error'
                        : obligation?.maxDPD > 10
                        ? 'dot--warning'
                        : 'dot--success',
                    ].join(' ')}
                  />
                )}
              </td>
              <td>{obligation.productType}</td>
              <td>{formatPrice(obligation.currentDueAmount)} PLN</td>
              <td>{obligation.maxDPD}</td>
              <td>{obligation.status || '-'}</td>
            </tr>
          );
        }
        if (code === CodeEnum.ID) {
          return (
            <tr key={key}>
              <td>
                <span className={`dot--${grade}`} />
              </td>
              <td>{obligation.dataSource}</td>
              <td>{obligation.productType}</td>
              <td>{moment(obligation.entryDate).format(dateFormat)}</td>
              <td>{formatPrice(obligation.amount)} PLN</td>
              <td>{obligation.lenderName}</td>
            </tr>
          );
        }
        if (code === CodeEnum.IA) {
          return (
            <tr key={key}>
              <td>{obligation.productType}</td>
              <td>{formatPrice(obligation.creditAmount)} PLN</td>
              <td>{formatPrice(obligation.installmentAmount)} PLN</td>
            </tr>
          );
        }
        if (code === CodeEnum.IP) {
          return (
            <tr key={key}>
              <td>
                {moment(obligation.from).format('MMMM yyyy')} -{' '}
                {moment(obligation.to).format('MMMM yyyy')}
              </td>
              <td>{obligation.count}</td>
              <td>{formatPrice(obligation.inflowCleared)} PLN</td>
              <td>
                <>
                  {obligation.indicator === DirectionEnum.UP && (
                    <ArrowUpOutlined />
                  )}
                  {obligation.indicator === DirectionEnum.STABLE && (
                    <ArrowRightOutlined />
                  )}
                  {obligation.indicator === DirectionEnum.DOWN && (
                    <ArrowDownOutlined />
                  )}
                </>
              </td>
            </tr>
          );
        }
        return null;
      })}
    </tbody>
  </table>
);

export default ObligationTable;
