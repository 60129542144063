import { FC, useMemo } from 'react';
import { RootState } from 'js/redux/reducers';
import { useSelector } from 'react-redux';

import Steps from 'js/components/Steps';
import Box from 'js/components/Box';
import SelfForm from 'js/views/Data/Self';
import { MaritalStatusEnum } from 'js/interfaces/account';

export type Data = {
  maritalStatus: MaritalStatusEnum | null;
};

const SelfData: FC = () => {
  const { loading, user, isRefresh } = useSelector(
    (state: RootState) => state.Account
  );

  const defaultData: Data = useMemo(
    () => ({
      maritalStatus: user?.maritalStatus || null,
    }),
    [user]
  );

  return (
    <>
      <Steps active="data" />
      <Box type="heading">
        <h3>
          {isRefresh
            ? 'Sprawdź, czy Twoje dane są poprawne'
            : 'Podaj dane o sobie'}
        </h3>
      </Box>
      <SelfForm data={defaultData} loading={loading} />
    </>
  );
};

export default SelfData;
