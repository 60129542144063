import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'js/redux/reducers';

import Steps from 'js/components/Steps';
import Box from 'js/components/Box';
import Sms from 'js/views/Register/Sms';

const VerificationCode: FC = () => {
  const { loading, user, isCodeSend, isPhoneEdited } = useSelector(
    (state: RootState) => state.Account
  );

  return (
    <>
      <Steps active="register" />
      <Box type="heading">
        <h3>Wprowadź kod weryfikacyjny</h3>
        <p>
          Na numer ({user.phone}) wysłaliśmy SMS z kodem. Wprowadź go poniżej w
          celu weryfikacji konta.
        </p>
      </Box>
      <Sms
        active={!!user.email && user.isActive}
        send={isCodeSend}
        phone={user.phone}
        loading={loading}
        edited={isPhoneEdited}
      />
    </>
  );
};

export default VerificationCode;
