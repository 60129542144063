import { FC, useMemo } from 'react';
import { RootState } from 'js/redux/reducers';
import { useSelector } from 'react-redux';

import Steps from 'js/components/Steps';
import Box from 'js/components/Box';
import DataCheck from 'js/views/Data/DataCheck';

export type Data = {
  nip: string;
  name: string;
  street: string;
  houseNo: string;
  apartmentNo: string;
  postCode: string;
  city: string;
};

const CheckData: FC = () => {
  const { loading, isVerifiedCompany, company } = useSelector(
    (state: RootState) => state.Account
  );

  const defaultData: Data = useMemo(
    () => ({
      nip: company.nip ?? '',
      name: company.name ?? '',
      street: company.street ?? '',
      houseNo: company.houseNo ?? '',
      apartmentNo: company.apartmentNo ?? '',
      postCode: company.postCode ?? '',
      city: company.city ?? '',
    }),
    [company]
  );

  return (
    <>
      <Steps active="data" />
      <Box type="heading">
        <h3>
          Sprawdź poprawność danych. Jeśli wszystko się zgadza, przejdź dalej
        </h3>
      </Box>
      <DataCheck
        data={defaultData}
        loading={loading}
        verified={isVerifiedCompany}
      />
    </>
  );
};

export default CheckData;
