import { REHYDRATE } from 'redux-persist';
import { RehydrateRootState } from 'js/redux/reducers';
import { AppState } from 'js/interfaces/account';
import {
  AccountEnum,
  AccountState,
  AccountActionTypes,
  AccountApiTypes,
} from 'js/redux/account/types';

import { notification } from 'antd';
import { getApp, getStep, userStateAdapter } from 'js/redux/account/helpers';

export const APP_INIT_STATE: AppState = {
  consents: [],
  createdAt: '',
  hasKontomatik: false,
  hasPendingReport: false,
  hasReport: false,
  id: null,
  token: null,
  userId: null,
};

export const INIT_STATE: AccountState = {
  actualStep: -1,
  loading: false,
  app: APP_INIT_STATE,
  applications: [],
  user: {
    firstName: '',
    lastName: '',
    apartmentNo: '',
    city: '',
    email: '',
    houseNo: '',
    idCard: '',
    isActive: false,
    pesel: '',
    phone: '',
    postCode: '',
    street: '',
    maritalStatus: null,
    isWealthonUser: false,
  },
  wfUser: {
    email: '',
    phone: '',
    exists: false,
    token: '',
  },
  company: {
    nip: '',
    name: '',
    city: '',
    postCode: '',
    street: '',
    houseNo: '',
    apartmentNo: '',
    monthlyIncome: null,
    contractors: '',
    invoicePaymentDate: null,
    bookkeepingRecordType: null,
    trade: null,
    fundingPurpose: null,
    arrearsInstallmentZus: false,
    arrearsInstallmentUs: false,
    arrearsZus: 0,
    arrearsUs: 0,
  },
  consentsRaw: [],
  consentsLoading: false,
  isVerifiedCompany: false,
  isCodeSend: false,
  isPhoneEdited: false,
  isVerified: false,
  isRefresh: false,
};

export default function reducer(
  state = INIT_STATE,
  action: AccountActionTypes | AccountApiTypes | RehydrateRootState
): AccountState {
  if (state === undefined) {
    return INIT_STATE;
  }

  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        ...action.payload?.Account,
        loading: false,
      };

    case AccountEnum.NIP_REQUEST:
      return {
        ...state,
        actualStep: 2,
        loading: true,
      };

    case AccountEnum.NIP_SUCCESS:
      notification['success']({
        message: 'Nip zaktualizowano pomyślnie',
      });
      return {
        ...state,
        loading: false,
        actualStep: 3,
        isVerifiedCompany: false,
        company: {
          ...state.company,
          ...action.payload.data,
        },
      };

    case AccountEnum.NIP_FAILURE:
      notification['error']({
        message: 'Błąd podczas aktualizacji nip',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.CONFIRM_NIP_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AccountEnum.CONFIRM_NIP_SUCCESS:
      notification['success']({
        message: 'Nip zaktualizowano pomyślnie',
      });
      return {
        ...state,
        loading: false,
        actualStep: 4,
        isVerifiedCompany: true,
        company: action.payload.data,
      };

    case AccountEnum.CONFIRM_NIP_FAILURE:
      notification['error']({
        message: 'Błąd podczas aktualizacji nip',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.PERSONAL_REQUEST:
      return {
        ...state,
        actualStep: 5,
        loading: true,
      };

    case AccountEnum.PERSONAL_SUCCESS:
      notification['success']({
        message: 'Dane zaktualizowane pomyślnie',
      });
      const userPersonal = userStateAdapter(action.payload.data);

      return {
        ...state,
        loading: false,
        actualStep: 6,
        user: userPersonal,
      };

    case AccountEnum.PERSONAL_FAILURE:
      notification['error']({
        message: 'Błąd podczas aktualizacji danych',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.SELF_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AccountEnum.SELF_SUCCESS:
      notification['success']({
        message: 'Dane zaktualizowane pomyślnie',
      });
      const userSelf = userStateAdapter(action.payload.data);

      return {
        ...state,
        loading: false,
        actualStep: 7,
        user: userSelf,
      };

    case AccountEnum.SELF_FAILURE:
      notification['error']({
        message: 'Błąd podczas aktualizacji danych',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AccountEnum.COMPANY_SUCCESS:
      notification['success']({
        message: 'Dane zaktualizowane pomyślnie',
      });
      return {
        ...state,
        loading: false,
        actualStep: 5,
        company: action.payload.data,
      };

    case AccountEnum.COMPANY_FAILURE:
      notification['error']({
        message: 'Błąd podczas aktualizacji danych',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.GET_CONSENTS_REQUEST:
      return {
        ...state,
        consentsLoading: true,
      };

    case AccountEnum.GET_CONSENTS_SUCCESS:
      return {
        ...state,
        consentsRaw: action.payload.data,
        consentsLoading: false,
      };

    case AccountEnum.GET_CONSENTS_FAILURE:
      notification['error']({
        message: 'Błąd podczas wczytywania danych',
      });
      return {
        ...state,
        consentsLoading: false,
      };

    case AccountEnum.POST_CONSENTS_REQUEST:
      return {
        ...state,
        actualStep: 8,
        loading: true,
      };

    case AccountEnum.POST_CONSENTS_SUCCESS:
      notification['success']({
        message: 'Dane zaktualizowane pomyślnie',
      });
      return {
        ...state,
        loading: false,
        actualStep: 9,
        app: {
          ...state.app,
          consents: action.payload.data,
        },
      };

    case AccountEnum.POST_CONSENTS_FAILURE:
      notification['error']({
        message: 'Błąd podczas aktualizacji danych',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AccountEnum.ADDRESS_SUCCESS:
      notification['success']({
        message: 'Dane zaktualizowane pomyślnie',
      });
      const userAddress = userStateAdapter(action.payload.data);

      return {
        ...state,
        loading: false,
        actualStep: 8,
        user: userAddress,
      };

    case AccountEnum.ADDRESS_FAILURE:
      notification['error']({
        message: 'Błąd podczas aktualizacji danych',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.EDIT_PHONE_REQUEST:
      return {
        ...state,
        actualStep: 0,
        loading: true,
      };

    case AccountEnum.EDIT_PHONE_SUCCESS:
      notification['success']({
        message: 'Edytowano numer telefonu pomyślnie',
      });

      return {
        ...state,
        loading: false,
        actualStep: 1,
        user: {
          ...state.user,
          phone: action.payload.data.phone,
        },
      };

    case AccountEnum.EDIT_PHONE_FAILURE:
      notification['error']({
        message: 'Błąd podczas edycji numeru telefonu',
      });
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.VERIFY_PHONE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AccountEnum.VERIFY_PHONE_SUCCESS:
      notification['success']({
        message: 'Numer zweryfikowany pomyślnie',
      });
      const userVerify = userStateAdapter(action.payload.data.user);
      return {
        ...state,
        loading: false,
        actualStep: getStep(
          state.app,
          userVerify,
          state.company,
          state.consentsRaw
        ),
        user: userVerify,
      };

    case AccountEnum.VERIFY_PHONE_FAILURE:
      notification['error']({
        message: 'Błąd podczas weryfikacji numeru telefonu',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.NEW_VERIFY_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        isCodeSend: false,
      };

    case AccountEnum.NEW_VERIFY_CODE_SUCCESS:
      notification['success']({
        message:
          'Nowy kod weryfikacyjny został wysłany na twój numer telefonu.',
      });
      return {
        ...state,
        loading: false,
        isCodeSend: true,
      };

    case AccountEnum.NEW_VERIFY_CODE_FAILURE:
      notification['error']({
        message: 'Błąd podczas wysyłania kodu sms.',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
        isCodeSend: true,
      };

    case AccountEnum.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AccountEnum.GET_USER_SUCCESS:
      const app = getApp(action.payload.data.applications);
      const company = action.payload.data.company;
      const user = userStateAdapter(action.payload.data.user);
      const step = getStep(app, user, company, state.consentsRaw);
      return {
        ...state,
        applications: action.payload.data.applications,
        company,
        user,
        loading: false,
        actualStep: step,
        isVerifiedCompany: !!company && !!company.nip,
        app,
      };

    case AccountEnum.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.VERIFY_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        isVerified: false,
      };

    case AccountEnum.VERIFY_DATA_SUCCESS:
      notification['success']({
        message: 'Kod weryfikacyjny został wysłany na twój numer telefonu.',
      });
      return {
        ...state,
        loading: false,
        isVerified: true,
      };

    case AccountEnum.VERIFY_DATA_FAILURE:
      notification['error']({
        message: 'Błąd podczas wysyłania kodu sms.',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.CHANGE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AccountEnum.CHANGE_DATA_SUCCESS:
      notification['success']({
        message: 'Dane zaktualizowane pomyślnie.',
      });
      const userChangeData = userStateAdapter(action.payload.data);

      return {
        ...state,
        loading: false,
        user: userChangeData,
        isVerified: false,
      };

    case AccountEnum.CHANGE_DATA_FAILURE:
      notification['error']({
        message: 'Błąd podczas aktualizacji danych',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.CREATE_APP_REQUEST:
      return {
        ...state,
        loading: true,
        isRefresh: false,
      };

    case AccountEnum.CREATE_APP_SUCCESS:
      return {
        ...state,
        actualStep: state.company.nip ? 4 : 2,
        app: action.payload.data,
        applications: [...state.applications, action.payload.data],
        loading: false,
        isRefresh: !!state.company.nip,
      };

    case AccountEnum.CREATE_APP_FAILURE:
      notification['error']({
        message: 'Błąd generowania nowego raportu.',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.GET_APPS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AccountEnum.GET_APPS_SUCCESS:
      if (!state.user.email)
        return {
          ...state,
          loading: false,
        };
      const newestApp = getApp(action.payload.data);
      return {
        ...state,
        app: newestApp,
        loading: false,
      };

    case AccountEnum.GET_APPS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.CANCEL_APP_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AccountEnum.CANCEL_APP_UPDATE_SUCCESS:
      notification['success']({
        message: 'Aktualizacja raportu przerwana pomyślnie.',
      });
      const apps = state.applications.filter((app) => app.hasReport);
      const currentApp = getApp(apps);
      return {
        ...state,
        loading: false,
        applications: apps,
        app: currentApp,
        actualStep: -1,
      };

    case AccountEnum.CANCEL_APP_UPDATE_FAILURE:
      notification['error']({
        message: 'Błąd podczas anulowania aktualizacji raportu.',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.GET_WF_USER_REQUEST:
      return {
        ...state,
        loading: true,
        wfUser: {
          email: '',
          phone: '',
          exists: false,
          token: action.data.token,
        },
      };

    case AccountEnum.GET_WF_USER_SUCCESS:
      notification['success']({
        message:
          'Uzupełniono dane użytkownika informacjami z aplikacji Wealthon.',
      });
      return {
        ...state,
        loading: false,
        wfUser: { ...action.payload.data, token: state.wfUser.token },
      };

    case AccountEnum.GET_WF_USER_FAILURE:
      notification['error']({
        message: 'Użytkownik nie istnieje w aplikacji Wealthon.',
      });
      return {
        ...state,
        loading: false,
      };

    case AccountEnum.FINISH_STEP:
      return {
        ...state,
        actualStep: -1,
        app: {
          ...state.app,
          hasKontomatik: true,
        },
      };

    default:
      return state;
  }
}
