import { FC, useMemo } from 'react';

import { SearchUserData, TableMeta } from 'js/interfaces/admin';

import { Table } from 'antd';

import { columns } from 'js/views/AdminSearchPanel/columns';

type Props = {
  loading: boolean;
  data: {
    data: SearchUserData[];
    pagination: TableMeta;
  };
  onPageChange: (page: number) => void;
};

const UsersTable: FC<Props> = ({ loading, data, onPageChange }) => {
  const tableData = useMemo(() => {
    if (!!data.data.length) {
      return data.data.map((el: SearchUserData, i: number) => ({
        key: i,
        id: el.user.id,
        createdAt: el?.user?.createdAt || '',
        email: el.user.email || '',
        phone: el.user.phone || '',
        nip: el?.company?.nip || '',
        companyName: el?.company?.name || '',
        hasReport: !!el.reports.length ? 'Tak' : 'Nie',
        isWealthonUser: el.user.isWealthonUser ? 'Tak' : 'Nie',
      }));
    }
    return [];
  }, [data]);

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={tableData}
      scroll={{ x: 1200 }}
      onChange={({ current }) => current && onPageChange(current)}
      pagination={{ ...data.pagination, showSizeChanger: false }}
    />
  );
};

export default UsersTable;
