import {
  AdaptedMark,
  AdaptedRecommendation,
  CodeEnum,
  AdaptedGrade,
  GradeValueEnum,
} from 'js/interfaces/report';

export type Content = {
  grade: AdaptedGrade;
  description: string;
  mark: AdaptedMark;
  recommendations: AdaptedRecommendation[];
};

export type ContentType = string;

export type Data = {
  key: string;
  code: CodeEnum;
  description: string;
  name: string;
  content: Record<ContentType, Content>;
}[];

export const getSummariesContent = (
  countOverdueInstallmentsBik: number,
  maxDelayDaysBik: number,
  countNegativeInformationImKrdErif: number,
  creditsNoClosePsnBik: number
) => {
  const data: Data = [
    {
      key: 'taskSimpleCurrentArrearsMessage',
      code: CodeEnum.IB,
      name: 'Bieżące zaległości',
      description:
        'Tutaj dowiesz się, czy aktualnie masz opóźnienia w spłacie swoich zobowiązań kredytowych',
      content: {
        good: {
          grade: {
            name: 'Dobra',
            value: GradeValueEnum['Dobra'],
          },
          description: 'Nie masz niespłaconych zobowiązań!',
          mark: {
            value: '0',
            description: ' zaległości',
          },
          recommendations: [
            {
              title: 'Nie masz bieżących zaległości!',
              description:
                'Nie masz bieżących zaległości. Aktualnie, wszystkie Twoje zobowiązania są zapłacone na czas. Tak trzymaj.',
            },
          ],
        },
        low: {
          grade: {
            name: 'Niska',
            value: GradeValueEnum['Niska'],
          },
          description: 'Masz niespłacone zobowiązania.',
          mark: {
            value: `${countOverdueInstallmentsBik} `,
            description: 'masz zaległości',
          },
          recommendations: [
            {
              title: 'Masz niezapłaconą ratę.',
              description:
                'Mając niespłacone raty nie uda ci się uzyskać kredytu lub pożyczki. Jak najszybciej spłać bieżące zaległości. Po spłacie odczekaj 7 dni, aby twoje dane w BIK zostały odświeżone.',
            },
          ],
        },
      },
    },
    {
      key: 'taskSimplePunctualityMessage',
      code: CodeEnum.IT,
      name: 'Terminowość',
      description:
        'Dowiedz się, czy zawsze płacisz swoje zobowiązania na czas i jak to wpływa na scoring          ',
      content: {
        good: {
          grade: {
            name: 'Dobra',
            value: GradeValueEnum['Dobra'],
          },
          description:
            'Nie odnotowaliśmy istotnych opóźnień w spłacie zobowiązań.',
          mark: {
            value: '0',
            description: ' opóźnień w historii',
          },
          recommendations: [
            {
              title: 'Jesteś terminowym płatnikiem!',
              description:
                'Dobra robota! Pamiętaj, by zawsze pilnować swojej terminowości. Ma to bardzo duży wpływ na Twój scoring.',
            },
          ],
        },
        medium: {
          grade: {
            name: 'Średnia',
            value: GradeValueEnum['Średnia'],
          },
          description:
            'W przeszłości były niewielkie opóźnienia w płatnościach.',
          mark: {
            value: `${maxDelayDaysBik}`,
            description: ' opóźnień w historii',
          },
          recommendations: [
            {
              title: 'Masz opóźnienie do 10 dni. Spłacaj raty kredytu na czas.',
              description:
                'Masz w swojej historii opóźnienie pow. 4 dni, ale mniej niż 10. Obecnie to nie ma istotnego wpływu na Twój scoring kredytowy. Pamiętaj, jeżeli będziesz się spóźniać z płatnością lub przekroczysz 10 dni, może to wpłynąć na Twój scoring i zmniejszyć Twoje szanse na kredyt.',
            },
          ],
        },
        low: {
          grade: {
            name: 'Niska',
            value: GradeValueEnum['Niska'],
          },
          description: 'W przeszłości były spore opóźnienia w płatnościach.',
          mark: {
            value: `${maxDelayDaysBik}`,
            description: ' opóźnień w historii',
          },
          recommendations: [
            {
              title:
                'Masz opóźnienia ponad 30 dni. Pilnuj terminowości kredytowej przez co najmniej 6 msc.',
              description:
                'Opóźnienia ponad 30 dni mają duży wpływ na szanse pozyskania kredytu. Aby zwiększyć swoje szanse, spłacaj otwarte kredyty stabilnie i bez opóźnień. Zanim złożysz wniosek, upewnij się, że od ostatniego opóźnienia płaciłeś/aś raty kredytu terminowo przez co najmniej 6 miesięcy.                ',
            },
          ],
        },
      },
    },
    {
      key: 'taskSimpleListDebtorsMessage',
      code: CodeEnum.ID,
      name: 'Listy dłużników',
      description: 'Sprawdź, czy widniejesz na liście dłużników',
      content: {
        good: {
          grade: {
            name: 'Dobra',
            value: GradeValueEnum['Dobra'],
          },
          description: 'Nie widniejesz na listach dłużników!',
          mark: {
            value: '0',
            description: ' liczba wpisów',
          },

          recommendations: [
            {
              title: 'Wszystko dobrze! Nie widniejesz na listach dłużników!',
              description:
                'Pamiętaj, by zawsze pilnować terminowości. Jeżeli trafisz na listę dłużników, może to ograniczyć Twoje możliwości pozyskania finansowania.',
            },
          ],
        },
        low: {
          grade: {
            name: 'Niska',
            value: GradeValueEnum['Niska'],
          },
          description: 'Widniejesz na listach dłużników.',
          mark: {
            value: `${countNegativeInformationImKrdErif}`,
            description: ' liczba wpisów',
          },
          recommendations: [
            {
              title: 'Jesteś na liście dłużników. Spłać zadłużenie.',
              description:
                'Masz małe szanse na uzyskanie kredytu, dopóki widniejesz na liście dłużników. Spłać zadłużenie jak najszybciej, lub wyjaśnij, dlaczego widniejesz na liście. Miej na uwadze, że znikniesz z listy dłużników dopiero po 14 dniach od zgłoszenia.',
            },
          ],
        },
      },
    },
    {
      key: 'taskSimpleDebtsDtiMessage',
      code: CodeEnum.IZ,
      name: 'Zadłużenie do DTI',
      description:
        'Ta sekcja pokazuje stosunek Twoich przychodów do obciążeń kredytowych.',
      content: {
        good: {
          grade: {
            name: 'Dobra',
            value: GradeValueEnum['Dobra'],
          },
          description:
            'Kwota Twoich zobowiązań jest optymalna w stosunku do przychodów.',
          mark: {
            value: `0-50%`,
            description: ' DTI',
          },
          recommendations: [
            {
              title: 'Twój DTI jest dobry!',
              description:
                'Aktualnie Twoje zobowiązania finansowe stanowią niewielki procent Twoich przychodów. To pozytywnie wpływa na Twoje szanse pozyskania kredytu!',
            },
          ],
        },
        medium: {
          grade: {
            name: 'Średnia',
            value: GradeValueEnum['Średnia'],
          },
          description:
            'Kwota Twoich zobowiązań może być za duża w stosunku do przychodów.',
          mark: {
            value: `50%-80%`,
            description: ' DTI',
          },
          recommendations: [
            {
              title: 'Twój DTI jest średni.',
              description:
                'Aktualnie Twoje zobowiązania finansowe stanowią istotną część Twoich przychodów. To może obniżyć ocenę Twojej zdolności kredytowej.',
            },
          ],
        },
        low: {
          grade: {
            name: 'Niska',
            value: GradeValueEnum['Niska'],
          },
          description:
            'Kwota Twoich zobowiązań jest za duża w stosunku do przychodów.',
          mark: {
            value: `80%+`,
            description: ' DTI',
          },
          recommendations: [
            {
              title:
                'Stosunek Twojego zadłużenia do przychodów (DTI) jest za duży.            ',
              description:
                'Spłać kredyty/pożyczki, lub zrezygnuj z kart kredytowych.',
            },
            {
              title:
                'Przy tak wysokim DTI masz małe szanse na uzyskanie kredytu.',
              description:
                'Aby zwiększyć swoje szanse, możesz: * Spłacić kredyty/pożyczki * Zrezygnować z karty kredytowej i limitów na koncie (zazwyczaj około 3-5% limitu liczy się do DTI).',
            },
          ],
        },
      },
    },
    {
      key: 'taskSimpleDynamicsIncomeMessage',
      code: CodeEnum.IP,
      name: 'Dynamika Przychodów',
      description:
        'Poznaj tendencje swoich przychodów w ostatnich miesiącach i jak to wpływa na scoring.',
      content: {
        good: {
          grade: {
            name: 'Dobra',
            value: GradeValueEnum['Dobra'],
          },
          description:
            'W ciągu ostatnich miesięcy na Twoim koncie widać wzrost przychodów.',
          mark: {
            value: `%`,
            description: ' wzrost przychodów',
          },
          recommendations: [
            {
              title: 'Twoje przychody rosną.',
              description:
                'Rosnące przychody zwiększają twoje szanse na uzyskanie kredytu.',
            },
          ],
        },
        medium: {
          grade: {
            name: 'Średnia',
            value: GradeValueEnum['Średnia'],
          },
          description:
            'Twoje przychody utrzymują się na stałym poziomie w ciągu ostatnich miesięcy.',
          mark: {
            value: `%`,
            description: ' wzrost przychodów',
          },
          recommendations: [
            {
              title:
                'Twoje przychody utrzymują się na tym samym poziomie!            ',
              description:
                'Dobra robota! Stałe przychody mają pozytywny wpływ na Twoje szanse uzyskania finansowania!',
            },
          ],
        },
        low: {
          grade: {
            name: 'Niska',
            value: GradeValueEnum['Niska'],
          },
          description:
            'W ciągu ostatnich miesięcy na Twoim koncie widać spadek przychodów, co obniża szanse na pozyskanie kredytu.',
          mark: {
            value: `%`,
            description: ' spadek przychodów',
          },
          recommendations: [
            {
              title: 'Twoje przychody spadają.            ',
              description:
                'Spadające przychody mogą mieć negatywny wpływ na decyzję banku odnośnie udzielenia Ci finansowania',
            },
          ],
        },
      },
    },
    {
      key: 'taskSimpleOtherCreditsActivityMessage',
      code: CodeEnum.IA,
      name: 'Inna aktywność kredytowa',
      description:
        'Sprawdź pozostałe czynniki wpływające na Twój scoring kredytowy.',
      content: {
        good: {
          grade: {
            name: 'Dobra',
            value: GradeValueEnum['Dobra'],
          },
          description:
            'Twoja wcześniejsza aktywność kredytowa nie wpłynie negatywnie na Twoją ocenę.',
          mark: {
            value: '0',
            description: ' brak wpisów',
          },
          recommendations: [
            {
              title: 'Nie masz pożyczek w instytucjach pozabankowych.',
              description:
                'Warto dodać, że dla niektórych banków posiadanie pożyczki z sektora pozabankowego stanowi podstawę do odrzucenia wniosku kredytowego.',
            },
          ],
        },
        medium: {
          grade: {
            name: 'Średnia',
            value: GradeValueEnum['Średnia'],
          },
          description:
            'Twoja wcześniejsza aktywność kredytowa może mieć negatywny wpływ na szanse pozyskania kredytu.',
          mark: {
            value: `${creditsNoClosePsnBik}`,
            description: ' wpisy w sekcji',
          },
          recommendations: [
            {
              title: 'Masz pożyczkę w instytucji pozabankowej.',
              description:
                'Dla niektórych banków posiadanie pożyczki z sektora pozabankowego stanowi podstawę do odrzucenia wniosku kredytowego. Spłać tę pożyczkę, zanim złożysz wniosek o kredyt do banku.',
            },
          ],
        },
        low: {
          grade: {
            name: 'Niska',
            value: GradeValueEnum['Niska'],
          },
          description:
            'Twoja wcześniejsza aktywność kredytowa może mieć negatywny wpływ Twoją ocenę.',
          mark: {
            value: `${creditsNoClosePsnBik}`,
            description: ' wpisy w sekcji',
          },
          recommendations: [
            {
              title:
                'Czy w ostatni czasie wnioskowałeś/aś kilkakrotnie o kredyt?',
              description:
                'Pamiętaj, że każdy wniosek o kredyt jest rejestrowany w BIK. Jeżeli jednocześnie wnioskujesz o kredyt do kilku banków, zmniejsza to Twoje szanse na uzyskanie kredytu. ',
            },
            {
              title: 'Masz pożyczkę w instytucji pozabankowej.',
              description:
                'Dla niektórych banków posiadanie pożyczki z sektora pozabankowego stanowi podstawę do odrzucenia wniosku kredytowego. Spłać tę pożyczkę, zanim złożysz wniosek o kredyt do banku.',
            },
          ],
        },
      },
    },
  ];

  return data;
};
