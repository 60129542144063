import { FC } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { resetPassword } from 'js/redux/password/actions';

import { Form, Input, FormInstance } from 'antd';

import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { SubmitBtn } from 'js/components/Buttons';

import 'js/views/Register/ResetPassword/styles.scss';

type ResetPasswordData = {
  email: string;
};

type Props = {
  loading: boolean;
};

const ResetPassword: FC<Props> = ({ loading }) => {
  const [form]: [FormInstance<ResetPasswordData>] = Form.useForm();
  const dispatch: Dispatch = useDispatch();

  const handleSubmit = (values: ResetPasswordData) => {
    dispatch(resetPassword(values));
  };

  return (
    <>
      <Box type="content" className="reset-password-form">
        <Form
          form={form}
          scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
          onFinish={handleSubmit}
        >
          <Form.Item
            name="email"
            label="Adres e-mail"
            rules={[
              { required: true, message: 'Proszę podaj email' },
              { type: 'email', message: 'Podaj poprawny adres email' },
            ]}
          >
            <Input
              size="large"
              placeholder="Wpisz adres e-mail..."
              type="email"
            />
          </Form.Item>

          <BtnWrapper className="reset-password-form__buttons">
            <SubmitBtn loading={loading}>Wyślij link</SubmitBtn>
          </BtnWrapper>
        </Form>
      </Box>
    </>
  );
};

export default ResetPassword;
