import { REHYDRATE } from 'redux-persist';
import moment from 'moment';

import { RehydrateRootState } from 'js/redux/reducers';
import {
  ReportEnum,
  ReportState,
  ReportActionTypes,
  ReportApiTypes,
} from 'js/redux/report/types';

import { notification } from 'antd';
import { dateFormat } from 'js/constants';
import { getNewestReport } from 'js/helpers/Report/getNewest';
import { formatReportData } from 'js/helpers/Report';

export const INIT_STATE: ReportState = {
  list: [],
  error: false,
  chosenOffer: null,
  isLogged: false,
  loading: false,
  pendingReport: false,
  byId: {},
  newestReportId: null,
  newestReportData: null,
};

export default function reducer(
  state = INIT_STATE,
  action: ReportActionTypes | ReportApiTypes | RehydrateRootState
): ReportState {
  if (state === undefined) {
    return INIT_STATE;
  }

  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        ...action.payload?.Report,
        loading: false,
      };

    case ReportEnum.CHECK_OFFER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ReportEnum.CHECK_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        chosenOffer: action.payload.data.company,
      };

    case ReportEnum.CHECK_OFFER_FAILURE:
      notification['error']({
        message: 'Wystąpił błąd, spróbuj ponownie.',
      });
      return {
        ...state,
        loading: false,
      };

    case ReportEnum.GET_REPORT_REQUEST:
      return {
        ...state,
        isLogged: true,
        loading: true,
      };

    case ReportEnum.GET_REPORT_SUCCESS:
      const adaptedReport = formatReportData({
        ...state.byId[action.payload.data.id],
        ...action.payload.data,
      });
      const reportsDataList = adaptedReport
        ? Object.values({
            ...state.byId,
            [action.payload.data.id]: adaptedReport,
          })
        : Object.values(state.byId);
      return {
        ...state,
        loading: false,
        byId: adaptedReport
          ? {
              ...state.byId,
              [action.payload.data.id]: adaptedReport,
            }
          : state.byId,
        newestReportData: getNewestReport(reportsDataList),
      };

    case ReportEnum.GET_REPORT_FAILURE:
      notification['error']({
        message: 'Wystąpił błąd podczas pobierania raportu',
        description: 'Spróbuj ponownie później',
      });
      return {
        ...state,
        loading: false,
        byId: {
          ...state.byId,
          [action.id]: state.byId[action.id],
        },
      };

    case ReportEnum.GET_REPORTS_REQUEST:
      return {
        ...state,
        isLogged: true,
        loading: true,
      };

    case ReportEnum.GET_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingReport: false,
        list: state.isLogged ? action.payload.data : [],
        newestReportId: state.isLogged
          ? action.payload.data.sort(
              (a, b) =>
                moment(b.date, dateFormat).unix() -
                moment(a.date, dateFormat).unix()
            )[0].id
          : null,
      };

    case ReportEnum.GET_REPORTS_FAILURE:
      if (!action.payload)
        notification['error']({
          message: 'Wystąpił błąd podczas pobierania listy raportów',
          description: 'Spróbuj ponownie później',
        });
      return {
        ...state,
        loading: false,
      };

    case ReportEnum.GENERATE_REPORT_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };

    case ReportEnum.GENERATE_REPORT_SUCCESS:
      notification['success']({
        message: 'Prośba o wygenerowanie raportu została wysłana.',
        description:
          'Prosimy o cierpliwość, generowanie raportu może potrwać kilka minut.',
      });
      return {
        ...state,
        pendingReport: true,
        error: false,
        loading: false,
      };

    case ReportEnum.GENERATE_REPORT_FAILURE:
      notification['error']({
        message: 'Błąd podczas generowania raportu',
        description: Object.values(action.payload.errors)[0],
      });
      return {
        ...state,
        error: true,
        loading: false,
      };

    case ReportEnum.REFRESH_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ReportEnum.REFRESH_REPORT_SUCCESS:
      notification['success']({
        message: 'Raport zaktualizowany pomyślnie.',
      });
      return {
        ...state,
        loading: false,
        list: [],
      };

    case ReportEnum.REFRESH_REPORT_FAILURE:
      notification['error']({
        message: 'Błąd podczas aktualizacji raportu.',
      });
      return {
        ...state,
        loading: false,
      };

    case ReportEnum.CLEAR_OFFER:
      return {
        ...state,
        chosenOffer: null,
      };

    default:
      return state;
  }
}
