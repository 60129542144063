import { AppId } from 'js/interfaces';
import {
  BankEnum,
  BankingActionTypes,
  PostKontomatikData,
  Status,
} from 'js/redux/banking/types';

export const postKontomatik = (
  data: PostKontomatikData
): BankingActionTypes => ({
  type: BankEnum.POST_KONTOMATIK_REQUEST,
  method: 'POST',
  data,
});

export const finishKontomatik = (data: AppId): BankingActionTypes => ({
  type: BankEnum.KONTOMATIK_READY_REQUEST,
  method: 'POST',
  data,
});

export const setBankingStatus = (data: Status): BankingActionTypes => ({
  type: BankEnum.SET_BANKING_STATUS,
  data,
});

export const clearKontomatik = (): BankingActionTypes => ({
  type: BankEnum.CLEAR_KONTOMATIK,
});
