import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { setAddress } from 'js/redux/account/actions';

import { postCode } from 'js/helpers/formatters';
import { routes } from 'js/routes';

import { Form, Input, FormInstance } from 'antd';

import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { PrevBtn, SubmitBtn } from 'js/components/Buttons';

import { Data } from 'js/pages/Data/address';
import { objectsEquals } from 'js/helpers/objectsEquals';
import { validateCity, validatePostCode } from 'js/helpers/validators';

import 'js/views/Data/Address/styles.scss';

type Props = {
  loading: boolean;
  data: Data;
};

const AddressForm: FC<Props> = ({ data, loading }) => {
  const [form]: [FormInstance<Data>] = Form.useForm();
  const dispatch: Dispatch = useDispatch();

  const history = useHistory();

  const handleSubmit = (values: Data) => {
    objectsEquals(data, values)
      ? history.push(routes.consents)
      : dispatch(setAddress(values));
  };

  useEffect(() => {
    data && form.setFieldsValue(data);
  }, [data, form]);

  return (
    <Box type="content" className="address-form">
      <Form
        form={form}
        onFinish={handleSubmit}
        scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
      >
        <Form.Item name="street" label="Ulica">
          <Input size="large" placeholder="Wpisz ulicę..." />
        </Form.Item>

        <Form.Item
          name="houseNo"
          label="Numer budynku"
          rules={[
            {
              required: true,
              message: 'Proszę wpisz numer budynku',
            },
          ]}
        >
          <Input size="large" placeholder="Wpisz numer..." />
        </Form.Item>

        <Form.Item name="apartmentNo" label="Numer lokalu">
          <Input size="large" placeholder="Wpisz numer..." />
        </Form.Item>

        <Form.Item
          name="city"
          label="Miejscowość"
          rules={[
            {
              required: true,
              validator: validateCity,
            },
          ]}
        >
          <Input size="large" placeholder="Wpisz miejscowość..." />
        </Form.Item>

        <Form.Item
          name="postCode"
          label="Kod pocztowy"
          normalize={postCode}
          rules={[
            {
              required: true,
              validator: validatePostCode,
            },
          ]}
        >
          <Input
            size="large"
            inputMode="numeric"
            maxLength={6}
            placeholder="Wpisz kod..."
          />
        </Form.Item>

        <BtnWrapper>
          <PrevBtn to={routes.self}>Wstecz</PrevBtn>
          <SubmitBtn loading={loading}>Przejdź dalej</SubmitBtn>
        </BtnWrapper>
      </Form>
    </Box>
  );
};

export default AddressForm;
