import {
  AccountEnum,
  AccountActionTypes,
  NipData,
  PersonalData,
  CompanyData,
  PostConsentsData,
  AddressData,
  NewVerifyCodeData,
  EditPhoneData,
  VerifyPhoneData,
  SelfData,
  VerifyData,
  ChangeData,
  WfUserData,
} from 'js/redux/account/types';

export const createApp = (): AccountActionTypes => ({
  type: AccountEnum.CREATE_APP_REQUEST,
  method: 'POST',
});

export const getApps = (): AccountActionTypes => ({
  type: AccountEnum.GET_APPS_REQUEST,
  method: 'GET',
});

export const setNip = (data: NipData): AccountActionTypes => ({
  type: AccountEnum.NIP_REQUEST,
  method: 'GET',
  data,
});

export const confirmNip = (data: NipData): AccountActionTypes => ({
  type: AccountEnum.CONFIRM_NIP_REQUEST,
  method: 'POST',
  data,
});

export const setPersonalData = (data: PersonalData): AccountActionTypes => ({
  type: AccountEnum.PERSONAL_REQUEST,
  method: 'POST',
  data,
});

export const setSelfData = (data: SelfData): AccountActionTypes => ({
  type: AccountEnum.SELF_REQUEST,
  method: 'POST',
  data,
});

export const setCompanyData = (data: CompanyData): AccountActionTypes => ({
  type: AccountEnum.COMPANY_REQUEST,
  method: 'POST',
  data,
});

export const getConsents = (): AccountActionTypes => ({
  type: AccountEnum.GET_CONSENTS_REQUEST,
  method: 'GET',
});

export const postConsents = (data: PostConsentsData): AccountActionTypes => ({
  type: AccountEnum.POST_CONSENTS_REQUEST,
  method: 'POST',
  data,
});

export const setAddress = (data: AddressData): AccountActionTypes => ({
  type: AccountEnum.ADDRESS_REQUEST,
  method: 'POST',
  data,
});

export const editPhone = (data: EditPhoneData): AccountActionTypes => ({
  type: AccountEnum.EDIT_PHONE_REQUEST,
  method: 'POST',
  data,
});

export const generateNewVerifyToken = (
  data: NewVerifyCodeData
): AccountActionTypes => ({
  type: AccountEnum.NEW_VERIFY_CODE_REQUEST,
  method: 'POST',
  data,
});

export const getUser = (): AccountActionTypes => ({
  type: AccountEnum.GET_USER_REQUEST,
  method: 'GET',
});

export const verifyPhone = (data: VerifyPhoneData): AccountActionTypes => ({
  type: AccountEnum.VERIFY_PHONE_REQUEST,
  method: 'POST',
  data,
});

export const finishStep = (): AccountActionTypes => ({
  type: AccountEnum.FINISH_STEP,
});

export const verifyData = (data: VerifyData): AccountActionTypes => ({
  type: AccountEnum.VERIFY_DATA_REQUEST,
  method: 'POST',
  data,
});

export const changeData = (data: ChangeData): AccountActionTypes => ({
  type: AccountEnum.CHANGE_DATA_REQUEST,
  method: 'POST',
  data,
});

export const cancelAppData = (id: number): AccountActionTypes => ({
  type: AccountEnum.CANCEL_APP_UPDATE_REQUEST,
  method: 'POST',
  id: `${id}/cancel`,
});

export const getWfUser = (data: WfUserData): AccountActionTypes => ({
  type: AccountEnum.GET_WF_USER_REQUEST,
  method: 'GET',
  data,
});
