import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { setNip } from 'js/redux/account/actions';

import { onlyDigits } from 'js/helpers/formatters';
import { validateNip } from 'js/helpers/validators';

import { Form, Input, FormInstance } from 'antd';

import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { SubmitBtn } from 'js/components/Buttons';
import { routes } from 'js/routes';

import 'js/views/Data/Nip/styles.scss';

type NipData = {
  nip: string;
};

type Props = {
  loading: boolean;
  data: NipData;
  isRefresh: boolean;
};

const Nip: FC<Props> = ({ loading, data, isRefresh }) => {
  const [form]: [FormInstance<NipData>] = Form.useForm();
  const dispatch: Dispatch = useDispatch();

  const history = useHistory();

  const handleSubmit = (values: NipData) => {
    values.nip !== data.nip
      ? dispatch(setNip(values))
      : history.push(routes.check);
  };

  useEffect(() => {
    data && form.setFieldsValue(data);
  }, [data, form]);

  return (
    <Box type="content" className="nip-form">
      <Form
        form={form}
        scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="nip"
          label="NIP"
          normalize={onlyDigits}
          rules={[
            {
              required: true,
              validator: validateNip,
            },
          ]}
        >
          <Input
            size="large"
            inputMode="numeric"
            maxLength={10}
            placeholder="Wpisz NIP..."
            disabled={loading || isRefresh}
          />
        </Form.Item>
        <BtnWrapper>
          <SubmitBtn loading={loading}>Pobierz dane</SubmitBtn>
        </BtnWrapper>
      </Form>
    </Box>
  );
};

export default Nip;
