import moment from 'moment';

export const onlyDigits = (input: string | number | undefined = '') =>
  `${input}`.replace(/[\D]/g, '');

export const postCode = (input: string | number | undefined = '') =>
  `${input}`.replace(/[^\d-]/g, '').replace(/^(\d{2})(\d{3})$/, '$1-$2');

export const commaToDot = (input: string | undefined = '') =>
  +`${input}`.replace(/,/g, '.');

export const phoneNumber = (input: string | number | undefined = '') =>
  `${input}`
    .replace(/[^\d]/, '')
    .replace(/^0/, '')
    .replace(/^048|^48|^\+48/, '')
    .replace(/^(\d{3})(\d{3})(\d{3})$/, '$1 $2 $3');

export const nip = (input: string | number | undefined = '') =>
  `${input}`.replace(/^(.{3})(.{3})(.{2})(.{2})(.*)$/, '$1 $2 $3 $4');

export const formatPrice = (price: number | undefined) => {
  if (typeof price === 'number') {
    return (Math.round(price * 100) / 100)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return `${price ?? 0}`;
};

export const formatTime = (time: number) => {
  const diffDuration = moment.duration(Math.abs(time));

  const years = diffDuration.years();
  const months = diffDuration.months();
  const days = diffDuration.days();
  const hours = diffDuration.hours();
  const minutes = diffDuration.minutes();

  if (years >= 1) {
    return `  ${years > 1 ? `${years.toFixed(0)} lat` : 'rok'}`;
  } else if (months >= 1) {
    return `${months > 1 ? `${months.toFixed(0)}  miesięcy` : 'miesiąc'}`;
  } else if (days >= 1) {
    return `${days > 1 ? `${days.toFixed(0)} dni` : 'dzień'}`;
  } else if (hours >= 1) {
    return `${hours > 1 ? `${hours.toFixed(0)} godzin` : 'godzinę'}`;
  }
  return `${minutes > 1 ? `${minutes} minut` : 'minutę'}`;
};
