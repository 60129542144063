import moment from 'moment';
import {
  EngineTypeEnum,
  ReportFrsData,
  ReportRiskbiteData,
  ReportTypeData as Report,
} from 'js/interfaces/report';
import { report } from 'js/mocks/db/reports/5';

type ReportListElement = Report & { applicationId: number; _token: string };

let reports: {
  [id: number]: ReportListElement;
} = {};

export type ReportFrstDataExtended =
  | Partial<Omit<ReportFrsData, 'id' | 'applicationId' | 'company' | 'user'>> &
      Pick<ReportFrsData, 'applicationId' | 'company' | 'user'> & {
        _token: string;
      };

export type ReportRiskbiteDataExtended =
  | Partial<
      Omit<ReportRiskbiteData, 'id' | 'applicationId' | 'company' | 'user'>
    > &
      Pick<ReportRiskbiteData, 'applicationId' | 'company' | 'user'> & {
        _token: string;
      };

function addExisting(token: string, data: Report) {
  const id = Object.keys(reports).length + 1;

  reports[id] = {
    ...data,
    _token: token,
    id,
  };
  return reports[id];
}

function createFrs(data: ReportFrstDataExtended) {
  const id = Object.keys(reports).length + 1;

  const base = {
    ...data,
    id,
    date: moment().format('DD-MM-YYYY'),
    expirationDate: moment(moment(), 'DD-MM-YYYY')
      .add(14, 'days')
      .format('DD-MM-YYYY'),
  };

  if (base.engineType === EngineTypeEnum.FRS && 'variables' in report.data) {
    reports[id] = {
      ...base,
      engineType: report.data.engineType,
      variables: report.data.variables,
      trace: report.data.trace,
      freeAmountBikKontoAgr: report.data?.freeAmountBikKontoAgr ?? 5000,
      ratingSimplePlBpmnValue: report.data?.ratingSimplePlBpmnValue ?? 1,
    };
  }

  return reports[id];
}

function createRiskbite(data: ReportRiskbiteDataExtended) {
  const id = Object.keys(reports).length + 1;

  const base = {
    ...data,
    id,
    date: moment().format('DD-MM-YYYY'),
    expirationDate: moment(moment(), 'DD-MM-YYYY')
      .add(14, 'days')
      .format('DD-MM-YYYY'),
  };

  if (
    base.engineType === EngineTypeEnum.RiskBite &&
    'summaries' in report.data
  ) {
    reports[id] = {
      ...base,
      engineType: report.data.engineType,
      summaries: report.data.summaries,
      grantedAmountMax: report.data?.grantedAmountMax ?? 5000,
      rating: report.data?.rating ?? 1,
    };
  }

  return reports[id];
}

function read(id: number) {
  if (validate(id)) return reports[id];
  return null;
}

function readAll(token: string) {
  return Object.values(reports)
    .filter((report: ReportListElement) => report._token === token)
    ?.map((report) => ({
      id: report.id,
      applicationId: report.applicationId,
      date: report.date,
      expirationDate: report.expirationDate,
    }));
}

function find(nip: string) {
  return Object.values(reports).filter((report) => report.company.nip === nip);
}

function findById(id: number) {
  return Object.values(reports).find((report) => report.id === id);
}

function findByEmail(email: string) {
  return Object.values(reports).filter((report) => report.user.email === email);
}

function validate(id: number) {
  if (!Object.keys(reports).includes(`${id}`)) return false;
  return true;
}

export {
  createFrs,
  createRiskbite,
  read,
  readAll,
  find,
  findById,
  findByEmail,
  addExisting,
};
