import { FC } from 'react';
import 'js/components/BtnWrapper/styles.scss';

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
};

const BtnWrapper: FC<Props> = ({ className = '', children }) => (
  <div className={`btn-wrapper ${className}`}>{children}</div>
);

export default BtnWrapper;
