import { FC, useEffect, useState } from 'react';
import { Button } from 'antd';

import * as serviceWorkerRegistration from 'js/serviceWorkerRegistration';

import 'js/components/SwReload/styles.scss';

const SWReload: FC = () => {
  const [show, setShow] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
    null
  );

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShow(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShow(false);
    window.location.reload();
  };

  return (
    <>
      {show && (
        <div className="sw-reload">
          <h4>Dostępna aktualizacja</h4>
          <div className="buttons-wrapper">
            <Button
              onClick={() => setShow(false)}
              htmlType="button"
              type="text"
            >
              anuluj
            </Button>
            <Button htmlType="button" onClick={reloadPage}>
              aktualizuj
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default SWReload;
