import { FC, useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { ConsentStepEnum } from 'js/interfaces/account';
import { RootState } from 'js/redux/reducers';
import { getConsents } from 'js/redux/account/actions';

import Steps from 'js/components/Steps';
import Box from 'js/components/Box';
import Loader from 'js/components/Loader';
import ConsentsForm from 'js/views/Data/Consents';

export type Data = {
  id: number;
  title: string;
  required: boolean;
  text: string;
  checked: boolean;
};

const Consents: FC = () => {
  const { loading, consentsRaw, app } = useSelector(
    (state: RootState) => state.Account
  );

  const { consents } = app;

  const [defaultData, setDefaultData] = useState<Data[]>([]);

  const dispatch: Dispatch = useDispatch();

  // TODO: suggest move this logic and all props to ConsentsForm and useSelector inside
  useEffect(() => {
    if (!loading && !!consentsRaw?.length && consents) {
      const data = consentsRaw
        .filter(({ step }) => step === ConsentStepEnum.consent)
        .map((content) => ({
          ...content,
          checked:
            consents?.length > 0
              ? !!consents?.find((consent) => consent?.id === content?.id)
                  ?.checked
              : false,
        }));
      setDefaultData(data);
    }
  }, [consents, consentsRaw, loading, app.id]);

  useEffect(() => {
    if (!consentsRaw.length) dispatch(getConsents());
  }, [dispatch, consentsRaw]);

  return (
    <>
      <Steps active="data" />
      <Box type="heading">
        <h3>Wyraź zgody</h3>
        <p>
          Zgodnie z wymogami prawnymi (w tym RODO), potrzebujemy jeszcze Twoich
          zgód. Bez zgód nie będziemy mogli pobrać dane, niezbędne do
          oszacowania Twojej zdolności kredytowej oraz przedstawić Ci oferty
          finansowania.
        </p>
      </Box>
      {!consentsRaw.length ? (
        <Loader />
      ) : (
        <ConsentsForm
          data={defaultData}
          loading={loading}
          applicationId={app.id}
        />
      )}
    </>
  );
};

export default Consents;
