import moment, { Moment } from 'moment';

export const getTimeDiff = (
  start: string | Moment,
  end: string | Moment
): number => {
  const a = moment(start, 'DD/MM/YYYY HH:mm:ss');
  const b = moment(end, 'DD/MM/YYYY HH:mm:ss');
  const diff = a.diff(b);
  return diff;
};
