import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'js/redux/reducers';

import { getSmeoConsents } from 'js/redux/smeo/actions';

import SmeoSteps from 'js/components/Steps/smeo';
import Box from 'js/components/Box';
import Loader from 'js/components/Loader';
import ConsentsForm from 'js/views/Smeo/Consents';
import Regulations from 'js/views/Smeo/Regulations';

export type Data = {
  consentId: number;
  consentType: string;
  content: string;
  required: boolean;
};

const SmeoConsents: FC = () => {
  const [showRegs, setShowRegs] = useState(true);

  const { user, app, company } = useSelector(
    (state: RootState) => state.Account
  );

  const { loading, consents, processNumber } = useSelector(
    (state: RootState) => state.Smeo
  );

  const { firstName, lastName, pesel, email, phone } = user;

  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    if (app.id)
      dispatch(
        getSmeoConsents({
          applicationId: app.id,
        })
      );
  }, [dispatch, app]);

  if (showRegs) {
    return <Regulations onHide={() => setShowRegs(false)} />;
  }

  return (
    <div className="smeo-consents">
      <SmeoSteps active="application" />
      <Box type="content" className="wider wider--content">
        <h2>Wniosek o faktoring</h2>
        <div className="smeo-consents__content">
          <div>
            <h5>Dane konsumenta</h5>
            <h3>
              {firstName} {lastName}
            </h3>
            <h3>PESEL: {pesel}</h3>
          </div>
          <div>
            <h5>Dane przedsiębiorcy</h5>
            <h3>{company.name}</h3>
            <h3>NIP: {company.nip}</h3>
          </div>
        </div>
        <h6>
          Warunkiem uzyskania dostępu do faktoringu jest zaznaczenie poniższych
          zgód:
        </h6>
      </Box>

      {!consents.length || !app.id || !company.nip ? (
        <Loader />
      ) : (
        <ConsentsForm
          data={consents}
          loading={loading}
          applicationId={app.id}
          processNumber={processNumber}
          email={email}
          phoneNumber={phone}
          nip={company.nip}
        />
      )}
    </div>
  );
};

export default SmeoConsents;
