import { FC, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'js/redux/reducers';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import ReportView from 'js/views/Report';
import ExpiredModal, {
  ExpiredModalType,
} from 'js/components/Report/ExpiredModal';
import ReportHeading from 'js/components/Report/Heading';
import { ErrorBoxBig } from 'js/components/Report/InfoBoxes';

import 'js/pages/Report/styles.scss';

const Report: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const { list, loading, newestReport } = useSelector(
    (state: RootState) => state.Admin
  );

  const { id }: { id: string } = useParams();

  const history = useHistory();

  const data = useMemo(
    () => list.find((report) => report.id === Number(id)),
    [id, list]
  );

  return (
    <div className="raport">
      {data && (
        <ExpiredModal
          show={showModal}
          onClose={() => setShowModal(false)}
          date={data.date}
          expirationDate={data.expirationDate}
          type={ExpiredModalType.notAllowed}
        />
      )}

      {loading || !data || !newestReport ? (
        <Spin className="loader" indicator={<LoadingOutlined spin />} />
      ) : (
        <>
          <ReportHeading
            selected={Number(id)}
            list={list}
            firstName={data.client.firstName}
            onSelect={(selected) =>
              history.push(`/podglad-raportu/${selected}`)
            }
          />

          {newestReport && Number(id) !== newestReport.id && (
            <ErrorBoxBig
              onSelect={() =>
                history.push(`/podglad-raportu/${newestReport.id}`)
              }
            />
          )}

          {data && (
            <ReportView
              data={data}
              newestData={newestReport}
              loading={loading}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Report;
