import { User } from 'js/interfaces/account';

export enum PasswordEnum {
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE',

  NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST',
  NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS',
  NEW_PASSWORD_FAILURE = 'NEW_PASSWORD_FAILURE',

  VERIFY_PASSWORD_REQUEST = 'VERIFY_PASSWORD_REQUEST',
  VERIFY_PASSWORD_SUCCESS = 'VERIFY_PASSWORD_SUCCESS',
  VERIFY_PASSWORD_FAILURE = 'VERIFY_PASSWORD_FAILURE',

  CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE',
}

export interface PasswordState {
  error: string;
  loading: boolean;
  isNewPassword: boolean;
  isResetPassword: boolean;
  isVerified: boolean;
}

export type ResetPasswordData = {
  email: string;
};

export type NewPasswordData = {
  email: string;
  password: string;
  token: string;
};

export type VerifyPasswordData = {
  currentPassword: string;
  newPassword: string;
};

export type ChangePasswordData = {
  currentPassword: string;
  newPassword: string;
  smsCode: string;
};

// Actions

// RESET PASSWORD
interface ResetPasswordAction {
  type: PasswordEnum.RESET_PASSWORD_REQUEST;
  method: 'POST';
  data: ResetPasswordData;
}

interface ResetPasswordSuccess {
  type: PasswordEnum.RESET_PASSWORD_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      email: string;
    };
  };
}

interface ResetPasswordFailure {
  type: PasswordEnum.RESET_PASSWORD_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// NEW PASSWORD
interface NewPasswordAction {
  type: PasswordEnum.NEW_PASSWORD_REQUEST;
  method: 'POST';
  data: NewPasswordData;
}

interface NewPasswordSuccess {
  type: PasswordEnum.NEW_PASSWORD_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      password: string;
    };
  };
}

interface NewPasswordFailure {
  type: PasswordEnum.NEW_PASSWORD_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// VERIFY PASSWORD
interface VerifyPasswordAction {
  type: PasswordEnum.VERIFY_PASSWORD_REQUEST;
  method: 'POST';
  data: VerifyPasswordData;
}

interface VerifyPasswordSuccess {
  type: PasswordEnum.VERIFY_PASSWORD_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: '';
  };
}

interface VerifyPasswordFailure {
  type: PasswordEnum.VERIFY_PASSWORD_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// CHANGE PASSWORD
interface ChangePasswordAction {
  type: PasswordEnum.CHANGE_PASSWORD_REQUEST;
  method: 'POST';
  data: ChangePasswordData;
}

interface ChangePasswordSuccess {
  type: PasswordEnum.CHANGE_PASSWORD_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: User;
  };
}

interface ChangePasswordFailure {
  type: PasswordEnum.CHANGE_PASSWORD_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

export type PasswordActionTypes =
  | ResetPasswordAction
  | NewPasswordAction
  | VerifyPasswordAction
  | ChangePasswordAction;

export type PasswordApiTypes =
  | ResetPasswordSuccess
  | ResetPasswordFailure
  | NewPasswordSuccess
  | NewPasswordFailure
  | VerifyPasswordSuccess
  | VerifyPasswordFailure
  | ChangePasswordSuccess
  | ChangePasswordFailure;
