import { rest } from 'msw';
import { AuthActionTypes, AuthApiTypes, AuthEnum } from 'js/redux/auth/types';
import * as dbAccount from 'js/mocks/db/account';
import * as dbUser from 'js/mocks/db/user';
import * as dbCompany from 'js/mocks/db/company';

type RegisterRequest = Extract<
  AuthActionTypes,
  { type: AuthEnum.REGISTER_REQUEST }
>['data'];

type RegisterResponse = Extract<
  AuthApiTypes,
  { type: AuthEnum.REGISTER_SUCCESS } | { type: AuthEnum.REGISTER_FAILURE }
>['payload'];

type LoginRequest = Extract<
  AuthActionTypes,
  { type: AuthEnum.LOGIN_REQUEST }
>['data'];

type LoginResponse = Extract<
  AuthApiTypes,
  { type: AuthEnum.LOGIN_SUCCESS } | { type: AuthEnum.LOGIN_FAILURE }
>['payload'];

type LogoutResponse = Extract<
  AuthApiTypes,
  { type: AuthEnum.LOGOUT_SUCCESS } | { type: AuthEnum.LOGOUT_FAILURE }
>['payload'];

export const authHandlers = [
  rest.post<RegisterRequest, RegisterResponse>(
    process.env.API_URL + '/auth/register',
    (req, res, ctx) => {
      const { email, password, phone, consents } = req.body;

      const hasEmail = !!dbUser.read(email);

      if (hasEmail)
        return res(
          ctx.status(422),
          ctx.json({
            status: 422,
            code: 'Unprocessable Entity',
            errors: {
              email: ['email jest już zajęty.'],
            },
          })
        );

      const user = dbUser.create({ email, phone });
      const company = dbCompany.create({});

      const account = dbAccount.create({
        user,
        company,
        consents,
        isAdmin: false,
        applications: [],
        _hiddenData: {
          password,
          smsCode: '1111',
        },
      });

      if (!account)
        return res(
          ctx.status(422),
          ctx.json({
            status: 422,
            code: 'Unprocessable Entity',
            errors: {
              email: ['email jest już zajęty.'],
            },
          })
        );

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: {
            token: account.token,
          },
        })
      );
    }
  ),

  rest.post<LoginRequest, LoginResponse>(
    process.env.API_URL + '/auth/login',
    (req, res, ctx) => {
      const { email, password } = req.body;

      const account = dbAccount.readByEmail(email);

      if (!account || account._hiddenData.password !== password)
        return res(
          ctx.status(400),
          ctx.json({
            status: 400,
            code: 'Bad Request',
            errors: ['Nieprawidłowe dane uwierzytelniające'],
          })
        );

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: {
            token: account.token,
            isAdmin: account.isAdmin,
          },
        })
      );
    }
  ),

  rest.post<never, LogoutResponse>(
    process.env.API_URL + '/auth/logout',
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: [],
        })
      );
    }
  ),
];
