import { FC, useState } from 'react';

import { Form } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import SmsCodeInput from 'js/components/SmsCodeInput';
import { SubmitBtn } from 'js/components/Buttons';
import BtnWrapper from 'js/components/BtnWrapper';

type Props = {
  loading: boolean;
};

const SmsCode: FC<Props> = ({ loading }) => {
  const codeLength = 4;
  const [error, setError] = useState('');

  return (
    <>
      <small className="sms-form__code-info">
        <ExclamationCircleOutlined /> Na nowy numer wysłaiśmy 4 cyfrowy kod.
        Wprowadz go ponizej aby zapisać wrpowadzone zmiany
      </small>

      <Form.Item name="smsCode" initialValue="">
        <SmsCodeInput
          error={error}
          codeLength={codeLength}
          placeholder="-"
          onChange={(val) => {
            if (error) setError('');
            return val;
          }}
        />
      </Form.Item>

      <button className="sms-form__send-new">Wyślij nowy kod</button>

      <BtnWrapper className="center">
        <SubmitBtn loading={loading}>Prześlij kod</SubmitBtn>
      </BtnWrapper>
    </>
  );
};

export default SmsCode;
