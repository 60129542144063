import { AppId } from 'js/interfaces';
import {
  SmeoEnum,
  SmeoActionTypes,
  PostSmeoConsentsData,
  VerifiData,
  SubmitApplicationData,
  SubmitCreateContractData,
  SignInContractData,
} from 'js/redux/smeo/types';

export const getSmeoConsents = (data: AppId): SmeoActionTypes => ({
  type: SmeoEnum.GET_SMEO_CONSENTS_REQUEST,
  method: 'POST',
  data,
});

export const postSmeoConsents = (
  data: PostSmeoConsentsData
): SmeoActionTypes => ({
  type: SmeoEnum.POST_SMEO_CONSENTS_REQUEST,
  method: 'POST',
  data,
});

export const getCompanyVerification = (data: VerifiData): SmeoActionTypes => ({
  type: SmeoEnum.GET_COMPANY_VERIFICATION_REQUEST,
  method: 'POST',
  data,
});

export const submitSmeoApplication = (
  data: SubmitApplicationData
): SmeoActionTypes => ({
  type: SmeoEnum.SUBMIT_APPLICATION_REQUEST,
  method: 'POST',
  data,
});

export const getProcessApplication = (data: VerifiData): SmeoActionTypes => ({
  type: SmeoEnum.GET_PROCESS_APP_REQUEST,
  method: 'POST',
  data,
});

export const submitCreateContract = (
  data: SubmitCreateContractData
): SmeoActionTypes => ({
  type: SmeoEnum.SUBMIT_CREATE_CONTRACT_REQUEST,
  method: 'POST',
  data,
});

export const getCreateContract = (data: VerifiData): SmeoActionTypes => ({
  type: SmeoEnum.GET_CREATE_CONTRACT_REQUEST,
  method: 'POST',
  data,
});

export const getContract = (data: VerifiData): SmeoActionTypes => ({
  type: SmeoEnum.GET_CONTRACT_REQUEST,
  method: 'POST',
  data,
});

export const signInContract = (data: SignInContractData): SmeoActionTypes => ({
  type: SmeoEnum.SIGN_IN_CONTRACT_REQUEST,
  method: 'POST',
  data,
});

export const getProgress = (data: AppId): SmeoActionTypes => ({
  type: SmeoEnum.GET_PROGRESS_REQUEST,
  method: 'POST',
  data,
});

export const submitLimit = (): SmeoActionTypes => ({
  type: SmeoEnum.SUBMIT_LIMIT,
});
