import { FC } from 'react';

import { Steps } from 'antd';
import Icon from 'js/components/Icon';

import 'js/components/Steps/styles.scss';

type Props = {
  active?: 'register' | 'data' | 'payment' | 'report';
};

const AllSteps: FC<Props> = ({ active }) => {
  const { Step } = Steps;

  return (
    <Steps labelPlacement="vertical" className="custom-steps">
      <Step
        status={
          active === 'register' ||
          active === 'data' ||
          active === 'payment' ||
          active === 'report'
            ? 'finish'
            : 'wait'
        }
        title="Rejestracja"
        icon={<Icon name="poster" />}
      />
      <Step
        status={
          active === 'data' || active === 'payment' || active === 'report'
            ? 'finish'
            : 'wait'
        }
        title="Dane"
        icon={<Icon name="pencil" />}
      />
      {/* <Step
        status={active === 'payment' || active === 'report' ? 'finish' : 'wait'}
        title="Płatności"
        icon={<Icon name="dolar" />}
      /> */}
      <Step
        status={active === 'report' ? 'finish' : 'wait'}
        title="Raport"
        icon={<Icon name="document" />}
      />
    </Steps>
  );
};

export default AllSteps;
