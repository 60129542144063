import { CodeEnum, ReportTypeData, EngineTypeEnum } from 'js/interfaces/report';

type Response = {
  status: 200;
  code: 'OK';
  data: ReportTypeData;
};

export const report: Response = {
  status: 200,
  code: 'OK',
  data: {
    id: 228,
    applicationId: 536,
    rating: 1,
    grantedAmountMax: 5000,
    date: '05-10-2021',
    expirationDate: '19-10-2021',
    engineType: EngineTypeEnum.RiskBite,
    summaries: [
      {
        code: 'IB1',
        codeType: CodeEnum.IB,
        codeValue: 1,
        grade: 'Niska',
        description: 'Masz niespłacone zobowiązania.',
        indicator: '! masz zaległości',
        recommendation: [
          {
            title: 'Masz niezapłaconą ratę',
            body: 'Mając niezapłacone raty, nie uda Ci się uzyskać kredytu lub pożyczki. Jak najszybciej spłać bieżące zaległości. Po spłacie odczekaj 7 dni, aby twoje dane w BIK zostały zaktualizowane.',
          },
        ],
        output: {
          obligations: [
            {
              productType: 'securitiesPurchaseCredit',
              currentBalance: 136072,
              currentDueAmount: 1955,
              creditAmount: 189625,
              currentDPD: 9,
              maxDPD: 6,
              status: 'Current',
              closeDate: null,
              installmentAmount: 2074,
              businessType: 'bank',
            },
            {
              productType: 'revolvingCredit',
              currentBalance: 97297,
              currentDueAmount: 936,
              creditAmount: 0,
              currentDPD: 35,
              maxDPD: 35,
              status: 'Current',
              closeDate: null,
              installmentAmount: 0,
              businessType: 'bank',
            },
            {
              productType: 'noPurposeCredit',
              currentBalance: 162361,
              currentDueAmount: 4236,
              creditAmount: 179976,
              currentDPD: 33,
              maxDPD: 33,
              status: 'Current',
              closeDate: null,
              installmentAmount: 2950,
              businessType: 'bank',
            },
            {
              productType: 'creditCard',
              currentBalance: 20319,
              currentDueAmount: 1066,
              creditAmount: 0,
              currentDPD: 28,
              maxDPD: 28,
              status: 'Current',
              closeDate: null,
              installmentAmount: 0,
              businessType: 'bank',
            },
            {
              productType: 'noPurposeCredit',
              currentBalance: 119287,
              currentDueAmount: 3058,
              creditAmount: 147461,
              currentDPD: 34,
              maxDPD: 34,
              status: 'Current',
              closeDate: null,
              installmentAmount: 1873,
              businessType: 'bank',
            },
            {
              productType: 'creditCard',
              currentBalance: 7958,
              currentDueAmount: 503,
              creditAmount: 0,
              currentDPD: 35,
              maxDPD: 35,
              status: 'Current',
              closeDate: null,
              installmentAmount: 0,
              businessType: 'bank',
            },
          ],
          sum: 11754,
        },
      },
      {
        code: 'IT5',
        codeType: CodeEnum.IT,
        codeValue: 5,
        grade: 'Niska',
        description: 'W przeszłości były spore opóźnienia w płatnościach.',
        indicator: '! opóźnienia w historii',
        recommendation: [
          {
            title:
              'Masz opóźnienia ponad 30 dni. Pilnuj terminowości kredytowej przez co najmniej 6 msc.',
            body: 'Opóźnienia ponad 30 dni mają duży wpływ na szanse pozyskania kredytu. Aby zwiększyć swoje szanse, spłacaj otwarte kredyty stabilnie i bez opóźnień. Zanim złożysz wniosek, upewnij się, że od ostatniego opóźnienia płaciłeś/aś raty kredytu terminowo przez co najmniej 6 miesięcy.',
          },
        ],
        output: {
          obligations: [
            {
              productType: 'revolvingCredit',
              currentBalance: 97297,
              currentDueAmount: 936,
              creditAmount: 0,
              currentDPD: 35,
              maxDPD: 35,
              status: 'Current',
              closeDate: null,
              installmentAmount: 0,
              businessType: 'bank',
            },
            {
              productType: 'noPurposeCredit',
              currentBalance: 162361,
              currentDueAmount: 4236,
              creditAmount: 179976,
              currentDPD: 33,
              maxDPD: 33,
              status: 'Current',
              closeDate: null,
              installmentAmount: 2950,
              businessType: 'bank',
            },
            {
              productType: 'noPurposeCredit',
              currentBalance: 119287,
              currentDueAmount: 3058,
              creditAmount: 147461,
              currentDPD: 34,
              maxDPD: 34,
              status: 'Current',
              closeDate: null,
              installmentAmount: 1873,
              businessType: 'bank',
            },
            {
              productType: 'creditCard',
              currentBalance: 7958,
              currentDueAmount: 503,
              creditAmount: 0,
              currentDPD: 35,
              maxDPD: 35,
              status: 'Current',
              closeDate: null,
              installmentAmount: 0,
              businessType: 'bank',
            },
          ],
          sum: 8733,
        },
      },
      {
        code: 'ID0',
        codeType: CodeEnum.ID,
        codeValue: 0,
        grade: 'Dobra',
        description: 'Nie widniejesz na listach dłużników!',
        indicator: '0 liczba wpisów',
        recommendation: [
          {
            title: 'Wszystko dobrze! Nie widniejesz na listach dłużników!',
            body: 'Pamiętaj, by zawsze pilnować terminowości. Jeżeli trafisz na listę dłużników, może to ograniczyć Twoje możliwości pozyskania finansowania.',
          },
        ],
        output: [],
      },
      {
        code: 'IZ2',
        codeType: CodeEnum.IZ,
        codeValue: 2,
        grade: 'Niska',
        description:
          'Kwota Twoich zobowiązań jest za duża w stosunku do przychodów.',
        indicator: '100+% DTI',
        recommendation: [
          {
            title:
              'Stosunek Twojego zadłużenia do przychodów (DTI) jest za duży.',
            body: 'Spłać kredyty/pożyczki, lub zrezygnuj z kart kredytowych.',
          },
          {
            title:
              'Przy tak wysokim DTI masz małe szanse na uzyskanie kredytu.',
            body: 'Aby zwiększyć swoje szanse, możesz:\n* Spłacić kredyty/pożyczki\n* Zrezygnować z karty kredytowej i limitów na koncie (zazwyczaj około 3-5% limitu liczy się do DTI).',
          },
        ],
        output: {
          dti: 3.75,
          averageInflowCleared: 4027.67,
          income: 3999.7,
          currentDebt: 14984,
          accounts: 4,
        },
      },
      {
        code: 'IP2',
        codeType: CodeEnum.IP,
        codeValue: 2,
        grade: 'Niska',
        description:
          'W ciągu ostatnich miesięcy na Twoim koncie widać spadek przychodów, co obniża szanse na pozyskanie kredytu.',
        indicator: '24% spadek przychodów',
        recommendation: [
          {
            title: 'Twoje przychody spadają.',
            body: 'Spadające przychody mogą mieć negatywny wpływ na decyzję banku odnośnie udzielenia Ci finansowania',
          },
        ],
        output: [
          {
            from: '2021-09-05',
            to: '2021-10-05',
            inflowCleared: 3657.51,
            count: 2,
            indicator: 'Down',
            deviation: -24.99,
          },
          {
            from: '2021-08-06',
            to: '2021-09-05',
            inflowCleared: 4876.28,
            count: 1,
            indicator: 'Down',
            deviation: 3.74,
          },
          {
            from: '2021-07-07',
            to: '2021-08-06',
            inflowCleared: 4700.48,
            count: 1,
            indicator: 'Down',
            deviation: 18.76,
          },
        ],
      },
      {
        code: 'IA1',
        codeType: CodeEnum.IA,
        codeValue: 1,
        grade: 'Średnia',
        description:
          'Twoja wcześniejsza aktywność kredytowa może mieć negatywny wpływ na szanse pozyskania kredytu.',
        indicator: '! wpisy w sekcji',
        recommendation: [
          {
            title: 'Masz pożyczkę w instytucji pozabankowej.',
            body: 'Dla niektórych banków posiadanie pożyczki z sektora pozabankowego stanowi podstawę do odrzucenia wniosku kredytowego. Spłać tę pożyczkę, zanim złożysz wniosek o kredyt do banku.',
          },
        ],
        output: {
          obligations: {
            obligations: [
              {
                productType: 'noPurposeCredit',
                currentBalance: 2000,
                currentDueAmount: 0,
                creditAmount: 2000,
                currentDPD: 0,
                maxDPD: 0,
                status: 'Current',
                closeDate: null,
                installmentAmount: 2000,
                businessType: 'loanCompany',
              },
            ],
            sum: 0,
          },
          inquiries: {
            recentP: 1,
            recentKI: 7,
            pastP: 6,
            pastKI: 13,
          },
          paydayInfo: null,
        },
      },
      {
        code: 'IM1',
        codeType: CodeEnum.IM,
        codeValue: 1,
        grade: 'Dobra',
        description: null,
        indicator: 'do ',
        recommendation: [
          {
            title: 'Brak oferty',
            body: 'Na razie nie mamy dla Ciebie żadnych ofert.',
          },
        ],
        output: {
          masterDecision: 'Reject',
        },
      },
    ],
    user: {
      firstName: 'Ida',
      lastName: 'Adamczak',
      phone: '555444333',
      email: 'test6@ww.com',
      idCard: 'BED715676',
      pesel: '26011337076',
      street: 'Murowana 7',
      houseNo: '5',
      apartmentNo: '13',
      city: 'Poznań',
      postCode: '60-452',
      maritalStatus: null,
      isActive: true,
      isWealthonUser: false,
    },
    company: {
      nip: '1134568647',
      name: 'Testowa 6',
      street: null,
      houseNo: null,
      apartmentNo: null,
      city: null,
      postCode: null,
      monthlyIncome: 1,
      contractors: '12',
      invoicePaymentDate: 4,
      bookkeepingRecordType: 2,
      trade: 1,
      fundingPurpose: 2,
      arrearsInstallmentZus: true,
      arrearsInstallmentUs: true,
      arrearsZus: 10000,
      arrearsUs: 15000,
    },
  },
};
