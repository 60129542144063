import * as React from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import {
  AdaptedOffer,
  AdaptedReport,
  ChanceColorEnum,
  CreditChanceEnum,
  OfferCategoryEnum,
} from 'js/interfaces/report';
import { checkOffer } from 'js/redux/report/actions';

import { Button } from 'antd';
import Box from 'js/components/Box';
import ExpiredModal, {
  ExpiredModalType,
} from 'js/components/Report/ExpiredModal';

import 'js/components/Report/OffersList/styles.scss';

type Props = {
  data: AdaptedReport;
  loading: boolean;
  canRefresh: boolean;
  onRefresh: () => void;
};

const ReportOffersList: React.FC<Props> = ({
  data,
  loading,
  canRefresh,
  onRefresh,
}) => {
  const [showMore, setShowMore] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const dispatch: Dispatch = useDispatch();

  const handleCheckOffer = (offer: AdaptedOffer) =>
    dispatch(
      checkOffer({
        applicationId: offer.appId,
        company: offer.company.toLowerCase(),
        category: OfferCategoryEnum.bankOffer,
      })
    );

  return (
    <section className="report-offers">
      {data.offers.map((offer, key) => (
        <React.Fragment key={key}>
          <ExpiredModal
            show={showModal}
            onClose={() => setShowModal(false)}
            date={data.date}
            expirationDate={data.expirationDate}
            onSubmit={() => handleCheckOffer(offer)}
            onRefresh={onRefresh}
            type={ExpiredModalType.refresh}
          />

          <Box
            className={`report-box ${
              offer.applied ? 'report-box--applied' : ''
            }`}
            type="content"
          >
            <img src={offer.logo} alt="logo" />

            <ul className={`report-box__info ${showMore ? 'active' : ''}`}>
              <li>
                <h4>Kwota</h4>
                <span>{offer.grossAmount} PLN</span>
                <div className="desktop-only">
                  <h4>Liczba rat</h4>
                  <span>{offer.tenor}</span>
                </div>
              </li>
              <li>
                <h4>Miesięczna rata</h4>
                <span className="purple">{offer.installmentAmount} PLN</span>
                <div className="desktop-only">
                  <h4>Kwota do zapłaty</h4>
                  <span>{offer.amountToPay} PLN</span>
                </div>
              </li>
              <li>
                <h4>Szanse</h4>
                <span className={ChanceColorEnum[offer.chance]}>
                  {CreditChanceEnum[offer.chance]}
                </span>
                <div className="desktop-only">
                  <h4>Typ produktu</h4>
                  <span>{offer.productType}</span>
                </div>
              </li>
            </ul>
            <div className="report-box__details-wrapper">
              <button
                className={`report-box__details-btn ${
                  showMore ? 'active' : ''
                }`}
                onClick={() => setShowMore(!showMore)}
                type="button"
              >
                Szczegóły
              </button>

              <Button
                onClick={() =>
                  canRefresh ? setShowModal(true) : handleCheckOffer(offer)
                }
                className="report-box__offer-btn"
                type="primary"
                loading={loading}
                disabled={loading}
              >
                Złóż wniosek
              </Button>
            </div>
            <>
              {showMore && (
                <ul className="report-box__details">
                  <li>
                    <h4>Liczba rat</h4>
                    <span>{offer.tenor}</span>
                  </li>
                  <li>
                    <h4>Kwota do zapłaty</h4>
                    <span>{offer.amountToPay} PLN</span>
                  </li>
                  <li>
                    <h4>Typ produktu</h4>
                    <span>{offer.productType}</span>
                  </li>
                </ul>
              )}
            </>
          </Box>
        </React.Fragment>
      ))}
    </section>
  );
};

export default ReportOffersList;
