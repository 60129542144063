import { FC, useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { postConsents } from 'js/redux/account/actions';
import { Data } from 'js/pages/Data/consents';
import { Store } from 'antd/lib/form/interface';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { routes } from 'js/routes';

import { Form, Checkbox, Divider, FormInstance } from 'antd';
import Box from 'js/components/Box';
import Collapse from 'js/components/Collapse';
import BtnWrapper from 'js/components/BtnWrapper';
import { PrevBtn, SubmitBtn } from 'js/components/Buttons';

import { objectsEquals } from 'js/helpers/objectsEquals';

import 'js/views/Data/Consents/styles.scss';

type Props = {
  loading: boolean;
  data: Data[];
  applicationId: number | null;
};

type FormData = {
  [id: number]: boolean;
};

const Consents: FC<Props> = ({ loading, data, applicationId }) => {
  const [form]: [FormInstance<FormData>] = Form.useForm();
  const dispatch: Dispatch = useDispatch();

  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const hasExactLength = checkedList.length === data.length;
    setIndeterminate(checkedList.length > 0 && !hasExactLength);
    setCheckAll(hasExactLength);
  }, [checkedList, data]);

  const handleCheck = (e: CheckboxChangeEvent) => {
    const id = Number(e.target.id);
    setCheckedList((state) =>
      state.includes(id) ? state.filter((el) => el !== id) : [...state, id]
    );
  };

  const handleCheckAll = (e: CheckboxChangeEvent) => {
    const list = e.target.checked ? data.map((el) => el.id) : [];
    const output = data.reduce((a: Store, item) => {
      a[item.id] = e.target.checked;
      return a;
    }, {});

    setCheckAll(e.target.checked);
    setCheckedList(list);
    form.setFieldsValue(output);
  };

  const handleSubmit = (values: FormData) => {
    const newData = Object.keys(values).map((key: string) => ({
      id: Number(key),
      checked: values[Number(key)],
    }));

    const oldData = data.map((e) => ({
      id: e.id,
      checked: e.checked,
    }));

    objectsEquals(oldData, newData)
      ? history.push(routes.transactions)
      : dispatch(
          postConsents({ consents: newData, applicationId: applicationId })
        );
  };

  useEffect(() => {
    if (data) {
      setCheckedList(
        data
          .filter((agreement) => agreement.checked)
          .map((agreement) => agreement.id)
      );
      const defaultData = data.reduce((a: Store, item) => {
        a[item.id] = item.checked;
        return a;
      }, {});
      form.setFieldsValue(defaultData);
    }
  }, [data, form]);

  return (
    <Box type="content" className="consent-form">
      <Checkbox
        checked={checkAll}
        indeterminate={indeterminate}
        onChange={handleCheckAll}
      >
        Zaznacz wszystkie zgody
      </Checkbox>
      <Divider />
      <Form form={form} onFinish={handleSubmit}>
        {data.map((el) => (
          <Collapse
            key={el.id}
            id={el.id}
            label={el.title}
            text={el.text}
            required={el.required}
            initCheck={false}
            onChange={handleCheck}
          />
        ))}
        <BtnWrapper className="consent-form__button-wrapper">
          <PrevBtn to={routes.address}>Wstecz</PrevBtn>
          <SubmitBtn loading={loading}>Przejdź dalej</SubmitBtn>
        </BtnWrapper>
      </Form>
    </Box>
  );
};

export default Consents;
