import {
  App,
  AppState,
  ConsentRaw,
  ConsentChecked,
  Company,
  InvoicePaymentDateEnum,
  MaritalStatusEnum,
  User,
  UserState,
  BookKeepingRecordEnum,
  MonthlyIncomeEnum,
  TradeEnum,
  FundingPurposeEnum,
  WfUser,
  Step,
} from 'js/interfaces/account';
import { DeepNonNullable } from 'js/interfaces/helpers';

export enum AccountEnum {
  GET_USER_REQUEST = 'GET_USER_REQUEST',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_FAILURE = 'GET_USER_FAILURE',

  EDIT_PHONE_REQUEST = 'EDIT_PHONE_REQUEST',
  EDIT_PHONE_SUCCESS = 'EDIT_PHONE_SUCCESS',
  EDIT_PHONE_FAILURE = 'EDIT_PHONE_FAILURE',

  VERIFY_PHONE_REQUEST = 'VERIFY_PHONE_REQUEST',
  VERIFY_PHONE_SUCCESS = 'VERIFY_PHONE_SUCCESS',
  VERIFY_PHONE_FAILURE = 'VERIFY_PHONE_FAILURE',

  NEW_VERIFY_CODE_REQUEST = 'NEW_VERIFY_CODE_REQUEST',
  NEW_VERIFY_CODE_SUCCESS = 'NEW_VERIFY_CODE_SUCCESS',
  NEW_VERIFY_CODE_FAILURE = 'NEW_VERIFY_CODE_FAILURE',

  CREATE_APP_REQUEST = 'CREATE_APP_REQUEST',
  CREATE_APP_SUCCESS = 'CREATE_APP_SUCCESS',
  CREATE_APP_FAILURE = 'CREATE_APP_FAILURE',

  GET_APPS_REQUEST = 'GET_APPS_REQUEST',
  GET_APPS_SUCCESS = 'GET_APPS_SUCCESS',
  GET_APPS_FAILURE = 'GET_APPS_FAILURE',

  NIP_REQUEST = 'NIP_REQUEST',
  NIP_SUCCESS = 'NIP_SUCCESS',
  NIP_FAILURE = 'NIP_FAILURE',

  CONFIRM_NIP_REQUEST = 'CONFIRM_NIP_REQUEST',
  CONFIRM_NIP_SUCCESS = 'CONFIRM_NIP_SUCCESS',
  CONFIRM_NIP_FAILURE = 'CONFIRM_NIP_FAILURE',

  COMPANY_REQUEST = 'COMPANY_REQUEST',
  COMPANY_SUCCESS = 'COMPANY_SUCCESS',
  COMPANY_FAILURE = 'COMPANY_FAILURE',

  PERSONAL_REQUEST = 'PERSONAL_REQUEST',
  PERSONAL_SUCCESS = 'PERSONAL_SUCCESS',
  PERSONAL_FAILURE = 'PERSONAL_FAILURE',

  SELF_REQUEST = 'SELF_REQUEST',
  SELF_SUCCESS = 'SELF_SUCCESS',
  SELF_FAILURE = 'SELF_FAILURE',

  ADDRESS_REQUEST = 'ADDRESS_REQUEST',
  ADDRESS_SUCCESS = 'ADDRESS_SUCCESS',
  ADDRESS_FAILURE = 'ADDRESS_FAILURE',

  GET_CONSENTS_REQUEST = 'GET_CONSENTS_REQUEST',
  GET_CONSENTS_SUCCESS = 'GET_CONSENTS_SUCCESS',
  GET_CONSENTS_FAILURE = 'GET_CONSENTS_FAILURE',

  POST_CONSENTS_REQUEST = 'POST_CONSENTS_REQUEST',
  POST_CONSENTS_SUCCESS = 'POST_CONSENTS_SUCCESS',
  POST_CONSENTS_FAILURE = 'POST_CONSENTS_FAILURE',

  VERIFY_DATA_REQUEST = 'VERIFY_DATA_REQUEST',
  VERIFY_DATA_SUCCESS = 'VERIFY_DATA_SUCCESS',
  VERIFY_DATA_FAILURE = 'VERIFY_DATA_FAILURE',

  CHANGE_DATA_REQUEST = 'CHANGE_DATA_REQUEST',
  CHANGE_DATA_SUCCESS = 'CHANGE_DATA_SUCCESS',
  CHANGE_DATA_FAILURE = 'CHANGE_DATA_FAILURE',

  CANCEL_APP_UPDATE_REQUEST = 'CANCEL_APP_UPDATE_REQUEST',
  CANCEL_APP_UPDATE_SUCCESS = 'CANCEL_APP_UPDATE_SUCCESS',
  CANCEL_APP_UPDATE_FAILURE = 'CANCEL_APP_UPDATE_FAILURE',

  GET_WF_USER_REQUEST = 'GET_WF_USER_REQUEST',
  GET_WF_USER_SUCCESS = 'GET_WF_USER_SUCCESS',
  GET_WF_USER_FAILURE = 'GET_WF_USER_FAILURE',

  FINISH_STEP = 'FINISH_STEP',
}

export interface AccountState {
  loading: boolean;
  user: UserState;
  wfUser: WfUser;
  company: Company;
  app: AppState;
  applications: App[];
  consentsRaw: ConsentRaw[];
  consentsLoading: boolean;
  actualStep: Step;
  isVerifiedCompany: boolean;
  isCodeSend: boolean;
  isPhoneEdited: boolean;
  isVerified: boolean;
  isRefresh: boolean;
}

interface UserData {
  applications: App[];
  user: User;
  company: Company;
  consents: ConsentChecked[];
}

interface WfUserDataResponse {
  email: string;
  phone: string;
  exists: boolean;
}

// ========== DATA ==========
export type NipData = {
  nip: string;
};

export type PersonalData = {
  idCard: string;
  pesel: string;
};

export type SelfData = {
  maritalStatus: MaritalStatusEnum;
};

export type CompanyData = {
  monthlyIncome: MonthlyIncomeEnum | null;
  contractors: string;
  invoicePaymentDate: InvoicePaymentDateEnum | null;
  bookkeepingRecordType: BookKeepingRecordEnum | null;
  trade: TradeEnum | null;
  fundingPurpose: FundingPurposeEnum | null;
  arrearsInstallmentZus: boolean;
  arrearsInstallmentUs: boolean;
  arrearsZus: number;
  arrearsUs: number;
};

export type PostConsentsData = {
  applicationId: number | null;
  consents: ConsentChecked[];
};

export type AddressData = {
  street: string | null;
  houseNo: string;
  apartmentNo: string | null;
  city: string;
  postCode: string;
};

export type EditPhoneData = {
  phone: string;
};

export type VerifyPhoneData = {
  smsCode: string;
};

export type NewVerifyCodeData = {
  phone: string;
};

export type VerifyData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  pesel: string;
  idCard: string;
  street: string;
  houseNo: string;
  apartmentNo: string;
  city: string;
  postCode: string;
};

export type ChangeData = {
  smsCode: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  pesel: string;
  idCard: string;
  street: string;
  houseNo: string;
  apartmentNo: string;
  city: string;
  postCode: string;
  maritalStatus: MaritalStatusEnum | null;
};

export type WfUserData = {
  token: string;
};

export type RefreshReportData = {
  reportId: number;
};

// ========== ACTIONS ==========

// USER DATA
interface GetUserAction {
  type: AccountEnum.GET_USER_REQUEST;
  method: 'GET';
}

interface GetUserSuccess {
  type: AccountEnum.GET_USER_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: UserData;
  };
}
interface GetUserFailure {
  type: AccountEnum.GET_USER_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// EDIT PHONE
interface EditPhoneAction {
  type: AccountEnum.EDIT_PHONE_REQUEST;
  method: 'POST';
  data: EditPhoneData;
}

interface EditPhoneSuccess {
  type: AccountEnum.EDIT_PHONE_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      phone: string;
    };
  };
}

interface EditPhoneFailure {
  type: AccountEnum.EDIT_PHONE_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// VERIFY PHONE
interface VerifyPhoneAction {
  type: AccountEnum.VERIFY_PHONE_REQUEST;
  method: 'POST';
  data: VerifyPhoneData;
}

interface VerifyPhoneSuccess {
  type: AccountEnum.VERIFY_PHONE_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: UserData;
  };
}

interface VerifyPhoneFailure {
  type: AccountEnum.VERIFY_PHONE_FAILURE;
  payload:
    | {
        status: 422;
        code: 'Failed';
        errors: string[];
      }
    | {
        status: 400;
        code: 'Bad Request';
        errors: string[];
      };
}

// NEW VERIFY CODE
interface NewVerifyCodeAction {
  type: AccountEnum.NEW_VERIFY_CODE_REQUEST;
  method: 'POST';
  data: NewVerifyCodeData;
}

interface NewVerifyCodeSuccess {
  type: AccountEnum.NEW_VERIFY_CODE_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      phone: string;
    };
  };
}

interface NewVerifyCodeFailure {
  type: AccountEnum.NEW_VERIFY_CODE_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// CREATE APP
interface AppAction {
  type: AccountEnum.CREATE_APP_REQUEST;
  method: 'POST';
}

interface AppSuccess {
  type: AccountEnum.CREATE_APP_SUCCESS;
  payload: {
    status: 201;
    code: 'Created';
    data: App;
  };
}

interface AppFailure {
  type: AccountEnum.CREATE_APP_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// GET APP
interface GetAppsAction {
  type: AccountEnum.GET_APPS_REQUEST;
  method: 'GET';
}

interface GetAppsSuccess {
  type: AccountEnum.GET_APPS_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: App[];
  };
}

interface GetAppsFailure {
  type: AccountEnum.GET_APPS_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// NIP
interface NipAction {
  type: AccountEnum.NIP_REQUEST;
  method: 'GET';
  data: NipData;
}

interface NipSuccess {
  type: AccountEnum.NIP_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: Pick<
      Company,
      | 'apartmentNo'
      | 'city'
      | 'houseNo'
      | 'name'
      | 'nip'
      | 'postCode'
      | 'street'
    >;
  };
}

interface NipFailure {
  type: AccountEnum.NIP_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// CONFIRM NIP
interface ConfirmNipAction {
  type: AccountEnum.CONFIRM_NIP_REQUEST;
  method: 'POST';
  data: NipData;
}

interface ConfirmNipSuccess {
  type: AccountEnum.CONFIRM_NIP_SUCCESS;
  payload: {
    status: 201;
    code: 'Created';
    data: Company;
  };
}

interface ConfirmNipFailure {
  type: AccountEnum.CONFIRM_NIP_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// COMPANY
interface CompanyAction {
  type: AccountEnum.COMPANY_REQUEST;
  method: 'POST';
  data: CompanyData;
}

interface CompanySuccess {
  type: AccountEnum.COMPANY_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: Company;
  };
}

interface CompanyFailure {
  type: AccountEnum.COMPANY_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// PERSONAL
interface PersonalAction {
  type: AccountEnum.PERSONAL_REQUEST;
  method: 'POST';
  data: PersonalData;
}

interface PersonalSuccess {
  type: AccountEnum.PERSONAL_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: Omit<User, 'idCard' | 'pesel'> &
      DeepNonNullable<Pick<User, 'idCard' | 'pesel'>>;
  };
}

interface PersonalFailure {
  type: AccountEnum.PERSONAL_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// SELF
interface SelfAction {
  type: AccountEnum.SELF_REQUEST;
  method: 'POST';
  data: SelfData;
}

interface SelfSuccess {
  type: AccountEnum.SELF_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: Omit<User, 'maritalStatus'> &
      DeepNonNullable<Pick<User, 'maritalStatus'>>;
  };
}

interface SelfFailure {
  type: AccountEnum.SELF_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// ADDRESS
interface AddressAction {
  type: AccountEnum.ADDRESS_REQUEST;
  method: 'POST';
  data: AddressData;
}

interface AddressSuccess {
  type: AccountEnum.ADDRESS_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: Omit<User, 'houseNo' | 'city' | 'postCode'> &
      DeepNonNullable<Pick<User, 'houseNo' | 'city' | 'postCode'>>;
  };
}

interface AddressFailure {
  type: AccountEnum.ADDRESS_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// POST CONSENTS
interface PostConsentsAction {
  type: AccountEnum.POST_CONSENTS_REQUEST;
  method: 'POST';
  data: PostConsentsData;
}

interface PostConsentsSuccess {
  type: AccountEnum.POST_CONSENTS_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: ConsentChecked[];
  };
}

interface PostConsentsFailure {
  type: AccountEnum.POST_CONSENTS_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// GET CONSENTS
interface GetConsentsAction {
  type: AccountEnum.GET_CONSENTS_REQUEST;
  method: 'GET';
}

interface GetConsentsSuccess {
  type: AccountEnum.GET_CONSENTS_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: ConsentRaw[];
  };
}

interface GetConsentsFailure {
  type: AccountEnum.GET_CONSENTS_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// VERIFY DATA
interface VerifyDataAction {
  type: AccountEnum.VERIFY_DATA_REQUEST;
  method: 'POST';
  data: VerifyData;
}

interface VerifyDataSuccess {
  type: AccountEnum.VERIFY_DATA_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: '';
  };
}

interface VerifyDataFailure {
  type: AccountEnum.VERIFY_DATA_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// CHANGE DATA
interface ChangeDataAction {
  type: AccountEnum.CHANGE_DATA_REQUEST;
  method: 'POST';
  data: ChangeData;
}

interface ChangeDataSuccess {
  type: AccountEnum.CHANGE_DATA_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: User;
  };
}

interface ChangeDataFailure {
  type: AccountEnum.CHANGE_DATA_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// CANCEL APP UPDATE
interface CancelAppUpdateAction {
  type: AccountEnum.CANCEL_APP_UPDATE_REQUEST;
  method: 'POST';
  id: string;
}

interface CancelAppUpdateSuccess {
  type: AccountEnum.CANCEL_APP_UPDATE_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {};
  };
}
interface CancelAppUpdateFailure {
  type: AccountEnum.CANCEL_APP_UPDATE_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

// WEALTHON USER DATA
interface GetWfUserAction {
  type: AccountEnum.GET_WF_USER_REQUEST;
  method: 'GET';
  data: WfUserData;
}

interface GetWfUserSuccess {
  type: AccountEnum.GET_WF_USER_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: WfUserDataResponse;
  };
}
interface GetWfUserFailure {
  type: AccountEnum.GET_WF_USER_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

interface FinishStepAction {
  type: AccountEnum.FINISH_STEP;
}

export type AccountActionTypes =
  | AppAction
  | GetAppsAction
  | NipAction
  | CompanyAction
  | PersonalAction
  | SelfAction
  | PostConsentsAction
  | GetConsentsAction
  | AddressAction
  | EditPhoneAction
  | VerifyPhoneAction
  | NewVerifyCodeAction
  | GetUserAction
  | ConfirmNipAction
  | FinishStepAction
  | VerifyDataAction
  | ChangeDataAction
  | CancelAppUpdateAction
  | GetWfUserAction;

export type AccountApiTypes =
  | AppSuccess
  | AppFailure
  | GetAppsSuccess
  | GetAppsFailure
  | NipSuccess
  | NipFailure
  | CompanySuccess
  | CompanyFailure
  | PersonalSuccess
  | PersonalFailure
  | SelfSuccess
  | SelfFailure
  | PostConsentsSuccess
  | PostConsentsFailure
  | GetConsentsSuccess
  | GetConsentsFailure
  | AddressSuccess
  | AddressFailure
  | VerifyPhoneSuccess
  | VerifyPhoneFailure
  | EditPhoneSuccess
  | EditPhoneFailure
  | NewVerifyCodeSuccess
  | NewVerifyCodeFailure
  | GetUserSuccess
  | GetUserFailure
  | ConfirmNipSuccess
  | ConfirmNipFailure
  | VerifyDataSuccess
  | VerifyDataFailure
  | ChangeDataSuccess
  | ChangeDataFailure
  | CancelAppUpdateSuccess
  | CancelAppUpdateFailure
  | GetWfUserSuccess
  | GetWfUserFailure;
