import * as dbAccount from 'js/mocks/db/account';
import * as dbUser from 'js/mocks/db/user';
import * as dbCompany from 'js/mocks/db/company';
import * as dbApp from 'js/mocks/db/application';
import * as dbReports from 'js/mocks/db/report';
import consents from 'js/mocks/db/consents.json';

import { report as report1 } from 'js/mocks/db/reports/1';
import { report as report2 } from 'js/mocks/db/reports/2';
import { report as report3 } from 'js/mocks/db/reports/3';
import { report } from 'js/mocks/db/reports/5';

import { Company, MaritalStatusEnum, User } from 'js/interfaces/account';
import { EngineTypeEnum } from 'js/interfaces/report';

export const createHasReport = () => {
  // report step
  const hasReport = dbAccount.create({
    applications: [
      dbApp.create({
        consents: consents.map(({ id }) => ({ id, checked: true })),
        createdAt: '01-09-2021',
        token: 'bgx5t994xl9ukps9hk272',
        userId: dbAccount.getLastId() + 1,
        hasPendingReport: false,
        hasKontomatik: true,
        hasReport: true,
      }),
    ],
    consents: [],
    user: dbUser.create({
      apartmentNo: '3',
      city: 'Gdańsk',
      email: 'hasReport@t.pl',
      firstName: 'Piotr',
      houseNo: '12',
      idCard: 'SAP177848',
      isActive: true,
      lastName: 'Nowak',
      maritalStatus: MaritalStatusEnum.married,
      pesel: '67082492402',
      phone: '504333222',
      postCode: '80-933',
      street: 'Leśna',
    }),
    company: dbCompany.create({
      name: 'Piotr Nowak "PNW"',
      nip: '5661023609',
      city: 'Gdansk',
      postCode: '80-301',
      street: 'Kasztanowa',
      houseNo: '3',
      apartmentNo: '5',
      monthlyIncome: 2,
      contractors: '32',
      invoicePaymentDate: 1,
      bookkeepingRecordType: 1,
      trade: 1,
      fundingPurpose: 1,
      arrearsInstallmentZus: true,
      arrearsInstallmentUs: false,
      arrearsZus: 12000,
      arrearsUs: 0,
    }),
    isAdmin: false,
    _hiddenData: {
      password: 'Password1',
      smsCode: '',
    },
  });

  dbReports.addExisting(hasReport?.token as string, report1.data);
  dbReports.addExisting(hasReport?.token as string, report2.data);
  dbReports.addExisting(hasReport?.token as string, report3.data);

  if (report.data.engineType === EngineTypeEnum.RiskBite) {
    dbReports.createRiskbite({
      applicationId: hasReport?.applications[0].id as number,
      _token: hasReport?.token as string,
      company: hasReport?.company as Company,
      user: hasReport?.user as User,
      rating: report?.data?.rating,
      grantedAmountMax: report.data.grantedAmountMax,
      date: report.data.date,
      expirationDate: report.data.date,
      engineType: report.data.engineType,
    });
  } else {
    dbReports.createFrs({
      applicationId: hasReport?.applications[0].id as number,
      _token: hasReport?.token as string,
      company: hasReport?.company as Company,
      user: hasReport?.user as User,
      freeAmountBikKontoAgr: report.data.freeAmountBikKontoAgr,
      ratingSimplePlBpmnValue: report.data.ratingSimplePlBpmnValue,
      date: report.data.date,
      expirationDate: report.data.date,
      engineType: report.data.engineType,
    });
  }
};
