import { FC, useEffect } from 'react';

import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { loginUser } from 'js/redux/auth/actions';
import { WfUser } from 'js/interfaces/account';

import { Form, Input, FormInstance } from 'antd';

import { validatePassword } from 'js/helpers/validators';

import { Link } from 'react-router-dom';
import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { SubmitBtn } from 'js/components/Buttons';
import { routes } from 'js/routes';

import 'js/views/Register/Login/styles.scss';

export type LoginData = {
  email: string;
  password: string;
};

type Props = {
  loading: boolean;
  wfUser?: WfUser;
};

const Login: FC<Props> = ({ loading, wfUser }) => {
  const [form]: [FormInstance<LoginData>] = Form.useForm();
  const dispatch: Dispatch = useDispatch();

  const handleSubmit = (values: LoginData) => {
    dispatch(loginUser({ ...values, token: wfUser?.token || undefined }));
  };

  useEffect(() => {
    if (form && wfUser?.email) {
      form.setFieldsValue({
        email: wfUser.email,
      });
    }
  }, [form, wfUser]);

  return (
    <>
      <Box type="content" className="login-form">
        <Form
          form={form}
          onFinish={handleSubmit}
          scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
        >
          <Form.Item
            name="email"
            label="Adres e-mail"
            rules={[
              { required: true, message: 'Proszę podaj email' },
              { type: 'email', message: 'Podaj poprawny adres email' },
            ]}
          >
            <Input
              size="large"
              placeholder="Wpisz adres e-mail..."
              type="email"
              disabled={!!wfUser?.email}
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="Hasło"
            rules={[
              {
                required: true,
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password size="large" placeholder="Wpisz hasło..." />
          </Form.Item>

          <div className="login-form__text login-form__text--left">
            Zapomniałeś hasła?{' '}
            <Link to={routes.resetPassword} className="login-form__link">
              Zresetuj hasło
            </Link>
          </div>

          <BtnWrapper className="login-form__buttons">
            <SubmitBtn loading={loading}>Zaloguj</SubmitBtn>
          </BtnWrapper>

          <div className="login-form__text">
            Nie masz konta?{' '}
            <Link to={routes.register} className="login-form__link">
              Zarejestruj się
            </Link>
          </div>
        </Form>
      </Box>
    </>
  );
};

export default Login;
