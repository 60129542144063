import { FC } from 'react';

import Box from 'js/components/Box';
import Icon from 'js/components/Icon';

import 'js/components/Report/NoOfferBox/styles.scss';

const NoOfferBox: FC = () => {
  return (
    <Box className="expired-box" type="content">
      <div>
        <Icon name="emptyBox" />
        <h4>Brak ofert</h4>
        <p>Obecnie nie mamy dla Ciebie żadnej oferty.</p>
      </div>
    </Box>
  );
};

export default NoOfferBox;
