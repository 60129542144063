import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'js/redux/reducers';

import {
  BookKeepingEnum,
  EducationEnum,
  HousingStatusEnum,
  MaritalStatusEnum,
} from 'js/interfaces/account';

import SmeoSteps from 'js/components/Steps/smeo';
import Box from 'js/components/Box';
import SmeoCompanyForm from 'js/views/Smeo/Company';
import { SmeoConsent } from 'js/redux/smeo/types';
import Provider from 'js/components/Provider';

export type Data = {
  applicationId: number | null;
  processNumber: string;
  application: {
    factorerNip: string | null;
    income: number | null;
    contractorCount: number | null;
    isDeferredPaymentDate: boolean;
    bookkeepingTypeId: BookKeepingEnum | null;
    avgInvoiceMaturity: string | null;
    proposedLimit?: number;
    applicant: {
      pesel: string;
      firstName: string;
      surname: string;
      idNumber: string;
      idIssueDate: string;
      idExpiryDate: string;
      mainAddress: {
        locality: string;
        voivodship?: string;
        postalCode: string;
        street: string;
        houseNumber: string;
        apartmentNumber?: string;
      };
      correspondenceAddress: {
        locality: string;
        voivodship?: string;
        postalCode: string;
        street: string;
        houseNumber: string;
        apartmentNumber?: string;
      };
      educationTypeId: EducationEnum | null;
      maritalStatusTypeId: MaritalStatusEnum | null;
      houseStatusTypeId: HousingStatusEnum | null;
      otherIncome: number | null;
      phone: string;
      email: string;
      consents: { id: number; checked: boolean }[];
      isRepresentative: boolean;
      isShareholder: boolean;
      isPep: boolean;
    };
  };
};

const SmeoCompany: FC = () => {
  const {
    user,
    app,
    company,
    loading: accLoading,
  } = useSelector((state: RootState) => state.Account);
  const { loading, consents, processNumber } = useSelector(
    (state: RootState) => state.Smeo
  );

  const defaultData: Data = useMemo(
    () => ({
      applicationId: app.id,
      processNumber,
      application: {
        factorerNip: company.nip || null,
        income: null,
        contractorCount: null,
        isDeferredPaymentDate: false,
        bookkeepingTypeId: null,
        avgInvoiceMaturity: null,
        proposedLimit: undefined,
        applicant: {
          pesel: user.pesel,
          firstName: user.firstName,
          surname: user.lastName,
          idNumber: user.idCard,
          idIssueDate: '', // <<<<<<<<<
          idExpiryDate: '',
          mainAddress: {
            locality: user.city,
            voivodship: undefined,
            postalCode: user.postCode,
            street: user.street || user.city,
            houseNumber: user.houseNo,
            apartmentNumber: user?.apartmentNo || undefined,
          },
          correspondenceAddress: {
            locality: user.city,
            voivodship: undefined,
            postalCode: user.postCode,
            street: user.street || user.city,
            houseNumber: user.houseNo,
            apartmentNumber: user?.apartmentNo || undefined,
          },
          educationTypeId: null,
          maritalStatusTypeId: user.maritalStatus,
          houseStatusTypeId: null, // <<<<<<<<<
          otherIncome: null,
          phone: user.phone,
          email: user.email,
          consents: !!consents?.length
            ? consents.map((consent: SmeoConsent) => ({
                id: consent.consentId,
                checked: true,
              }))
            : [],
          isRepresentative: true,
          isShareholder: true,
          isPep: true,
        },
      },
    }),
    [user, company, consents, app.id, processNumber]
  );

  return (
    <div className="smeo-company">
      <SmeoSteps active="application" />
      <Box type="content" className="wider wider--content">
        <h2>Uzupełnij dane Twojej firmy</h2>
      </Box>
      {app.id && processNumber && (
        <SmeoCompanyForm data={defaultData} loading={accLoading || loading} />
      )}
      <Provider company="smeo" />
    </div>
  );
};

export default SmeoCompany;
