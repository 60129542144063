import { FC } from 'react';

import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';

import { phoneNumber } from 'js/helpers/formatters';
import { validatePhone } from 'js/helpers/validators';

import { Form, Input, FormInstance } from 'antd';
import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { SubmitBtn } from 'js/components/Buttons';
import { editPhone } from 'js/redux/account/actions';

type EditPhoneData = {
  phone: string;
};

type Props = {
  loading: boolean;
};

const EditNumber: FC<Props> = ({ loading }) => {
  const [form]: [FormInstance<EditPhoneData>] = Form.useForm();

  const dispatch: Dispatch = useDispatch();

  const handleSubmit = (values: EditPhoneData) => {
    const phone = values.phone.split(' ').join('');
    dispatch(editPhone({ phone }));
  };

  return (
    <Box type="content" className="edit-number-form">
      <Form
        form={form}
        scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="phone"
          label="Numer telefonu"
          normalize={phoneNumber}
          rules={[
            {
              required: true,
              validator: validatePhone,
            },
          ]}
        >
          <Input type="tel" size="large" placeholder="Wpisz numer..." />
        </Form.Item>

        <BtnWrapper>
          <SubmitBtn loading={loading}>Wyślij kod</SubmitBtn>
        </BtnWrapper>
      </Form>
    </Box>
  );
};

export default EditNumber;
