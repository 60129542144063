import { rest } from 'msw';
import {
  BankingActionTypes,
  BankingApiTypes,
  BankEnum,
} from 'js/redux/banking/types';
import * as dbApp from 'js/mocks/db/application';

type KontomatikRequest = Extract<
  BankingActionTypes,
  { type: BankEnum.POST_KONTOMATIK_REQUEST }
>['data'];

type KontomatikResponse = Extract<
  BankingApiTypes,
  | { type: BankEnum.POST_KONTOMATIK_SUCCESS }
  | { type: BankEnum.POST_KONTOMATIK_FAILURE }
>['payload'];

type KontomatikFinishRequest = Extract<
  BankingActionTypes,
  { type: BankEnum.KONTOMATIK_READY_REQUEST }
>['data'];

type KontomatikFinishResponse = Extract<
  BankingApiTypes,
  | { type: BankEnum.KONTOMATIK_READY_SUCCESS }
  | { type: BankEnum.KONTOMATIK_READY_FAILURE }
>['payload'];

export const kontomatikHandlers = [
  rest.post<KontomatikRequest, KontomatikResponse>(
    process.env.API_URL + '/kontomatik',
    (req, res, ctx) => {
      const { applicationId } = req.body;

      if (!applicationId)
        return res(
          ctx.status(400),
          ctx.json({
            status: 400,
            code: 'Bad Request',
            errors: ['Wniosek nie został znaleziony.'],
          })
        );

      dbApp.update(applicationId, { hasKontomatik: true });

      return res(
        ctx.status(201),
        ctx.json({
          status: 201,
          code: 'Created',
          data: [],
        })
      );
    }
  ),

  rest.post<KontomatikFinishRequest, KontomatikFinishResponse>(
    process.env.API_URL + '/kontomatik/ready',
    (req, res, ctx) => {
      const { applicationId } = req.body;

      if (!applicationId)
        return res(
          ctx.status(400),
          ctx.json({
            status: 400,
            code: 'Bad Request',
            errors: ['Wniosek nie został znaleziony.'],
          })
        );

      return res(
        ctx.status(200),
        ctx.json({
          status: 200,
          code: 'OK',
          data: [],
        })
      );
    }
  ),
];
