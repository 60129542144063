import { FC } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

import 'js/components/Buttons/styles.scss';

type Props = {
  children: string | string[] | JSX.Element;
  to: string;
  className?: string;
  loading?: boolean;
};

type BtnProps = {
  children: string;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

const PrevBtn: FC<Props> = ({ to, className = '', children, loading }) => (
  <Button
    className={`navigation-btn navigation-btn--prev ${className}`}
    loading={loading}
  >
    <Link to={to}>{children}</Link>
  </Button>
);

const NextBtn: FC<Props> = ({ to, className = '', children, loading }) => (
  <Button
    className={`navigation-btn navigation-btn--next ${className}`}
    loading={loading}
  >
    <Link to={to}>{children}</Link>
  </Button>
);

const SubmitBtn: FC<BtnProps> = ({
  className = '',
  loading = false,
  disabled,
  children,
  onClick,
}) => (
  <Button
    className={`submit-btn ${className}`}
    disabled={disabled}
    loading={loading}
    htmlType="submit"
    type="primary"
    onClick={onClick}
  >
    {children}
  </Button>
);

export { PrevBtn, NextBtn, SubmitBtn };
