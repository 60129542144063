import { FC, useState } from 'react';

import Box from 'js/components/Box';
import RatingModal from 'js/components/Report/RatingModal';

import { Result } from 'antd';

import { AdaptedSummary } from 'js/interfaces/report';

import 'js/components/Report/SummaryBox/styles.scss';

type Props = {
  data: AdaptedSummary;
};

const SummaryBox: FC<Props> = ({ data }) => {
  const [show, setShow] = useState(false);

  return (
    <Box
      className={`summary-box summary-box--${data.grade.value}`}
      type="content"
    >
      <h3>{data.name}</h3>
      <p>{data.description}</p>
      <span className="summary-box__stripe" />
      <div className="summary-box__content">
        <div className="grade">
          <Result status={data.grade.value} />
          <p>
            Twoja ocena:
            <span>{data.grade.name}</span>
          </p>
        </div>
        <div className="indicator">
          <div>
            <span className="number">{data.mark.value}</span>
          </div>
          <p>{data.mark.description}</p>
        </div>
      </div>
      <button onClick={() => setShow(true)}>
        Zobacz szczegóły i rekomendacje
      </button>
      <RatingModal data={data} show={show} onClose={() => setShow(false)} />
    </Box>
  );
};

export default SummaryBox;
