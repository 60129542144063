import { useEffect } from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'js/redux/reducers';
import { getConsents } from 'js/redux/account/actions';

const useInitialData = () => {
  const { consentsRaw } = useSelector((state: RootState) => state.Account);

  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    if (!consentsRaw?.length) dispatch(getConsents());
  }, [dispatch, consentsRaw]);
};

export default useInitialData;
