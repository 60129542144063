import { FC } from 'react';

import SmeoSteps from 'js/components/Steps/smeo';
import Provider from 'js/components/Provider';
import SmeoBankView from 'js/views/Smeo/Bank';

const SmeoBank: FC = () => (
  <>
    <SmeoSteps active="account" />
    <SmeoBankView />
    <Provider company="smeo" />
  </>
);

export default SmeoBank;
