import { AppliedOffer } from 'js/interfaces/admin';
import {
  AdaptedOffer,
  AdaptedReport,
  CodeDescriptionEnum,
  CodeEnum,
  CodeNameEnum,
  GradeValueEnum,
  OfferCategoryEnum,
  OfferOutput,
  OtherTransactionsEnum,
  PaymentStatusEnum,
  PotentialOffer,
  ReportOfferType,
  ReportProductTypeEnum,
  ReportRecommendation,
  ReportRiskbiteData,
  ReportSummary,
  TransactionTypeEnum,
} from 'js/interfaces/report';
import { formatPrice } from '../formatters';

const getOffers = (output: OfferOutput | null) => {
  if (output && Array.isArray(output) && !!output[0]?.offer) {
    return output;
  } else if (
    output &&
    'offer' in output &&
    output.offer &&
    !('clientAge' in output) &&
    !('masterDecision' in output)
  ) {
    return [output];
  }
  return [];
};

const getPotentialOffers = (output: OfferOutput | null) => {
  if (output && Array.isArray(output) && !!output[0]?.potentialOffers) {
    return output?.flatMap((offer) => offer?.potentialOffers || []);
  } else if (output && 'potentialOffers' in output && output.potentialOffers) {
    return output.potentialOffers;
  }
  return [];
};

const getMark = (summaryMark: string) => {
  const value = summaryMark?.split(' ')[0]?.toLowerCase()?.includes('infinity')
    ? '0'
    : summaryMark?.split(' ')[0];

  const description =
    summaryMark?.split(' ').slice(1, summaryMark.split(' ').length).join(' ') ||
    '';

  return { value, description };
};

const adaptOffers = (
  offers: ReportOfferType[],
  appId: number,
  reportAppliedOffers?: AppliedOffer[]
) => {
  const adaptedOffers: AdaptedOffer[] =
    offers?.map(({ company, offer, productType }) => {
      const applied = !!reportAppliedOffers?.find(
        (appliedOffer) =>
          appliedOffer?.company?.toLocaleLowerCase() ===
            (company?.toLocaleLowerCase() || 'wealthon') &&
          appliedOffer.category === OfferCategoryEnum.bankOffer
      );

      return {
        appId,
        company: company ?? 'wealthon',
        logo: '/images/wealthon.png',
        category: OfferCategoryEnum.bankOffer,
        grossAmount: formatPrice(offer.grossAmount),
        tenor: offer.tenor,
        installmentAmount: formatPrice(offer.installmentAmount),
        amountToPay: formatPrice(offer.amountToPay),
        chance: offer.chance,
        productType: ReportProductTypeEnum[productType],
        applied,
      };
    }) ?? [];

  return adaptedOffers;
};

const adaptPotentialOffers = (
  potentialOffers: PotentialOffer[],
  type: OfferCategoryEnum,
  appId: number,
  reportAppliedOffers?: AppliedOffer[]
) => {
  const adaptedPotentialOffers =
    potentialOffers?.map((offer) => {
      const applied = !!reportAppliedOffers?.find(
        (appliedOffer) =>
          appliedOffer?.company?.toLocaleLowerCase() ===
            offer?.lender?.toLocaleLowerCase() && appliedOffer.category === type
      );

      return {
        appId,
        type: offer.productType,
        offerType: type,
        category: offer.productCategory,
        company: offer.lender,
        link: offer.productLink,
        logo: offer.iconUrl,
        applied,
      };
    }) ?? [];

  return adaptedPotentialOffers;
};

const getObligations = (summary: ReportSummary) => {
  const initData = {
    productType: undefined,
    currentDueAmount: undefined,
    currentDPD: undefined,
    maxDPD: undefined,
    status: undefined,
    dataSource: undefined,
    entryDate: undefined,
    amount: undefined,
    lenderName: undefined,
    creditAmount: undefined,
    installmentAmount: undefined,
    from: undefined,
    to: undefined,
    count: undefined,
    inflowCleared: undefined,
    indicator: undefined,
  };

  if (summary.codeType === CodeEnum.IB && 'obligations' in summary.output) {
    return summary.output.obligations.map((obligation) => ({
      ...initData,
      productType: TransactionTypeEnum[obligation.productType],
      currentDueAmount: obligation.currentDueAmount,
      currentDPD: obligation.currentDPD,
    }));
  }
  if (summary.codeType === CodeEnum.IT && 'obligations' in summary.output) {
    return summary.output.obligations.map((obligation) => ({
      ...initData,
      productType: TransactionTypeEnum[obligation.productType],
      currentDueAmount: obligation.currentDueAmount,
      maxDPD: obligation.maxDPD,
      status: PaymentStatusEnum[obligation.status],
    }));
  }

  if (summary.codeType === CodeEnum.ID) {
    return summary.output.map((obligation) => ({
      ...initData,
      productType: OtherTransactionsEnum[obligation.productType],
      dataSource: obligation.dataSource,
      entryDate: obligation.entryDate,
      amount: obligation.amount,
      lenderName: obligation.lenderName,
    }));
  }

  if (summary.codeType === CodeEnum.IA) {
    return summary.output.obligations.obligations.map((obligation) => ({
      ...initData,
      productType: TransactionTypeEnum[obligation.productType],
      creditAmount: obligation.creditAmount,
      installmentAmount: obligation.installmentAmount,
    }));
  }

  if (summary.codeType === CodeEnum.IP) {
    return summary.output.map((obligation) => ({
      ...initData,
      from: obligation.from,
      to: obligation.to,
      count: obligation.count,
      inflowCleared: obligation.inflowCleared,
      indicator: obligation.indicator,
    }));
  }
  return [initData];
};

const adaptRecommendations = (recommendations: ReportRecommendation[]) => {
  const adaptedRecommendations = recommendations?.map(({ title, body }) => ({
    title,
    description: body ?? '',
  }));

  return adaptedRecommendations ?? [];
};

const adaptSummaries = (summaries: ReportSummary[]) => {
  const adaptedSummaries =
    summaries?.map((summary) => {
      return {
        code: summary.codeType,
        name: CodeNameEnum[summary.codeType],
        grade: {
          name: summary.grade,
          value: GradeValueEnum[summary.grade],
        },
        gradeDescription: summary.description ?? '',
        description: CodeDescriptionEnum[summary.codeType],
        mark: getMark(summary?.indicator ?? ''),
        recommendations: adaptRecommendations(summary?.recommendation),
        sum:
          summary.codeType === CodeEnum.IB && !Array.isArray(summary?.output)
            ? summary?.output?.sum
            : null,
        averageInflowCleared:
          summary.codeType === CodeEnum.IZ
            ? summary.output?.averageInflowCleared
            : null,
        currentDebt:
          summary.codeType === CodeEnum.IZ ? summary.output.currentDebt : null,
        accounts:
          summary.codeType === CodeEnum.IZ ? summary.output.accounts : null,
        obligations: getObligations(summary),
      };
    }) ?? [];

  return adaptedSummaries;
};

export const adaptRiskbiteData = (
  report: ReportRiskbiteData,
  appliedOffers?: AppliedOffer[]
) => {
  const offersSummary = report.summaries.find(
    (summary) => summary?.codeType === CodeEnum.IM && 'output' in summary
  );

  const otherSummaries = report.summaries.filter(
    (summary) => summary?.codeType !== CodeEnum.IM
  );

  const offersIndicator = offersSummary?.indicator ?? '';

  const hasOffers =
    offersSummary?.codeValue === 0 && offersSummary?.codeType === CodeEnum.IM;

  const output = hasOffers ? offersSummary?.output : null;

  const offers = getOffers(output);
  const potentialOffers = getPotentialOffers(output);
  const reportAppliedOffers = appliedOffers?.filter(
    (appliedOffer) => appliedOffer.applicationId === report.applicationId
  );

  const data: AdaptedReport = {
    id: report.id,
    date: report.date,
    expirationDate: report.expirationDate,
    rating: report.rating,
    amount: report.grantedAmountMax,
    amountIndicator: offersIndicator,
    offers: adaptOffers(offers, report.applicationId, reportAppliedOffers),
    loanPotentialOffers: adaptPotentialOffers(
      potentialOffers,
      OfferCategoryEnum.bankOffer,
      report.applicationId,
      reportAppliedOffers
    ),
    factoringPotentialOffers: adaptPotentialOffers(
      potentialOffers,
      OfferCategoryEnum.factoring,
      report.applicationId,
      reportAppliedOffers
    ),
    summaries: adaptSummaries(otherSummaries),
    client: {
      nip: report.company.nip ?? '',
      companyName: report.company.name ?? '',
      email: report.user.email,
      firstName: report.user.firstName ?? '',
      lastName: report.user.lastName ?? '',
    },
  };

  return data;
};
