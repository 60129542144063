import { takeLatest } from 'redux-saga/effects';
import { AdminEnum } from 'js/redux/admin/types';
import { createSaga } from 'js/redux/sagas';

function* adminSaga() {
  yield takeLatest(
    AdminEnum.SEARCH_USER_REQUEST,
    createSaga('SEARCH_USER', '/user/search', 'POST')
  );
  yield takeLatest(
    AdminEnum.REMOVE_USER_REQUEST,
    createSaga('REMOVE_USER', '/user/remove-gdpr-data', 'DELETE')
  );
  yield takeLatest(
    AdminEnum.GET_USERS_REQUEST,
    createSaga('GET_USERS', '/user/list')
  );
  yield takeLatest(
    AdminEnum.GENERATE_REPORT_MANUAL_REQUEST,
    createSaga('GENERATE_REPORT_MANUAL', '/report/generate', 'POST')
  );
}

export default adminSaga;
