import { FC } from 'react';
import 'js/components/Provider/styles.scss';

type Props = {
  company: 'smeo';
};

const Provider: FC<Props> = ({ company }) => {
  const images = {
    smeo: '/images/providers/smeo.svg',
  };

  return (
    <div className="provider">
      <span>Usługę dostarcza</span>
      <img src={images[company]} alt={company} />
    </div>
  );
};

export default Provider;
