import { FC, useEffect } from 'react';
import { Dispatch } from 'redux';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { setSelfData } from 'js/redux/account/actions';
import { objectsEquals } from 'js/helpers/objectsEquals';
import { Data } from 'js/pages/Data/self';
import { MaritalStatusEnum } from 'js/interfaces/account';
import { DeepNonNullable } from 'js/interfaces/helpers';
import { routes } from 'js/routes';

import { Form, Select, FormInstance } from 'antd';
import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { PrevBtn, SubmitBtn } from 'js/components/Buttons';

import 'js/views/Data/Self/styles.scss';

type Props = {
  loading: boolean;
  data: Data;
};

const Self: FC<Props> = ({ loading, data }) => {
  const [form]: [FormInstance<Data>] = Form.useForm();
  const dispatch: Dispatch = useDispatch();

  const history = useHistory();

  const handleSubmit = (values: Data) => {
    if (values.maritalStatus) {
      objectsEquals(data, values)
        ? history.push(routes.address)
        : dispatch(setSelfData(values as DeepNonNullable<Data>));
    }
  };

  useEffect(() => {
    data && form.setFieldsValue(data);
  }, [data, form]);

  return (
    <Box type="content" className="self-form">
      <Form
        form={form}
        onFinish={handleSubmit}
        scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
      >
        <Form.Item
          name="maritalStatus"
          label="Stan cywilny"
          rules={[
            {
              required: true,
              message: 'Proszę o uzupełnienie pola',
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Wybierz"
            options={[
              { label: 'Kawaler / Panna', value: MaritalStatusEnum.single },
              {
                label: 'Żonaty / Mężatka',
                value: MaritalStatusEnum.married,
              },
              {
                label: 'Wdowiec / Wdowa',
                value: MaritalStatusEnum.widow,
              },
              { label: 'Rozwiedziony/a', value: MaritalStatusEnum.divorced },
              {
                label: 'Wolny związek',
                value: MaritalStatusEnum.freeRelationship,
              },
            ]}
          />
        </Form.Item>

        <BtnWrapper>
          <PrevBtn to={routes.personal}>Wstecz</PrevBtn>
          <SubmitBtn loading={loading}>Przejdź dalej</SubmitBtn>
        </BtnWrapper>
      </Form>
    </Box>
  );
};

export default Self;
