import moment from 'moment';
import { AdaptedReport } from 'js/interfaces/report';
import { dateFormat } from 'js/constants';

export const getNewestReport = (reports: AdaptedReport[]) => {
  const newestReport = reports?.length
    ? reports.sort(
        (a, b) =>
          moment(b.date, dateFormat).unix() - moment(a.date, dateFormat).unix()
      )[0]
    : null;

  return newestReport;
};
