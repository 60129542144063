import { ConsentChecked } from 'js/interfaces/account';

export enum AuthEnum {
  REGISTER_REQUEST = 'REGISTER_REQUEST',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_FAILURE = 'REGISTER_FAILURE',

  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'LOGOUT_FAILURE',
}
export interface AuthState {
  loading: boolean;
  token: string;
  isAdmin: boolean;
}

export type LoginData = {
  email: string;
  password: string;
  token?: string;
};

export type RegisterData = {
  email: string;
  password: string;
  phone: string;
  consents: ConsentChecked[];
  token?: string;
};

// Actions

// REGISTER
interface RegisterAction {
  type: AuthEnum.REGISTER_REQUEST;
  method: 'POST';
  data: RegisterData;
}

interface RegisterSuccess {
  type: AuthEnum.REGISTER_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      token: string;
    };
  };
}

interface RegisterFailure {
  type: AuthEnum.REGISTER_FAILURE;
  payload: {
    status: 422;
    code: 'Unprocessable Entity';
    errors: {
      email?: string[];
      password?: string[];
    };
  };
}

// LOGIN
interface LoginAction {
  type: AuthEnum.LOGIN_REQUEST;
  method: 'POST';
  data: LoginData;
}

interface LoginSuccess {
  type: AuthEnum.LOGIN_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      token: string;
      isAdmin: boolean;
    };
  };
}

interface LoginFailure {
  type: AuthEnum.LOGIN_FAILURE;
  payload: {
    status: 400;
    code: 'Bad Request';
    errors: string[];
  };
}

// LOGOUT
interface LogoutAction {
  type: AuthEnum.LOGOUT_REQUEST;
  method: 'POST';
}

interface LogoutSuccess {
  type: AuthEnum.LOGOUT_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: [];
  };
}

interface LogoutFailure {
  type: AuthEnum.LOGOUT_FAILURE;
  payload: {
    status: 400;
    code: 'Bad Request';
    errors: string[];
  };
}

export type AuthActionTypes = RegisterAction | LoginAction | LogoutAction;

export type AuthApiTypes =
  | RegisterSuccess
  | RegisterFailure
  | LoginSuccess
  | LoginFailure
  | LogoutSuccess
  | LogoutFailure;
