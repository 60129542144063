import { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { RootState } from 'js/redux/reducers';
import {
  getUsers,
  removeUser,
  searchUser,
  generateReportManual,
} from 'js/redux/admin/actions';

import { Form, Input, FormInstance, Modal, Button } from 'antd';
import Icon from 'js/components/Icon';
import { Link } from 'react-router-dom';
import { routes } from 'js/routes';
import Box from 'js/components/Box';
import { SubmitBtn } from 'js/components/Buttons';
import UsersTable from 'js/components/UsersTable';

import { dateFormat } from 'js/constants';

import 'js/views/AdminSearchPanel/styles.scss';
import 'js/components/UsersTable/styles.scss';

type Email = {
  email: string;
};

const perPage = 10;

const AdminSearchPanel: FC = () => {
  const [hasModal, setHasModal] = useState(false);
  const [form]: [FormInstance<Email>] = Form.useForm();

  const {
    loading,
    reportLoading,
    pendingReport,
    list,
    user,
    usersTable,
    applications,
  } = useSelector((state: RootState) => state.Admin);

  const dispatch: Dispatch = useDispatch();

  const fullName = useMemo(
    () => `${user?.firstName ?? ''} ${user?.lastName ?? ''}`,
    [user]
  );

  const notGeneratedReports = useMemo(() => {
    if (applications?.length) {
      return applications
        ?.filter((app) => !app?.hasReport && app?.hasPendingReport)
        .sort(
          (a, b) =>
            moment(b.createdAt, dateFormat).unix() -
            moment(a.createdAt, dateFormat).unix()
        );
    }
    return null;
  }, [applications]);

  const handleSearch = useCallback(
    (values: Email) => {
      dispatch(searchUser(values));
    },
    [dispatch]
  );

  const confirmDelete = () => {
    if (user?.id) dispatch(removeUser(user.id));
  };

  const cancelDelete = () => setHasModal(false);

  const onPageChange = (page: number) => {
    dispatch(getUsers({ count: perPage, page }));
  };

  useEffect(() => {
    dispatch(getUsers({ count: perPage, page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    if (!user?.id) setHasModal(false);
  }, [user]);

  useEffect(() => {
    if (pendingReport && user?.email && notGeneratedReports?.length) {
      const interval = setInterval(() => {
        handleSearch({ email: user.email });
      }, 2000);

      setTimeout(() => {
        clearInterval(interval);
      }, 120000);

      return () => clearInterval(interval);
    }
  }, [pendingReport, user, notGeneratedReports, handleSearch]);

  return (
    <>
      <Box type="content" className="admin-search">
        <>
          <Form
            form={form}
            scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
            onFinish={handleSearch}
          >
            <Form.Item
              name="email"
              label="Adres e-mail"
              rules={[
                { required: true, message: 'Proszę podaj email' },
                { type: 'email', message: 'Podaj poprawny adres email' },
              ]}
            >
              <Input
                size="large"
                placeholder="Wpisz adres e-mail użytkownika..."
                type="email"
              />
            </Form.Item>
            <SubmitBtn loading={loading}>Szukaj raportu</SubmitBtn>
          </Form>

          {user?.id && (
            <div className="admin-search__user-panel">
              <div>
                <p>
                  Email: <span>{user?.email ?? 'Brak danych'}</span>
                </p>
                <p>
                  Numer telefonu: <span>{user?.phone ?? 'Brak danych'}</span>
                </p>
                <p>
                  Imię i nazwisko:{' '}
                  <span>{fullName?.length > 1 ? fullName : 'Brak danych'}</span>
                </p>
                <p>
                  PESEL: <span>{user.pesel ?? 'Brak danych'}</span>
                </p>
              </div>

              <div>
                <SubmitBtn onClick={() => setHasModal(true)} loading={loading}>
                  Usuń użytkownika
                </SubmitBtn>
              </div>
            </div>
          )}

          <Modal
            title="Potwierdź usunięcie"
            visible={hasModal}
            onOk={confirmDelete}
            onCancel={cancelDelete}
          >
            Czy napewno chcesz usunąć użytkownika {user?.firstName}{' '}
            {user?.lastName}?
          </Modal>

          {!list.length && user?.id && !notGeneratedReports?.length && (
            <div className="admin-search__empty-list">
              <Icon name="emptyBox" />
              <h4>Brak raportów do wyświetlenia</h4>
            </div>
          )}

          {!!notGeneratedReports?.length && (
            <div className="admin-search__empty-list">
              <Icon name="emptyBox" />
              <h4>
                {reportLoading || pendingReport
                  ? 'Trwa generowanie raportu'
                  : 'Raport nie został wygenerowany pomyślnie'}{' '}
              </h4>
              <Button
                loading={reportLoading || pendingReport}
                onClick={() =>
                  dispatch(
                    generateReportManual({
                      applicationId: notGeneratedReports[0].id,
                    })
                  )
                }
              >
                WYGENERUJ
              </Button>
            </div>
          )}

          {!!list.length && (
            <div className="admin-search__table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Lp</th>
                    <th>Data</th>
                    <th>Firma</th>
                    <th></th>
                  </tr>
                </thead>
                {list.map((report, index) => (
                  <tbody key={report.id}>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{report.date}</td>
                      <td>{report.client.companyName}</td>
                      <td>
                        <Link to={`${routes.reportView}/${report.id}`}>
                          Otwórz raport
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          )}
        </>
      </Box>
      <Box type="content" className="users-table">
        <UsersTable
          data={usersTable}
          loading={loading && !!list.length}
          onPageChange={onPageChange}
        />
      </Box>
    </>
  );
};

export default AdminSearchPanel;
