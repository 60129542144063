import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'js/redux/reducers';

import Steps from 'js/components/Steps';
import Box from 'js/components/Box';
import EditNumberForm from 'js/views/Register/EditNumber';

const EditNumberPage: FC = () => {
  const { loading } = useSelector((state: RootState) => state.Account);

  return (
    <>
      <Steps active="register" />
      <Box type="heading">
        <h3>Wprowadź poprawny numer telefonu</h3>
      </Box>
      <EditNumberForm loading={loading} />
    </>
  );
};

export default EditNumberPage;
