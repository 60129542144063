import * as dbAccount from 'js/mocks/db/account';
import * as dbUser from 'js/mocks/db/user';
import * as dbCompany from 'js/mocks/db/company';

export const createNoNip = () => {
  // phone verified with no NIP
  dbAccount.create({
    consents: [],
    user: dbUser.create({
      email: 'verified@t.pl',
      phone: '555666777',
      isActive: true,
    }),
    company: dbCompany.create({}),
    applications: [],
    isAdmin: false,
    _hiddenData: {
      password: 'Password1',
      smsCode: '',
    },
  });
};
