import { FC } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';

import {
  Form,
  InputNumber,
  FormInstance,
  Checkbox,
  Input,
  DatePicker,
  Select,
} from 'antd';

import {
  BookKeepingEnum,
  EducationEnum,
  HousingStatusEnum,
} from 'js/interfaces/account';
import {
  validateExpirationDate,
  validateIssueDate,
} from 'js/helpers/validators';

import { onlyDigits } from 'js/helpers/formatters';
import { dateFormat } from 'js/constants';

import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { SubmitBtn } from 'js/components/Buttons';
import { Data } from 'js/pages/Smeo/company';
import { submitSmeoApplication } from 'js/redux/smeo/actions';

import 'js/views/Smeo/Company/styles.scss';

type Props = {
  loading: boolean;
  data: Data;
};

type FormData = {
  income: number;
  contractorCount: number;
  otherIncome: number;
  isDeferredPaymentDate: boolean;
  avgInvoiceMaturity: string | null;
  idExpiryDate: string;
  education: EducationEnum | null;
  bookkeepingType: BookKeepingEnum | null;
};

const SmeoCompany: FC<Props> = ({ loading, data }) => {
  const [form]: [FormInstance<FormData>] = Form.useForm();
  const dispatch: Dispatch = useDispatch();

  const handleSubmit = (values: FormData) => {
    const newData: Data = {
      ...data,
      application: {
        ...data.application,
        income: values.income,
        contractorCount: values.contractorCount,
        isDeferredPaymentDate: values?.isDeferredPaymentDate || false,
        avgInvoiceMaturity: values.avgInvoiceMaturity,
        bookkeepingTypeId: values.bookkeepingType,
        applicant: {
          ...data.application.applicant,
          otherIncome: values.otherIncome,
          idExpiryDate: values.idExpiryDate,
          educationTypeId: values.education,
        },
      },
    };
    dispatch(submitSmeoApplication(newData));
  };

  return (
    <Box type="content" className="wider wider--content company-data-form">
      <Form
        form={form}
        onFinish={handleSubmit}
        scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
      >
        <Form.Item
          name="income"
          label="Przychody z ostatnich 6 miesięcy"
          rules={[
            {
              required: true,
              message: 'Proszę o uzupełnienie pola',
            },
          ]}
        >
          <InputNumber
            placeholder="Wpisz przychody z ostatnich 6 miesięcy..."
            type="number"
            size="large"
            min={0}
          />
        </Form.Item>

        <Form.Item
          name="avgInvoiceMaturity"
          label="Średni termin faktury (dni)"
          normalize={onlyDigits}
          rules={[
            {
              required: true,
              message: 'Proszę o uzupełnienie pola',
            },
          ]}
        >
          <Input
            size="large"
            maxLength={9}
            placeholder="Wpisz średni termin faktury..."
          />
        </Form.Item>

        <Form.Item
          name="contractorCount"
          label="Ilość klientów z ostatniego miesiąca"
          rules={[
            {
              required: true,
              message: 'Proszę o uzupełnienie pola',
            },
          ]}
        >
          <InputNumber
            placeholder="Wpisz ilość klientów z ostatniego miesiąca..."
            type="number"
            size="large"
            min={0}
          />
        </Form.Item>

        <Form.Item
          name="bookkeepingType"
          label="Jak prowadzisz księgowość?"
          rules={[
            {
              required: true,
              message: 'Proszę o uzupełnienie pola',
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Wybierz"
            options={[
              { label: 'Samodzielnie', value: BookKeepingEnum.individual },
              {
                label: 'Biuro księgowe',
                value: BookKeepingEnum.bookKeepingOffice,
              },
              {
                label: 'Etatowy księgowy',
                value: BookKeepingEnum.fulltimeAccountant,
              },
              { label: 'Osoba trzecia', value: BookKeepingEnum.thirdParty },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="education"
          label="Wykształcenie"
          rules={[
            {
              required: true,
              message: 'Proszę o uzupełnienie pola',
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Wybierz"
            options={[
              { label: 'Podstawowe', value: EducationEnum.primary },
              {
                label: 'Średnie',
                value: EducationEnum.secondary,
              },
              {
                label: 'Zawodowe',
                value: EducationEnum.vocational,
              },
              { label: 'Student', value: EducationEnum.student },
              {
                label: 'Wyższe zawodowe (licencjat, inżynier)',
                value: EducationEnum.higherVocational,
              },
              {
                label: 'Wyższe magisterskie',
                value: EducationEnum.higherMasterDegree,
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="houseStatusTypeId"
          label="Status mieszkaniowy"
          rules={[
            {
              required: true,
              message: 'Proszę o uzupełnienie pola',
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Wybierz"
            options={[
              {
                label: 'Własne / Spółdzielcze własnościowe',
                value: HousingStatusEnum.owner,
              },
              {
                label: 'Spółdzielcze lokatorskie',
                value: HousingStatusEnum.cooperativeTenant,
              },
              {
                label: 'Z rodzicami',
                value: HousingStatusEnum.withParents,
              },
              {
                label: ' Innego członka rodziny',
                value: HousingStatusEnum.familyMember,
              },
              {
                label: 'Wynajęte ',
                value: HousingStatusEnum.rented,
              },
              {
                label: 'Komunalne / Kwaterunkowe',
                value: HousingStatusEnum.communal,
              },
              {
                label: 'Dom studencki / stancja studencka',
                value: HousingStatusEnum.studentHouse,
              },
              {
                label: 'Służbowe ',
                value: HousingStatusEnum.business,
              },
              {
                label: 'Brak stałego zamieszkania ',
                value: HousingStatusEnum.noPermanentResidence,
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="idCardIssueDate"
          label="Data wydania dowodu osobistego"
          rules={[{ required: true, validator: validateIssueDate }]}
        >
          <DatePicker format="DD-MM-yyyy" placeholder="dd-mm-rrrr" />
        </Form.Item>

        <Form.Item
          name="idExpiryDate"
          label="Data ważności dowodu osobistego"
          rules={[
            {
              required: true,
              validator: validateExpirationDate,
            },
          ]}
        >
          <DatePicker format={dateFormat} placeholder="dd-mm-rrrr" />
        </Form.Item>

        <Form.Item
          name="otherIncome"
          label={<span>Wysokość dochodu dodatkowego</span>}
          rules={[
            {
              required: true,
              message: 'Proszę o uzupełnienie pola',
            },
          ]}
        >
          <InputNumber
            placeholder="Wpisz wysokość dochodu dodatkowego..."
            type="number"
            size="large"
            min={0}
          />
        </Form.Item>

        <Form.Item name="isDeferredPaymentDate" valuePropName="checked">
          <Checkbox>Faktury z odroczonym terminem płatności</Checkbox>
        </Form.Item>

        <BtnWrapper className="company-data-form__button-wrapper">
          <SubmitBtn loading={loading}>Przejdź dalej</SubmitBtn>
        </BtnWrapper>
      </Form>
    </Box>
  );
};

export default SmeoCompany;
