import { AdaptedReport } from 'js/interfaces/report';
import {
  SearchUserData,
  TableMeta,
  SearchUser,
  AppliedOffer,
  TableLink,
} from 'js/interfaces/admin';
import { App } from 'js/interfaces/account';
import { AppId } from 'js/interfaces';

export enum AdminEnum {
  SEARCH_USER_REQUEST = 'SEARCH_USER_REQUEST',
  SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS',
  SEARCH_USER_FAILURE = 'SEARCH_USER_FAILURE',

  REMOVE_USER_REQUEST = 'REMOVE_USER_REQUEST',
  REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS',
  REMOVE_USER_FAILURE = 'REMOVE_USER_FAILURE',

  GET_USERS_REQUEST = 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE = 'GET_USERS_FAILURE',

  GENERATE_REPORT_MANUAL_REQUEST = 'GENERATE_REPORT_MANUAL_REQUEST',
  GENERATE_REPORT_MANUAL_SUCCESS = 'GENERATE_REPORT_MANUAL_SUCCESS',
  GENERATE_REPORT_MANUAL_FAILURE = 'GENERATE_REPORT_MANUAL_FAILURE',
}

export interface AdminState {
  loading: boolean;
  reportLoading: boolean;
  pendingReport: boolean;
  list: AdaptedReport[];
  applications: App[];
  usersTable: {
    data: SearchUserData[];
    pagination: TableMeta;
  };
  user: SearchUser | null;
  appliedOffers: AppliedOffer[];
  newestReport: AdaptedReport | null;
}

// Actions
// ADMIN SERACH FOR USER
interface SearchUserAction {
  type: AdminEnum.SEARCH_USER_REQUEST;
  method: 'POST';
  data: {
    email: string;
  };
}

interface SearchUserSuccess {
  type: AdminEnum.SEARCH_USER_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: SearchUserData[];
  };
}

interface SearchUserFailure {
  type: AdminEnum.SEARCH_USER_FAILURE;
  payload: {
    status: 422;
    code: 'Unprocessable Entity';
    errors: string[];
  };
}

// REMOVE USER
interface RemoveUserAction {
  type: AdminEnum.REMOVE_USER_REQUEST;
  method: 'DELETE';
  id: number;
}

interface RemoveUserSuccess {
  type: AdminEnum.REMOVE_USER_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {};
  };
}

interface RemoveUserFailure {
  type: AdminEnum.REMOVE_USER_FAILURE;
  payload: {
    status: 422;
    code: 'Unprocessable Entity';
    errors: string[];
  };
}

// GET USER
interface GetUsersAction {
  type: AdminEnum.GET_USERS_REQUEST;
  data: {
    count: number;
    page: number;
  };
}

interface GetUsersSuccess {
  type: AdminEnum.GET_USERS_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: {
      data: SearchUserData[];
      links: TableLink;
      meta: TableMeta;
    };
  };
}

interface GetUsersFailure {
  type: AdminEnum.GET_USERS_FAILURE;
  payload: {
    status: 422;
    code: 'Unprocessable Entity';
    errors: string[];
  };
}

// POST GENERATE REPORT
interface GenerateReportManualAction {
  type: AdminEnum.GENERATE_REPORT_MANUAL_REQUEST;
  method: 'POST';
  data: AppId;
}

interface GenerateReportManualSuccess {
  type: AdminEnum.GENERATE_REPORT_MANUAL_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: string;
  };
}

interface GenerateReportManualFailure {
  type: AdminEnum.GENERATE_REPORT_MANUAL_FAILURE;
  payload: {
    status: 422;
    code: 'Failed';
    errors: string[];
  };
}

export type AdminActionTypes =
  | SearchUserAction
  | RemoveUserAction
  | GetUsersAction
  | GenerateReportManualAction;

export type AdminApiTypes =
  | SearchUserSuccess
  | SearchUserFailure
  | RemoveUserSuccess
  | RemoveUserFailure
  | GetUsersSuccess
  | GetUsersFailure
  | GenerateReportManualSuccess
  | GenerateReportManualFailure;
