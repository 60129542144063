import { FC, ReactElement } from 'react';

import 'js/components/Icon/styles.scss';

interface IIcon {
  [key: string]: JSX.Element;
}

const icons: IIcon = {
  logo: (
    <svg
      width="151"
      height="36"
      viewBox="0 0 151 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.9509 25.4997H57.9703C55.6534 25.4997 53.7695 23.6159 53.7695 21.2989C53.7695 20.8146 54.1631 20.4211 54.6474 20.4211C55.1317 20.4211 55.5253 20.8146 55.5253 21.2989C55.5253 22.6472 56.622 23.7463 57.9726 23.7463H60.9532C62.2991 23.7463 63.3935 22.6518 63.3935 21.3059C63.3935 20.0904 62.5203 19.0751 61.3188 18.8935L57.4231 18.3021C55.4088 17.997 53.9465 16.2948 53.9465 14.2573C53.9465 12.0009 55.7814 10.166 58.0378 10.166H60.8391C63.0722 10.166 64.8908 11.9823 64.8908 14.2177C64.8908 14.7021 64.4973 15.0956 64.0129 15.0956C63.5286 15.0956 63.1351 14.7021 63.1351 14.2177C63.1351 12.951 62.1035 11.9194 60.8368 11.9194H58.0355C56.7478 11.9194 55.6976 12.9673 55.6976 14.2573C55.6976 15.4216 56.5335 16.3926 57.6839 16.5673L61.5796 17.1587C63.645 17.4731 65.1446 19.2172 65.1446 21.3059C65.1446 23.6182 63.2631 25.4997 60.9509 25.4997Z"
        fill="white"
      />
      <path
        d="M73.2978 25.4999C72.8134 25.4999 72.4199 25.1064 72.4199 24.6221V11.0395C72.4199 10.5551 72.8134 10.1616 73.2978 10.1616C73.7821 10.1616 74.1757 10.5551 74.1757 11.0395V24.6221C74.1757 25.1087 73.7821 25.4999 73.2978 25.4999Z"
        fill="white"
      />
      <path
        d="M97.7816 25.7137C97.7118 25.7137 97.6419 25.7067 97.5744 25.6927C97.5069 25.6811 97.4417 25.6601 97.3765 25.6322C97.3137 25.6066 97.2531 25.5716 97.1972 25.5367C97.1367 25.4971 97.0832 25.4529 97.0343 25.404C96.8364 25.2084 96.7246 24.936 96.7246 24.6566C96.7246 24.3772 96.8364 24.1072 97.0343 23.9093C97.2322 23.7114 97.5046 23.5996 97.7816 23.5996C98.0587 23.5996 98.3311 23.7114 98.529 23.9093C98.7269 24.1072 98.8387 24.3772 98.8387 24.6566C98.8387 24.936 98.7269 25.2084 98.529 25.404C98.4801 25.4529 98.4266 25.4995 98.366 25.5367C98.3101 25.574 98.2496 25.6066 98.1867 25.6322C98.1239 25.6578 98.0564 25.6787 97.9888 25.6927C97.919 25.709 97.8491 25.7137 97.7816 25.7137Z"
        fill="white"
      />
      <path
        d="M82.3271 25.509C81.8427 25.509 81.4492 25.1155 81.4492 24.6312V11.7099C81.4492 10.9555 81.9662 10.3291 82.7066 10.1824C83.4471 10.038 84.162 10.4245 84.4461 11.1231L89.3897 23.2992C89.5573 23.7137 89.9415 23.7463 90.0533 23.7463C90.1674 23.7463 90.5493 23.7137 90.7169 23.2992L95.6605 11.1231C95.9446 10.4245 96.6595 10.038 97.3999 10.1824C98.1404 10.3267 98.6574 10.9555 98.6574 11.7099V20.2022C98.6574 20.6866 98.2638 21.0801 97.7795 21.0801C97.2952 21.0801 96.9016 20.6866 96.9016 20.2022V12.7275L92.3423 23.9629C91.9627 24.899 91.0639 25.5044 90.0533 25.5044C89.0427 25.5044 88.1439 24.899 87.7643 23.9629L83.2026 12.7275V24.6312C83.205 25.1178 82.8114 25.509 82.3271 25.509Z"
        fill="white"
      />
      <path
        d="M106.81 25.5019C106.325 25.5019 105.932 25.1084 105.932 24.624V11.0414C105.932 10.5571 106.325 10.1636 106.81 10.1636H112.561C115.225 10.1636 117.393 12.3315 117.393 14.9954C117.393 17.6593 115.225 19.8272 112.561 19.8272H107.687V24.624C107.687 25.1084 107.294 25.5019 106.81 25.5019ZM107.687 18.0761H112.561C114.259 18.0761 115.639 16.6952 115.639 14.9977C115.639 13.3002 114.259 11.9193 112.561 11.9193H107.687V18.0761Z"
        fill="white"
      />
      <path
        d="M133.109 25.4996H127.145C125.166 25.4996 123.555 23.8882 123.555 21.9089V11.0414C123.555 10.5571 123.948 10.1636 124.433 10.1636C124.917 10.1636 125.31 10.5571 125.31 11.0414V21.9089C125.31 22.9218 126.135 23.7461 127.148 23.7461H133.111C133.595 23.7461 133.989 24.1397 133.989 24.624C133.987 25.1084 133.593 25.4996 133.109 25.4996Z"
        fill="white"
      />
      <path
        d="M149.144 23.7465H143.181C142.168 23.7465 141.343 22.9222 141.343 21.9093V18.7075H148.31C148.795 18.7075 149.188 18.314 149.188 17.8296C149.188 17.3453 148.795 16.9517 148.31 16.9517H141.343V11.9174H149.144C149.628 11.9174 150.022 11.5238 150.022 11.0395C150.022 10.5551 149.628 10.1616 149.144 10.1616H140.468C139.983 10.1616 139.59 10.5551 139.59 11.0395V21.9069C139.59 23.8862 141.201 25.4976 143.181 25.4976H149.144C149.628 25.4976 150.022 25.1041 150.022 24.6197C150.02 24.14 149.628 23.7465 149.144 23.7465Z"
        fill="white"
      />
      <path
        d="M40.0349 17.4052L39.3922 16.2898C36.6748 11.5814 31.6078 8.65674 26.1706 8.65674H15.6478C15.1611 8.65674 14.7676 9.05027 14.7676 9.53694C14.7676 10.0236 15.1611 10.4171 15.6478 10.4171H26.1706C30.9791 10.4171 35.4616 13.0042 37.8647 17.17L38.5074 18.2854C38.6704 18.5672 38.9661 18.7255 39.2712 18.7255C39.4202 18.7255 39.5716 18.6882 39.7113 18.6067C40.1351 18.3646 40.2794 17.8267 40.0349 17.4052Z"
        fill="white"
      />
      <path
        d="M36.2342 19.0799C33.7962 15.0957 29.6863 12.8114 24.9592 12.8114H15.6496C13.845 12.8114 12.378 11.3444 12.378 9.53975C12.378 7.7351 13.845 6.2681 15.6496 6.2681H26.8873C29.8865 6.2681 32.0078 6.72683 33.7659 7.75839C33.9056 7.83989 34.0593 7.87947 34.2107 7.87947C34.5134 7.87947 34.8068 7.72346 34.9721 7.44403C35.2189 7.02489 35.0769 6.48466 34.6578 6.23783C32.6133 5.03861 30.2172 4.50537 26.8873 4.50537H15.6496C12.8739 4.50537 10.6152 6.76409 10.6152 9.53975C10.6152 12.3154 12.8739 14.5741 15.6496 14.5741H24.9592C29.0575 14.5741 32.6203 16.5511 34.7346 20.0067C34.7439 20.0207 35.6684 21.5133 36.0456 22.95C36.1504 23.3459 36.5066 23.609 36.8978 23.609C36.9724 23.609 37.0469 23.5997 37.1214 23.581C37.5918 23.4576 37.8735 22.9756 37.7501 22.5052C37.3053 20.8007 36.2738 19.1405 36.2342 19.0799Z"
        fill="white"
      />
      <path
        d="M24.5253 27.0344C25.012 27.0344 25.4055 26.6408 25.4055 26.1542C25.4055 25.6675 25.012 25.274 24.5253 25.274H14.0025C9.19401 25.274 4.7115 22.6869 2.30841 18.5211L1.66573 17.4057C1.42123 16.9842 0.883326 16.8399 0.461855 17.0844C0.0403828 17.3265 -0.103988 17.8668 0.140512 18.2882L0.783199 19.4036C3.50064 24.112 8.56762 27.0367 14.0048 27.0367H24.5253V27.0344Z"
        fill="white"
      />
      <path
        d="M24.526 31.1883C27.3016 31.1883 29.5604 28.9296 29.5604 26.1539C29.5604 23.3783 27.3016 21.1195 24.526 21.1195H15.2163C11.1181 21.1195 7.55534 19.1426 5.441 15.687C5.43168 15.673 4.50724 14.1804 4.13001 12.7437C4.0066 12.2733 3.52458 11.9915 3.05421 12.1149C2.58384 12.2384 2.30208 12.7204 2.42549 13.1907C2.87025 14.8929 3.90414 16.5509 3.9414 16.6138C6.37941 20.5979 10.4893 22.8823 15.2163 22.8823H24.526C26.3306 22.8823 27.7976 24.3493 27.7976 26.1539C27.7976 27.9586 26.3306 29.4256 24.526 29.4256H13.2883C10.2891 29.4256 8.16776 28.9668 6.40968 27.9353C5.99054 27.6884 5.45031 27.8305 5.20348 28.2496C4.95665 28.6688 5.0987 29.209 5.51784 29.4558C7.56233 30.655 9.95843 31.1883 13.2883 31.1883H24.526Z"
        fill="white"
      />
      <path
        d="M24.526 16.9631H20.0878H15.6495C11.5535 16.9631 8.22367 13.6309 8.22367 9.5373C8.22367 5.44134 11.5559 2.11148 15.6495 2.11148H19.2401H27.9723C28.459 2.11148 28.8525 1.71795 28.8525 1.23127C28.8525 0.744603 28.459 0.351074 27.9723 0.351074H19.2401H15.6495C10.5825 0.351074 6.46094 4.47265 6.46094 9.53963C6.46094 14.6066 10.5825 18.7282 15.6495 18.7282H20.0878H24.526C28.622 18.7282 31.9518 22.0604 31.9518 26.154C31.9518 30.2476 28.6196 33.5798 24.526 33.5798H18.8909H12.2032C11.7165 33.5798 11.323 33.9734 11.323 34.46C11.323 34.9467 11.7165 35.3402 12.2032 35.3402H18.8909H24.526C29.593 35.3402 33.7146 31.2186 33.7146 26.1517C33.7146 21.087 29.593 16.9631 24.526 16.9631Z"
        fill="white"
      />
    </svg>
  ),
  chat: (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7285 23.3195C16.4707 22.7906 15.4004 21.8632 14.6348 20.6367C13.8613 19.4027 13.4551 17.954 13.4551 16.4439C13.4551 12.3891 16.4473 9.08545 20.1191 9.08545C23.791 9.08545 26.7832 12.3891 26.7832 16.4439C26.7832 17.9923 26.3535 19.464 25.541 20.7211L24.1348 19.8472C24.7754 18.8584 25.1113 17.678 25.1113 16.4516C25.1113 13.2936 22.8691 10.7258 20.1113 10.7258C17.3535 10.7258 15.1113 13.2936 15.1113 16.4516C15.1113 18.8355 16.4238 20.997 18.377 21.8248L17.7285 23.3195Z"
        fill="white"
      />
      <path
        d="M29.2832 19.717H27.6191V18.0844H29.2832C29.7441 18.0844 30.1191 17.7164 30.1191 17.2642V15.6315C30.1191 15.1793 29.7441 14.8114 29.2832 14.8114H27.6191V13.1787H29.2832C30.6582 13.1787 31.7832 14.2825 31.7832 15.6315V17.2642C31.7832 18.6209 30.666 19.717 29.2832 19.717Z"
        fill="white"
      />
      <path
        d="M12.6191 19.717H10.9551C9.58008 19.717 8.45508 18.6133 8.45508 17.2642V15.6315C8.45508 14.2825 9.58008 13.1787 10.9551 13.1787H12.6191V14.8114H10.9551C10.4941 14.8114 10.1191 15.1793 10.1191 15.6315V17.2642C10.1191 17.7164 10.4941 18.0844 10.9551 18.0844H12.6191V19.717Z"
        fill="white"
      />
      <path
        d="M29.2832 14.8113H27.6191V13.9912C27.6191 9.93632 24.252 6.63266 20.1191 6.63266C15.9863 6.63266 12.6191 9.93632 12.6191 13.9912V14.8113H10.9551V13.9912C10.9551 9.03184 15.0645 5 20.1191 5C25.1738 5 29.2832 9.03184 29.2832 13.9912V14.8113Z"
        fill="white"
      />
      <path
        d="M23.4551 26.2631H21.791C20.416 26.2631 19.291 25.1593 19.291 23.8103C19.291 22.4612 20.416 21.3574 21.791 21.3574H23.4551C24.8301 21.3574 25.9551 22.4612 25.9551 23.8103C25.9551 25.1593 24.8301 26.2631 23.4551 26.2631ZM21.7832 22.9901C21.3223 22.9901 20.9473 23.358 20.9473 23.8103C20.9473 24.2625 21.3223 24.6304 21.7832 24.6304H23.4473C23.9082 24.6304 24.2832 24.2625 24.2832 23.8103C24.2832 23.358 23.9082 22.9901 23.4473 22.9901H21.7832Z"
        fill="white"
      />
      <path
        d="M26.7832 24.6228H25.1191V22.9901H26.7832C27.2441 22.9901 27.6191 22.6222 27.6191 22.17V18.897H29.2832V22.17C29.2832 23.5267 28.166 24.6228 26.7832 24.6228Z"
        fill="white"
      />
      <path
        d="M20.0889 36.0742C15.5186 36.0742 10.3623 35.7369 6.97168 31.6897L6.66699 31.3218L6.83887 30.8772C8.22168 27.2516 11.2373 24.3926 15.1045 23.0205L15.667 24.5612C12.417 25.711 9.8623 28.0411 8.57324 31.0075C11.5029 34.1656 15.8623 34.4338 20.0811 34.4338C24.3467 34.4338 28.7451 34.1579 31.6748 30.9156C30.8936 29.0606 29.6123 27.4203 27.9561 26.1632L28.9795 24.8678C30.9717 26.3855 32.4873 28.3784 33.3701 30.6473C33.4248 30.7853 33.4561 30.9386 33.4561 31.0919V31.3831L33.2139 31.6821C29.8154 35.7292 24.6592 36.0742 20.0889 36.0742ZM31.8154 31.2298C31.8154 31.2298 31.8154 31.2375 31.8154 31.2298C31.8154 31.2375 31.8154 31.2298 31.8154 31.2298Z"
        fill="white"
      />
    </svg>
  ),
  poster: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.75 3.75H2.25C1.83516 3.75 1.5 4.08516 1.5 4.5V19.5C1.5 19.9148 1.83516 20.25 2.25 20.25H21.75C22.1648 20.25 22.5 19.9148 22.5 19.5V4.5C22.5 4.08516 22.1648 3.75 21.75 3.75ZM20.8125 18.5625H3.1875V5.4375H20.8125V18.5625Z"
        fill="#4F0A8A"
      />
      <path
        d="M14.3038 11.1562H17.196C17.2265 11.1562 17.2499 11.0719 17.2499 10.9688V9.84375C17.2499 9.74063 17.2265 9.65625 17.196 9.65625H14.3038C14.2734 9.65625 14.2499 9.74063 14.2499 9.84375V10.9688C14.2499 11.0719 14.2734 11.1562 14.3038 11.1562ZM14.4163 14.5312H18.7687C18.8601 14.5312 18.9351 14.4469 18.9351 14.3438V13.2188C18.9351 13.1156 18.8601 13.0312 18.7687 13.0312H14.4163C14.3249 13.0312 14.2499 13.1156 14.2499 13.2188V14.3438C14.2499 14.4469 14.3249 14.5312 14.4163 14.5312ZM5.24993 15.7734H6.27884C6.37727 15.7734 6.45696 15.6961 6.46399 15.5977C6.55306 14.4141 7.54212 13.4766 8.74212 13.4766C9.94212 13.4766 10.9312 14.4141 11.0202 15.5977C11.0273 15.6961 11.107 15.7734 11.2054 15.7734H12.2343C12.2597 15.7735 12.2849 15.7683 12.3083 15.7583C12.3317 15.7483 12.3528 15.7337 12.3703 15.7152C12.3879 15.6968 12.4015 15.675 12.4103 15.6512C12.4192 15.6274 12.4231 15.602 12.4218 15.5766C12.3562 14.3273 11.6718 13.2398 10.6734 12.6211C11.1137 12.1371 11.357 11.5059 11.3554 10.8516C11.3554 9.40078 10.1859 8.22656 8.74446 8.22656C7.30306 8.22656 6.13353 9.40078 6.13353 10.8516C6.13353 11.5336 6.39134 12.1523 6.81556 12.6211C6.30726 12.9361 5.88276 13.3694 5.57829 13.8841C5.27382 14.3987 5.09844 14.9794 5.06712 15.5766C5.05774 15.6844 5.14212 15.7734 5.24993 15.7734ZM8.74212 9.63281C9.41009 9.63281 9.95384 10.1789 9.95384 10.8516C9.95384 11.5242 9.41009 12.0703 8.74212 12.0703C8.07415 12.0703 7.5304 11.5242 7.5304 10.8516C7.5304 10.1789 8.07415 9.63281 8.74212 9.63281Z"
        fill="#4F0A8A"
      />
    </svg>
  ),
  pencil: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.625 19.5938H3.375C2.96016 19.5938 2.625 19.9289 2.625 20.3438V21.1875C2.625 21.2906 2.70937 21.375 2.8125 21.375H21.1875C21.2906 21.375 21.375 21.2906 21.375 21.1875V20.3438C21.375 19.9289 21.0398 19.5938 20.625 19.5938ZM6.03984 17.625C6.08672 17.625 6.13359 17.6203 6.18047 17.6133L10.1227 16.9219C10.1695 16.9125 10.2141 16.8914 10.2469 16.8563L20.182 6.92109C20.2038 6.89941 20.221 6.87366 20.2328 6.8453C20.2445 6.81695 20.2506 6.78656 20.2506 6.75586C20.2506 6.72516 20.2445 6.69477 20.2328 6.66642C20.221 6.63806 20.2038 6.61231 20.182 6.59063L16.2867 2.69297C16.2422 2.64844 16.1836 2.625 16.1203 2.625C16.057 2.625 15.9984 2.64844 15.9539 2.69297L6.01875 12.6281C5.98359 12.6633 5.9625 12.7055 5.95312 12.7523L5.26172 16.6945C5.23892 16.8201 5.24707 16.9493 5.28545 17.071C5.32384 17.1927 5.39132 17.3032 5.48203 17.393C5.63672 17.543 5.83125 17.625 6.03984 17.625ZM7.61953 13.5375L16.1203 5.03906L17.8383 6.75703L9.3375 15.2555L7.25391 15.6234L7.61953 13.5375Z"
        fill="#4F0A8A"
      />
    </svg>
  ),
  dolar: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
        fill="#4F0A8A"
      />
      <path
        d="M13.1181 11.4563L12.5228 11.318V8.17031C13.4134 8.29219 13.9642 8.85 14.0579 9.53438C14.0696 9.62813 14.1493 9.69609 14.2431 9.69609H15.2954C15.4056 9.69609 15.4923 9.6 15.4829 9.48984C15.34 8.02969 14.1376 7.09219 12.5321 6.93047V6.16406C12.5321 6.06094 12.4478 5.97656 12.3446 5.97656H11.686C11.5829 5.97656 11.4985 6.06094 11.4985 6.16406V6.9375C9.83916 7.09922 8.54072 8.01562 8.54072 9.72656C8.54072 11.3109 9.70792 12.075 10.9337 12.368L11.5126 12.5156V15.8602C10.4767 15.7219 9.89542 15.1688 9.77588 14.4234C9.76182 14.3344 9.68213 14.2688 9.59073 14.2688H8.50791C8.39775 14.2688 8.31104 14.3625 8.32041 14.4727C8.42588 15.7617 9.40323 16.9477 11.4892 17.1V17.8359C11.4892 17.9391 11.5735 18.0234 11.6767 18.0234H12.3423C12.4454 18.0234 12.5298 17.9391 12.5298 17.8336L12.5251 17.0906C14.3603 16.9289 15.6728 15.9469 15.6728 14.1844C15.6704 12.5578 14.6368 11.8313 13.1181 11.4563ZM11.5103 11.0766C11.379 11.0391 11.2689 11.0039 11.1587 10.9594C10.3665 10.6734 9.99854 10.2117 9.99854 9.61641C9.99854 8.76562 10.6431 8.28047 11.5103 8.17031V11.0766ZM12.5228 15.8672V12.7336C12.5954 12.7547 12.661 12.7711 12.729 12.7852C13.8376 13.1227 14.2103 13.5914 14.2103 14.3109C14.2103 15.2273 13.5212 15.7781 12.5228 15.8672Z"
        fill="#4F0A8A"
      />
    </svg>
  ),
  document: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7866 6.92233L14.8953 2.03104C14.759 1.89466 14.5749 1.81738 14.3817 1.81738H4.72635C4.32405 1.81738 3.99902 2.14241 3.99902 2.54471V21.4553C3.99902 21.8576 4.32405 22.1826 4.72635 22.1826H19.2729C19.6753 22.1826 20.0003 21.8576 20.0003 21.4553V7.43828C20.0003 7.24508 19.923 7.0587 19.7866 6.92233ZM18.3229 7.77239H14.0453V3.49479L18.3229 7.77239ZM18.3638 20.5461H5.63552V3.45387H12.4997V8.36335C12.4997 8.61653 12.6003 8.85934 12.7793 9.03837C12.9583 9.21739 13.2011 9.31797 13.4543 9.31797H18.3638V20.5461ZM11.8178 14.4093H7.63567C7.53566 14.4093 7.45384 14.4911 7.45384 14.5911V15.6821C7.45384 15.7821 7.53566 15.8639 7.63567 15.8639H11.8178C11.9178 15.8639 11.9997 15.7821 11.9997 15.6821V14.5911C11.9997 14.4911 11.9178 14.4093 11.8178 14.4093ZM7.45384 11.5V12.591C7.45384 12.691 7.53566 12.7728 7.63567 12.7728H16.3636C16.4636 12.7728 16.5455 12.691 16.5455 12.591V11.5C16.5455 11.4 16.4636 11.3181 16.3636 11.3181H7.63567C7.53566 11.3181 7.45384 11.4 7.45384 11.5Z"
        fill="#4F0A8A"
      />
    </svg>
  ),
  customerService: (
    <svg
      width="126"
      height="126"
      viewBox="0 0 126 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M34.3186 64.8851C34.0476 67.0125 33.7171 69.1598 33.4196 71.2542C33.0825 73.6658 32.7586 76.0641 32.4083 78.5946L33.0693 78.5483C37.3725 77.9471 41.6624 77.3525 46.0052 76.7777C46.4182 76.7346 46.8346 76.8151 47.2017 77.0089C53.0743 80.1901 59.7736 81.5181 66.4164 80.8177C73.0592 80.1174 79.3338 77.4216 84.4132 73.0857C89.4926 68.7498 93.1384 62.9774 94.8701 56.5291C96.6017 50.0808 96.338 43.2594 94.1135 36.964C92.4612 32.2681 89.7633 28.0086 86.2234 24.507C82.6836 21.0054 78.3943 18.3531 73.6793 16.7504C68.9642 15.1476 63.9465 14.6362 59.0048 15.2547C54.0632 15.8733 49.3266 17.6056 45.1525 20.321C36.1231 26.1286 31.1325 34.6053 30.1344 45.2954C29.5406 51.7619 30.9235 58.2562 34.1005 63.9205C34.2627 64.215 34.3383 64.5494 34.3186 64.8851Z"
          fill="#4F0A8A"
        />
        <path
          d="M59.9339 52.4814C58.2359 51.7674 56.791 50.5153 55.7574 48.8597C54.7132 47.1937 54.1648 45.2379 54.1648 43.1994C54.1648 37.7253 58.2042 33.2654 63.1613 33.2654C68.1183 33.2654 72.1578 37.7253 72.1578 43.1994C72.1578 45.2896 71.5777 47.2764 70.4808 48.9735L68.5824 47.7938C69.4472 46.459 69.9007 44.8654 69.9007 43.2097C69.9007 38.9464 66.8738 35.4799 63.1507 35.4799C59.4277 35.4799 56.4007 38.9464 56.4007 43.2097C56.4007 46.4279 58.1726 49.346 60.8093 50.4636L59.9339 52.4814Z"
          fill="white"
        />
        <path
          d="M75.5331 47.618H73.2866V45.4139H75.5331C76.1554 45.4139 76.6616 44.9172 76.6616 44.3067V42.1026C76.6616 41.4921 76.1554 40.9954 75.5331 40.9954H73.2866V38.7913H75.5331C77.3894 38.7913 78.9081 40.2814 78.9081 42.1026V44.3067C78.9081 46.1383 77.3999 47.618 75.5331 47.618Z"
          fill="white"
        />
        <path
          d="M53.0363 47.618H50.7898C48.9335 47.618 47.4148 46.1279 47.4148 44.3067V42.1026C47.4148 40.2814 48.9335 38.7913 50.7898 38.7913H53.0363V40.9954H50.7898C50.1675 40.9954 49.6613 41.4921 49.6613 42.1026V44.3067C49.6613 44.9172 50.1675 45.4139 50.7898 45.4139H53.0363V47.618Z"
          fill="white"
        />
        <path
          d="M75.5328 40.9953H73.2863V39.8881C73.2863 34.414 68.7406 29.9541 63.1613 29.9541C57.582 29.9541 53.0363 34.414 53.0363 39.8881V40.9953H50.7898V39.8881C50.7898 33.193 56.3375 27.75 63.1613 27.75C69.9851 27.75 75.5328 33.193 75.5328 39.8881V40.9953Z"
          fill="white"
        />
        <path
          d="M67.6645 56.4552H65.418C63.5617 56.4552 62.043 54.9651 62.043 53.1438C62.043 51.3226 63.5617 49.8325 65.418 49.8325H67.6645C69.5207 49.8325 71.0395 51.3226 71.0395 53.1438C71.0395 54.9651 69.5207 56.4552 67.6645 56.4552ZM65.4074 52.0366C64.7852 52.0366 64.2789 52.5333 64.2789 53.1438C64.2789 53.7544 64.7852 54.2511 65.4074 54.2511H67.6539C68.2762 54.2511 68.7824 53.7544 68.7824 53.1438C68.7824 52.5333 68.2762 52.0366 67.6539 52.0366H65.4074Z"
          fill="white"
        />
        <path
          d="M72.1581 54.2407H69.9116V52.0366H72.1581C72.7804 52.0366 73.2866 51.5399 73.2866 50.9293V46.5108H75.5331V50.9293C75.5331 52.7609 74.0249 54.2407 72.1581 54.2407Z"
          fill="white"
        />
        <path
          d="M63.1195 69.7003C56.9496 69.7003 49.9887 69.245 45.4113 63.7813L45 63.2846L45.232 62.6844C47.0988 57.7899 51.1699 53.9301 56.3906 52.0779L57.15 54.1578C52.7625 55.71 49.3137 58.8557 47.5734 62.8603C51.5285 67.1237 57.4137 67.4858 63.109 67.4858C68.8676 67.4858 74.8055 67.1133 78.7605 62.7362C77.7059 60.232 75.9762 58.0175 73.7402 56.3205L75.1219 54.5717C77.8113 56.6206 79.8574 59.311 81.0492 62.374C81.1231 62.5603 81.1652 62.7672 81.1652 62.9742V63.3674L80.8383 63.771C76.2504 69.2346 69.2895 69.7003 63.1195 69.7003ZM78.9504 63.1604C78.9504 63.1604 78.9504 63.1708 78.9504 63.1604C78.9504 63.1708 78.9504 63.1604 78.9504 63.1604Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="126"
          height="126"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="15" />
          <feGaussianBlur stdDeviation="15" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  emptyBox: (
    <svg
      width="72"
      height="45"
      viewBox="0 0 72 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M35.9999 44.1393C55.6477 44.1393 71.5754 40.681 71.5754 36.4149C71.5754 32.1489 55.6477 28.6906 35.9999 28.6906C16.352 28.6906 0.424316 32.1489 0.424316 36.4149C0.424316 40.681 16.352 44.1393 35.9999 44.1393Z"
          fill="#F0F0F0"
        />
        <path
          d="M61.5695 15.1922L50.2899 2.49994C49.7484 1.63481 48.958 1.11176 48.1253 1.11176H23.8739C23.0412 1.11176 22.2508 1.63481 21.7094 2.49883L10.4297 15.1933V25.3884H61.5695V15.1922Z"
          stroke="#C2BAE4"
          strokeWidth="1.11174"
        />
        <path
          d="M46.6867 18.6913C46.6867 16.9202 47.7918 15.4581 49.1626 15.457H61.5695V35.4709C61.5695 37.8136 60.102 39.7336 58.2899 39.7336H13.7093C11.8972 39.7336 10.4297 37.8125 10.4297 35.4709V15.457H22.8367C24.2074 15.457 25.3125 16.9169 25.3125 18.688V18.7123C25.3125 20.4834 26.4298 21.9135 27.7994 21.9135H44.1998C45.5694 21.9135 46.6867 20.4702 46.6867 18.6991V18.6913Z"
          fill="#FAFAFA"
          stroke="#C2BAE4"
          strokeWidth="1.11174"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="71.1511"
            height="44.4694"
            fill="white"
            transform="translate(0.424316)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  cross: (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.434018 18H3.62628C3.88101 18 4.1249 17.8811 4.29291 17.6774L8.99729 11.8189L13.7017 17.6774C13.8643 17.8811 14.1082 18 14.3683 18H17.5606C17.9291 18 18.1296 17.5528 17.8912 17.2585L11.2628 9L17.8966 0.741509C18.1351 0.44717 17.9345 0 17.566 0H14.3737C14.119 0 13.8751 0.118869 13.7071 0.322642L8.99729 6.18113L4.29291 0.322642C4.13032 0.118869 3.88643 0 3.62628 0H0.434018C0.0654716 0 -0.135061 0.44717 0.10341 0.741509L6.73182 9L0.10341 17.2585C0.0499906 17.3241 0.0157198 17.4043 0.00466538 17.4897C-0.00638901 17.575 0.00623675 17.6619 0.041046 17.74C0.0758553 17.818 0.131385 17.884 0.201042 17.9301C0.270699 17.9762 0.351557 18.0005 0.434018 18Z"
        fill="white"
      />
    </svg>
  ),
};

type Props = {
  name: string;
  color?: string;
};

const Icon: FC<Props> = ({ name, color }): ReactElement => {
  return (
    <span className={`icon ${color || ''}`}>{icons[name] || icons.search}</span>
  );
};

export default Icon;
