import { AppliedOffer } from 'js/interfaces/admin';
import {
  AdaptedReport,
  ReportFrsData,
  AdaptedSummary,
  SummaryVariable,
  OfferCategoryEnum,
  ReportProductTypeEnum,
} from 'js/interfaces/report';
import { getSummariesContent } from 'js/helpers/Report/frsSummariesContent';
import { formatPrice } from 'js/helpers/formatters';

const getSummaries = (variables: SummaryVariable[]) => {
  const countOverdueInstallmentsBik = Number(
    variables.find(({ name }) => name === 'countOverdueInstallmentsBik')
      ?.value ?? 0
  );
  const maxDelayDaysBik = Number(
    variables.find(({ name }) => name === 'maxDelayDaysBik')?.value ?? 0
  );
  const countNegativeInformationImKrdErif = Number(
    variables.find(({ name }) => name === 'countNegativeInformationImKrdErif')
      ?.value ?? 0
  );
  const creditsNoClosePsnBik = Number(
    variables.find(({ name }) => name === 'creditsNoClosePsnBik')?.value ?? 0
  );
  const averageInflowCleared = Number(
    variables.find(({ name }) => name === 'avgSalaryCompensationB2bKontoAgr')
      ?.value ?? 0
  );
  const currentDebt = Number(
    variables.find(({ name }) => name === 'monthlyChargerBik')?.value ?? 0
  );
  const accounts = Number(
    variables.find(({ name }) => name === 'creditsNoCloseBik')?.value ?? 0
  );
  const sum = Number(
    variables.find(({ name }) => name === 'sumOverdueInstallmentsBik')?.value ??
      0
  );

  const summariesContent = getSummariesContent(
    countOverdueInstallmentsBik,
    maxDelayDaysBik,
    countNegativeInformationImKrdErif,
    creditsNoClosePsnBik
  );

  const data = summariesContent?.map((summaryContent) => {
    const summaryVariable = variables.find(
      (variable) => variable.name === summaryContent.key
    );

    const key = `${summaryVariable?.value}`;

    if (!!summaryVariable) {
      const content = summaryContent.content[key];
      const summary: AdaptedSummary = {
        code: summaryContent.code,
        name: summaryContent.name,
        description: summaryContent.description,
        grade: content?.grade,
        gradeDescription: content.description,
        mark: content.mark,
        recommendations: content.recommendations,
        sum,
        averageInflowCleared,
        currentDebt,
        accounts,
        obligations: [],
      };
      return summary;
    }
    return null;
  });

  return (
    data.filter((report): report is AdaptedSummary => report !== null) ?? []
  );
};

const getOffers = (
  appId: number,
  variables: SummaryVariable[],
  reportAppliedOffers?: AppliedOffer[]
) => {
  const displayOffer =
    variables.find(({ name }) => name === 'taskRelationDecisionValue')
      ?.value !== 'false';

  if (!displayOffer) {
    return [];
  }

  const applied = !!reportAppliedOffers?.find(
    (appliedOffer) =>
      appliedOffer?.company?.toLocaleLowerCase() === 'wealthon' &&
      appliedOffer.category === OfferCategoryEnum.bankOffer
  );

  const grossAmount = Number(
    variables.find(({ name }) => name === 'taskScoringAmountPeriodValue')
      ?.value ?? 0
  );

  const tenor = Number(
    variables.find(({ name }) => name === 'periodMatrixWealthon')?.value ?? 0
  );

  const chance =
    variables.find(({ name }) => name === 'sumScoringWealthonBpmn')?.value !==
    'false'
      ? 3
      : 1;

  const installmentAmount = Number(
    variables.find(({ name }) => name === 'installemntFromWealthon')?.value ?? 0
  );

  const amountToPay = Number(
    variables.find(({ name }) => name === 'amountToPayWealthon')?.value ?? 0
  );

  const data = {
    appId,
    company: 'wealthon',
    logo: '/images/wealthon.png',
    category: OfferCategoryEnum.bankOffer,
    grossAmount: formatPrice(grossAmount),
    tenor,
    installmentAmount: formatPrice(installmentAmount),
    amountToPay: formatPrice(amountToPay),
    chance,
    productType: ReportProductTypeEnum['InstallmentBusiness'],
    applied,
  };

  return [data];
};

const getPotentialFactoringOffers = (
  appId: number,
  variables: SummaryVariable[],
  reportAppliedOffers?: AppliedOffer[]
) => {
  const offers = [];

  const isIdeaMoney =
    variables.find(({ name }) => name === 'taskExternalOffersIdeaMoneyValue')
      ?.value === 'true';

  const isMonevia =
    variables.find(({ name }) => name === 'taskExternalOffersMoneviaValue')
      ?.value === 'true';

  const isBibbyFinancial =
    variables.find(({ name }) => name === 'taskExternalOffersBibbyValue')
      ?.value === 'true';

  const isSmeo =
    variables.find(({ name }) => name === 'taskExternalOffersSmeoValue')
      ?.value === 'true';

  if (isIdeaMoney) {
    const name = 'Idea Money';

    const applied = !!reportAppliedOffers?.find(
      (appliedOffer) =>
        appliedOffer?.company?.toLocaleLowerCase() ===
          name.toLocaleLowerCase() &&
        appliedOffer.category === OfferCategoryEnum.factoring
    );

    const data = {
      appId,
      type: 'Faktoring',
      offerType: OfferCategoryEnum.factoring,
      category: 'Faktoring',
      company: name,
      link: 'https://simple.ideamoney.pl',
      logo: '/images/IdeaMoney.png',
      applied,
    };

    offers.push(data);
  }

  if (isMonevia) {
    const name = 'Monevia';

    const applied = !!reportAppliedOffers?.find(
      (appliedOffer) =>
        appliedOffer?.company?.toLocaleLowerCase() ===
          name.toLocaleLowerCase() &&
        appliedOffer.category === OfferCategoryEnum.factoring
    );

    const data = {
      appId,
      type: 'Faktoring',
      offerType: OfferCategoryEnum.factoring,
      category: 'Faktoring',
      company: name,
      link: 'https://www.monevia.pl/monevia-faktoring-dla-malych-firm/?contract=201A1D1A19201D1F1A1C18',
      logo: '/images/Monevia.jpeg',
      applied,
    };

    offers.push(data);
  }

  if (isBibbyFinancial) {
    const name = 'Bibby Financial Services';

    const applied = !!reportAppliedOffers?.find(
      (appliedOffer) =>
        appliedOffer?.company?.toLocaleLowerCase() ===
          name.toLocaleLowerCase() &&
        appliedOffer.category === OfferCategoryEnum.factoring
    );

    const data = {
      appId,
      type: 'Faktoring',
      offerType: OfferCategoryEnum.factoring,
      category: 'Faktoring',
      company: name,
      link: 'https://www.bibbyfinancialservices.pl/kampanie/faktoring-simple?utm_source=-&utm_medium=-&utm_campaign=introducers&utm_term=simple',
      logo: '/images/Bibby.png',
      applied,
    };

    offers.push(data);
  }

  if (isSmeo) {
    const name = 'SMEO';

    const applied = !!reportAppliedOffers?.find(
      (appliedOffer) =>
        appliedOffer?.company?.toLocaleLowerCase() ===
          name.toLocaleLowerCase() &&
        appliedOffer.category === OfferCategoryEnum.factoring
    );

    const data = {
      appId,
      type: 'Faktoring',
      offerType: OfferCategoryEnum.factoring,
      category: 'Faktoring',
      company: name,
      link: '',
      logo: '/images/smeo.png',
      applied,
    };

    offers.push(data);
  }

  return offers;
};

const getPotentialLoanOffers = (
  appId: number,
  variables: SummaryVariable[],
  reportAppliedOffers?: AppliedOffer[]
) => {
  const offers = [];

  const isSantander =
    variables.find(({ name }) => name === 'taskExternalOffersSantanderValue')
      ?.value === 'true';

  const isNest =
    variables.find(({ name }) => name === 'taskExternalOffersNestBankValue')
      ?.value === 'true';

  if (isSantander) {
    const name = 'Santander Bank Polska S.A.';

    const applied = !!reportAppliedOffers?.find(
      (appliedOffer) =>
        appliedOffer?.company?.toLocaleLowerCase() ===
          name.toLocaleLowerCase() &&
        appliedOffer.category === OfferCategoryEnum.bankOffer
    );

    const data = {
      appId,
      type: 'Pożyczka',
      offerType: OfferCategoryEnum.bankOffer,
      category: 'Oferta bankowa',
      company: name,
      link: 'https://simplepl.ebrokerpartner.pl/short/5frrv',
      logo: '/images/Santander.jpeg',
      applied,
    };

    offers.push(data);
  }

  if (isNest) {
    const name = 'Nest Bank';

    const applied = !!reportAppliedOffers?.find(
      (appliedOffer) =>
        appliedOffer?.company?.toLocaleLowerCase() ===
          name.toLocaleLowerCase() &&
        appliedOffer.category === OfferCategoryEnum.bankOffer
    );

    const data = {
      appId,
      type: 'Pożyczka',
      offerType: OfferCategoryEnum.bankOffer,
      category: 'Oferta bankowa',
      company: name,
      link: 'https://finansowanie.nestbank.pl/?FieldString101=DIMA_SIMPLE',
      logo: '/images/NestBank.png',
      applied,
    };

    offers.push(data);
  }

  return offers;
};

export const adaptFrsData = (
  report: ReportFrsData,
  applied?: AppliedOffer[]
) => {
  const rating = Number(
    report.variables.find(
      (variable) => variable.name === 'ratingSimplePlBpmnValue'
    )?.value ?? 0
  );

  const amount = Number(
    report.variables.find((variable) => variable.name === 'dtiSimple')?.value ??
      0
  );

  const data: AdaptedReport = {
    id: report.id,
    date: report.date,
    expirationDate: report.expirationDate,
    rating: rating < 0 ? 0 : rating,
    amount,
    amountIndicator: 'do ',
    offers: getOffers(report.applicationId, report.variables, applied),
    loanPotentialOffers: getPotentialLoanOffers(
      report.applicationId,
      report.variables,
      applied
    ),
    factoringPotentialOffers: getPotentialFactoringOffers(
      report.applicationId,
      report.variables,
      applied
    ),
    summaries: getSummaries(report.variables),
    client: {
      nip: report.company.nip ?? '',
      companyName: report.company.name ?? '',
      email: report.user.email,
      firstName: report.user.firstName ?? '',
      lastName: report.user.lastName ?? '',
    },
  };

  return data;
};
