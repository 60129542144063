import { StringifiableRecord } from 'query-string';
import { all } from 'redux-saga/effects';
import { call, put, select } from 'redux-saga/effects';

import API from 'js/services/api';

import authSaga from 'js/redux/auth/sagas';
import accountSaga from 'js/redux/account/sagas';
import adminSaga from 'js/redux/admin/sagas';
import bankingSaga from 'js/redux/banking/sagas';
import passwordSaga from 'js/redux/password/sagas';
import reportSaga from 'js/redux/report/sagas';
import smeoSaga from 'js/redux/smeo/sagas';
import { RootState } from 'js/redux/reducers';

export interface DefaultApiAction {
  id?: string | number;
  type: string;
  error?: unknown;
  payload: {
    id?: unknown;
    data?: unknown;
  };
  data?: StringifiableRecord;
  formData?: FormData;
}

export function createSaga(
  prefix: string,
  endpoint: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET'
) {
  return function* (action: DefaultApiAction): unknown {
    const id = action.id;
    const paramsEndpoint = id ? `${endpoint}/${id}` : endpoint;

    const getToken = (state: RootState) => state.Auth.token;
    const token: RootState['Auth']['token'] = yield select(getToken);

    try {
      const response = yield call(
        API.call,
        token,
        paramsEndpoint,
        method,
        action.data,
        action.formData
      );

      const responseBody = yield call([response, 'json']);

      if (response.status >= 400) {
        yield put({
          type: `${prefix}_FAILURE`,
          payload: responseBody,
          id,
        });
      } else {
        yield put({
          type: `${prefix}_SUCCESS`,
          payload: responseBody,
        });
      }
    } catch (e) {
      if (e instanceof Error)
        yield put({ type: `ERROR`, payload: e.message, id });
      else yield put({ type: `ERROR`, id });
    }
  };
}

export default function* rootSaga() {
  yield all([
    authSaga(),
    accountSaga(),
    adminSaga(),
    bankingSaga(),
    passwordSaga(),
    reportSaga(),
    smeoSaga(),
  ]);
}
