import { FC, useCallback, useEffect, useState } from 'react';

import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';

import { Form, Input, FormInstance } from 'antd';

import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { SubmitBtn } from 'js/components/Buttons';

import { PasswordData } from 'js/pages/Account';

import { validatePassword, validateNewPassword } from 'js/helpers/validators';

import SmsCode from 'js/components/SmsCode';
import { changePassword, verifyPassword } from 'js/redux/password/actions';

import { objectsEquals } from 'js/helpers/objectsEquals';

import 'js/views/Register/Sms/styles.scss';

type Props = {
  loading: boolean;
  data: PasswordData;
  verified: boolean;
};

const AccountPassword: FC<Props> = ({ loading, data, verified }) => {
  const [form]: [FormInstance<PasswordData>] = Form.useForm();
  const dispatch: Dispatch = useDispatch();
  const [canSave, setCanSave] = useState(false);

  const handleChange = useCallback(() => {
    const values = form.getFieldsValue();

    const newData = {
      ...values,
      smsCode: values.smsCode || '',
      newPassword: values.newPassword || '',
    };

    const equals = !!objectsEquals(data, newData);

    setCanSave(!equals);
  }, [form, data]);

  const handleSubmit = (values: PasswordData) => {
    if (values.smsCode) {
      dispatch(changePassword(values));
      form.setFieldsValue({ smsCode: '' });
    } else {
      dispatch(verifyPassword(values));
    }
  };

  useEffect(() => {
    form.setFieldsValue(data);
    setCanSave(false);
  }, [data, form]);

  return (
    <>
      <Box type="content" className="settings-form sms-form">
        <h2>Zmień hasło</h2>
        <Form
          form={form}
          onFinish={handleSubmit}
          onChange={handleChange}
          scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
        >
          <Form.Item
            name="currentPassword"
            label="Aktualne hasło"
            rules={[
              {
                required: true,
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password
              size="large"
              placeholder="Wpisz aktualne hasło..."
            />
          </Form.Item>

          <Form.Item
            name="newPassword"
            label="Nowe hasło"
            dependencies={['currentPassword']}
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  validateNewPassword(
                    value,
                    form.getFieldsValue().currentPassword
                  ),
              },
            ]}
          >
            <Input.Password size="large" placeholder="Wpisz nowe hasło..." />
          </Form.Item>

          {!verified && (
            <BtnWrapper>
              <SubmitBtn disabled={!canSave} loading={loading}>
                Zapisz zmiany
              </SubmitBtn>
            </BtnWrapper>
          )}

          {verified && <SmsCode loading={loading} />}
        </Form>
      </Box>
    </>
  );
};

export default AccountPassword;
