import { AppId } from 'js/interfaces';

export enum BankEnum {
  POST_KONTOMATIK_REQUEST = 'POST_KONTOMATIK_REQUEST',
  POST_KONTOMATIK_SUCCESS = 'POST_KONTOMATIK_SUCCESS',
  POST_KONTOMATIK_FAILURE = 'POST_KONTOMATIK_FAILURE',

  KONTOMATIK_READY_REQUEST = 'KONTOMATIK_READY_REQUEST',
  KONTOMATIK_READY_SUCCESS = 'KONTOMATIK_READY_SUCCESS',
  KONTOMATIK_READY_FAILURE = 'KONTOMATIK_READY_FAILURE',

  CLEAR_KONTOMATIK = 'CLEAR_KONTOMATIK',
  SET_BANKING_STATUS = 'SET_BANKING_STATUS',
}

export interface BankState {
  loading: boolean;
  status: Status;
  lastBank: string;
  bankList: string[];
  isKontomatikReady: boolean;
}

export type Status = 'info' | 'login' | 'success' | 'failure' | 'repeated';

export type PostKontomatikData = {
  applicationId: number | null;
  target: string;
  sessionId: string;
  sessionIdSignature: string;
  options: {
    officialName: string;
    multipleImportId: boolean;
  };
};

// POST KONTOMATIK BANK
interface PostKontomatikAction {
  type: BankEnum.POST_KONTOMATIK_REQUEST;
  method: 'POST';
  data: PostKontomatikData;
}

interface PostKontomatikSuccess {
  type: BankEnum.POST_KONTOMATIK_SUCCESS;
  payload: {
    status: 201;
    code: 'Created';
    data: [];
  };
}

interface PostKontomatikFailure {
  type: BankEnum.POST_KONTOMATIK_FAILURE;
  payload:
    | {
        status: 400;
        code: 'Bad Request';
        errors: string[];
      }
    | {
        status: 422;
        code: 'Unprocessable Entity';
        errors: {
          sessionId: string[];
          sessionIdSignature: string[];
          target: string[];
        };
      };
}

// FINISH ADDING BANKS
interface FinishKontomatikAction {
  type: BankEnum.KONTOMATIK_READY_REQUEST;
  method: 'POST';
  data: AppId;
}

interface FinishKontomatikSuccess {
  type: BankEnum.KONTOMATIK_READY_SUCCESS;
  payload: {
    status: 200;
    code: 'OK';
    data: [];
  };
}

interface FinishKontomatikFailure {
  type: BankEnum.KONTOMATIK_READY_FAILURE;
  payload:
    | {
        status: 400;
        code: 'Bad Request';
        errors: string[];
      }
    | {
        status: 422;
        code: 'Unprocessable Entity';
        errors: string[];
      };
}

interface SetBankingStatusAction {
  type: BankEnum.SET_BANKING_STATUS;
  data: Status;
}

interface ClearKontomatikAction {
  type: BankEnum.CLEAR_KONTOMATIK;
}

export type BankingActionTypes =
  | PostKontomatikAction
  | FinishKontomatikAction
  | SetBankingStatusAction
  | ClearKontomatikAction;

export type BankingApiTypes =
  | PostKontomatikSuccess
  | PostKontomatikFailure
  | FinishKontomatikSuccess
  | FinishKontomatikFailure;
