import { FC, useMemo } from 'react';
import { RootState } from 'js/redux/reducers';
import { useSelector } from 'react-redux';

import Steps from 'js/components/Steps';
import Box from 'js/components/Box';
import PersonalForm from 'js/views/Data/Personal';

export type Data = {
  idCard: string;
  pesel: string;
};

const PersonalData: FC = () => {
  const { loading, user, isRefresh } = useSelector(
    (state: RootState) => state.Account
  );

  const defaultData: Data = useMemo(
    () => ({
      idCard: user.idCard,
      pesel: user.pesel,
    }),
    [user]
  );

  return (
    <>
      <Steps active="data" />
      <Box type="heading">
        <h3>
          {isRefresh ? 'Potwierdź' : 'Podaj'} numery dowodu oraz PESEL, abyśmy
          mogli pobrać Twoją historie z baz danych gospodarczych
        </h3>
        <p>
          Każdy bank, zanim udzieli Ci kredytu, będzie sprawdzał Twoją historię
          finansową w zewnętrznych bazach danych (BIG Infomonitor, BIG ERIF, KRD
          i innych). Dane z tych baz są wykorzystywane do szacowania Twojej
          zdolność kredytowej i scoringu. Podane przez Ciebie numery dowodu i
          PESEL są potrzebne do pobrania danych z zewnętrznych baz.
        </p>
      </Box>
      <PersonalForm data={defaultData} loading={loading} />
    </>
  );
};

export default PersonalData;
