import { sanctumHandlers } from 'js/mocks/handlers/sanctum';
import { authHandlers } from 'js/mocks/handlers/auth';
import { accountHandlers } from 'js/mocks/handlers/account';
import { kontomatikHandlers } from 'js/mocks/handlers/kontomatik';
import { reportHandlers } from 'js/mocks/handlers/report';
import { smeoHandlers } from 'js/mocks/handlers/smeo';

export const handlers = [
  ...sanctumHandlers,
  ...authHandlers,
  ...accountHandlers,
  ...kontomatikHandlers,
  ...reportHandlers,
  ...smeoHandlers,
];
