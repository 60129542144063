import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'js/redux/reducers';
import { getContract, signInContract } from 'js/redux/smeo/actions';
import { routes } from 'js/routes';

import { Result } from 'antd';
import Loader from 'js/components/Loader';
import BtnWrapper from 'js/components/BtnWrapper';
import { PrevBtn, SubmitBtn } from 'js/components/Buttons';
import 'js/views/Smeo/Agreement/styles.scss';

const SmeoAgreement: FC = () => {
  const { app, user } = useSelector((state: RootState) => state.Account);
  const { agreement, loading, processNumber } = useSelector(
    (state: RootState) => state.Smeo
  );

  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    if (!agreement.name && processNumber && app.id)
      dispatch(getContract({ applicationId: app.id, processNumber }));
  }, [agreement.name, app.id, processNumber, dispatch]);

  const handleSubmit = () => {
    dispatch(
      signInContract({
        applicationId: app.id as number,
        processNumber,
        signatories: [
          {
            pesel: user.pesel,
            firstName: user.firstName,
            surname: user.lastName,
          },
        ],
      })
    );
  };

  if (loading)
    return (
      <div className="smeo-agreement">
        <Loader text={!agreement.name ? 'Trwa wczytywanie umowy' : undefined} />
      </div>
    );

  if (!agreement.name)
    return (
      <div className="smeo-agreement">
        <h2 className="smeo-agreement__error-title">Wystąpił błąd</h2>
        <h3 className="smeo-agreement__error-subtitle">Odśwież stronę.</h3>
        <h3 className="smeo-agreement__error-subtitle">
          Jeśli problem nadal się pojawia, skontatkuj się z nami.
        </h3>
        <Result status="error" />
      </div>
    );

  return (
    <div className="smeo-agreement">
      <div className="smeo-agreement__pdf-wrapper">
        {agreement?.data && (
          <embed
            title={agreement.name}
            src={`data:application/pdf;base64,${agreement.data}`}
            height="100%"
            width="100%"
          />
        )}
      </div>
      <div className="smeo-agreement__attachment-wrapper">
        <p className="smeo-agreement__attachment-title">Załączniki do umowy:</p>
        <a
          className="smeo-agreement__attachment"
          href="https://smeo.pl/dokumenty-do-pobrania/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Regulamin świadczenia usług
        </a>
        <a
          className="smeo-agreement__attachment"
          href="https://www.smeo.pl/wp-content/uploads/pdf/smeo-tabela-oplat-i-prowizji.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          Tabela prowizji i opłat
        </a>
      </div>

      <BtnWrapper className="smeo-agreement__btn-wrapper">
        <PrevBtn to={routes.report}>Nie zgadzam się i anuluję wniosek</PrevBtn>
        <SubmitBtn onClick={handleSubmit}>
          Zgadzam się na warunki umowy
        </SubmitBtn>
      </BtnWrapper>
    </div>
  );
};

export default SmeoAgreement;
