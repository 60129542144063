import { RestRequest } from 'msw';
import * as dbAccount from 'js/mocks/db/account';

export const getToken = (req: RestRequest) => {
  return req.headers.all().authorization?.split('Bearer ')[1];
};

export const getAccount = (req: RestRequest) => {
  const token = getToken(req);
  return dbAccount.read(token);
};
