import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'js/redux/reducers';

import SmeoSteps from 'js/components/Steps/smeo';
import Provider from 'js/components/Provider';

import Limits from 'js/views/Smeo/Limits';

import 'js/pages/Smeo/styles.scss';

const SmeoLimits: FC = () => {
  const { limit, limitOnline } = useSelector((state: RootState) => state.Smeo);

  return (
    <div className="smeo-limits">
      <SmeoSteps />
      <h1>Otrzymujesz dostęp do faktoringu</h1>
      <h2>Podpisz umowę i sfinansuj fakturę!</h2>
      {limit && limitOnline && (
        <Limits limit={limit} limitOnline={limitOnline} loading={false} />
      )}
      <Provider company="smeo" />
    </div>
  );
};

export default SmeoLimits;
