import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import { RootState } from 'js/redux/reducers';
import { logoutUser } from 'js/redux/auth/actions';

import { SubmitBtn } from 'js/components/Buttons';
import Icon from 'js/components/Icon';
import CancelUpdate from 'js/components/CancelUpdate';
import { routes } from 'js/routes';

import { App } from 'js/interfaces/account';

import 'js/views/Header/styles.scss';

const Header: FC = () => {
  const { user, app, applications } = useSelector(
    (state: RootState) => state.Account
  );
  const { isAdmin } = useSelector((state: RootState) => state.Auth);
  const dispatch: Dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const hasAnyReport = useMemo(() => {
    if (!!applications?.length)
      return (
        !!applications?.find((application: App) => application.hasKontomatik) ||
        app.hasKontomatik
      );
    return false;
  }, [applications, app]);

  const isUpdating = useMemo(() => {
    return (
      user.email &&
      applications?.length > 1 &&
      applications.find((application) => !application.hasReport)
    );
  }, [user.email, applications]);

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch({ type: 'RESET_APP' });
    setIsOpen(false);
  };

  return (
    <>
      <header className="header">
        <div className="header__inside">
          <div className="header__inside--left">
            <Icon name="logo" />
            {isAdmin && <NavLink to={routes.search}>Wyszukaj raport</NavLink>}
            {!!user.email && hasAnyReport && !isUpdating && (
              <>
                <NavLink to={app.hasReport ? routes.report : routes.status}>
                  Raport
                </NavLink>

                <NavLink to={routes.account}>Ustawienia konta</NavLink>
              </>
            )}
          </div>
          <div className="header__inside--right">
            {!user.email && (
              <>
                <a href="tel:+48780080360">
                  Skontaktuj się z naszym agentem <br />
                  +48 780 080 360
                </a>
                <Icon name="chat" />
              </>
            )}

            {(!!user.email || isAdmin) && (
              <SubmitBtn onClick={handleLogout}>Wyloguj</SubmitBtn>
            )}

            <button onClick={() => setIsOpen(true)} className="hamburger">
              <span />
              <span />
              <span />
            </button>
          </div>
        </div>

        {isOpen && (
          <button onClick={() => setIsOpen(false)} className="overlay" />
        )}
        <aside className={`menu ${isOpen ? 'open' : ''}`}>
          <button className="menu__close" onClick={() => setIsOpen(false)}>
            <Icon name="cross" />
          </button>

          <ul>
            {!!user.email && hasAnyReport && (
              <>
                <li>
                  <NavLink onClick={() => setIsOpen(false)} to={routes.account}>
                    Ustawienia konta
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    onClick={() => setIsOpen(false)}
                    to={app.hasReport ? routes.report : routes.status}
                  >
                    Raport
                  </NavLink>
                </li>
              </>
            )}
            {isAdmin && (
              <li>
                <NavLink onClick={() => setIsOpen(false)} to={routes.search}>
                  Wyszukaj raport
                </NavLink>
              </li>
            )}
            {!!user.email && (
              <li>
                <SubmitBtn onClick={handleLogout}>Wyloguj</SubmitBtn>
              </li>
            )}
          </ul>

          <div>
            <Icon name="chat" />
            <a href="tel:+48780080360">
              Skontaktuj się <br /> z naszym agentem: <br />{' '}
              <span>+48 780 080 360</span>
            </a>
          </div>
        </aside>
      </header>
      {isUpdating && <CancelUpdate />}
    </>
  );
};

export default Header;
