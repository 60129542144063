import { REHYDRATE } from 'redux-persist';
import { RehydrateRootState } from 'js/redux/reducers';
import {
  AuthEnum,
  AuthState,
  AuthActionTypes,
  AuthApiTypes,
} from 'js/redux/auth/types';

import { notification } from 'antd';

export const INIT_STATE: AuthState = {
  isAdmin: false,
  loading: false,
  token: '',
};

export default function reducer(
  state = INIT_STATE,
  action: AuthActionTypes | AuthApiTypes | RehydrateRootState
): AuthState {
  if (state === undefined) {
    return INIT_STATE;
  }

  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        ...action.payload?.Auth,
        loading: false,
      };

    case AuthEnum.REGISTER_REQUEST:
      document.cookie = 'XSRF-TOKEN=; expires = Thu, 01 Jan 1970 00:00:00 GMT';
      return {
        ...state,
        loading: true,
        token: '',
      };

    case AuthEnum.REGISTER_SUCCESS:
      notification['success']({
        message: 'Zarejestrowano pomyślnie',
      });
      return {
        ...state,
        loading: false,
        token: action.payload.data.token,
      };

    case AuthEnum.REGISTER_FAILURE:
      notification['error']({
        message: 'Błąd podczas rejestracji',
        description: Object.values(action?.payload?.errors)[0][0] ?? '',
      });
      return {
        ...state,
        loading: false,
      };

    case AuthEnum.LOGIN_REQUEST:
      document.cookie = 'XSRF-TOKEN=; expires = Thu, 01 Jan 1970 00:00:00 GMT';
      return {
        ...state,
        loading: true,
        token: '',
      };

    case AuthEnum.LOGIN_SUCCESS:
      notification['success']({
        message: 'Zalogowano pomyślnie',
      });
      return {
        ...state,
        isAdmin: action.payload.data.isAdmin,
        loading: false,
        token: action.payload.data.token,
      };

    case AuthEnum.LOGIN_FAILURE:
      notification['error']({
        message: 'Błąd podczas logowania',
        description: action.payload.errors[0] ?? '',
      });
      return {
        ...state,
        loading: false,
      };

    case AuthEnum.LOGOUT_REQUEST:
      return {
        ...state,
        token: '',
      };

    case AuthEnum.LOGOUT_SUCCESS:
      notification['success']({
        message: 'Wylogowano pomyślnie',
      });
      return {
        ...state,
      };

    case AuthEnum.LOGOUT_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
}
