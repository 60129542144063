import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { Data } from 'js/pages/Data/check-data';
import { confirmNip } from 'js/redux/account/actions';

import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { PrevBtn, SubmitBtn } from 'js/components/Buttons';
import { routes } from 'js/routes';

import 'js/views/Data/DataCheck/styles.scss';

type Props = {
  loading: boolean;
  verified: boolean;
  data: Data;
};

const DataCheck: FC<Props> = ({ loading, data, verified }) => {
  const { nip, name, street, houseNo, apartmentNo, postCode, city } = data;
  const dispatch: Dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = () => {
    !verified ? dispatch(confirmNip(data)) : history.push(routes.company);
  };

  return (
    <Box type="content">
      <div className="check-data">
        <div className="check-data__info">
          <h4>NIP</h4>
          <h3>{nip}</h3>
        </div>
        <div className="check-data__info">
          <h4>Nazwa firmy</h4>
          <h3>{name}</h3>
        </div>
        <div className="check-data__info">
          <h4>Adres</h4>
          <h3>
            {street || ''} {houseNo}
            {apartmentNo ? `/ ${apartmentNo}` : ''} {postCode}
            {city ? `, ${city}` : ''}
          </h3>
        </div>{' '}
      </div>

      <BtnWrapper>
        <PrevBtn to={routes.nip}>Popraw NIP</PrevBtn>
        <SubmitBtn onClick={handleSubmit} loading={loading}>
          Zatwierdź
        </SubmitBtn>
      </BtnWrapper>
    </Box>
  );
};

export default DataCheck;
