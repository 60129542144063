import { FC } from 'react';
import { routes } from 'js/routes';

import Steps from 'js/components/Steps';
import { NextBtn } from 'js/components/Buttons';
import BtnWrapper from 'js/components/BtnWrapper';
import 'js/pages/Welcome/styles.scss';

const Welcome: FC = () => (
  <>
    <Steps />
    <div className="welcome-page">
      <img alt="Ada Knapik" src="/images/woman.png" />
      <div className="welcome-page__description">
        <h1>Dzień dobry, </h1>
        <h3>
          Nazywam się Ada Knapik <br /> i jestem ekspertem finansowym Simple.pl.
          Przygotuję dla Ciebie analizę Twojej zdolności kredytowej oraz prześlę
          dopasowane do Twoich potrzeb oferty finansowania.
        </h3>
      </div>
      <BtnWrapper className="welcome-page__button-wrapper">
        <NextBtn to={routes.register}>Rozpocznij</NextBtn>
      </BtnWrapper>
    </div>
  </>
);

export default Welcome;
