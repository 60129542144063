import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'js/redux/reducers';

import useWfUser from 'js/hooks/useWfUser';

import Steps from 'js/components/Steps';
import LoginForm from 'js/views/Register/Login';
import Box from 'js/components/Box';

const Login: FC = () => {
  const { loading } = useSelector((state: RootState) => state.Auth);
  const { loading: accLoading, wfUser } = useSelector(
    (state: RootState) => state.Account
  );

  useWfUser();

  return (
    <>
      <Steps active="register" />
      <Box type="heading">
        <h3>Zaloguj się</h3>
      </Box>
      <LoginForm loading={loading || accLoading} wfUser={wfUser} />
    </>
  );
};

export default Login;
