import { FC } from 'react';

import { routes } from 'js/routes';

import Box from 'js/components/Box';
import BtnWrapper from 'js/components/BtnWrapper';
import { PrevBtn, SubmitBtn } from 'js/components/Buttons';

import Provider from 'js/components/Provider';
import SmeoSteps from 'js/components/Steps/smeo';

import 'js/views/Smeo/Regulations/styles.scss';

type Props = {
  onHide: () => void;
};

const SmeoRegulations: FC<Props> = ({ onHide }) => {
  return (
    <>
      <SmeoSteps active="application" />
      <Box type="content" className="wider wider--content smeo-regulamin">
        <h3>
          Klikając „dalej” potwierdzasz, iż zostałeś poinformowany, że Twoje
          dane:
          <span>
            Imię, Nazwisko, Numer telefonu, Adres email, Nip, Pesel, Numer
            dowodu osobistego, data wydania oraz data ważności dowodu
            osobistego, Adres zamieszkania, Wykształcenie, Stan cywilny, Status
            mieszkaniowy, Rodzaj prowadzenia księgowości
          </span>{' '}
          zostaną przekazane do SMEO S.A. z siedzibą w Warszawie przy ulicy
          Emilii Plater nr 53 XIV piętro 00-113 Warszawa wpisana do rejestru
          przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd
          Rejonowy dla m. st. Warszawy w Warszawie XII Wydział Gospodarczy
          Krajowego Rejestru Sądowego pod numerem KRS 0000 679116 REGON:
          367336046 NIP: 5252712429 w celu ukończenia procesu wnioskowania o
          faktoring, <br /> zgodnie z{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://simple.pl/regulamin-swiadczenia-uslug-przez-simple-pl-sp-z-o-o/"
          >
            regulaminem usługi
          </a>
          .
        </h3>
        <BtnWrapper>
          <PrevBtn to={routes.report}>Anuluj</PrevBtn>
          <SubmitBtn onClick={() => onHide()}>Zatwierdź</SubmitBtn>
        </BtnWrapper>
      </Box>
      <Provider company="smeo" />
    </>
  );
};

export default SmeoRegulations;
