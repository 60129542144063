import { routes } from 'js/routes';

const registerOrder = {
  0: routes.editNumber,
  1: routes.verify,
  2: routes.nip,
  3: routes.check,
  4: routes.company,
  5: routes.personal,
  6: routes.self,
  7: routes.address,
  8: routes.consents,
  9: routes.transactions,
};

const smeoOrder = {
  0: routes.smeoLoading,
  1: routes.smeoConsents,
  2: routes.smeoLoading,
  3: routes.smeoCompanyData,
  4: routes.smeoLoading,
  5: routes.smeoLimits,
  6: routes.smeoBank,
  7: routes.smeoLoading,
  8: routes.smeoAgreement,
  9: routes.smeoFinish,
};

export { registerOrder, smeoOrder };
