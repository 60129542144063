import { FC } from 'react';

import Box from 'js/components/Box';
import ObligationArray from 'js/components/Report/RatingModal/obligationArray';
import ObligationTable from 'js/components/Report/RatingModal/obligationTable';
import ReportModalTitle from 'js/components/Report/RatingModal/title';

import { Modal, Result } from 'antd';

import { BulbOutlined, InfoCircleOutlined } from '@ant-design/icons';

import {
  CodeTableHeadingEnum,
  CodeEnum,
  AdaptedSummary,
  GradeValueEnum,
} from 'js/interfaces/report';

import { formatPrice } from 'js/helpers/formatters';

import 'js/components/Report/RatingModal/styles.scss';

type Props = {
  show: boolean;
  onClose: () => void;
  data: AdaptedSummary;
};

const RatingModal: FC<Props> = ({ data, show, onClose }) => {
  return (
    <Modal
      width={1000}
      className="rating-modal"
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      title={<ReportModalTitle onClose={onClose} code={data.code} />}
      visible={show}
      onOk={onClose}
      onCancel={onClose}
    >
      <Box
        className={`rating-modal rating-modal--${data.grade.value}`}
        type="content"
      >
        <>
          <span className="rating-modal__stripe" />
          <div className="rating-modal__content">
            <div className="result">
              <Result status={data.grade.value} />
              <div className="result__description">
                Twoja ocena: <span>{data.grade.name}</span>
                <p>{data.description}</p>
              </div>
            </div>

            <div className="mark">
              <span className="mark__number">{data.mark.value}</span>
              <p className="mark__description">{data.mark.description}</p>
            </div>

            {data.code === CodeEnum.IZ ? (
              <div className="additional-information">
                <small>
                  <b>* W skład sumy miesięcznych obciążeń</b> wchodzą kredyty,
                  otwarte limity i karty kredytowe.{' '}
                </small>
              </div>
            ) : null}
          </div>
          {data.code === CodeEnum.IZ ? (
            <ul className="rating-modal__delay">
              <li className="good">0-50% - dobry</li>
              <li className="medium">50%-80% - średni</li>
              <li className="bad">powyżej 80% - wysoki</li>
            </ul>
          ) : null}

          <div className="rating-modal__descriptions-mobile">
            {data.code === CodeEnum.IZ ? (
              <small>
                * W skład sumy miesięcznych obciążeń wchodzą kredyty, otwarte
                limity i karty kredytowe.
              </small>
            ) : null}
          </div>

          {data.code === CodeEnum.IZ ? (
            <ul className="rating-modal__inline-list">
              {data.averageInflowCleared && (
                <li>
                  Średniomiesięczny przychód za 12msc:{' '}
                  <b>{data.averageInflowCleared}</b>
                </li>
              )}
              {data.currentDebt && (
                <li>
                  Miesięczne obciążenie kredytowe:{' '}
                  <b>{formatPrice(data.currentDebt)} PLN</b>
                </li>
              )}
              {data.accounts && (
                <li>
                  Liczba produktów: <b>{data.accounts}</b>
                </li>
              )}
            </ul>
          ) : null}

          {data.code === CodeEnum.IT && (
            <ul className="rating-modal__delay">
              <li className="good">opóźnienie od 4 do 10 dni</li>
              <li className="medium">opóźnienie do 30 dni</li>
              <li className="bad">opóźnienie powyżej 30 dni</li>
            </ul>
          )}

          <ul className="rating-modal__inline-list">
            {data.code === CodeEnum.IB && data.sum ? (
              <li>
                Suma zaległości: <b>{data.sum}</b>
              </li>
            ) : null}
          </ul>

          {!!data?.recommendations?.length ? (
            <>
              <h4>Rekomendacje</h4>
              {data.recommendations.map((recommendation) => (
                <div key={recommendation.title} className="rating-modal__info">
                  {data.grade.value === GradeValueEnum['Niska'] ? (
                    <InfoCircleOutlined />
                  ) : (
                    <BulbOutlined />
                  )}
                  <div>
                    <h5>{recommendation.title}</h5>
                    <p>{recommendation.description}</p>
                  </div>
                </div>
              ))}
            </>
          ) : null}
          {!!data.obligations?.length && (
            <>
              <h4>{data.code && CodeTableHeadingEnum[data.code]}</h4>

              <ObligationTable
                data={data.obligations}
                code={data.code}
                grade={data.grade.value}
              />

              <ObligationArray
                data={data.obligations}
                code={data.code}
                grade={data.grade.value}
              />
            </>
          )}
        </>
      </Box>
    </Modal>
  );
};

export default RatingModal;
