import moment from 'moment';
import { App } from 'js/interfaces/account';

let apps: { [id: number]: App } = {};

export const clearApplications = () => {
  apps = {};
};

type ApplicationData = Partial<Omit<App, 'userId' | 'id'>> &
  Pick<App, 'userId'>;

function create(data: ApplicationData) {
  const id = Object.keys(apps).length + 1;

  apps[id] = {
    id,
    consents: [],
    createdAt: moment().format('DD-MM-YYYY'),
    hasKontomatik: false,
    hasPendingReport: false,
    hasReport: false,
    token: null,
    ...data,
  };

  return apps[id];
}

function read(id: number) {
  if (validate(id)) return apps[id];
  return null;
}

function findFirst(key: keyof App, value: string | number | boolean) {
  return Object.values(apps).find((el) => {
    if (!Object.keys(el).includes(key)) return false;
    return el[key] === value;
  });
}

function getLastId() {
  return Object.keys(apps).length;
}

function update(appId: number, updates: Partial<App>) {
  if (!validate(appId)) return null;

  Object.assign(apps[appId], updates);
  return read(appId);
}

function generateToken(appId: number) {
  if (!validate(appId)) return null;
  return update(appId, { token: randomToken() });
}

function getByUserId(userId: number) {
  return Object.values(apps).filter((app) => app.userId === userId);
}

const randomToken = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

function validate(appId: number) {
  if (!Object.keys(apps).includes(`${appId}`)) return false;
  return true;
}

export {
  create,
  findFirst,
  update,
  generateToken,
  read,
  getLastId,
  getByUserId,
};
